.new-investment-instruction {
    .ycof-amount-field__input {
      margin-top: 4px !important;
    }
    .ycof-amount-field__input:focus {
      border-color: $sb-blue-link !important;
      font-weight: bold !important;
      color:  rgba(0, 0, 0, 0.86) !important;
      opacity: 1 !important;
    }

    .invest-more__radio {
        [type="radio"]:checked + label {
            border-color: $sb-blue-link !important;
            border-color: var(--sb-blue-link) !important;
            background-color: $sb-ui-blue-light-2 !important;
            background-color: var(--sb-ui-blue-light-2) !important;
            color: $sb-black-86 !important;
            color: var(--sb-black-86) !important;
        }

        [type="radio"]:not(:checked) + label {
            border-color: $sb-opacity-5 !important;
            border-color: var(--sb-opacity-5) !important;
            background-color: $sb-white !important;
            background-color: var(--sb-white) !important;
            color: $sb-btn-box-shadow !important;
            color: var(--sb-btn-box-shadow) !important;
        }
    }

    .test-investment-amount {
        .sb-words {
            color: $sb-white !important;
            color: var(--sb-white) !important;
        }
    }
      
    .new-investment-instruction-datepicker {
      .input-field {
        margin-top: 0 !important;
      }
    }
  
    .DayPicker > div:first-child {
      margin-left: -21px !important;
    }
    
    .Collapsible__trigger.is-disabled {
      background: #EEF9EC !important;
    }
  
    .sb-btn-normal.sb-btn-secondary:active, :focus {
      border: 1px solid rgba(0, 0, 0, 0.5) !important;
    }
  
    .sb-btn-normal.sb-btn-primary:focus {
      border: 1px solid transparent !important;
    }
  }
  
  .sip-renewal-action-tile {
    background-color: $custom-color-106;
    background-color: var(--custom-color-106);
  }
  