.withdrawal-accordion {
  .withdrawal-accordion-body {
    background: $sb-white;
    background: var(--sb-white);
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    margin: auto 10px;
    border-radius: 8px;
    border: 0.5px solid $custom-color-113;
    border: 0.5px solid var(--custom-color-113);

    .withdrawal-accordion-item {
      padding: 10px 6px;
      border-bottom: 2px solid $sb-light-gray;
      border-bottom: 2px solid var(--sb-light-gray);
    }
  }

  .withdraw-accordion-button {
    color: $sb-blue-link;
    color: var(--sb-blue-link);
    margin: 5px 10px;
    padding: 5px auto;
    cursor: pointer;
  }
}
