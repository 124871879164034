.error-card-containers {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .error-card-containers {
    flex-direction: column;
  }

  .error-card-container {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .error-card-containers {
    flex-direction: row;
  }

  .error-card-container {
    width: 33%;
  }
}
