.public-mf-page-container {
  .content-container.search-container {
    width: 100%;

    .search-box {
      margin-top: 0;
    }
  }

  .hide-on-mobile-device.desktop-top-nav {
    text-align: center;
  }
}

.search-container {
  .search-box {
    display: inline-flex;
    width: 100%;
    height: 50px;
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
    border-radius: 50px;
    padding-left: 16px;

    .search-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      color: $sb-black-50;
      color: var(--sb-black-50);
    }

    .search-input {
      border: none;
      padding-left: 10px;
      margin: auto;
      font-size: 14px;
      color: $sb-black-85;
      color: var(--sb-black-85);

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }

    .search-input::placeholder {
      color: $sb-black-60;
      color: var(--sb-black-60);
    }

    img {
      width: 16px;
    }
  }

  .scard-container {
    padding: 20px;
    border-radius: 5px;
  }

  .hint-for-fund-search {
    padding-left: 40px;
    width: 94%;

    ul li {
      list-style-type: disc;
      padding: 8px 0;
    }
  }

  .separator {
    border: 1px solid $custom-color-113;
    border: 1px solid var(--custom-color-113);
    margin: 3px 0;
  }

  .scripbox-recommended-card {
    background: $sb-orange;
    background: var(--sb-orange);
    position: absolute;
    width: 4px;
    height: 100%;
    border-radius: 5px 0 0 5px;
    left: 1px;
  }

  .scard-title {
    font-size: 18px;
  }

  .recommendation {
    color: $custom-color-54;
    color: var(--custom-color-54);
    font-size: 16px;

    img {
      width: 11.79px;
      height: 11.73px;
    }
  }

  .fund-image-container {
    border: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .search-container {
    .remove-desktop-padding {
      padding: 0;
    }

    .recommendation,
    .scard-title,
    .separator-spacing {
      padding-left: 5px;
    }
  }

  .public-mf-page-container {
    .top50 {
      top: 0% !important;
    }

    .back-button-container {
      flex: 1 1 0%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      max-width: 600px;
      min-width: 400px;
      width: 100%;
      margin: auto;
      position: relative;
    }

    .app-bar__back-button {
      flex: 0 1 0%;
      position: absolute;
      top: 9px;
      left: 0;
    }

    .public-search-loader {
      position: absolute;
      left: 50%;
      top: 150px;
    }

    .app-header {
      flex: 1;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}

@media only screen and (max-width: 993px) {
  .public-mf-page-container {
    .public-mf-page-content-container,
    .content-container.search-container {
      max-width: 600px;
    }

    .public-mf-page-content-container {
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 991px) {
  .public-mf-page-container {
    .back-button-container {
      flex: 1 1 0%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin: auto;
      position: relative;
      margin-top: 16px;
      padding-bottom: 16px;
    }

    .app-bar__back-button {
      flex: 0 1 0%;
      position: absolute;
      // top: 9px;
      left: 0;
    }

    .public-search-loader {
      position: absolute;
      left: 50%;
      top: -100px;
    }

    .app-header {
      flex: 1;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
  }

  .search-container {
    margin: 0 8px;

    .search-box {
      height: 50px;
      margin-top: 20px;

      .search-input::placeholder {
        font-size: 14px;
        font-weight: normal;
        color: $sb-black-60;
        color: var(--sb-black-60);
        line-height: 18px;
        display: block;
        text-align: inherit;
        font-style: normal;
        letter-spacing: 0;
      }
    }

    .hint-for-fund-search {
      font-size: 16px;
    }

    .padding-left-6 {
      padding-left: 6px !important;
    }

    .scard-title {
      font-size: 16px;
    }

    .recommendation {
      font-size: 12px;
    }

    .scard-container {
      padding: 10px;
    }

    .fund-image-container {
      height: 20px;
      width: 22px;
    }
  }
}

.search-fund-wizard {
  margin-top: 60px;

  .search-container {
    .search-box {
      height: 56px;
      border: 1px solid $sb-opacity-5;
      border: 1px solid var(--sb-opacity-5);
      border-radius: 5px;
      padding-left: 16px;

      .search-input {
        border: none;
        padding-left: 10px;
        margin: auto;
        font-size: 16px;
        color: $sb-black-85;
        color: var(--sb-black-85);

        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }

      .search-input::placeholder {
        color: $custom-color-11;
        color: var(--custom-color-11);
      }

      img {
        width: 16px;
      }
    }

    .scard-container {
      padding: 20px;
      border-radius: 5px;
    }

    .hint-for-fund-search {
      padding-left: 20px;
      width: 80%;

      ul {
        margin: 0;

        li {
          list-style-type: disc;
          padding: 8px 0;
        }
      }
    }

    .separator {
      border: 1px solid $custom-color-113;
      border: 1px solid var(--custom-color-113);
      margin: 3px 0;
    }

    .scripbox-recommended-card {
      background: $sb-orange;
      background: var(--sb-orange);
      position: absolute;
      width: 4px;
      height: 100%;
      border-radius: 5px 0 0 5px;
      left: 1px;
    }

    .scard-title {
      font-size: 18px;
    }

    .recommendation {
      color: $custom-color-54;
      color: var(--custom-color-54);
      font-size: 16px;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .fund-image-container {
      border: none !important;
    }
  }

  .filter-btn {
    height: 56px;
    width: 56px;
    border-radius: 8px;
    background-color: $sb-white;
    background-color: var(--sb-white);
    border: 1px solid $sb-opacity-5;
    border: 1px solid var(--sb-opacity-5);
  }

  [type=checkbox].filled-in:not(:checked) + label::after,
  [type=checkbox].filled-in:checked + label::after {
    height: 22px;
    width: 22px;
  }

  [type=checkbox].filled-in:checked + label::after {
    border: 1px solid $sb-blue-link;
    border: 1px solid var(--sb-blue-link);
    background-color: $sb-blue-link;
    background-color: var(--sb-blue-link);
  }

  [type=checkbox].filled-in:checked + label::before {
    left: 2px;
    width: 8px;
    height: 14px;
  }

  .filter-count {
    background-color: $sb-orange;
    background-color: var(--sb-orange);
    width: 20px;
    height: 20px;
    padding: 2.5px;
    border-radius: 50%;
    font-size: 10px;
    color: $sb-white;
    color: var(--sb-white);
    position: absolute;
    top: 8px;
    right: 4px;
  }

  .filter-label {
    display: flex !important;
    align-items: center;

    img {
      width: 20px;
      height: 15px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .search-fund-wizard {
    margin-top: 20px;
  }
}
