.mobile-help-desk-list {
  height: 100%;
  display: flex;
  flex-direction: column;

  .item-container {
    flex: 1;
    justify-content: flex-start;
    padding-left: 33%;
    align-items: center;
    display: flex;
    height: 65px;
  }

  .text {
    padding-left: 10px;
    font-size: 14px;
  }
}
