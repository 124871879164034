.strategy-desc-cont {
    border-radius: 5px;
    border: 1px solid $sb-grey-88;
}

.grey-border {
    border: 1px solid $sb-grey-88;
}

.new-sip-blue-container {
    background: $sb-ui-blue-light-2;
    border-radius: 5px;

    .selected-growth {
        background-color: $sb-white;
    }
}

.amc-logo-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $custom-color-13;
    border: 1px solid var(--custom-color-13);
}

.existing-sip-accordion-item {
    border: 1px solid $sb-light-gray;
}

.asset-allocation-activation.custom-amount-container {
    margin-left: -10px;

    #growth-investment-amount {
        background-color: $sb-white;
    }
}

.asset-allocation-activation-date-duration {
    .input-field {
        padding-left: 0;

        label {
            margin-left: -12px;
        }
    }
}

.scripbox-recommended-flag {
    background-color: $custom-color-106;
    border-radius: 10px;
    width: 106px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
        width: 9px;
        height: 9px;
    }
}

.pick-allocation-modal-content {
    width: 100%;
    border-radius: 5px;
    // margin-bottom: 32px;
    .modal-close-icon{
        right: 24px;
        top:24px;
        i{
            font-size: 24px;
        }
    }
    .modal-title{
        top: 24px;
    }
    .modal-header{
        padding-top: 4px !important;
        top: 24px;
    }
    @media only screen and (max-width: 560px) {
        margin-bottom: 0;
    }
    .risk-color-circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 560px) {
    .asset-allocation-activation {
        .modal-content-div {
            top: 0;
        }
    }
}

.allocation-strategy-button{
    height: 80px;
}

.asset-allocation-activation {
    .plan-icon {
        width: 48px;
    }

    .banner-warning {
        div:nth-child(2) {
            flex-basis: 90%;
        }
    }

    .modal-back-button {
        color: $sb-menu-icon;
    }

    .modal-dialog-div .modal-close-icon {
        top: 24px;
        right: 24px;
    }

    .allocation-status-nudge-card {
        max-width: 280px;
    }

    .perf-inflation-graph {
        div:nth-child(2),
        .highcharts-container {
            overflow: visible !important;
        }
    }

    .allocation-name-badge {
        width: fit-content;
        &.primary {
            background-color: $offeringTaxSaver;
        }

        &.secondary {
            background-color: $sb-white;
        }
    }

    .risk-color-circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }

    .something-missing-link {
        align-self: flex-end;
    }

    .allocation-tabs {
        &:hover {
            border: 1px solid $sb-blue-link;
            background-color: $sb-ui-blue-light-2;
            color: rgba(255, 255, 255, 0.5);
        }

        &.active {
            border: 1px solid $sb-blue-link;
            background-color: $sb-ui-blue-light-2;
        }
        &.inactive {
            border: 1px solid $sb-opacity-5;
        }
    }

    .allocation-status {
        .allocation-header {
            height: 175px;
            background: $sb-ui-blue-light-2;
            border-radius: 5px;
        }

        .status-section {
            border: 1px solid $sb-grey-88;
            border-radius: 5px;
        }

        .section-width {
            width: 620px;
        }

        .first-section {
            width: 70%;
        }

        .image-section {
            width: 30%;
        }
    }

    .investment-success {
        .horizontal-divider {
            height: 45px;
            width: 1px;
            border: 1px solid $sb-grey-88;
        }
    }

    .ycof-amount-field__input {
        margin-top: 4px !important;
    }

    .ycof-amount-field__input:focus {
        border-color: $sb-blue-link !important;
        font-weight: bold !important;
        color: rgba(0, 0, 0, 0.86) !important;
        opacity: 1 !important;
    }

    .sip-renewal-extension-datepicker {
        .input-field {
            margin-top: 0 !important;
        }
    }

    .DayPicker > div:first-child {
        margin-left: -21px !important;
    }

    .view-more-modal {
        &.modal-dialog-div {
            max-width: 528px;
        }
    }

    .compare-allocations {
        .row .col {
            padding: 0;
        }
    }
}

.risk-profile-results-modal {
    .modal-dialog-div {

        .risk-profile-modal-actions {
            margin-top: 24px;
        }

        &.desktop-width {
            max-width: 714px;
        }

        &.mobile-width {
            width: 100%;
            max-width: 100%;
        }
    }
}
.select-asset-allocation{
    .slist-container{
        border-radius: 5px;
    }
}
.custom-allocation-input{
    border-radius: 5px;
    height: 65px;
    flex: 1;
    border: 1px solid $sb-grey-88;
    border: 1px solid var(--sb-grey-88);
    .label-container{
        height: inherit;
        display: flex;
        align-items: center;
        border-radius: 5px;
        .separator-with-color{
            width: 20px;
            height: inherit;
            content: ' ';
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        } 
    }
    .input-container{
        height: inherit;
        width: 125px;
        input{
            &[type=text] {
                text-align:right;
                padding-right: 5px;
            }
        }  
    }
    &.add-height{
        height: 85px;
    }
}
.custom-height{
    // min-height: 793px;
    max-height: 793px;
}

  [type=checkbox].filled-in:checked + label::after {
    border: 1px solid $sb-blue-link;
    border: 1px solid var(--sb-blue-link);
    background-color: $sb-blue-link;
    background-color: var(--sb-blue-link);
  }