.help-desk {
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: 30px;

  .list {
    position: relative;
  }

  .help-desk-overlay {
    position: absolute;
    width: 2750px;
    height: 1000px;
    right: -350px;
    top: -60px;
  }

  .list-item {
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .text {
    font-size: 14px;
    padding-left: 10px;
  }

  .no-cursor {
    cursor: default !important;
  }

  .image-container {
    display: flex;
    align-items: center;
  }
}
