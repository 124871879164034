.add-delegation-button {
    margin-top: 69px;
    text-align: center;
    margin-bottom: 29px;
}

.loader-container {
    width: 25rem;
    height: 25rem;
}

.close-modal {
    text-align: center;
    margin-top: 130px;
    margin-bottom: 26px;
}

.success-message-header {
    padding: 30px;
    text-align: center;
}

.success-message-container {
    text-align: center;
    .message {
        display: flex;
        justify-content: center;
    }
}