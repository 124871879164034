.order-container {
  padding: 0;
  border-top: 2px solid $custom-color-72;
  border-top: 2px solid var(--custom-color-72);
}

.buy-order-button {
  padding: 5px 0 !important;
  font-size: $font-14;
  color: $sb-white;
  color: var(--sb-white);
  background-color: $sb-primary-btn-default;
  background-color: var(--sb-primary-btn-default);
  border: 1px solid $sb-primary-btn-default;
  border: 1px solid var(--sb-primary-btn-default);
  border-left: 2px solid $custom-color-72;
  border-left: 2px solid var(--custom-color-72);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 8px;

  &:hover {
    background-color: $sb-primary-btn-hover;
    background-color: var(--sb-primary-btn-hover);
  }
}

.sell-order-button {
  padding: 6px 0 !important;
  font-size: $font-14;
  color: $sb-secondary-default-color;
  color: var(--sb-secondary-default-color);
  background-color: $sb-white;
  background-color: var(--sb-white);
  border: none;
  border-bottom-left-radius: 8px;

  &:hover {
    background-color: $sb-light-gray;
    background-color: var(--sb-light-gray);
  }
}

.suggestion-box {
  position: absolute;
  top: 38px;
  border: 2px solid $custom-color-72;
  border: 2px solid var(--custom-color-72);
  padding: 10px 0 5px 0 !important;
  border-radius: 0 0 8px 8px;
  z-index: 9999;
  background-color: $sb-white;
  background-color: var(--sb-white);
  overflow: auto;
  max-height: 300px;
}

.suggestion-item {
  cursor: pointer;
  margin-bottom: 0 !important;
  padding: 5px 0 !important;

  &:hover {
    background-color: $sb-light-gray;
    background-color: var(--sb-light-gray);
  }
}
