@import "../utils/all";

.withdrawal-details {
  .withdrawals-asset-class {
    .withdrawals-asset-class-name {
      font-size: $font-16;
      font-weight: 600;
      padding: 10px 20px 0 20px;
    }

    .withdrawal-item {
      .fund-image-wrapper {
        max-width: 25px;
      }

      .withdrawal-item-image {
        padding: 3px;
        max-width: 25px;
      }

      .withdrawal-item-fund-name {
        font-size: $font-14;
        color: $card-layout-primary-text;
        color: var(--card-layout-primary-text);
        padding-left: 10px;
      }

      .investment-summary {
        margin-top: 10px;
        margin-bottom: 0;
      }

      .parameter-group {
        text-align: right;
      }
    }
  }
}

@media (max-width: 961px) {
  .withdrawal-item {
    border-bottom: 1px solid $sb-secondary-default-border;
    border-bottom: 1px solid var(--sb-secondary-default-border);
  }
}
