.scard-header-container {
  background: $sb-white;
  background: var(--sb-white);
  box-shadow: -2px 2px 13px 0 $custom-color-142;
  box-shadow: -2px 2px 13px 0 var(--custom-color-142);
  border-radius: 8px;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  padding: 10px 6px;
  line-height: 1.2;
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);

  .row {
    margin-bottom: 0;
  }
}

.curved-shadow {
  box-shadow: -2px 9px 13px 0 $custom-color-142;
  box-shadow: -2px 9px 13px 0 var(--custom-color-142);
  width: 120%;
  height: 100px;
  border-radius: 0 0 50% 50%;
  position: relative;
  top: -30px;
  z-index: -1;
}

.scard-container-mobile {
  background: $sb-white;
  background: var(--sb-white);
  border-radius: 5px;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  margin: 8px;
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);

    &.focused {
      border: 1px solid $custom-color-112;
      border: 1px solid var(--custom-color-112);
    }

    &.hover-pointer {
      cursor: pointer;
    }
}

.scard-container {
  background: $sb-white;
  background: var(--sb-white);
  border-radius: 5px;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  padding: 10px 6px;
  margin: 10px 10px 0;
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);

  &.focused {
    border: 1px solid $custom-color-112;
    border: 1px solid var(--custom-color-112);
  }

  &.hover-pointer {
    cursor: pointer;
  }

  &.disable-list-card {
    cursor: not-allowed;
    background: $sb-grey-88;
    background: var(--sb-grey-88);

    .scard-title,
    .scard-description {
      color: $custom-color-36;
      color: var(--custom-color-36);
    }
  }

  .row {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  &.smenu-card {
    padding: 22px 6px;
  }

  .scard-recommended-wrapper {
    position: relative;
  }

  .scard-recommended-label {
    position: absolute;
    right: -25px;
    background: $custom-color-85;
    background: var(--custom-color-85);
    padding: 2px 5px 1px 5px;
    font-size: $font-10;
    color: $sb-white;
    color: var(--sb-white);
    float: right;
    border-radius: 2px;
  }

  &.full-width-slist-card {
    box-shadow: none;
    border-radius: 0;
    border: 0;
  }

  &.dark-border {
    border: 1px solid $custom-color-118;
    border: 1px solid var(--custom-color-118);
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.no-border {
    border: none;
  }
}

.disabled-card-wrapper {
  cursor: not-allowed;

  .scard-container {
    background: $sb-white;
    background: var(--sb-white);
  }

  .payment-type-description,
  .scard-title {
    color: $custom-color-36;
    color: var(--custom-color-36);
  }
}

.scard-title {
  font-size: $font-16;
  font-weight: 600;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

.input-field label.scard-title {
  font-size: $font-16;
  font-weight: 600;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

.scard-description {
  font-size: $font-14;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
}

.card-investments-title {
  font-size: $font-16;
  font-weight: 600;
  margin: 16px 10px 0 10px;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

.scard-divider {
  border-bottom: 1px solid $custom-color-42;
  border-bottom: 1px solid var(--custom-color-42);
  margin: 10px 0;
}

.scard-cta-button {
  background: $sb-primary-btn-default;
  background: var(--sb-primary-btn-default);
  color: $sb-white;
  color: var(--sb-white);
  font-size: $font-16;
  font-weight: 600;
  padding: 6px;
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-top: 6px;

  &.btn-disabled {
    color: $sb-white;
    color: var(--sb-white);
    cursor: not-allowed;
    background: $sb-primary-btn-disabled;
    background: var(--sb-primary-btn-disabled);
  }

  &:focus {
    background: $sb-primary-btn-focus;
    background: var(--sb-primary-btn-focus);
  }
}

.withdraw-button {
  background: $sb-primary-btn-default;
  background: var(--sb-primary-btn-default);
  color: $sb-white;
  color: var(--sb-white);
  font-size: $font-16;
  font-weight: 600;
  padding: 8px;
  display: inline-block;
  width: 25%;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-top: 6px;
  text-transform: uppercase;
}

.withdraw-cancel-button {
  background: $sb-white;
  background: var(--sb-white);
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  font-size: $font-16;
  font-weight: 600;
  padding: 8px;
  display: inline-block;
  width: 25%;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-top: 6px;
  text-transform: uppercase;
}

@media only screen and (max-width: 992px) {
  .withdraw-button {
    background: $sb-primary-btn-default;
    background: var(--sb-primary-btn-default);
    color: $sb-white;
    color: var(--sb-white);
    font-size: $font-16;
    font-weight: 600;
    padding: 8px;
    display: block;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-top: 6px;
    text-transform: uppercase;
  }

  .withdraw-cancel-button {
    background: $sb-white;
    background: var(--sb-white);
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
    font-size: $font-16;
    font-weight: 600;
    padding: 8px;
    display: block;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-top: 6px;
    text-transform: uppercase;
  }
}

.border-right-dark {
  border-right: 1px solid $custom-color-118;
  border-right: 1px solid var(--custom-color-118);

  &:last-of-type {
    border-right: none;
  }
}

.sb-gradient-card {
  background-image: url('../images/dashboard/profile-card-background.png');
  padding: 30px 10px 12px 10px;
  background-size: cover;
  background-position: center;
}

.snotifcation-card {
  padding: 10px;
  border-left: 6px solid;
  background: $sb-white;
  background: var(--sb-white);
  margin: 10px 0;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);

  p {
    margin: 0;
  }

  &.success {
    border-color: $sb-green;
    border-color: var(--sb-green);
    background-color: $success-bg-color;
    background-color: var(--success-bg-color);
  }

  &.error {
    border-color: $error-color;
    border-color: var(--error-color);
    background-color: $error-bg-color;
    background-color: var(--error-bg-color);
  }

  &.info {
    border-color: $custom-color-82;
    border-color: var(--custom-color-82);
    background-color: $custom-color-83;
    background-color: var(--custom-color-83);
  }
}

.scard-fund-title {
  font-size: $font-20;
  font-weight: 600;
}

a.card-on-click-link {
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);

  &:focus,
  &:active,
  &:hover,
  &.filter-btn-selected {
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
  }
}
