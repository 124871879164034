@import "../../../styles/utils/all";
@import "../../../styles/utils/colors";

.bottom-tab-navigator-container {
  background-color: $sb-white;
  background-color: var(--sb-white);
  bottom: 0;
  box-shadow: 0 -1px 4px $sb-black-10;
  box-shadow: 0 -1px 4px var(--sb-black-10);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 30;
}

.bottom-tab-navigator-sub-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}

.bottom-tab-navigator-label {
  font-size: $font-14;
  font-weight: normal;
  margin-bottom: 12px;
  margin-top: -4px;
  padding-left: 13px;
  padding-right: 13px;
}

.bottom-tab-navigator-item-active {
  filter: invert(60%) sepia(83%) saturate(2302%) hue-rotate(345deg)
    brightness(99%) contrast(94%);
}

@media only screen and (min-width: 993px) {
  .bottom-tab-navigator-container {
    display: none;
  }
}
