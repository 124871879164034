.square-fund-type-card-container {
  position: relative;
  min-height: 190px;

  &.scard-container {
    border: 0.5px solid $sb-card-border;
    border: 0.5px solid var(--sb-card-border);
  }

  .icon-image {
    height: 35px;
  }

  .description-container {
    padding: 15px 10px 0 10px;
  }

  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .sub-title {
    font-size: 14px;
    text-align: center;
    color: $sb-black-60;
    color: var(--sb-black-60);
  }

  .badge-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .badge {
    position: absolute;
    color: $sb-white;
    color: var(--sb-white);
    background: $sb-badge-green;
    background: var(--sb-badge-green);
    padding: 0 10px;
    border-radius: 5px;
    font-size: 12px;
  }

  .number-offering {
    font-size: 12px;
    text-align: center;
    color: $sb-badge-green;
    color: var(--sb-badge-green);
    margin-top: 3px;
  }

  .type-bg {
    border-radius: 8px;
    padding: 22px;
    text-align: center;
    background: $offeringEmergency;
    background: var(--offeringEmergency);

    &.type-tax_filing-bg {
      padding: 15px;
    }
  }

  .type-equity-bg {
    background: $offeringLongTerm;
    background: var(--offeringLongTerm);
  }

  .type-principal_protection-bg {
    background: $offeringPrincipalProtection;
    background: var(--offeringPrincipalProtection);
  }

  .type-debt-bg {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  .type-lifestyle_goals-bg {
    background: $offeringDreamPlanner;
    background: var(--offeringDreamPlanner);
  }

  .type-elss-bg,
  .type-tax_filing-bg {
    background: $offeringTaxSaver;
    background: var(--offeringTaxSaver);
  }

  .type-liquid-bg {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  .type-overseas_education-bg {
    background: $offeringEducation;
  }

  .type-indian_education-bg {
    background: $offeringEducation;
  }

  .type-independent_income-bg {
    background: $offeringRetireConfident;
    background: var(--offeringRetireConfident);
  }

  .type-financial_freedom-bg {
    background: $offeringRetireConfident;
    background: var(--offeringRetireConfident);
  }

  .type-wealth_accelerator-bg {
    background: $offeringWealthEdgePlan;
    background: var(--offeringWealthEdgePlan);
  }

  .type-secure_growth-bg {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  .type-crorepati-bg {
    background: $offeringMyFirstCrore;
    background: var(--offeringMyFirstCrore);
  }

  .type-child_crorepati-bg {
    background: $offeringChildHeadstart;
    background: var(--offeringChildHeadstart);
  }

  .type-emergency_fund-bg {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  .type-wealth_edge-bg {
    background: $offeringWealthEdgePlan;
    background: var(--offeringWealthEdgePlan);
  }

  .type-long_term_wealth_us-bg {
    background: $offeringLongTerm;
    background: var(--offeringLongTerm);
  }

  .type-short_term_money_us-bg {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  .type-emergency_fund_us-bg {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  .type-tax_saver_plan_us-bg {
    background: $offeringTaxSaver;
    background: var(--offeringTaxSaver);
  }

  .type-principal_protection_us-bg {
    background: $offeringPrincipalProtection;
    background: var(--offeringPrincipalProtection);
  }
}
