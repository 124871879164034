@media only screen and (max-width: 992px) {
  .purpose-details {
    padding-left: 16px;
    padding-right: 16px;
  }

  .recommended-funds.recommended-funds-container{
    padding: 0 !important;
  }

  .custom-allocation-screen {
    padding-left: 0 !important;
  }

  .purpose-details .footer{
    padding-top: 32px;
  }
}