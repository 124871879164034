.login {
  padding: 10px;
}

.login-info-container {
  padding: 0 10px;
}

.error-message {
  color: $sb-red-error;
  color: var(--sb-red-error);
}

.username-icon,
.password-icon {
  padding: 6px;
  color: $sb-black;
  color: var(--sb-black);
  height: 3rem;
  text-align: center;
  vertical-align: middle;
}

.scripmark-icon {
  height: 50px;
  vertical-align: middle;
  margin-top: 30px;
}

.email-suggestion {
  padding: 5px 0 5px 11px;
  font-size: $font-14;

  &.signup-email-suggestion {
    padding: 0;
  }
}

.sb-login-avatar {
  max-width: 150px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  display: inline-block;
  background-color: $sb-menu-icon;
  background-color: var(--sb-menu-icon);
  text-align: center;
  color: $sb-white;
  color: var(--sb-white);
  border: 2px solid $sb-white;
  border: 2px solid var(--sb-white);
}

.login-welcome-loading {
  margin-top: 20px;
}

.login-welcome-msg {
  color: $sb-menu-gray;
  color: var(--sb-menu-gray);
  font-size: $font-12;
}

.login-welcome-forgot-msg {
  margin-top: 20px;
}
