.landing-card {
  border-radius: 15px;
  background: $custom-color-55;
  background: var(--custom-color-55);
  box-shadow: 0 5px 14px 0 $sb-black-11;
  box-shadow: 0 5px 14px 0 var(--sb-black-11);
  padding: 20px;
  margin: 20px 0;

  .landing-card-title {
    font-size: $font-32;
    color: $sb-white;
    color: var(--sb-white);
    letter-spacing: 0.5px;
    line-height: 38px;
  }

  .landing-card-image {
    margin: 30px auto 10px auto;
    text-align: center;
  }
}

.landing-sub-title {
  font-size: $font-18;
  margin: 10px 0;
}

.survey-title {
  font-size: $font-18;
  font-weight: 600;
  margin: 10px 0;
}

.landing-info-border {
  border-left: 1px solid $sb-gray;
  border-left: 1px solid var(--sb-gray);
}

.fund-landing-info-container {
  p {
    margin: 0 0 10px 0;
  }
}

.landing-section-card {
  text-align: center;
  font-size: $font-18;

  .landing-section-title {
    font-weight: 600;
  }
}
