.nudge-card-container {
  border-radius: 8px;
  color: $sb-white;
  color: var(--sb-white);
  margin: 10px;
  position: relative;

  &.scard-container {
    border: 0.5px solid $sb-card-border;
    border: 0.5px solid var(--sb-card-border);
  }

  p {
    margin: 0;
  }

  .title {
    font-size: $font-20;
  }

  .description {
    font-size: $font-16;
  }

  .recommendation-ribbon {
    background: $sb-white;
    background: var(--sb-white);
    display: inline-block;
    color: $sb-black;
    color: var(--sb-black);
    font-size: $font-12;
    padding: 2px 6px;
    position: relative;
    margin-top: 10px;
    left: -6px;

    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      top: 100%;
      left: 0;
      border-top: 6px solid $custom-color-119;
      border-top: 6px solid var(--custom-color-119);
      border-left: 6px solid transparent;
    }
  }

  .nudge-close-wrapper {
    position: relative;
  }

  .nudge-close-icon {
    position: absolute;
    padding: 5px;
    right: 0;
    top: 0;
    font-weight: 200;
    font-size: 20px;
    opacity: 0.4;
    color: $sb-black;
    color: var(--sb-black);
    cursor: pointer;
  }

  .nudge-img {
    padding-bottom: 0;
    width: 60px;
  }

  .nudge-content-wrapper {
    padding: 15px 15px 15px 5px;
    justify-content: center;
    align-items: center;
  }

  &.type-bg {
    border-radius: 8px;
    background: $offeringEmergency;
    background: var(--offeringEmergency);
    min-height: 100px;
  }

  &.type-equity-bg {
    background: $offeringLongTerm;
    background: var(--offeringLongTerm);
  }

  &.type-principal_protection-bg {
    background: $offeringPrincipalProtection;
    background: var(--offeringPrincipalProtection);
  }

  &.type-debt-bg {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-lifestyle_goals-bg {
    background: $offeringDreamPlanner;
    background: var(--offeringDreamPlanner);
  }

  &.type-elss-bg {
    background: $offeringTaxSaver;
    background: var(--offeringTaxSaver);
  }

  &.type-liquid-bg {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  &.type-overseas_education-bg {
    background: $offeringEducation;
  }

  &.type-indian_education-bg {
    background: $offeringEducation;
  }

  &.type-independent_income-bg {
    background: $offeringRetireConfident;
    background: var(--offeringRetireConfident);
  }

  &.type-financial_freedom-bg {
    background: $offeringRetireConfident;
    background: var(--offeringRetireConfident);
  }

  &.type-wealth_accelerator-bg {
    background: $offeringWealthEdgePlan;
    background: var(--offeringWealthEdgePlan);
  }

  &.type-secure_growth-bg {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-crorepati-bg {
    background: $offeringMyFirstCrore;
    background: var(--offeringMyFirstCrore);
  }

  &.type-child_crorepati-bg {
    background: $offeringChildHeadstart;
    background: var(--offeringChildHeadstart);
  }

  &.type-emergency_fund-bg {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  &.type-wealth_edge-bg {
    background: $offeringWealthEdgePlan;
    background: var(--offeringWealthEdgePlan);
  }

  &.type-long_term_wealth_us-bg {
    background: $offeringLongTerm;
    background: var(--offeringLongTerm);
  }

  &.type-short_term_money_us-bg {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-emergency_fund_us-bg {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  &.type-tax_saver_plan_us-bg {
    background: $offeringTaxSaver;
    background: var(--offeringTaxSaver);
  }

  &.type-principal_protection_us-bg {
    background: $offeringPrincipalProtection;
    background: var(--offeringPrincipalProtection);
  }
}

.plan-nudge-button-container-v1 {
  padding: 0 15px 15px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .plan-nudge-button-seperator {
    margin-left: 15px;
  }
}
.plan-nudge-button-container {
  padding: 0 15px 15px 15px;

  .plan-nudge-button {
    text-align: center;
    font-size: $font-16;
    font-weight: 500;
    display: block;
    width: 100%;
    color: $sb-white;
    color: var(--sb-white);
    background-color: $sb-primary-btn-default;
    background-color: var(--sb-primary-btn-default);
    border: none;
    padding: 10px;
    border-radius: 8px;

    &:focus,
    &:active {
      background-color: $sb-primary-btn-focus;
      background-color: var(--sb-primary-btn-focus);
    }

    &:hover {
      background-color: $sb-primary-btn-hover;
      background-color: var(--sb-primary-btn-hover);
    }
  }
}

@media only screen and (min-width: 993px) {
  .nudge-card-container {
    margin: 0 0 10px 10px;
  }
}
