.goal-portfolio-graph {
  .sub-asset-class-accordion {
    margin-top: 10px;
  }
}

.sub-asset-class-accordion {
  background-color: $sb-white;
  background-color: var(--sb-white);
  border-radius: 5px;
  border: 1px solid $sb-light-gray;
  border: 1px solid var(--sb-light-gray);

  .fund-type {
    border-bottom: 1px solid $sb-light-gray;
    border-bottom: 1px solid var(--sb-light-gray);

    .fund-type-description {
      padding: 7px 15px;
      margin: 0;
      cursor: pointer;
      font-size: $font-16;
    }

    .fund-type-current-value {
      font-size: $font-14;
    }
  }

  .active {
    background-color: $sb-sidemenu-gray;
    background-color: var(--sb-sidemenu-gray);
    font-weight: 600;
  }

  .arrow {
    color: $custom-color-92;
    color: var(--custom-color-92);

    .material-icons {
      line-height: inherit;
    }
  }
}
