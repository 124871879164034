@import "../../../../styles/utils/colors";

.banner-container{
  padding: 16px;
}
.banner-notification {
  background-color: $sb-white;
  background-color: var(--sb-white);
  border: 1px solid $sb-opacity-5;
  border: 1px solid var(--sb-opacity-5);
}

.banner-info {
  background-color: $offeringTaxSaver;
  background-color: var(--offeringTaxSaver);
  border: 1px solid $custom-color-105;
  border: 1px solid var(--custom-color-105);
}

.banner-warning {
  background-color: $custom-color-106;
  background-color: var(--custom-color-106);
  border: 1px solid $custom-color-107;
  border: 1px solid var(--custom-color-107);
}

.banner-success {
  background-color: $custom-color-108;
  background-color: var(--custom-color-108);
  border: 1px solid $custom-color-109;
  border: 1px solid var(--custom-color-109);
}

.banner-error {
  background-color: $sb-bg-rose;
  background-color: var(--sb-bg-rose);
  border: 1px solid $custom-color-110;
  border: 1px solid var(--custom-color-110);
}

.banner-help {
  background-color: $custom-color-13;
  background-color: var(--custom-color-13);
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);
}

.banner-insights {
  background-color: $offeringTaxSaver;
  background-color: var(--offeringTaxSaver);
}
