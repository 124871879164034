.sb-typography {
  line-height: 140% !important;
}

.sb-typography-v2 {
  line-height: 140%;
}

.inline-typography {
  .sb-typography,
  .sb-typography-v2 {
    display: inline;
  }
}

@media only screen and (max-width: 993px) {
  .sb-page-header {
    font-size: 20px !important;
  }
}
