.referral-page-card {
  background-color: $sb-white;
  background-color: var(--sb-white);
  padding-bottom: 16px;

  .referral-background {
    background-image: url('../images/referral/referral.png');
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 19px;

    .referral-image {
      width: 140px;
      height: 140px;
    }
  }

  .referral-content-container {
    padding-left: 8px;
    padding-right: 8px;

    .referral-title {
      font-size: 16px;
      font-weight: bold;
      margin-top: 12px;
      margin-bottom: 0;
    }

    .referral-desc {
      color: $sb-checklist-gray;
      color: var(--sb-checklist-gray);
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .referral-link {
      margin: 10px auto;
    }

    .copy-link-help-text {
      font-size: 0.9em;
      color: $sb-notication-badge-gray;
      color: var(--sb-notication-badge-gray);
      margin-top: 5px;
    }

    .referral-link-input {
      margin: auto;
      padding: 3px;
      border: solid 1px $custom-color-11;
      border: solid 1px var(--custom-color-11);
      border-right: none;
    }

    .referral-link-input-copied {
      color: $custom-color-16;
      color: var(--custom-color-16);
      border: solid 1px $custom-color-16;
      border: solid 1px var(--custom-color-16);
      border-right: none;
    }

    .referral-link-copy-button {
      height: 36px;
      background: $custom-color-16;
      background: var(--custom-color-16);
      color: $sb-white;
      color: var(--sb-white);
      border: none;
      font-size: 90%;
    }

    .link-copied-text {
      margin: 5px auto;
      font-size: 0.8em;
      text-align: right;
      color: $custom-color-16;
      color: var(--custom-color-16);
      position: absolute;
      right: 0;
      padding-right: 10px;
    }

    .share-icons-row {
      margin-top: 20px;

      .social-image {
        max-height: 35px;
      }

      .social-image-text {
        font-size: 14px;
        color: $sb-charcoal;
        color: var(--sb-charcoal);
      }
    }

    .share-button {
      border-radius: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .share-button-image {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    .separation {
      color: $sb-checklist-gray;
      color: var(--sb-checklist-gray);
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 993px) {
  .referral-page-card {
    margin: 20px;
  }
}
