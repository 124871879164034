.payment-type-selection-container {
  .payment-info-container {
    display: flex;
    padding-bottom: 10px;

    .info-indicator-bullet {
      color: $sb-primary-btn-default;
      color: var(--sb-primary-btn-default);
      padding-right: 6px;
    }
  }

  .payment-type-card {
    padding: $font-18;

    .disabled-payment-card {
      .payment-card-description,
      .payment-card-title {
        color: $sb-black-50;
        color: var(--sb-black-50);
      }
    }
  }

  .payment-card-title {
    font-size: $font-16;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
    font-weight: 600;
    margin-bottom: 4px;
  }

  .payment-card-description {
    font-size: $font-14;
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
    margin-bottom: 20px;
  }
}
