.Collapsible {
  background-color: $sb-white;
  background-color: var(--sb-white);
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid $sb-sip-reallocate-gray;
  border: 1px solid var(--sb-sip-reallocate-gray);
  border-top: 0;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid $sb-sip-reallocate-gray;
  border-bottom: 1px solid var(--sb-sip-reallocate-gray);
  padding: 10px;
  color: $sb-black;
  color: var(--sb-black);
  cursor: pointer;
}

.Collapsible-new {
  border-radius: 5px;
  border: 1px solid $sb-sip-reallocate-gray;
  border: 1px solid var(--sb-sip-reallocate-gray);
  cursor: pointer;
  margin: 10px;
}

.Collapsible-description {
  margin: 0;
  padding: 0 10px 10px 10px;
}

.Collapsible-new__trigger {
  font-weight: 600;
}

.investment-faq {
  .Collapsible-new__trigger {
    font-weight: normal;
    padding: 10px 0 10px 0;
  }
}

.Collapsible__trigger::after {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open::after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  background: $sb-primary-btn-default;
  background: var(--sb-primary-btn-default);
}

.CustomTriggerCSS {
  background-color: $sb-light-coral;
  background-color: var(--sb-light-coral);
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--gray {
  color: $sb-black !important;
  color: var(--sb-black) !important;
  background: $custom-color-17 !important;
  background: var(--custom-color-17) !important;
}

.CustomTriggerCSS--open {
  background-color: $sb-darkslateblue;
  background-color: var(--sb-darkslateblue);
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: $custom-color-48;
  background-color: var(--custom-color-48);
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
}

.offering-fund-list > .Collapsible .Collapsible__contentInner {
  padding: 0;
  border: 0;
}

.offering-fund-list > .Collapsible > .Collapsible__trigger {
  text-align: center;
  background: transparent;
  color: $sb-orange;
  color: var(--sb-orange);
  cursor: pointer;
  border: none;

  > .material-icons.clear_block {
    display: none;
  }
}

.scheme-information,
.top-holdings {
  .Collapsible__trigger {
    font-weight: bold;
    font-size: 16px;
    padding-left: 20px;
  }

  .Collapsible__contentInner {
    border: 0;
    padding: 10px 0;
  }
}

.shortlisted-funds {
  .Collapsible__trigger.is-disabled {
    color: $custom-color-49;
    color: var(--custom-color-49);
    background: $custom-color-50;
    background: var(--custom-color-50);
  }
}
