@media (max-width: 992px) {
  .explore-portfolio-modal {
    .modal-overlay-div > .modal-content-div.center-modal {
      padding-right: 0;
      padding-left: 0;
    }

    .modal-dialog-div .modal-close-icon {
      z-index: 1;
    }
  }
}

.portfolio-explore {
  .pf-top-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    z-index: 1;
    width: 224px;
  }

  .modal-bg-image {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
  }

  .modal-close-icon {
    z-index: 10;
  }

  @media (min-width: 992px) {
    &.desktop-modal {
      min-width: 720px;
      left: 50%;
      right: 50%;
      transform: translate(-50%);
      margin-bottom: 80px;
    }

    .modal-intro {
      display: flex;
      margin-bottom: 100px;

      .description,
      .customer-icon {
        width: 50%;
      }

      .customer-icon {
        padding-left: 32px;

        img {
          display: block;
          margin: auto;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .pf-carousel-container {
      padding-top: 100px;
      padding-bottom: 0;
      height: calc(100vh - 50px);
    }

    .pf-mobile-container {
      padding-top: 40px;
      height: calc(100vh - 50px);
      display: flex;
      align-items: center;
    }

    .modal-intro {
      flex-direction: column-reverse;
      align-items: center;

      .sb-typography-v2.sb-h4 {
        padding: 0 50px;
      }
    }

    .customer-icon {
      margin-bottom: 80px;
    }

    .footer-btn-mobile {
      position: sticky;
      left: 16px;
      bottom: 16px;
      right: 16px;

      .sb-btn {
        width: 100%;
      }
    }

    .carousel-item {
      padding-top: 64px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info-img {
        margin-bottom: 60px;
      }
    }
  }

  @media (max-width: 350px) {
    .customer-icon {
      margin-bottom: 32px;
    }

    .pf-carousel-container,
    .carousel-item {
      padding-top: 54px;
    }

    .footer-btn-mobile {
      padding-top: 0 !important;
    }

    .carousel-item .description {
      padding: 0 8px;
    }
  }

  &.full-screen {
    margin: 0;
    height: 100%;
    overflow: auto;
  }

  .health-insights {
    flex-direction: row-reverse;
  }

  .health-insights-text {
    text-align: right;
    padding-right: 32px;
  }
}

.explore-portfolio-modal-icon {
  .summary svg {
    rect {
      fill: $offeringRetireConfident;
    }

    circle.circle-fill {
      fill: $custom-color-228;
    }

    circle.circle-stroke {
      stroke: $custom-color-228;
    }
  }

  .health svg {
    rect {
      fill: $custom-color-108;
    }

    circle.circle-fill {
      fill: $custom-color-229;
    }

    circle.circle-stroke {
      stroke: $custom-color-229;
    }
  }

  .recommendations svg {
    rect {
      fill: $offeringTaxSaver;
    }

    circle.circle-fill {
      fill: $custom-color-230;
    }

    circle.circle-stroke {
      stroke: $custom-color-230;
    }
  }
}
