.investment-plan-transactions-container {
  .collection {
    margin-top: 0;
    border: none;
  }

  .collection-item {
    padding: 0;
    margin: 0;

    a {
      display: block;
      padding: 1rem 0;
    }

    .row {
      margin-bottom: 0;
    }
  }
}

.user-name {
  font-weight: 600;
  padding-top: 2px;
  text-align: left;
}

.large-name {
  font-size: $font-22;
}

.medium-name {
  font-size: $font-20;
}

.small-name {
  font-size: $font-18;
}

.investment-amount {
  font-weight: 600;

  .value {
    font-size: $font-32;
  }

  .unit {
    font-size: $font-22;
  }
}

.investment-amount-margin {
  margin-top: -4px;
}

.investment-type-container {
  background: $sb-white;
  background: var(--sb-white);
}

.change-funds {
  color: $sb-orange;
  color: var(--sb-orange);
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
}
