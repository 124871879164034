.account-statement-container {
  .collection {
    margin-top: 0;
    border: none;
  }

  .collection-item {
    padding: 0;
    margin: 0;

    a {
      display: block;
      padding: 1rem 0;
    }

    .row {
      margin-bottom: 0;
    }
  }

  .account-statement-transaction-tile {
    display: block;
    padding: 1rem 0;
  }

  .divider {
    background-color: $custom-color-43;
    background-color: var(--custom-color-43);
  }

  .account-statement-load-more {
    padding-bottom: 16px;
    font-size: $font-16;
  }
}

.fund-invested-card {
  padding: 10px;
  border-bottom: 1px solid $custom-color-114;
  border-bottom: 1px solid var(--custom-color-114);
}

#unrealized-capital-gains {
  .collection-item {
    padding-bottom: 1rem;
  }
}

.funds-invested-tile {
  padding: 1rem 0;
}
