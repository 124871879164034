.add-new-plan-container{
    display: flex;
    border: 1px solid;
    padding: 16px;
    border-radius: 5px;
    border-color: $sb-grey-88;
    border-color: var(--sb-grey-88);
    .card-details-with-icons{
        display: flex;
        flex: 1;
        justify-content: space-between;
        .icon-size{
            height: auto;
            width: 24px;
        }
        .card-description-with-icon{
            display: flex;
            .card-icon-left{
                margin-right: 5px;
            }
            .card-header-title{
                .card-sub-text{
                    margin-top: 5px;
                }
            }
        }
        .card-amount-container{
            display: flex;
            align-items: flex-start;
            .right-arrow{
                margin-top: -4px;
                margin-left: 10px;
            }
        }
        .card-details-icon-right{
            margin-left: 12px
        }
    }
}