.selected-growth {
  display: inline-flex;
  align-items: center;
  background: $offeringTaxSaver;
  padding: 4px 7px;
  border-radius: 50px;
}

.growth-recommendations-count {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $sb-input-error;
  border-radius: 50%;
}

.growth-strategy-container {
  .border-radius-20 {
    border-radius: 20;
  }

  #app-container-heading-v2 {
    margin-top: 24px;
  }

  .recommendation-points {
    min-height: 28px;
    min-width: 28px;
    max-width: 28px;
    max-height: 28px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    color: $sb-disable-gray;
    border: 3.5px solid $sb-disable-gray;
  }

  .growth-strategy.custom-amount-container {
    margin-left: -10px;

    #growth-investment-amount {
      background-color: $sb-white;
    }
  }

  .strategy-tag {
    min-width: 130px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid $sb-opacity-5;
  }

  .strategy-tag-active {
    border: 1px solid $sb-blue-link;
    background-color: $sb-ui-blue-light-2;
  }

  .horizontal-slider-item {
    width: 100%;
  }

  .timeline-container {
    border-radius: 5px;
    border: 1px solid $sb-grey-88;
  }

  .recommendation-close {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .review-sip-img {
    width: 216px;
  }

  .amc-logo-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $custom-color-13;
    border: 1px solid var(--custom-color-13);
  }

  .amc-logo {
    width: 15px;
    height: 17px;
  }

  .ycof-amount-field__input {
    margin-top: 4px !important;
  }

  .existing-sip-accordion-item {
    border: 1px solid $sb-light-gray;
  }

  .growth-custom-date-duration {
    .input-field {
      margin-top: 5px;
      padding-left: 0;

      label {
        margin-left: -12px;
      }
    }
  }

  .new-sip-blue-container {
    background: $sb-ui-blue-light-2;
    border-radius: 5px;

    .selected-growth {
      background-color: $sb-white;
    }
  }

  .edit-strategy {
    border: 1px solid;
    border-radius: 5px;

    ul li {
      list-style-type: disc;
    }
  }

  .growthTracking {
    width: 68px;
    border-radius: 20px;
  }

  .offTrackBg {
    background-color: $offeringRetireConfident;
  }

  .onTrackBg {
    background-color: $custom-color-232;
  }

  .current-sips-review.divider {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media only screen and (max-width: 560px) {
    .strategy-tag {
      min-width: 120px;
    }
  }

  .growth-percent-cont {
    border-bottom: 1px solid $sb-grey-88;
  }
}

.explore-strategy-modal-icon {
  .strategy svg {
    rect {
      fill: $offeringRetireConfident;
    }

    circle.circle-fill {
      fill: $custom-color-228;
    }

    circle.circle-stroke {
      stroke: $custom-color-228;
    }
  }

  .review svg {
    rect {
      fill: $custom-color-108;
    }

    circle.circle-fill {
      fill: $custom-color-229;
    }

    circle.circle-stroke {
      stroke: $custom-color-229;
    }
  }

  .recommendation svg {
    rect {
      fill: $offeringTaxSaver;
    }

    circle.circle-fill {
      fill: $custom-color-230;
    }

    circle.circle-stroke {
      stroke: $custom-color-230;
    }
  }
}

.performance-graph-timeline-cont {
  display: flex;

  li {
    border: 1px solid $sb-grey-88;
    padding: 1em;
    margin-right: -1px;
    list-style-type: none;
    width: 33%;
  }

  li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  > li.selected-item {
    border: 1.5px solid $sb-blue-link;
    margin: 0px;
    background-color: $sb-ui-blue-light-2;
  }
}
