$gef-college-block-radius: 4px;

.gef-wrapper {
  .balance-amount {
    font-size: 16px;
  }

  .gef-monthly-sip-card {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    padding: 4px 6px;
    margin: 8px 0;

    .gef-sip-card-description {
      font-size: 14px;
    }
  }
}

.sgoal-card {
  .sgoal-card-title {
    font-size: $font-18;
    font-weight: 600;
  }

  .sgoal-card-graph-container {
    height: 10px;
    position: relative;
    background: $sb-secondary-disabled-background;
    background: var(--sb-secondary-disabled-background);
    border-radius: 25px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 8px;
    min-width: 120px;
  }

  .goal-achievement-progress {
    display: block;
    height: 100%;
    border-radius: 4px;
  }

  .track-info-text {
    margin-left: 4px;
  }

  .track-info-text,
  .achievement-info-text {
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
  }

  .achievement-info-text {
    font-size: $font-12;
    margin: 2px 0;
  }
}

.gef-graph-column {
  display: inline-block;
}

.gef-graph-column:first-child {
  .education-plan-progress-block {
    border-top-left-radius: $gef-college-block-radius;
    border-bottom-left-radius: $gef-college-block-radius;
  }
}

.gef-graph-column:last-child {
  .education-plan-progress-block {
    border-right: 0;
    border-top-right-radius: $gef-college-block-radius;
    border-bottom-right-radius: $gef-college-block-radius;
  }
}

.education-plan-graph-legend {
  border-radius: 50%;
  padding: 6px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;

  &.annual-contribution-indicator {
    background: $custom-color-57;
    background: var(--custom-color-57);
  }

  &.scripbox-portfolio {
    background: $goalScripboxContribution;
    background: var(--goalScripboxContribution);
  }

  &.current-savings-indicator {
    background: $custom-color-58;
    background: var(--custom-color-58);
  }

  &.need-planning-indicator {
    background: $sb-orange;
    background: var(--sb-orange);
  }
}

.education-plan-graph-amount {
  margin-left: 18px;
  font-size: $font-16;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

.education-plan-graph-block {
  &.scard-container.smenu-card {
    padding: 22px 16px;
  }

  .education-plan-progress-block {
    font-size: $font-18;
    color: $sb-white;
    color: var(--sb-white);
    text-align: center;
    padding: 18px 0;
    border-right: 2px solid $sb-white;
    border-right: 2px solid var(--sb-white);

    .amount-value-title,
    .balance-amount {
      display: inline-block;
      font-size: $font-16;
    }

    .balance-amount {
      margin-left: 4px;
    }
  }

  .education-plan-college-year {
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
    font-size: $font-16;
    text-align: center;
    margin: 6px 0;
  }
}

.move-offering-container {
  .scard-container {
    cursor: pointer;
  }
}

.planned-amount-card {
  padding: 5px 0 15px 0;
}

.goal-amount-card {
  background: $sb-white;
  background: var(--sb-white);
  border-color: $sb-border-gray;
  border-color: var(--sb-border-gray);
  border-radius: 8px;

  .goal-card-divider {
    border-bottom: 1px solid $sb-border-gray;
    border-bottom: 1px solid var(--sb-border-gray);
  }

  .planned-title {
    text-align: left;
    font-size: $font-18;
    color: $sb-black;
    color: var(--sb-black);
    font-weight: 600;
  }

  .planned-amount {
    text-align: right;
    color: $sb-black;
    color: var(--sb-black);
    font-size: $font-18;
    font-weight: 600;
  }

  .subtitle {
    padding-top: 24px;
    font-size: $font-16;
    text-align: center;
    color: $sb-slate-gray;
    color: var(--sb-slate-gray);
  }

  .sip-amount {
    font-size: $font-28;
    font-weight: 600;
    color: $sb-black;
    color: var(--sb-black);
  }

  .increment-text {
    font-size: $font-16;
    text-align: center;
    color: $sb-black;
    color: var(--sb-black);
    padding-bottom: 20px;
  }
}

.recommended-label {
  background: $sb-primary-btn-default;
  background: var(--sb-primary-btn-default);
  padding: 2px 5px 1px 5px;
  font-size: $font-10;
  color: $sb-white;
  color: var(--sb-white);
  float: right;
  border-radius: 8px;
  align-items: right;
}

@media only screen and (min-width: 993px) {
  .planned-title {
    color: $sb-charcoal;
    color: var(--sb-charcoal);
  }
}

.plan-section-title {
  font-size: $font-26;
  line-height: 1.2px;
  padding-top: 30px;
  text-align: center;
}

.plan-section-description {
  font-size: $font-18;
  font-weight: 400;
  color: $sb-description-gray;
  color: var(--sb-description-gray);
  margin-top: 12px;
  text-align: center;
}

.plan-section {
  padding: 10px;
}

.ff-card {
  padding: 16px;
  background: $sb-white;
  background: var(--sb-white);
  border: 0.5px $sb-border-gray solid;
  border: 0.5px var(--sb-border-gray) solid;
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.ff-input-label {
  font-size: $font-18;
  font-weight: 600;
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  vertical-align: middle;
}

.ff-text-input {
  text-align: right;
  padding: 6px 12px;
  border: 1px solid $sb-border-gray;
  border: 1px solid var(--sb-border-gray);
  border-radius: 4px;
  font-size: $font-16;
  font-weight: 400;
  color: $sb-text-gray;
  color: var(--sb-text-gray);
  width: 100%;
}

.ff-plan-detail {
  margin-bottom: 10px;
  padding: 5px;
}

.ff-plan-label {
  font-size: $font-16;
}

.ff-plan-value {
  font-size: $font-16;
  font-weight: 600;
}

@media only screen and (min-width: 993px) {
  .ff-plan-footer {
    margin-left: 10px;
  }
}
