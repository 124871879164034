$sb-orange: #f58024;
$sb-black: #576574;
$sb-black-fixed: #576574;
$sb-green: #4ea986;
$sb-white: #ffffff;
$sb-white-fixed: #ffffff;
$sb-blue: #1b2330;
$sb-gray: #cccccc;
$sb-sidemenu-gray: #fafafa;
$sb-card-border: #e4e4e4;
$sb-divider: #e5e5e5;
$sb-light-gray: #eeeeee;
$sb-slate-gray: #666666;
$sb-menu-gray: #777777;
$sb-disable-gray: #9a9a9a;
$kyc-table-gray: #afafaf;
$sb-btn-border-radius: 2px;
$card-layout-background: #f4f4f4;
$card-layout-primary-text: #576574;
$card-layout-description-text: #8e8c8e;
$sb-success-green: #12a279;
$sb-nuetral-yellow: #f2c964;
$sb-failure-yellow: #eda20a;
$sb-blue-link: #007aff;
$sb-blue-link-36: rgba(0, 122, 255, 0.36);
$sb-blue-link-disabled: rgba(0, 122, 255, 0.5);
$sb-menu-icon: #808080;
$sb-primary-btn-default: #10ac84; //rgb(16, 172, 132)
$sb-primary-btn-hover: #0e8d6d;
$sb-primary-btn-focus: #25b38f;
$sb-primary-btn-disabled: #b1e4d7;
$sb-notication-badge-red: #da3236;
$sb-notication-badge-gray: #9b9b9b;
$sb-secondary-default-border: #c0c0c0;
$sb-secondary-default-color: #949494;
$sb-secondary-disabled-color: #bdc3c7;
$sb-secondary-disabled-background: #f6f6f6;
$sb-accordion-blue: #e5f0fe;
$sb-accordion-gray: #f9f9f9;
$sb-checklist-gray: #848f99;
$sb-border-gray: #d3d3d3;
$sb-charcoal: #333333;
$sb-sip-gray: #d8d8d8;
$sb-sip-gray-border: #d9d9d9;
$sb-sip-reallocate-gray: #ebebeb;
$sb-timeline-green: #21ab84;
$sb-pale-orange: #fef0e5;
$sb-text-gray: #495057;
$sb-description-gray: #969696;
$sb-black-50: rgba(0, 0, 0, 0.5);
$sb-black-60: rgba(0, 0, 0, 0.6);
$sb-black-86: rgba(0, 0, 0, 0.86);
$sb-badge-green: #57b4e1;
$sb-black-40: rgba(0, 0, 0, 0.4);
$info-color: #ffdbc1;
$info-bg-color: #fff0e6;
$success-bg-color: #ecfcf8;
$success-color: #0dab82;
$warning-color: #f08020;
$error-color: #ed6b66;
$error-bg-color: #fdf1f1;
$sb-vanta-black: #000000;
$sb-darkslateblue: #483d8b; //darkslateblue
$sb-light-coral: #f08080; //lightcoral
$sb-deep-blue-custom: #0000ff;
$sb-alice-blue-custom: #f0f8ff;
$sb-green-custom: #008000; //green
$sb-orange-custom: #ffa500; //orange
$sb-black-30: rgba(0, 0, 0, 0.3);
$sb-black-10: rgba(0, 0, 0, 0.1);
$sb-black-11: rgba(0, 0, 0, 0.11);
$sb-black-6: rgba(0, 0, 0, 0.06);
$sb-black-5: rgba(0, 0, 0, 0.05);
$sb-black-16: rgba(0, 0, 0, 0.16);
$sb-black-15: rgba(0, 0, 0, 0.15);
$sb-black-125: rgba(0, 0, 0, 0.125);
$sb-black-20: rgba(0, 0, 0, 0.2);
$sb-black-65: rgba(0, 0, 0, 0.65);
$sb-black-75: rgba(0, 0, 0, 0.75);
$sb-black-80: rgba(0, 0, 0, 0.8);
$sb-black-85: rgba(0, 0, 0, 0.85);
$sb-black-86: rgba(0, 0, 0, 0.86);
$sb-black-90: rgba(0, 0, 0, 0.9);
$sb-black-56: rgba(0, 0, 0, 0.56);
$sb-black-3: rgba(0, 0, 0, 0.03);
$sb-black-7: rgba(0, 0, 0, 0.07);
$sb-black-4: rgba(0, 0, 0, 0.04);
$sb-black-12: rgba(0, 0, 0, 0.12);
$sb-black-0: rgba(0, 0, 0, 0);
$sb-purple: #b48dce;

// Offering Color Codes
$offeringShortTerm: #fff6e4;
$offeringLongTerm: #eaf8e7;
$offeringTaxSaver: #eff6ff;
$offeringDreamPlanner: #f0efff;
$offeringMyFirstCrore: #eff6ff;
$offeringEmergency: #ffefef;
$offeringWealthEdgePlan: #eaf8e7;
$offeringWealthEdgePlus: #eaf8e7;
$offeringEducation: #eff6ff;
$offeringRetireConfident: #fff5ef;
$offeringChildHeadstart: #ffefef;
$offeringExternalInvestments: #fff1f1;
$offeringPrincipalProtection: #eaefff;

// goals bar graph indicator
$goalScripboxContribution: #edf523;

$filterSelectedColor: #0078ff;

//buttons color code
$sb-sea-green: #00a08d;
$sb-red-error: #ff0000;
$sb-dark-red: #cc2a2a;
$sb-sea-green-dark: #008878;
$sb-hover-grey-bg: #f8f8f8;
$sb-bg-rose: #fff1f1;
$sb-ui-blue-light-2: #f6f9ff;
$sb-btn-box-shadow: rgba(0, 0, 0, 0.5);
$sb-opacity-5: rgba(0, 0, 0, 0.36);
$sb-opacity-3: rgba(0, 0, 0, 0.6);
$sb-grey-88: #e0e0e0;

// toggle color codes
$sb-toggle-border-color: #636363;

// input box color codes
$sb-input-error: #f56565;
$sb-input-success: #0fac85;

$sb-dark-gray: darkgray; //#a9a9a9

//custom colors
$custom-color-1: #00000059;
$custom-color-2: #e9ecef;
$custom-color-3: #143158;
$custom-color-4: #eef3ff;
$custom-color-5: #c8ecfc;
$custom-color-6: #53b4df;
$custom-color-7: #d1dbe4;
$custom-color-8: #f2f9ff;
$custom-color-9: #ced4da;
$custom-color-10: #0000005c;
//
$custom-color-11: #979797;
$custom-color-12: #0288d1;
$custom-color-13: #f7f7f7;
$custom-color-14: #9cc1ff;
$custom-color-15: #f2f9fc;
$custom-color-16: #0fac83;
$custom-color-17: #f3f3f3;
$custom-color-18: #797979;
$custom-color-19: #8dbfff;
$custom-color-20: #999;
//
$custom-color-21: #f6fcff;
$custom-color-22: #f3fcff;
$custom-color-23: #fee;
$custom-color-24: #a2a2a2;
$custom-color-25: #f44336;
$custom-color-26: #4caf50;
$custom-color-27: #b3b3b3;
$custom-color-28: #00a551;
$custom-color-29: #fff9f4;
$custom-color-30: #2f6cdb;
//
$custom-color-31: #99bbf2;
$custom-color-32: #fef1e7;
$custom-color-33: #bdbdbd;
$custom-color-34: #0090f0;
$custom-color-35: #2e6ddb;
$custom-color-36: #989898;
$custom-color-37: #7d7d7d;
$custom-color-38: #f1f8ff;
$custom-color-39: #1b60df;
$custom-color-40: #6d6d6d;
//
$custom-color-41: #767676;
$custom-color-42: #f1f1f1;
$custom-color-43: #ececec;
$custom-color-44: #e2e2e2;
$custom-color-45: #bbb;
$custom-color-46: #cdcdcd;
$custom-color-47: #0091ff;
$custom-color-48: #cbb700;
$custom-color-49: #26251e;
$custom-color-50: #fffccd;
//
$custom-color-51: #fe7523;
$custom-color-52: #5c5c5c;
$custom-color-53: #fdefd8;
$custom-color-54: #7f7f7f;
$custom-color-55: #88cfe6;
$custom-color-56: #407dcf;
$custom-color-57: #13529e;
$custom-color-58: #08bbee;
$custom-color-59: #a7a7a7;
$custom-color-60: #ddd;
//
$custom-color-61: #e6e6e6;
$custom-color-62: #03a9f4;
$custom-color-63: #fff4c6;
$custom-color-64: #d7d7d7;
$custom-color-65: #f8c9a3;
$custom-color-66: #e7f0ff;
$custom-color-67: #4a4b4d;
$custom-color-68: #216ae3;
$custom-color-69: #fcf1e8;
$custom-color-70: #aaa;
//
$custom-color-71: #f5f5f5;
$custom-color-72: #f2f2f2; //rgb(242,242,242)
$custom-color-73: #05ab85;
$custom-color-74: #007eff;
$custom-color-75: #d0021b;
$custom-color-76: #fffcf5;
$custom-color-77: #ebf5ff;
$custom-color-78: #c2e0ff;
$custom-color-79: #d8eafd;
$custom-color-80: #0071e6;
//
$custom-color-81: #fcfcfc;
$custom-color-82: #f4d06f;
$custom-color-83: #f7e7bd;
$custom-color-84: #d0d7df;
$custom-color-85: #0a0;
$custom-color-86: #586473;
$custom-color-87: #b8b8b8;
$custom-color-88: #5d646c;
$custom-color-89: #c4c5c9;
$custom-color-90: #dedfe1;
//
$custom-color-91: #e9f0fb;
$custom-color-92: #d6d6d6;
$custom-color-93: #838183;
$custom-color-94: #ff6060;
$custom-color-95: #764ba2;
$custom-color-96: #667eea;
$custom-color-97: #b12a5b;
$custom-color-98: #cf556c;
$custom-color-99: #ff8c7f;
$custom-color-100: #ff8177;
//
$custom-color-101: #330867;
$custom-color-102: #30cfd0;
$custom-color-103: #3023ae;
$custom-color-104: #c86dd7;
$custom-color-105: #77aaff;
$custom-color-106: #fff8e9;
$custom-color-107: #fbe6cb;
$custom-color-108: #eef9ec;
$custom-color-109: #81c784;
$custom-color-110: #ffc4c7;
//
$custom-color-111: #0d97ff;
$custom-color-112: #00aa01;
$custom-color-113: #f0f0f0;
$custom-color-114: #b9b8b8;
$custom-color-115: #cecece;
$custom-color-116: #c6c6c6;
$custom-color-117: #d6eaff;
$custom-color-118: #d5d5d5;
$custom-color-119: #d2d2d2;
$custom-color-120: #dedfe0;
//
$custom-color-121: #d7e2e8;
$custom-color-122: #dcdcdc;
$custom-color-123: #de731d;
$custom-color-124: #e9e9e9;
$custom-color-125: #e3e3e3;
$custom-color-126: #fcc;
$custom-color-127: #f7c59f;
$custom-color-128: #ffdabd;
$custom-color-129: #ffe785;
$custom-color-130: #f09819;
//
$custom-color-131: #f87739;
$custom-color-132: #ff5858;
$custom-color-133: #f5f5ee; //rgb(245, 245, 238)
$custom-color-134: rgba(198, 196, 196, 0.34);
$custom-color-135: rgba(255, 0, 255, 0.9);
$custom-color-136: rgba(255, 255, 255, 0.85);
$custom-color-137: rgba(16, 0, 0, 0.1);
$custom-color-138: rgba(51, 51, 51, 1);
$custom-color-139: rgba(252, 198, 155, 0.4);
$custom-color-140: rgba(252, 198, 155, 0);
//
$custom-color-141: rgba(252, 198, 155, 0.6);
$custom-color-142: rgba(210, 210, 210, 0.3);
$custom-color-143: rgba(255, 255, 255, 0.7);
$custom-color-144: rgba(210, 210, 210, 0);
$custom-color-145: rgba(17, 0, 0, 0.86);
$custom-color-146: rgba(255, 0, 222, 0);
$custom-color-147: rgba(16, 172, 132, 0.4);
$custom-color-148: rgba(16, 172, 132, 0);
$custom-color-149: rgba(175, 175, 175, 0.8);
$custom-color-150: rgba(238, 249, 236, 0);
//
$custom-color-151: rgba(132, 143, 153, 0.2);
$custom-color-152: #ffe2cc;
$custom-color-153: #00c853;
$custom-color-154: #ffa000;
$custom-color-155: #d32f2f;
$custom-color-156: #fef1e8;
$custom-color-157: #c4c4c4;
$custom-color-158: #fef3eb;
$custom-color-159: #f58023;
$custom-color-160: #ff969a;
//
$custom-color-161: #ff5555;
$custom-color-162: #858385;
$custom-color-163: #ffefb9;
$custom-color-164: #ffc234;
$custom-color-165: #a89fa7;
$custom-color-166: #afd0c6;
$custom-color-167: #ace2d4;
$custom-color-168: #a3a1a3;
$custom-color-169: #cb961d;
$custom-color-170: #c8c8c8;
//
$custom-color-171: #cbcbcb;
$custom-color-172: #c0d7ff;
$custom-color-173: #cacaca;
$custom-color-174: #dadada;
$custom-color-175: #dadde0;
$custom-color-176: #d0d0d0;
$custom-color-177: #d1d1d1;
$custom-color-178: #eff8ff;
$custom-color-179: #e7e7e7;
$custom-color-180: #eae9e9;
//
$custom-color-181: #efefef;
$custom-color-182: #e5efff;
$custom-color-183: #eef4ff;
$custom-color-184: #e9f6fd;
$custom-color-185: #e8fcf7;
$custom-color-186: #ee6b67;
$custom-color-187: #0000001a;
$custom-color-188: #0bac83;
$custom-color-189: #00000080;
$custom-color-190: #3b3b3b;
//
$custom-color-191: #48535f;
$custom-color-192: #4a4a4a;
$custom-color-193: #4e93ef;
$custom-color-194: #438aff;
$custom-color-195: #4885ed;
$custom-color-196: #566574;
$custom-color-197: #556674;
$custom-color-198: #5890da;
$custom-color-199: #5393ff;
$custom-color-200: #586473;
//
$custom-color-201: #5194fe;
$custom-color-202: #6287d0;
$custom-color-203: #6d7278;
$custom-color-204: #6fbfaa;
$custom-color-205: #6b7682;
$custom-color-206: #8295a7;
$custom-color-207: #9ea7ae;
$custom-color-208: #919294;
$custom-color-209: #999999;
$custom-color-210: #9bc1ff;
//
$custom-color-211: #969da5;
$custom-color-212: rgb(216, 216, 216);
$custom-color-213: rgb(96, 96, 105);
$custom-color-214: rgba(247, 247, 247, 1);
$custom-color-215: rgba(72, 83, 95, 0.56);
$custom-color-216: rgba(0, 122, 255, 1);
$custom-color-217: rgba(255, 255, 255, 0);
$custom-color-218: rgba(179, 245, 228, 0.6);
$custom-color-219: rgba(16, 172, 132, 0.35);
$custom-color-220: rgba(15, 172, 133, 1);
$custom-color-221: rgba(255, 246, 228, 1);
//
$custom-color-222: #424242;
$custom-color-223: #fffdf5;
$custom-color-224: #ffb74d;
$custom-color-225: #fffbf8;
$custom-color-226: rgba(255, 255, 255, 0.9);
$custom-color-227: #bea9ff;
$custom-color-228: #ffece0;
$custom-color-229: #d2e8d5;
$custom-color-230: #dfedff;
$custom-color-231: #909090;
$custom-color-232: #ebf9eb;
$custom-color-233: rgba(0, 0, 0, 0.4);
$custom-color-234: #ededed;
$custom-color-235: #11C095;
$custom-color-236: #F9FDFF;
$custom-color-blue: #0D5D7F;


//signup v5 color codes
$custom-color-237: #2563EB;
$custom-color-238: #52525B;
$custom-color-239: #FECACA;
$custom-color-240: #EF4444;
$custom-color-241: #3F3F46;
$custom-color-242: #BFDBFE;
$custom-color-243: #97dda5;

$sb-moonstone: #3194B7;