.withdraw-modal {
  background-color: $sb-white;
  background-color: var(--sb-white);
  padding: 15px 5px;
  line-height: 1.4;

  .withdraw-modal-heading {
    font-weight: 700;
    font-size: 21px;
    padding-bottom: 10px;
    color: $sb-black;
    color: var(--sb-black);
    animation-delay: 0s;
  }

  .withdraw-modal-subheading-bold {
    font-size: $font-16;
    color: $sb-black;
    color: var(--sb-black);
    font-weight: 600;
    margin-bottom: 10px;
    animation-delay: 1s;
  }

  .withdraw-modal-subheading {
    font-size: 16px;
    color: $sb-checklist-gray;
    color: var(--sb-checklist-gray);
  }

  .withdraw-modal-progress {
    height: 30px;
    border-radius: 20px;
    background-color: $sb-gray;
    background-color: var(--sb-gray);
    margin-top: 20px;
    margin-bottom: 10px;

    .determinate {
      background-color: $custom-color-94;
      background-color: var(--custom-color-94);
    }
  }

  .legends {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;

    .current {
      color: $custom-color-94;
      color: var(--custom-color-94);
    }

    .recommended {
      color: $sb-menu-gray;
      color: var(--sb-menu-gray);
    }
  }

  hr {
    border: 1px solid $sb-light-gray;
    border: 1px solid var(--sb-light-gray);
    margin: 20px auto;
  }

  ul li {
    list-style-type: disc;
    list-style-position: outside;
  }

  .withdraw-modal-image {
    margin: 20px auto;
  }

  .withdraw-modal-buttons {
    button {
      padding: 7px !important;
      // margin: 10px auto;
      height: 45px !important;
      font-size: $font-16 !important;
      border-radius: 10px;
      margin-right: 10px;
    }

    .secondary-btn {
      border: 2px solid $sb-light-gray;
      border: 2px solid var(--sb-light-gray);
    }
  }
}

.skylight-withdraw-modal {
  .skylight-wrapper .skylight-dialog {
    z-index: 3000;
    width: 90% !important;
    max-width: 475px;
    max-height: 90% !important;
    top: 45px !important;
    left: 20px !important;
    right: 20px !important;
    margin: auto !important;
    overflow-y: auto;
  }
}

.skylight-withdraw-modal.reallocate-confirmation-modal,
.skylight-withdraw-modal.reallocate-sip-modal {
  .skylight-dialog {
    top: 100px !important;
    width: 90% !important;
    left: 20px !important;
    min-height: 165px !important;
  }
}

.skylight-footer-withdraw-modal {
  .skylight-wrapper .skylight-dialog {
    z-index: 3000;
    width: 100% !important;
    max-width: 475px;
    max-height: 90% !important;
    min-height: auto !important;
    top: 45px !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    overflow-y: auto;
  }

  .skylight-close-button {
    display: none;
  }
}

@media only screen and (min-width: 993px) {
  .skylight-withdraw-modal {
    .skylight-wrapper .skylight-dialog {
      width: 475px !important;
      top: 10% !important;
      left: 0% !important;
    }
  }

  .skylight-footer-withdraw-modal {
    .skylight-wrapper .skylight-dialog {
      width: 475px !important;
      top: 10% !important;
      left: -25% !important;
    }
  }

  .reallocate-sip-modal {
    .skylight-wrapper .skylight-dialog {
      min-height: 600px !important;
    }
  }
}

@media only screen and (max-width: 993px) {
  .withdraw-modal {
    .withdraw-modal-buttons {
      .secondary-btn {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .skylight-footer-withdraw-modal {
    .skylight-wrapper .skylight-dialog {
      bottom: 0;
      top: auto !important;
      min-height: auto !important;
    }
  }
}

.exit-load-amount {
  text-align: center;
  font-size: 21px;
}

.sub-text {
  text-align: center;
  font-size: 12px;
}

.modal-single-button {
  text-align: center;
  font-size: $font-20;
  display: block;
  width: 100% !important;
  color: $sb-white;
  color: var(--sb-white);
  background-color: $sb-primary-btn-default;
  background-color: var(--sb-primary-btn-default);
  border: 1px solid $sb-primary-btn-default;
  border: 1px solid var(--sb-primary-btn-default);
  height: 53px;
  border-radius: 0;
  text-transform: uppercase;
}

.withdraw-modal-amount-card {
  display: flex;
  justify-content: space-around;
  margin-right: -20px;
  margin-left: -20px;
  background-color: $sb-alice-blue-custom;
  background-color: var(--sb-alice-blue-custom);
  line-height: 0.8;
  padding: 5px;
  align-items: center;

  > div:nth-child(1),
  > div:nth-child(3) {
    min-width: 140px;
  }
}

.withdraw-modal-amount-header {
  font-size: $font-12;
  color: $sb-secondary-default-color;
  color: var(--sb-secondary-default-color);
}

.withdraw-modal-amount-subheader {
  font-weight: 600;
  font-size: $font-20;
}

.withdraw-modal-amount-suffix {
  font-size: $font-12;
  color: $sb-secondary-default-color;
  color: var(--sb-secondary-default-color);
  margin-top: -5px;
}

.vl {
  border-left: 2px solid $sb-white;
  border-left: 2px solid var(--sb-white);
  height: 50px;
}

.withdraw-modal-amount-card-subheader {
  font-size: $font-16;
  color: $sb-black;
  color: var(--sb-black);
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  animation-delay: 1s;
}

.withdraw-modal-amout-link {
  font-size: $font-16;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 20px;
}

.withdraw-modal-with-index {
  background: $sb-white;
  background: var(--sb-white);
  box-shadow: -3px 3px 12px 5px $sb-black-5;
  box-shadow: -3px 3px 12px 5px var(--sb-black-5);
  padding: 30px 20px !important;
  position: relative;

  > p:last-child {
    color: $sb-primary-btn-default;
    color: var(--sb-primary-btn-default);
  }
}

.withdraw-modal-without-index {
  background-color: $sb-secondary-disabled-background;
  background-color: var(--sb-secondary-disabled-background);
  padding: 20px !important;
  height: 180px;
  margin-top: 10px;

  > p:last-child {
    color: $sb-notication-badge-red;
    color: var(--sb-notication-badge-red);
  }
}

.withdraw-modal-indexation {
  font-size: $font-12;
  line-height: 0.8;
}

.withdraw-modal-indexation-header {
  font-size: 14px;
  font-weight: 600;
}
