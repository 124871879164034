.nudge-card-cp {
  .nudge-card-container {
    &.change-portfolio-nudge-bg {
      background-color: $card-layout-description-text;
      background-color: var(--card-layout-description-text);
      background-image: url('../images/misc/nudge-bg.png');
      background-position: right bottom;
      border-radius: 8px;
    }

    .nudge-content-container {
      margin: 5px 0 5px 0;
      padding-bottom: 25px;
    }

    .nudge-label {
      background-color: $custom-color-3;
      background-color: var(--custom-color-3);
      font-size: 12px;
      margin-top: 10px;
      min-width: 120px;
      font-weight: 600;
      min-height: 26px;
      display: flex;
      align-items: center;
      margin-left: -5px;
      padding-left: 15px;
      color: $sb-white;
      color: var(--sb-white);
    }

    .nudge-label-shadow {
      width: 0;
      border-right: 3px solid $sb-gray;
      border-right: 3px solid var(--sb-gray);
      border-top: 3px solid $sb-gray;
      border-top: 3px solid var(--sb-gray);
      border-left: 2px solid transparent;
      border-bottom: 3px solid transparent;
      margin-top: 16px;
      margin-left: -15px;
      position: absolute;
    }

    .nudge-button-container {
      text-align: center;
      width: 100%;
    }

    // .cp-nudge-button {
    //   text-align: center;
    //   font-size: 16px;
    //   font-weight: 500;
    //   display: block;
    //   width: 100%;
    //   background-color: $sb-primary-btn-default;
    //   background-color: var(--sb-primary-btn-default);
    //   border: none;
    //   padding: 10px;
    //   border-radius: 8px;
    //   color: $sb-white;
    //   color: var(--sb-white);

    //   &:focus,
    //   &:active {
    //     background-color: $sb-primary-btn-focus;
    //     background-color: var(--sb-primary-btn-focus);
    //   }
    //   &:hover {
    //     background-color: $sb-primary-btn-hover;
    //     background-color: var(--sb-primary-btn-hover);
    //   }
    // }
  }
}
