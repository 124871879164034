.sip-renewal-extension {
  .ycof-amount-field__input {
    margin-top: 4px !important;
  }
  .ycof-amount-field__input:focus {
    border-color: $sb-blue-link !important;
    font-weight: bold !important;
    color:  rgba(0, 0, 0, 0.86) !important;
    opacity: 1 !important;
  }

  .sip-renewal-extension-datepicker {
    .input-field {
      margin-top: 0 !important;
    }
  }

  .DayPicker > div:first-child {
    margin-left: -21px !important;
  }
  
  .Collapsible__trigger.is-disabled {
    background: #EEF9EC !important;
  }

  .sb-btn-normal.sb-btn-secondary:active, :focus {
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
  }

  .sb-btn-normal.sb-btn-primary:focus {
    border: 1px solid transparent !important;
  }
}

.sip-renewal-action-tile {
  background-color: $custom-color-106;
  background-color: var(--custom-color-106);
}
