@keyframes dot-pulse {
  0% {
    box-shadow: 0 0 0 0 $custom-color-147;
    box-shadow: 0 0 0 0 var(--custom-color-147);
  }

  100% {
    box-shadow: 0 0 0 10px $custom-color-148;
    box-shadow: 0 0 0 10px var(--custom-color-148);
  }
}

.dot-pulse {
  animation: dot-pulse 3s infinite;
}
