.modify-flow-wizard {

  .ycof-amount-field__input:focus {
    border-color: $sb-blue-link !important;
    font-weight: bold !important;
    color:  rgba(0, 0, 0, 0.86) !important;
    opacity: 1 !important;
  }

  .terms {
    .row {
      .col {
        padding: 0 !important;
      }
    }
  }
    
  .app-container-heading-text,
  #app-container-heading {
    padding-bottom: 0 !important;
  }

  .modify-flow-datepicker {
    .input-field {
      margin-top: 0 !important;
    }
  }

  .modify-duration {
    label {
      margin-left: -11px !important;
      cursor: pointer;
    }

    .input-field {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .ycof-amount-field__input {
        margin-top: 8px !important;
      }
    }
  }

  [type=radio]+label:after,
  [type=radio]+label:before {
    width: 22px !important;
    height: 22px !important;
  }

  .duration-field__input-disabled,
  .amount-field__input-disabled {
    background-color: #f5f5f5;
    border-bottom: 1px solid $sb-opacity-5 !important;
    color: $sb-opacity-5 !important;
    color: var(--sb-opacity-5) !important;
    &:focus {
      border: 1px solid $sb-opacity-5 !important;
      border: 1px solid var(--sb-opacity-5) !important;
      box-shadow: none !important;
    }
  }

  .modal-dialog-div {
    border-radius: 5px;
  }

  .Collapsible__trigger.is-disabled {
    background: #eef9ec!important;
  }

  .input-field {
    .amount-field__input-disabled {
      height: 36px;
    }
  }

  .monthly-sip-amount {
    .input-field {
      padding-right: 2px !important;
    }
  }

  .SingleDatePicker_picker {
    margin-left: -20px !important;
  }

  @media only screen and (min-width: 992px) {
    .attribute-modal {
      .modal-dialog-div{
        width: 484px;
      }    
    } 
    
    .custom-amount-container {
      .amount-input-field {
        .input-field {
          padding-right: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 560px) {
    .modal-content-div {
      top: 0%;
    }
  }
}
