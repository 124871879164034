.mobile-verification-container {
  .otp-step-container {
    width: 100%;
  }

  @media only screen and (min-width: 992px) {
    .otp-success-modal {
      width: 420px;
      position: absolute;
      top: 127px;
      left: calc(50% - 210px);
    }

    .otp-step-container {
      width: 420px;

      .test-centered-button button {
        width: 100% !important;
      }
    }
  }
}
