.investment-category {
  font-size: $font-20;
  font-weight: 600;
  display: block;
}

.invest-more {
  padding: 10px 15px;
  margin: 10px;
  border-radius: 10px;
}

.invest-more__radio {
  margin-right: 10px;
  padding-left: 8px;
}

.invest-more__radio-button {
  margin-right: 5px !important;
}

.invest-more__investment-type {
  padding: 10px 0;
}

.invest-more__input {
  padding: 10px 5px;
}

.invest-more__input--left-label {
  padding-top: 7px;
  padding-right: 0;
  text-align: right;
}

.invest-more__input--right-label {
  padding-top: 7px;
  padding-left: 0;
}

.invest-more__input-style {
  margin-top: 3%;
}

.payment-type-icon-wrapper {
  margin-right: 14px;
  margin-top: 5px;
}

.investment-type-sprite {
  background-image: url('../images/logos/investment-type-sprite.png');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;

  &.calendar-icon {
    background-position: 0 -2px;
  }

  &.net-banking-icon {
    background-position: -87px -2px;
  }

  &.sip-icon {
    background-position: -30px -3px;
    width: 47px;
  }

  &.oti-icon {
    background-position: -117px -2px;
  }

  &.link-bank-icon {
    background-position: -172px -4px;
  }

  &.process-arrow-icon {
    background-position: -147px -4px;
    width: 13px;
  }

  &.pay-net-banking-icon {
    background-position: -253px -2px;
    height: 20px;
    width: 20px;

    &.disabled {
      background-position: -285px -2px;
    }
  }

  &.pay-upi-icon {
    background-position: -199px -1px;
    width: 16px;
    height: 22px;

    &.disabled {
      background-position: -226px -1px;
    }
  }
}

.payment-type-info-card {
  padding: 12px 0 0 0;
  margin-bottom: 20px;

  .section-split {
    margin-right: 30px;
  }

  .process-arrow-container {
    padding-left: 25px;
  }

  .payment-info-text {
    color: $sb-black;
    color: var(--sb-black);
    font-size: $font-12;
    margin: 4px 0 0 0;
    line-height: 1.4;
  }

  @media only screen and (min-width: 993px) {
    margin-bottom: 30px;

    .section-split {
      margin-right: 55px;
    }

    .process-arrow-container {
      padding-left: 55px;
    }
  }
}
