$font-8: 8px;
$font-10: 10px;
$font-12: 12px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-25: 25px;
$font-26: 26px;
$font-28: 28px;
$font-32: 32px;
$font-36: 36px;
$font-42: 42px;
$font-58: 58px;
$font-60: 60px;
