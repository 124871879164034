.fund-fact-sheet {
  .fact-sheet-parameter-group {
    margin: 8px auto;
    margin-right: 25px;
    text-align: right;
  }

  .fact-sheet-parameter-group:last-child {
    margin-bottom: 0;
  }

  .fact-sheet-table-heading {
    padding: 10px 20px;
    background-color: $sb-white;
    background-color: var(--sb-white);
    font-size: $font-16;
    font-weight: bold;
    margin: 0 10px;
  }

  .fact-sheet-table {
    background-color: $sb-white;
    background-color: var(--sb-white);
    color: $custom-color-52;
    color: var(--custom-color-52);

    thead {
      border-bottom: 1px solid $custom-color-44;
      border-bottom: 1px solid var(--custom-color-44);

      th {
        font-weight: 400;
        padding-top: 0;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $custom-color-44;
        border-bottom: 1px solid var(--custom-color-44);
      }

      tr:last-child {
        border-bottom: none;
      }
    }

    th,
    td {
      font-size: $font-16;
      padding: 7px 5px;
      width: 50%;
    }

    td:first-child {
      padding-left: 30px;
    }

    td:nth-child(2n) {
      padding-right: 30px;
    }
  }

  .disclaimer-text {
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
    margin: 10px 20px;
    margin-bottom: 5px;
  }

  .holdings-table {
    tr {
      background-color: $sb-white;
      background-color: var(--sb-white);
    }

    th,
    td {
      padding: 10px 5px;
    }

    .total {
      background-color: $custom-color-53;
      background-color: var(--custom-color-53);
      font-weight: 600;
    }
  }

  .scheme-information {
    td {
      vertical-align: baseline;
    }
  }

  .what-is-this-fund-for {
    .dot-separator {
      width: 4px;
      height: 4px;
      background: $custom-color-52;
      background: var(--custom-color-52);
      margin: 0.2rem 2.5rem;
    }
  }
}

@media only screen and (min-width: 993px) {
  .remove-desktop-padding {
    padding: 0;
  }
}

@media only screen and (max-width: 993px) {
  .fact-sheet-table {
    thead {
      th:first-child {
        padding-left: 20px;
      }

      th:last-child {
        padding-right: 20px;
      }
    }

    tbody {
      td:first-child {
        padding-left: 20px;
      }

      td:last-child {
        padding-right: 20px;
      }
    }
  }

  .fund-fact-sheet {
    .what-is-this-fund-for {
      .dot-separator {
        margin: 0.2rem 1.5rem;
      }
    }
  }
}
