.saccordion {
  margin: 12px 0;
  background: $sb-white;
  background: var(--sb-white);
  border: 1px solid $kyc-table-gray;
  border: 1px solid var(--kyc-table-gray);
  border-radius: 8px;

  .saccordion-header {
    cursor: pointer;
    padding: 0 0.75rem;
    border-bottom: 1px solid $kyc-table-gray;
    border-bottom: 1px solid var(--kyc-table-gray);
  }

  .saccordion-description {
    padding: 12px 0;
    border-bottom: 1px solid $kyc-table-gray;
    border-bottom: 1px solid var(--kyc-table-gray);
  }

  .saccordion-title {
    font-size: $font-14;
    font-weight: 600;
    display: inline-block;
    padding: 12px 0;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
  }

  .saccordion-icon-block {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }

  .saccordion-fund-count {
    font-size: $font-12;
    color: $kyc-table-gray;
    color: var(--kyc-table-gray);
  }

  i.saccordion-icon {
    border: solid $card-layout-primary-text;
    border: solid var(--card-layout-primary-text);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;

    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .remove-border-bottom > .saccordion-description {
    border-bottom: 0;
  }

  .remove-border-bottom.saccordion-header {
    border-bottom: 0;
  }
}

.s-grey-accordion {
  margin: 12px 0;
  background: $sb-secondary-disabled-background;
  background: var(--sb-secondary-disabled-background);
  border: 1px solid $custom-color-44;
  border: 1px solid var(--custom-color-44);
  border-radius: 8px;

  .saccordion-header {
    cursor: pointer;
    padding: 0 0.75rem;
    border-bottom: 1px solid $custom-color-44;
    border-bottom: 1px solid var(--custom-color-44);
  }

  .saccordion-description {
    font-size: $font-16;
    padding: 5px 10px;
    border-bottom: 1px solid $custom-color-44;
    border-bottom: 1px solid var(--custom-color-44);
    background: $sb-white;
    background: var(--sb-white);
    color: $sb-checklist-gray;
    color: var(--sb-checklist-gray);
    line-height: 1.3;
  }

  .saccordion-title {
    font-size: $font-16;
    font-weight: 600;
    display: inline-block;
    padding: 12px 0 12px 10px;
    color: $sb-black;
    color: (--sb-black);
  }

  .saccordion-icon-block {
    display: inline-block;
    float: right;
    margin-top: 10px;
  }

  .saccordion-fund-count {
    font-size: $font-12;
    color: $kyc-table-gray;
    color: var(--kyc-table-gray);
  }

  i.saccordion-icon {
    border: solid $card-layout-primary-text;
    border: solid var(--card-layout-primary-text);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    vertical-align: middle;

    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .remove-border-bottom > .saccordion-description {
    border-bottom: 0;
  }

  .remove-border-bottom.saccordion-header {
    border-bottom: 0;
  }
}
