.perf-inflation-graph {
  .actual-line {
    height: 16px;
    width: 2px;
    background: $custom-color-199;
    border-radius: 1px;
  }
  .inflation-line {
    border-radius: 1px;
    border-style: solid;
    height: 7px;
    border-radius: 2px;
    border: 1px solid $custom-color-14;
  }
}

.graph-tooltip-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: $custom-color-199;
  border-radius: 50%;
}

.graph-tooltip-series-name {
  margin: 6px 0 0 4px;
  display: inline-block;
}
