/* Font Weight 400 to 900
 * eg: class 'font-weight-600" is used for font-weight: 600 */
@for $i from 4 through 9 {
  .font-weight-#{$i*100} {
    font-weight: #{$i * 100} !important;
  }
}

/* Font size - lower(8) and upper(30) limit depends on need
 * class 'font-16" is used for font-size: 16px */
@for $i from 8 through 30 {
  .font-#{$i} {
    font-size: #{$i}px !important;
  }
}
