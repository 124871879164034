#verify_otp_modal {
  @media only screen and (min-width: 993px) {
    .modal-dialog-div {
      width: 374px;
      // height: 418px;
    }
    .waiting_state {
      height: 399px;
    }
  }

  .modal-dialog-div {
    .modal-children {
      height: 100%;

      .inherit-height {
        height: inherit;
      }
    }
    .modal-close-icon {
      top: 26px;
      right: 25px;
      width: 14px;
      height: 14px;
    }
  } 

  .verify_otp_modal_input {
    width: 180px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    letter-spacing: 4px;
  }

  .discrepant-radio-input {
    .sb-radio-container {
      text-align: left !important;
      margin: 0 !important;
    }
  }

  .contact-no {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    text-align: inherit;
    font-style: normal;
    letter-spacing: 0px;
  }
}
