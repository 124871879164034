
.asset-allocation-scheme-title {
  font-size: $font-16;
  font-weight: 600;
  color: $sb-menu-icon;
  color: var(--sb-menu-icon);
}

.asset-allocation-option-title {
  font-size: $font-16;
  font-weight: 600;
  color: $sb-black;
  color: var(--sb-black);
}

.asset-allocation-option-description {
  font-size: $font-14;
  color: $sb-menu-icon;
  color: var(--sb-menu-icon);
}

.asset-allocation-recommended-badge {
  font-size: $font-12;
  color: $sb-primary-btn-default;
  color: var(--sb-primary-btn-default);
  font-weight: 400;
}

.asset-allocation-header-container {
  .full-width-card-container {
    padding-bottom: 0;
  }
}

.asset-legend-text {
  font-size: $font-14;
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  margin-bottom: 6px;
}

.asset-allocation-radio-container {
  .asset-allocation-radio-card {
    background: $sb-white;
    background: var(--sb-white);
    box-shadow: -2px 2px 13px 0 $custom-color-144;
    box-shadow: -2px 2px 13px 0 var(--custom-color-144);
    border: 2px solid $custom-color-113;
    border: 2px solid var(--custom-color-113);
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    cursor: pointer;

    &.selected-asset-option {
      border: 2px solid $sb-success-green;
      border: 2px solid var(--sb-success-green);
    }

    .full-width-card-container {
      padding: 0;
      margin: 0;

      .col.s12 {
        padding: 0;
      }
    }

    .asset-allocation-graph-legend {
      .col {
        padding-left: 0;
      }
    }
  }

  &.position-fix {
    display: block;
    padding: 0;
  }

  .asset-allocation-radio-alignment {
    display: flex;
    text-align: left;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .radio-button-flag-container,
  .radio-content-container {
    display: inline-block;
  }

  .radio-button-flag-container {
    flex: 0;

    [type="radio"]:not(:checked) + label,
    [type="radio"]:checked + label {
      padding-left: 20px;
    }

    [type="radio"] + label::before,
    [type="radio"] + label::after {
      width: 24px;
      height: 24px;
    }
  }

  .radio-content-container {
    flex: 1;
  }
}

.withdraw-modal-wrapper {
  padding-bottom: 40px;

  .asset-allocation-modal-link {
    margin: 10px;
  }
}

.asset-allocation-explanation-modal-content {
  margin-top: 5px;

  .why-asset-allocation-title {
    font-size: $font-18;
    font-weight: 600;
    margin: 0;
  }
}

.asset-allocation-container {
  @media only screen and (max-device-width: 370px) {
    width: auto;
  }
}

.selector-parent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.selector-item {
  display: flex;
  border: 1px $custom-color-46 solid;
  border: 1px var(--custom-color-46) solid;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-right: none;
  border-left: none;
  font-weight: 600;
  color: $custom-color-46;
  color: var(--custom-color-46);
  font-size: 16px;
  background-color: $sb-white;
  background-color: var(--sb-white);
}

.selected {
  height: 43px;
  font-size: 18px;
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
  border: 4px $custom-color-111 solid;
  border: 4px var(--custom-color-111) solid;
  border-radius: 12px;
}

.bucket-list-item {
  .allocation {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .returns {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
}

.details-row {
  display: flex;
}

.stacked-bar {
  display: flex;
  width: 100%;
  background-color: $sb-menu-icon;
  background-color: var(--sb-menu-icon);
  height: 10px;
}

.allocation-profile {
  .content-item {
    padding: 14px 5px;
    border-bottom: 1px solid $custom-color-72;
    border-bottom: 1px solid var(--custom-color-72);
  }

  .svertical-line {
    border-left: 1px solid $custom-color-16 !important;
    border-left: 1px solid var(--custom-color-16) !important;
  }

  .allocation-header {
    font-size: 16px !important;
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid $custom-color-72;
    border-bottom: 1px solid var(--custom-color-72);
    border-radius: 6px;
    background: $custom-color-38;
    background: var(--custom-color-38);
    padding-left: 20px;
  }

  .badge {
    display: flex;
    border: 1px solid $custom-color-16;
    border: 1px solid var(--custom-color-16);
    border-radius: 4px;
    width: 115px;
    height: 30px;
    margin: 8px 0;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
}

.allocation-title {
  font-size: 18px !important;
  display: flex;
  justify-content: center;
  height: 48px;
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
  font-weight: 'bold';
}

.badge-icon {
  padding: 5px;
  border-right: 1px solid $custom-color-16;
  border-right: 1px solid var(--custom-color-16);
  height: 30px;
  width: 35%;
}

.allocation-profile {
  border: 2px solid $custom-color-72;
  border: 2px solid var(--custom-color-72);
  border-radius: 8px;
  margin: 10px;
  margin-top: 30px;
}

.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $custom-color-72;
  border-bottom: 1px solid var(--custom-color-72);
}

.table-cell {
  width: 30%;
  font-size: 14px;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  padding-right: 8px;
}

.bucket-list {
  display: flex;
  flex-direction: column;
  margin: 0 0;
  border: 2px solid $custom-color-72;
  border: 2px solid var(--custom-color-72);
  border-radius: 6px;
  padding: 15px 12px;
}

.allocation-graph-header {
  padding: 10px 20px 20px 10px;
  border-bottom: 1px solid $custom-color-72;
  border-bottom: 1px solid var(--custom-color-72);
  margin-bottom: 10px;
}

@media only screen and (max-width: 550px) {
  .allocation-graph-header {
    padding-left: 130px;
  }
}

.tab-header-item {
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: center;
  height: 50px;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid $custom-color-72;
  border-bottom: 1px solid var(--custom-color-72);
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
  cursor: pointer;
}

.selected-tab {
  border-bottom: 2px solid $custom-color-16 !important;
  border-bottom: 2px solid var(--custom-color-16) !important;
}

.tab-header-time:first-child {
  border-right: 1px solid $custom-color-72;
  border-right: 1px solid var(--custom-color-72);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: $sb-white;
  background-color: var(--sb-white);
}

@media only screen and (max-width: 560px) {
  .asset-allocation-content {
    .modal-content-div {
      top: 0%;
    }
  }
}

.asset-allocation-title {
  display: flex;
  justify-content: center;
  font-size: 17px;
  margin-top: 10px;
  font-weight: bold;
  color: $custom-color-47 !important;
  color: var(--custom-color-47) !important;
}

.allocation-radio {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.radio-allocation-container {
  padding: 20px;
  border-bottom: 1px solid $custom-color-72;
  border-bottom: 1px solid var(--custom-color-72);
}

.radio-allocation-container:last-child {
  margin-bottom: 10px;
}
