.whatsapp-opt-in-wrapper {
  .whatsapp-header {
    background: url('../images/misc/dotted-pattern.svg'), linear-gradient(180deg, $custom-color-108 0%, $custom-color-150 104.57%);
    background: url('../images/misc/dotted-pattern.svg'), linear-gradient(180deg, var(--custom-color-108) 0%, var(--custom-color-150) 104.57%);
    background-repeat: no-repeat;
    background-position: 98% 12px, 100% 100%;
    min-height: 240px;
    text-align: center;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .whatsapp-logo {
    margin-bottom: 14px;
    width: 48px;
    height: 48px;
  }

  .whatsapp-intro-points {
    margin: 0 20px;
  }
}
