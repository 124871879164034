.bring-your-investments {
  padding: 15px 0.75rem;
  font-size: $font-16;
}

.dont-have-your-cas {
  margin: 15px 0.75rem;
  text-align: center;
}

.cas-file-error {
  min-height: 35px;
  color: $custom-color-25;
  color: var(--custom-color-25);
  font-size: $font-14;
  font-weight: 500;
  padding: 0 0.75rem;
}

.cas-file-name {
  min-height: 35px;
  font-size: $font-14;
  font-weight: 500;
  padding: 0 0.75rem;
}

.cas-button {
  margin-left: 0;
  width: auto;
  border-radius: 8px;
  padding: 8px 16px;
  min-width: 200px;
  display: block;
  border-color: $sb-border-gray;
  border-color: var(--sb-border-gray);
}
