.sb-user-accounts {
  li {
    list-style: none;
  }
}

.user-info {
  padding: 10px 6px;
  background: $sb-white;
  background: var(--sb-white);
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: -2px 2px 13px 0 $custom-color-142;
  box-shadow: -2px 2px 13px 0 var(--custom-color-142);
  line-height: 1.2;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.add-image {
  position: absolute;
  top: 40px;
  left: 40px;
  background-color: $custom-color-149;
  background-color: var(--custom-color-149);
  border-radius: 8px;
  padding: 7px;
}

i.add-image {
  font-size: $font-16;
}

.image-placeholder {
  height: 100%;

  .uploader-text {
    text-align: center;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-18;
    font-weight: 700;
    border: none;

    .popup-add-image {
      font-size: $font-60 !important;
    }
  }
}

.hide {
  display: none;
}

.image-uploader-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.clear_block {
  float: right;
}

.number-circle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: $sb-white;
  background: var(--sb-white);
  border: 2px solid $sb-slate-gray;
  border: 2px solid var(--sb-slate-gray);
  color: $sb-slate-gray;
  color: var(--sb-slate-gray);
  text-align: center;
  font: 15px Arial, sans-serif;
}

.row.investment-summary {
  margin: 6px 0 10px 0;
}

.investment-summary {
  .card-amount-label {
    font-size: $font-14;
    color: $sb-menu-icon;
    color: var(--sb-menu-icon);
  }

  .card-amount-label-large {
    font-size: $font-16;
    color: $sb-menu-icon;
    color: var(--sb-menu-icon);
  }
}
