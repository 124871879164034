/* The toastBar - position it at the bottom and in the middle of the screen */
#toastBar {
  display: none;
  min-width: 250px;
  max-width: 300px;
  text-align: center;
  padding: 16px;
  position: fixed;
  z-index: 99;
  border-radius: 3px;
}

#toastBar.show {
  display: block;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@media screen and (min-device-width: 993px) {
  #toastBar {
    background-color: $sb-black-86;
    background-color: var(--sb-black-86);
    border-radius: 5;
    bottom: 53px;
    color: $sb-white;
    color: var(--sb-white);
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 50%;
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 53px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 53px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 53px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 53px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }
}

@media screen and (max-device-width: 992px) {
  #toastBar {
    background-color: $sb-black-86;
    background-color: var(--sb-black-86);
    border-radius: 5;
    bottom: 53px;
    color: $sb-white;
    color: var(--sb-white);
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 90%;
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 53px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 53px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 53px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 53px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }
}
