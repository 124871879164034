.gamification-container {
  .progress-card-title {
    background-color: $custom-color-56;
    background-color: var(--custom-color-56);
    color: $sb-white;
    color: var(--sb-white);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .celebration-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .celebration-title {
    position: absolute;
    top: 33px;
  }

  .celebration-image {
    height: 210px;
    border: none;
  }

  .badge-active {
    position: absolute;
    top: 130px;
  }

  .progress-card-body {
    position: relative;
    height: 264px;
  }

  .reset-vanish-effect {
    position: absolute;
    bottom: 39px;
    right: 1px;
    height: 187px;
    background-image: linear-gradient(to left, $sb-white 33%, $custom-color-146);
    background-image: linear-gradient(to left, var(--sb-white) 33%, var(--custom-color-146));
  }

  .reset-wand {
    position: absolute;
    bottom: 39px;
  }

  .progress-streak {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid $custom-color-113;
    border-bottom: 1px solid var(--custom-color-113);
  }

  .progress-streak-metrics {
    display: flex;
    justify-content: space-around;

    div:first-child {
      border-right: 1px solid $custom-color-113;
      border-right: 1px solid var(--custom-color-113);
    }
  }

  .progress-metric {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .achievements-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .badge-info-card {
    padding: 15px;
    display: flex;
    border-radius: 5px;
    border: 1px solid $custom-color-118;
    border: 1px solid var(--custom-color-118);
  }

  .btn-next {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 550px) {
  .gamification-container {
    .gamification-card {
      width: 500px;
    }

    .celebration-image {
      width: 500px;
      background-image: url('../images/gamification/BG-desktop.svg');
    }

    .reset-vanish-effect {
      width: 420px;
    }

    .progress-metric {
      width: 250px;
    }

    .month-status {
      padding-right: 30px;
    }

    .progress-streak {
      padding-left: 55px;
      padding-right: 55px;
    }

    .reset-wand {
      right: 60px;
    }

    .btn-next {
      width: 500px !important;
    }
  }
}

@media only screen and (max-width: 549px) {
  .gamification-container {
    .gamification-card {
      width: 330px;
    }

    .celebration-image {
      width: 330px;
      background-image: url('../images/gamification/BG-mobile.svg');
    }

    .progress-metric {
      width: 165px;
    }

    .reset-vanish-effect {
      width: 280px;
    }

    .month-status {
      padding-right: 10px;
    }

    .progress-streak {
      padding-left: 20px;
      padding-right: 20px;
    }

    .reset-wand {
      right: 30px;
    }

    .badge-info-card {
      align-items: center;
    }

    .btn-next {
      width: 330px;
    }
  }
}
