@for $i from 0 through 200 {
  .pa-#{$i} {
    padding: #{$i}px !important;
  }

  .px-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  .py-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .ma-#{$i} {
    margin: #{$i}px !important;
  }

  .mx-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .my-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  // negative margins
  .-ml-#{$i} {
    margin-left: -#{$i}px !important;
  }
  .-mr-#{$i} {
    margin-right: -#{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .-mt-#{$i} {
    margin-top: -#{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .-mb-#{$i} {
    margin-bottom: -#{$i}px !important;
  }

  .br-#{$i} {
    border-radius: #{$i}px !important;
  }
}

@for $i from 0 through 20 {
  .br-top-left-#{$i} {
    border-top-left-radius: #{$i}px !important
  }

  .br-bottom-left-#{$i} {
    border-bottom-left-radius: #{$i}px !important
  }

  .br-top-right-#{$i} {
    border-top-right-radius: #{$i}px !important
  }

  .br-bottom-right-#{$i} {
    border-bottom-right-radius: #{$i}px !important
  }
}
