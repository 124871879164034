@import "../utils/all";

.sb-tab {
  .tab-header {
    font-size: $font-14;
    padding: 6px 0;
    text-align: center;
  }

  .tabs-content {
    line-height: 1.5;

    a {
      color: $sb-black;
      color: var(--sb-black);
    }
  }

  .tab-content-message {
    padding: 0 0 4px 10px;
  }

  .tabs-nav {
    cursor: pointer;
  }

  .tabs-nav .active {
    text-decoration: none;
    border-bottom: 1.5px solid $sb-orange;
    border-bottom: 1.5px solid var(--sb-orange);
    font-weight: 600;
  }

  .tabs-content .tab-panel {
    display: none;
    visibility: hidden;
  }

  .tabs-content .tab-panel.active {
    display: block;
    visibility: visible;
  }
}
