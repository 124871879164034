.stooltip-container {
  padding: 0 !important;
  color: $sb-vanta-black !important;
  color: var(--sb-vanta-black) !important;
  border: 0 solid $sb-menu-icon !important;
  border: 0 solid var(--sb-menu-icon) !important;
  background-color: $sb-white !important;
  background-color: var(--sb-white) !important;
  min-width: 260px;
  box-shadow: none;
  opacity: 1 !important;

  &.place-right::after {
    margin-top: -4px !important;
    border-right-color: $sb-white !important;
    border-right-color: var(--sb-white) !important;
    border-right-style: solid !important;
  }

  &.place-top::after {
    margin-top: -4px !important;
    border-top-color: $sb-white !important;
    border-top-color: var(--sb-white) !important;
    border-right-style: solid !important;
  }

  .modal-dialog-div {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .stooltip-list {
    padding: 0;
    padding-bottom: 18px;

    .stooltip-list-header {
      font-size: 20px;
      padding: 10px;
      font-weight: 600;
      color: $custom-color-86;
      color: var(--custom-color-86);
      padding-top: 24px;
      padding-bottom: 12px;
      padding-left: 16px;
    }

    .stooltip-list-description {
      padding-bottom: 18px;
      padding-left: 16px;
      padding-right: 16px;
      color: $sb-checklist-gray;
      color: var(--sb-checklist-gray);
    }

    .stooltip-list-title {
      color: $custom-color-87;
      color: var(--custom-color-87);
      padding: 10px;
      font-size: 12px;
      padding-top: 24px;
      padding-bottom: 12px;
    }

    .stooltip-badge {
      padding: 5px;
      background: $custom-color-72;
      background: var(--custom-color-72);
      color: $custom-color-37;
      color: var(--custom-color-37);
      font-size: 10px;
      border-radius: 8px;
      margin-left: 10px;
    }

    .new-badge {
      background-color: $custom-color-73;
      background-color: var(--custom-color-73);
      color: $sb-white;
      color: var(--sb-white);
      border-radius: 20px;
      padding: 4px 8px;
      line-height: 1;
    }

    .stooltip-list-item {
      display: flex;
      color: $custom-color-88;
      color: var(--custom-color-88);
      flex-direction: row;
      cursor: pointer;
      justify-content: space-between;
      font-size: 16px;
      padding: 12px 16px;

      &:hover {
        background-color: $sb-ui-blue-light-2;
        background-color: var(--sb-ui-blue-light-2);
      }
    }

    .stooltip-list-item .holdings {
      &:hover {
        background-color: $sb-black-5;
        background-color: var(--sb-black-5);
      }
    }

    .stooltip-list-item-disabled {
      color: $custom-color-89;
      color: var(--custom-color-89);
      cursor: not-allowed;
    }

    .stooltip-arrow {
      color: $custom-color-90;
      color: var(--custom-color-90);
    }

    .stooltip-button-container .stooltip-button {
      color: $sb-secondary-default-color;
      color: var(--sb-secondary-default-color);
    }

    .stooltip-btn-hover {
      cursor: pointer;
    }
  }

  .stooltip-list.manage-funds {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 993px) {
  .stooltip-container {
    box-shadow: -1px 1px 10px 2px $sb-gray;
    box-shadow: -1px 1px 10px 2px var(--sb-gray);
    max-width: 400px;

    .modal-dialog-div {
      padding-left: 0;
      padding-right: 0;
    }

    .stooltip-list {
      padding: 0;
      padding-bottom: 18px;
    }

    .stooltip-list.manage-funds {
      padding: 0;
      padding-bottom: 0;
    }

    .stooltip-list-item {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .stooltip-button-container.portfolio-details-wizard {
    .stooltip-button {
      width: 46px !important;
      // padding: 2px !important;
      display: inline;
      min-width: 44px;
      min-height: 20px;
      height: 44px !important;
      padding: 0 !important;
      text-align: center;
      font-size: 12px;
    }
  }
}
