@import "../../../styles/utils/all";
@import "../../../styles/utils/colors";

.sub-menu-item-separator {
  margin-left: 9px;
  position: relative;
}

.sub-menu-item-divider {
  margin-left: 20px;
  position: relative;
}

.sub-menu-item-container {
  &:hover {
    background-color: transparent !important;
  }
}

.sub-menu-item-text {
  margin-left: 12px;
}
