.DateInput {
  width: 100%;
  display: block;
}

.DateInput_input {
  margin: 0 !important;
  font-weight: 400;
  color: $custom-color-84;
  color: var(--custom-color-84);
}

.CalendarMonth_table {
  td,
  th {
    padding: 0;
    border: 0;
  }
}

.SingleDatePicker_picker {
  z-index: 1;
}

.CalendarDay__default,
.CalendarDay__default:hover {
  border: 0;
  border-radius: 50%;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $sb-primary-btn-default;
  background: var(--sb-primary-btn-default);
  border-radius: 50%;
  border: 0;
}

.SingleDatePicker,
.SingleDatePickerInput__withBorder {
  width: 100% !important;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  border: 0;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: transparent !important;
  color: $sb-border-gray !important;
  color: var(--sb-border-gray) !important;
  border: 0 !important;
}

@media only screen and (max-width: 576px) {
  .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder {
    display: flex;
    justify-content: center;
  }

  .sip-pg-custom-date .SingleDatePicker {
    left: 60px;
  }
}
