.uploaderControls > label {
  cursor: pointer;
  width: 25%;
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  font-size: $font-14;
  font-weight: 700;
  height: 53px;
  background: $sb-white;
  background: var(--sb-white);
  padding-top: 16px;
  text-align: center;
}

.uploaderControls .image-submit-button {
  cursor: pointer;
  background-color: $sb-white;
  background-color: var(--sb-white);
  color: $sb-black;
  color: var(--sb-black);
  font-size: $font-14;
  font-weight: 700;
  height: 53px;
  border: none;
  width: 25%;
  border-radius: 0;
  border-left: $custom-color-59 1px solid;
  border-left: var(--custom-color-59) 1px solid;
}

.uploaderControls .image-submit-button:disabled {
  cursor: not-allowed;
  background-color: $custom-color-60;
  background-color: var(--custom-color-60);
  color: $sb-slate-gray;
  color: var(--sb-slate-gray);
}

.ReactCrop img {
  max-width: 100%;
  max-height: 100%;
}

.skylight-wrapper {
  .skylight-close-button {
    font-size: 2.5em !important;
    right: 4px !important;
    top: 2px !important;
    color: $sb-charcoal;
    color: var(--sb-charcoal);
    padding: 0 10px;
  }

  .skylight-dialog {
    button {
      &:focus {
        background-color: $custom-color-61;
        background-color: var(--custom-color-61);
      }
    }
  }

  .popup-add-image {
    margin-top: 4px;
  }
}
