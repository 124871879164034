.replan {
  .offering-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .text-small {
    font-size: 14px;
    color: $sb-menu-icon;
    color: var(--sb-menu-icon);
  }

  .replan-step-heading {
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: $sb-slate-gray;
    color: var(--sb-slate-gray);
  }

  .replan-step-title {
    background: $custom-color-66;
    background: var(--custom-color-66);
    color: $custom-color-67;
    color: var(--custom-color-67);
    padding: 10px 10px 10px 12px;
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
  }

  .plan-option-selector .plan-amount-selection {
    margin: 0 15px;
    display: inline-block;
  }

  .plan-option-selector .plan-duration-selection {
    font-size: 12px;
    vertical-align: middle;
    color: $sb-description-gray;
    color: var(--sb-description-gray);
  }

  select.plan-select-tag {
    padding: 10px 20px;
    border: 1px solid $custom-color-121;
    border: 1px solid var(--custom-color-121);
    height: 45px;
    background-color: $sb-white;
    background-color: var(--sb-white);
    background-repeat: no-repeat;
    background-size: 20px;
    min-width: 260px;
    background-position: center right 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .gef-save-card {
    margin-top: 10px;

    .gef-card {
      border: 1px solid $sb-black-125;
      border: 1px solid var(--sb-black-125);
      border-radius: 0.25rem;

      .card-body {
        padding: 1.25rem;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
      }

      .amount {
        font-size: 16px;
        font-weight: "600";
        color: $custom-color-68;
        color: var(--custom-color-68);
        text-align: right;

        &.black {
          color: $sb-black;
          color: var(--sb-black);
        }
      }
    }
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid $sb-black-10;
    border-top: 1px solid var(--sb-black-10);
  }

  .education-plan-confirmation-block.highlight-block {
    font-size: 16px;
    font-weight: 600;
    color: $sb-vanta-black;
    color: var(--sb-vanta-black);
  }

  .education-plan-confirmation-block {
    font-size: 14px;
    color: $sb-description-gray;
    color: var(--sb-description-gray);

    p {
      margin: 0;
    }
  }

  .input-group {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .input-field {
      flex: 8;
      margin: 0;
      padding: 0 12px;
    }

    .suffix {
      display: flex;
      flex: 2;
      background: $sb-border-gray;
      background: var(--sb-border-gray);

      span {
        font-size: 16px;
        width: 100%;
        text-align: center;
        margin: auto;
        padding: 0 10px;
      }
    }
  }

  .plan-summary {
    .summary-group {
      padding: 7px 0;
    }

    .summary-group-title {
      font-size: 16px;
      font-weight: bold;
    }

    .input-label {
      font-size: 16px;
      padding: 0.5rem 0;
      margin: 0;
    }

    .input-subscript {
      text-transform: capitalize;
    }

    .ff-year-block {
      background: $custom-color-17;
      background: var(--custom-color-17);
      padding: 6px;
      border-radius: 4px;
      flex: 1;
      margin: 5px;

      .year {
        font-size: 18px;
      }

      &.active {
        background: $sb-white;
        background: var(--sb-white);
        border: 1px solid $sb-orange;
        border: 1px solid var(--sb-orange);
      }
    }

    .disabled-input {
      background: $sb-black-20;
      background: var(--sb-black-20);
    }
  }
}
