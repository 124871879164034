@import "../../../../styles/utils/colors";

.sdot-icon-container {
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .sdot-icon {
        font-size: 20px;
    }
    
    .svertical-line {
        width: 1px;
        border: none;
        border-left: 1px dashed $sb-vanta-black;
        border-left: 1px dashed var(--sb-vanta-black);
        min-height: inherit;
        height: 35px;
        margin-left: 9px;
        margin-bottom: 1px;
    }
}