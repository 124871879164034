@import "../../../styles/utils/colors";

.desktop-sidebar {
  .profile-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .profile-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
  }

  .profile-overlay {
    position: absolute;
    width: 2750px;
    height: 1000px;
    right: -100px;
    top: -60px;
  }

  .profile-username {
    margin-bottom: 6px;
  }

  .profile-email {
    margin-bottom: 16px;
  }

  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
  }

  .health-score-container {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
  }

  .profile-divider {
    height: 1px;
    width: 80%;
    background-color: #f7f7f7;
  }

  .desktop-sidebar-avatar-style {
    position: absolute;
    align-items: center;
    background-color: $sb-orange;
    background-color: var(--sb-orange);
    color: $sb-white;
    color: var(--sb-white);
    display: flex;
    justify-content: center;
    line-height: 32px !important;
    font-size: 14px !important;
  }

  .desktop-button {
    margin-bottom: 18px;
    .sb-btn-normal {
      height: 32px;
      padding: 6.5px 12px;
    }
  }

  .score-label {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 40%;
  }
  .profile-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    background: white;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .profile-menu::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .profile-menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
}
