.score-bar {
  height: 12px;
  width: 80%;
  border-radius: 20px;
  background-color: $custom-color-72;
  background-color: var(--custom-color-72);
  margin: 16px 0 4px;

  .fund-score {
    height: 12px;
    border-radius: 20px;
  }
}

.overall-score {
  .score-bar {
    width: 91%;
  }

  p {
    width: 95% !important;
  }
}

@media only screen and (max-width: 991px) {
  .score-bar {
    width: 90%;
  }

  .overall-score {
    .score-bar {
      width: 95%;
    }

    p {
      width: 100% !important;
    }
  }
}
