@import "../../../../styles/utils/colors";

.image-card {
  background-color: $card-layout-description-text;
  background-color: var(--card-layout-description-text);
  background-repeat: no-repeat, repeat-x;
  background-size: cover, contain;
  background-position: left, right;
  background-image: url('../images/misc/nudge-bg.png')
}

.upload-or-create-container {
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);
  border-radius: 5px;
}

.upload-image {
  width: 25px;
}

.separator-container {
  position: relative;

  .separator {
    border-bottom: 1px solid $sb-grey-88;
    border-bottom: 1px solid var(--sb-grey-88);
    width: 100%;
    position: absolute;
  }
}

.or-circle-container {
  background: transparent;
  width: 100%;
  position: absolute;
}

.or-circle {
  background-color: $custom-color-4;
  background-color: var(--custom-color-4);
  border-radius: 50%;
  z-index: 10;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: -18px;
}