.time-line-loader {
  .wrapper {
    margin: 20px;
    padding: 20px;
    background: $sb-white;
    background: var(--sb-white);
    height: 500px;
    display: flex;
    flex-direction: column;
  }

  .wrapper-cell {
    display: flex;
    margin-bottom: 10px;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  .animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $sb-secondary-disabled-background;
    background: var(--sb-secondary-disabled-background);
    background: linear-gradient(to right, $sb-secondary-disabled-background 8%, $custom-color-113 18%, $sb-secondary-disabled-background 33%);
    background: linear-gradient(to right, var(--sb-secondary-disabled-background) 8%, var(--custom-color-113) 18%, var(--sb-secondary-disabled-background) 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }

  .image {
    height: 12px;
    width: 12px;
    border-radius: 25px;
    margin-top: 4px;

    @extend .animated-background;
  }

  .text {
    margin-left: 12px;
    width: 100%;
    padding-left: 12px;
  }

  .text-line {
    height: 10px;
    width: 100%;
    margin: 4px 0;

    @extend .animated-background;
  }

  .text-line-1 {
    height: 5px;
    width: 100%;
    margin: 10px 0;

    @extend .animated-background;
  }

  .timeline-padding {
    padding-bottom: 30px;
  }

  .vl {
    border-left: 1px solid $sb-secondary-disabled-background;
    border-left: 1px solid var(--sb-secondary-disabled-background);
    height: 220px;
    position: absolute;
    z-index: 1;
    margin-left: 5px;
    margin-top: 8px;
  }

  .vl-sip {
    border-left: 1px solid $sb-sip-gray;
    border-left: 1px solid var(--sb-sip-gray);
    height: 78px;
    position: absolute;
    z-index: 1;
    margin-left: 7px;
    margin-top: 20px;

    @media only screen and (max-width: 600px) {
      margin-left: 7px;
      margin-top: 18px;
      height: 80px;
    }
  }

  .text-line-sip {
    height: 5px;
    width: 100%;
    margin: 10px 0;
  }

  .circle {
    height: 16px;
    width: 16px;
    border-radius: 32px;
    margin-top: 4px;
    padding: 2px solid $sb-white;
    padding: 2px solid var(--sb-white);
    background-color: $sb-white;
    background-color: var(--sb-white);
    border: 2px solid $sb-gray;
    border: 2px solid var(--sb-gray);
    position: absolute;
  }

  .green-circle {
    height: 16px;
    width: 16px;
    border-radius: 32px;
    margin-top: 4px;
    background-color: $sb-timeline-green;
    background-color: var(--sb-timeline-green);
    border: 2px solid $sb-gray;
    border: 2px solid var(--sb-gray);
    box-shadow: inset 0 0 0 2px $sb-white;
    box-shadow: inset 0 0 0 2px var(--sb-white);
    position: absolute;
  }

  .description {
    height: 40px;
    margin-left: 12px;
    width: 100%;
    font-size: 14px;
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
    padding-left: 12px;
  }

  .coming-soon {
    border: 1px solid $custom-color-35;
    border: 1px solid var(--custom-color-35);
    padding: 4px 5px 1px 5px !important;
    font-size: 10px;
    color: $custom-color-35;
    color: var(--custom-color-35);
    float: left;
    margin-left: 8px;
  }
}
