.gef-save-card-modal {
  background-color: $sb-white;
  background-color: var(--sb-white);
  line-height: 1.1;

  .gef-save-card-heading {
    font-weight: 600;
    font-size: 21px;
    padding-bottom: 6px;
    color: $sb-black;
    color: var(--sb-black);
  }

  .gef-save-card-modal-buttons {
    button {
      padding: 7px !important;
      // margin: 10px auto;
      height: 45px !important;
      font-size: $font-16 !important;
      border-radius: 10px;
      margin-right: 10px;
    }

    .secondary-btn {
      border: 2px solid $sb-light-gray;
      border: 2px solid var(--sb-light-gray);
    }
  }
}

.skylight-gef-save-card-modal {
  .skylight-wrapper .skylight-dialog {
    min-height: auto !important;
    z-index: 3000;
    width: 90% !important;
    max-width: 475px;
    top: 45px !important;
    left: 20px !important;
    right: 20px !important;
    margin: auto !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 993px) {
  .skylight-gef-save-card-modal {
    .skylight-wrapper .skylight-dialog {
      width: 475px !important;
      top: 10% !important;
      left: -25% !important;
    }
  }
}

@media only screen and (max-width: 993px) {
  .gef-save-card-modal {
    .withdraw-modal-buttons {
      .secondary-btn {
        margin-top: 10px;
      }
    }
  }
}
