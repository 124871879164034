.sinformation-card {
  padding: 10px;
  border-radius: 8px;

  p {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &.sinformation-card-outline-success {
    background-color: $success-bg-color;
    background-color: var(--success-bg-color);
    border: 1px solid $sb-green;
    border: 1px solid var(--sb-green);
  }

  &.sinformation-card-outline-info {
    background-color: $info-bg-color;
    background-color: var(--info-bg-color);
    border: 1px solid $info-color;
    border: 1px solid var(--info-color);
  }

  &.sinformation-card-outline-warning {
    background-color: $custom-color-83;
    background-color: var(--custom-color-83);
    border: 1px solid $custom-color-82;
    border: 1px solid var(--custom-color-82);
  }
}
