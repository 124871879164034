.signup-intro {
  ol {
    margin: 0;
    padding: 0 30px;
  }

  p {
    margin: 0;
    font-size: $font-16;
  }

  .doc-check-list {
    padding: 10px 0;
  }

  .info-text-container {
    padding-bottom: 12px;
  }

  li {
    padding: 3px 0;
    font-size: $font-16;
  }

  .title {
    font-size: $font-18;
  }

  .money-transfer-info {
    padding: 4px 0 12px 0;
  }
}

.signup-icons-sprite {
  background-image: url('../images/signup/signup-icons-sprite.png');
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  margin: 0 auto;

  &.data-secure {
    background-position: -0px -0px;
  }

  &.no-charges {
    background-position: -50px -50px;
  }

  &.no-auto-debit {
    background-position: -0px -50px;
  }

  &.pan-mandatory {
    background-position: -0px -100px;
  }

  &.money-safe {
    background-position: -50px -0px;
  }
}

.signup-intro-sprite {
  background-image: url('../images/misc/signup-intro-sprite.png');
  width: 51px;
  height: 51px;
  background-repeat: no-repeat;
  margin: 0 auto;

  &.safety-icon {
    background-position: -23px -2px;
  }

  &.zero-fees-icon {
    background-position: -23px -77px;
  }
}
