@import '../../../../styles/utils/colors';


.notification-sidebar {
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: 30px;

  .notification-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  // .arrow-up {
  //   width: 10; 
  //   height: 10;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   -webkit-filter: drop-shadow(0px -2px 4px $custom-color-142);
  //   filter: drop-shadow(0px -2px 4px $custom-color-142);
  //   border-bottom: 10px solid $sb-white;;
  //   border-radius: 5px;
  //   z-index: 200;
  // }

  .notification-overlay {
    position: absolute;
    width: 2750px;
    height: 1000px;
    right: -350px;
    top: -60px;
  }

  .profile-divider {
    height: 1px;
    width: 80%;
    background-color: #f7f7f7;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .profile-menu::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .profile-menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .desktop-notification-wrapper {
    position: absolute;
    right: -50px;
    width: 400px;
    z-index: 100;
    border: 1px solid $custom-color-17;
    border: 1px solid var(--custom-color-17);
    box-shadow: -2px 0px 13px 0 $custom-color-142;
    box-shadow: -2px 0px 13px 0 var(--custom-color-142);
    border-top: none;
    height: 520px;
    background: $sb-white;
    background: var(--sb-white);
    overflow: auto;
    border-radius: 5px;
  }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .desktop-notification-wrapper::-webkit-scrollbar {
      display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */
    .desktop-notification-wrapper {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
}
