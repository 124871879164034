.portfolio-accordion {
  background-color: $sb-white;
  background-color: var(--sb-white);
  border-radius: 8px !important;

  .accordion-heading {
    padding: 7px 15px;
    margin: 0.15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background-color: $sb-sidemenu-gray;
    background-color: var(--sb-sidemenu-gray);
  }

  .dropdown-open {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .dropdown-closed {
    border-radius: 8px;
  }
}

.explore-gs-nudge {
  border: 1px solid $sb-grey-88;
  background-color: $sb-white;
  width: 343px;
  overflow: hidden;

  .label-new {
    width: 42px;
    border-radius: 11px;
    background-color: $custom-color-108;
  }

  .nudge-img-mobile {
    min-width: 133px;
    width: 133px;
  }

  .nudge-img {
    min-width: 114px;
    width: 114px;
    margin-bottom: -32px;
  }

  .nudge-desc-mobile {
    margin-top: -16px;
  }
}
