@import "../utils/colors";

.image-preview-section {
  height: 50%;
  width: 100%;
  max-height: 300px;
  background: $sb-light-gray;
  background: var(--sb-light-gray);
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 10px;

  .uploader-text {
    display: block;
    cursor: pointer;
    width: 100%;
    font-weight: bold;

    i {
      font-size: $font-58;
      color: $sb-description-gray;
      color: var(--sb-description-gray);
      display: block;
    }
  }
}

.authorise-image-sample {
  text-align: center;
  margin-top: 20px;

  img {
    max-width: 100%;
  }
}

.authorisation-guideline {
  background-color: $custom-color-63;
  background-color: var(--custom-color-63);
  margin-top: 20px;
  padding: 10px 0;
  border: 1px solid $custom-color-129;
  border: 1px solid var(--custom-color-129);
}

.authorisation-heading {
  padding: 0 10px 10px 10px;
  color: $sb-black;
  color: var(--sb-black);
}

.kyc-authorisation-icon-padding {
  padding-right: 5px;
}

.skip-document-upload-msg {
  margin-top: 5px;
}

.preview-image {
  background-repeat: no-repeat;
  background-origin: border-box;
  width: 100%;
  height: 200px;
  background-size: contain;
  background-position: center;
}
