.switch-process-steps div.card-with-placeholder-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $custom-color-91;
  background: var(--custom-color-91);
  border-radius: 30px;
  width: 60px !important;
  height: 60px !important;

  img {
    width: 30px !important;
    height: 30px !important;
  }
}
