@import "../../styles/utils/colors";

.custom-input-label {
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  font-size: $font-14;
  font-weight: 600;
}

.investment-card {
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 3%;
  padding-bottom: 3%;
}

.investment-card .right-arrow {
  font-size: 2em;
}

.info-investment-date {
  padding: 10px 0;
  margin-top: 8px;
  margin-bottom: 4px;
}

.material-icons.investment-success-icon {
  font-size: $font-42;
  color: $sb-primary-btn-default;
  color: var(--sb-primary-btn-default);
  margin-top: 10px;
}

.transfer-info-text {
  font-weight: 600;
  font-size: $font-22;
}

.transfer-info-text,
.transfer-info-title {
  text-align: center;
  margin: 0 0 4px 0;
}

.investment-start-text {
  font-size: $font-14;
}

.investment-start-date {
  font-size: $font-14;
  font-weight: 700;
}

.investment-info-notification-card {
  margin: 10px 0;
  border-radius: 8px;
  padding: 14px;

  .info-notification-title {
    color: $sb-black;
    color: var(--sb-black);
    margin-bottom: 10px;
  }

  .info-notification-description {
    color: $sb-black;
    color: var(--sb-black);
  }
}

.investment-info-card {
  margin: 10px 0;
  border-radius: 6px;
  border: 1px solid $info-color;
  border: 1px solid var(--info-color);
  padding: 16px 12px;
  background-color: $info-bg-color;
  background-color: var(--info-bg-color);
}
