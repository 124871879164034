@import "../utils/all";

.app-bar {
  background-color: $sb-white;
  background-color: var(--sb-white);
  box-shadow: 0 2px 5px 0 $sb-black-6;
  box-shadow: 0 2px 5px 0 var(--sb-black-6);

  .menu-hamburger-icon {
    font-size: $font-28;
  }
}

#slide-out {
  background-color: $sb-white;
  background-color: var(--sb-white);

  &.side-nav li {
    padding: 0;
  }

  &.side-nav a,
  &.side-nav span.menu-span {
    padding: 0 15px;
    color: $sb-slate-gray;
    color: var(--sb-slate-gray);
    height: 3rem;
    line-height: 3rem;
    display: flex;
    align-items: center;

    .material-icons {
      vertical-align: middle;
    }
  }

  .divider {
    background-color: $custom-color-45;
    background-color: var(--custom-color-45);
  }

  .side-nav-text {
    padding-left: 15px;
    font-size: $font-14;
    line-height: 2;
  }

  .sub-side-nav-text {
    padding-left: 15px;
    font-size: $font-14;
    line-height: 1;
    display: block;
  }

  .margin-left {
    padding-left: 38px;
  }

  .col.s10,
  .col.s12 {
    padding: 0;
  }

  .col.s2 .material-icons {
    margin-top: 10px;
    cursor: pointer;
    color: $custom-color-11;
    color: var(--custom-color-11);
  }

  .sub-menu__contentInner > ul > li:first-child {
    padding-top: 10px;
  }
}

.app-bar__menu-button {
  float: left;
  font-size: $font-25;
  background: none;
  border: none;
  color: $sb-orange;
  color: var(--sb-orange);
  margin-left: -5px;
}

.app-bar__logo {
  text-align: center;
}

.app-bar__back-button {
  font-size: $font-15;
  background: none;
  border: none;
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);

  &:focus,
  &:active,
  &:hover {
    background: transparent;
  }
}

.terms-dispute-points li {
  list-style-type: circle;
  margin-left: 10px;
}

.terms-and-policy-title {
  font-weight: bold;
  margin-left: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.terms-of-use-content {
  margin-left: -45px;
}

.terms-and-policy-no-space {
  margin-left: -10px;
}

.terms-and-policy-alphabet-list {
  list-style-type: lower-latin;
}

ul.terms-and-policy-disc-list li {
  list-style-type: disc;
  margin-left: 15px;
}

.app-bar-left-button {
  text-align: center;
}
