@import "../utils/all";

.public-mf-page-container #app-container-heading {
  max-width: 100%;
}

#app-container-heading {
  background-color: $sb-white;
  background-color: var(--sb-white);
  -webkit-box-shadow: 0 2px 5px -2px $sb-black-16;
  -webkit-box-shadow: 0 2px 5px -2px var(--sb-black-16);
  -moz-box-shadow: 0 2px 5px -2px $sb-black-16;
  -moz-box-shadow: 0 2px 5px -2px var(--sb-black-16);
  box-shadow: 0 2px 5px -2px $sb-black-16;
  box-shadow: 0 2px 5px -2px var(--sb-black-16);

  .app-container-heading-text {
    padding: 0.5rem;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 993px) {
    .app-container-heading-text {
      margin-right: 70px;
      margin-left: 70px;
    }
  }

  .app-container-left-align {
    margin-left: 0;
    margin-right: 0;
    text-align: left !important;
  }
}

.app-container-heading-container {
  align-items: center;
  background-color: $sb-white;
  background-color: var(--sb-white);
  box-shadow: 0 1px 4px $sb-black-20;
  box-shadow: 0 1px 4px var(--sb-black-20);
  display: flex;
  justify-content: space-between;
  padding: 18px 16px 18px 16px;
  top: 0;
  width: 100%;
  z-index: 4;

  .right-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
  }
}

.app-container-heading-sub-container {
  align-items: center;
  display: flex;
  flex: 1;
}

.app-container-heading-scripbox-icon {
  align-items: center;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  z-index: 1;
}

.app-container-heading-back-icon-container {
  display: flex;
  float: left;
  margin-right: 16px;
}

.app-container-heading-back-icon {
  vertical-align: middle;
}

.app-container-heading-header-container {
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
  display: flex;
  flex-direction: column;
  font-size: $font-18;
  font-weight: bold;
  height: 24px;
  line-height: 20px;
  flex: 1;

  &.center{
    justify-content: 'center';
  }

  &.left{
    justify-content: 'flex-start';
  }
}

.app-container-heading-icon-style {
  cursor: pointer;
  margin: -4px 12px -4px 0;
  vertical-align: text-top;
}

.app-container-heading-sub-title {
  color: $sb-black-65;
  color: var(--sb-black-65);
  font-size: $font-12;
  font-weight: normal;
  line-height: 14px;
  margin-top: 4px;
}

.app-container-heading-logo-container {
  display: flex;
}

.app-container-heading-notification-container,
.app-container-heading-help-container {
  display: flex;
  margin-left: 16px;
}
