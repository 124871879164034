@import "./utils/colors";

/*
    Global color classes. Do not import this file in any component css since it is available
    globally for the whole application
*/
:root {
    --sb-orange: #f58024;
  
    .text-sb-orange {
      color: $sb-orange;
      color: var(--sb-orange);
    }
  
    .background-sb-orange {
      background-color: $sb-orange;
      background-color: var(--sb-orange);
    }
  
    .border-sb-orange {
      border-color: $sb-orange !important;
      border-color: var(--sb-orange) !important;
    }
  
    --sb-black: #576574;
  
    .text-sb-black {
      color: $sb-black;
      color: var(--sb-black);
    }
  
    .background-sb-black {
      background-color: $sb-black;
      background-color: var(--sb-black);
    }
  
    .border-sb-black {
      border-color: $sb-black !important;
      border-color: var(--sb-black) !important;
    }
  
    --sb-black-fixed: #576574;
  
    .text-sb-black-fixed {
      color: $sb-black-fixed;
      color: var(--sb-black-fixed);
    }
  
    .background-sb-black-fixed {
      background-color: $sb-black-fixed;
      background-color: var(--sb-black-fixed);
    }
  
    .border-sb-black-fixed {
      border-color: $sb-black-fixed !important;
      border-color: var(--sb-black-fixed) !important;
    }
  
    --sb-green: #4ea986;
  
    .text-sb-green {
      color: $sb-green;
      color: var(--sb-green);
    }
  
    .background-sb-green {
      background-color: $sb-green;
      background-color: var(--sb-green);
    }
  
    .border-sb-green {
      border-color: $sb-green !important;
      border-color: var(--sb-green) !important;
    }
  
    --sb-white: #ffffff;
  
    .text-sb-white {
      color: $sb-white;
      color: var(--sb-white);
    }
  
    .background-sb-white {
      background-color: $sb-white;
      background-color: var(--sb-white);
    }
  
    .background-sb-transparent {
      background-color: transparent;
    }
  
    .border-sb-white {
      border-color: $sb-white !important;
      border-color: var(--sb-white) !important;
    }
  
    --sb-white-fixed: #ffffff;
  
    .text-sb-white-fixed {
      color: $sb-white-fixed;
      color: var(--sb-white-fixed);
    }
  
    .background-sb-white-fixed {
      background-color: $sb-white-fixed;
      background-color: var(--sb-white-fixed);
    }
  
    .border-sb-white-fixed {
      border-color: $sb-white-fixed !important;
      border-color: var(--sb-white-fixed) !important;
    }
  
    --sb-blue: #1b2330;
  
    .text-sb-blue {
      color: $sb-blue;
      color: var(--sb-blue);
    }
  
    .background-sb-blue {
      background-color: $sb-blue;
      background-color: var(--sb-blue);
    }
  
    .border-sb-blue {
      border-color: $sb-blue !important;
      border-color: var(--sb-blue) !important;
    }
  
    --sb-gray: #cccccc;
  
    .text-sb-gray {
      color: $sb-gray;
      color: var(--sb-gray);
    }
  
    .background-sb-gray {
      background-color: $sb-gray;
      background-color: var(--sb-gray);
    }
  
    .border-sb-gray {
      border-color: $sb-gray !important;
      border-color: var(--sb-gray) !important;
    }
  
    --sb-sidemenu-gray: #fafafa;
  
    .text-sb-sidemenu-gray {
      color: $sb-sidemenu-gray;
      color: var(--sb-sidemenu-gray);
    }
  
    .background-sb-sidemenu-gray {
      background-color: $sb-sidemenu-gray;
      background-color: var(--sb-sidemenu-gray);
    }
  
    .border-sb-sidemenu-gray {
      border-color: $sb-sidemenu-gray !important;
      border-color: var(--sb-sidemenu-gray) !important;
    }
  
    --sb-card-border: #e4e4e4;
  
    .text-sb-card-border {
      color: $sb-card-border;
      color: var(--sb-card-border);
    }
  
    .background-sb-card-border {
      background-color: $sb-card-border;
      background-color: var(--sb-card-border);
    }
  
    .border-sb-card-border {
      border-color: $sb-card-border !important;
      border-color: var(--sb-card-border) !important;
    }
  
    --sb-divider: #e5e5e5;
  
    .text-sb-divider {
      color: $sb-divider;
      color: var(--sb-divider);
    }
  
    .background-sb-divider {
      background-color: $sb-divider;
      background-color: var(--sb-divider);
    }
  
    .border-sb-divider {
      border-color: $sb-divider !important;
      border-color: var(--sb-divider) !important;
    }
  
    --sb-light-gray: #eeeeee;
  
    .text-sb-light-gray {
      color: $sb-light-gray;
      color: var(--sb-light-gray);
    }
  
    .background-sb-light-gray {
      background-color: $sb-light-gray;
      background-color: var(--sb-light-gray);
    }
  
    .border-sb-light-gray {
      border-color: $sb-light-gray !important;
      border-color: var(--sb-light-gray) !important;
    }
  
    --sb-slate-gray: #666666;
  
    .text-sb-slate-gray {
      color: $sb-slate-gray;
      color: var(--sb-slate-gray);
    }
  
    .background-sb-slate-gray {
      background-color: $sb-slate-gray;
      background-color: var(--sb-slate-gray);
    }
  
    .border-sb-slate-gray {
      border-color: $sb-slate-gray !important;
      border-color: var(--sb-slate-gray) !important;
    }
  
    --sb-menu-gray: #777777;
  
    .text-sb-menu-gray {
      color: $sb-menu-gray;
      color: var(--sb-menu-gray);
    }
  
    .background-sb-menu-gray {
      background-color: $sb-menu-gray;
      background-color: var(--sb-menu-gray);
    }
  
    .border-sb-menu-gray {
      border-color: $sb-menu-gray !important;
      border-color: var(--sb-menu-gray) !important;
    }
  
    --sb-disable-gray: #9a9a9a;
  
    .text-sb-orange {
      color: $sb-disable-gray;
      color: var(--sb-disable-gray);
    }
  
    .background-sb-disable-gray {
      background-color: $sb-disable-gray;
      background-color: var(--sb-disable-gray);
    }
  
    .border-sb-disable-gray {
      border-color: $sb-disable-gray !important;
      border-color: var(--sb-disable-gray) !important;
    }
  
    --kyc-table-gray: #afafaf;
  
    .text-kyc-table-gray {
      color: $kyc-table-gray;
      color: var(--kyc-table-gray);
    }
  
    .background-kyc-table-gray {
      background-color: $kyc-table-gray;
      background-color: var(--kyc-table-gray);
    }
  
    .border-kyc-table-gray {
      border-color: $kyc-table-gray !important;
      border-color: var(--kyc-table-gray) !important;
    }
  
    --sb-btn-border-radius: 2px;
  
    .text-sb-btn-border-radius {
      color: $sb-btn-border-radius;
      color: var(--sb-btn-border-radius);
    }
  
    .background-sb-btn-border-radius {
      background-color: $sb-btn-border-radius;
      background-color: var(--sb-btn-border-radius);
    }
  
    .border-sb-btn-border-radius {
      border-color: $sb-btn-border-radius !important;
      border-color: var(--sb-btn-border-radius) !important;
    }
  
    --card-layout-background: #f4f4f4;
  
    .text-card-layout-background {
      color: $card-layout-background;
      color: var(--card-layout-background);
    }
  
    .background-card-layout-background {
      background-color: $card-layout-background;
      background-color: var(--card-layout-background);
    }
  
    .border-card-layout-background {
      border-color: $card-layout-background !important;
      border-color: var(--card-layout-background) !important;
    }
  
    --card-layout-primary-text: #576574;
  
    .text-card-layout-primary-text {
      color: $card-layout-primary-text;
      color: var(--card-layout-primary-text);
    }
  
    .background-card-layout-primary-text {
      background-color: $card-layout-primary-text;
      background-color: var(--card-layout-primary-text);
    }
  
    .border-card-layout-primary-text {
      border-color: $card-layout-primary-text !important;
      border-color: var(--card-layout-primary-text) !important;
    }
  
    --card-layout-description-text: #8e8c8e;
  
    .text-card-layout-description-text {
      color: $card-layout-description-text;
      color: var(--card-layout-description-text);
    }
  
    .background-card-layout-description-text {
      background-color: $card-layout-description-text;
      background-color: var(--card-layout-description-text);
    }
  
    .border-card-layout-description-text {
      border-color: $card-layout-description-text !important;
      border-color: var(--card-layout-description-text) !important;
    }
  
    --sb-success-green: #12a279;
  
    .text-sb-success-green {
      color: $sb-success-green;
      color: var(--sb-success-green);
    }
  
    .background-sb-success-green {
      background-color: $sb-success-green;
      background-color: var(--sb-success-green);
    }
  
    .border-sb-success-green {
      border-color: $sb-success-green !important;
      border-color: var(--sb-success-green) !important;
    }
  
    --sb-nuetral-yellow: #f2c964;
  
    .text-sb-nuetral-yellow {
      color: $sb-nuetral-yellow;
      color: var(--sb-nuetral-yellow);
    }
  
    .background-sb-nuetral-yellow {
      background-color: $sb-nuetral-yellow;
      background-color: var(--sb-nuetral-yellow);
    }
  
    .border-sb-nuetral-yellow {
      border-color: $sb-nuetral-yellow !important;
      border-color: var(--sb-nuetral-yellow) !important;
    }
  
    --sb-failure-yellow: #eda20a;
  
    .text-sb-failure-yellow {
      color: $sb-failure-yellow;
      color: var(--sb-failure-yellow);
    }
  
    .background-sb-failure-yellow {
      background-color: $sb-failure-yellow;
      background-color: var(--sb-failure-yellow);
    }
  
    .border-sb-failure-yellow {
      border-color: $sb-failure-yellow !important;
      border-color: var(--sb-failure-yellow) !important;
    }
  
    --sb-blue-link: #007aff;
  
    .text-sb-blue-link {
      color: $sb-blue-link;
      color: var(--sb-blue-link);
    }
  
    .background-sb-blue-link {
      background-color: $sb-blue-link;
      background-color: var(--sb-blue-link);
    }
  
    .border-sb-blue-link {
      border-color: $sb-blue-link !important;
      border-color: var(--sb-blue-link) !important;
    }
  
    --sb-blue-link-36: rgba(0, 122, 255, 0.36);
    --sb-blue-link-disabled: rgba(0, 122, 255, 0.5);
  
    .text-sb-blue-link-disabled {
      color: $sb-blue-link-disabled;
      color: var(--sb-blue-link-disabled);
    }
  
    .background-sb-blue-link-disabled {
      background-color: $sb-blue-link-disabled;
      background-color: var(--sb-blue-link-disabled);
    }
  
    .border-sb-blue-link-disabled {
      border-color: $sb-blue-link-disabled !important;
      border-color: var(--sb-blue-link-disabled) !important;
    }
  
    --sb-menu-icon: #808080;
  
    .text-sb-menu-icon {
      color: $sb-menu-icon;
      color: var(--sb-menu-icon);
    }
  
    .background-sb-menu-icon {
      background-color: $sb-menu-icon;
      background-color: var(--sb-menu-icon);
    }
  
    .border-sb-menu-icon {
      border-color: $sb-menu-icon !important;
      border-color: var(--sb-menu-icon) !important;
    }
  
    --sb-primary-btn-default: #00a08d;
  
    .text-sb-primary-btn-default {
      color: $sb-primary-btn-default;
      color: var(--sb-primary-btn-default);
    }
  
    .background-sb-primary-btn-default {
      background-color: $sb-primary-btn-default;
      background-color: var(--sb-primary-btn-default);
    }
  
    .border-sb-primary-btn-default {
      border-color: $sb-primary-btn-default !important;
      border-color: var(--sb-primary-btn-default) !important;
    }
  
    --sb-primary-btn-hover: #00a08d;
  
    .text-sb-primary-btn-hover {
      color: $sb-primary-btn-hover;
      color: var(--sb-primary-btn-hover);
    }
  
    .background-sb-primary-btn-hover {
      background-color: $sb-primary-btn-hover;
      background-color: var(--sb-primary-btn-hover);
    }
  
    .border-sb-primary-btn-hover {
      border-color: $sb-primary-btn-hover !important;
      border-color: var(--sb-primary-btn-hover) !important;
    }
  
    --sb-primary-btn-focus: #25b38f;
  
    .text-sb-primary-btn-focus {
      color: $sb-primary-btn-focus;
      color: var(--sb-primary-btn-focus);
    }
  
    .background-sb-primary-btn-focus {
      background-color: $sb-primary-btn-focus;
      background-color: var(--sb-primary-btn-focus);
    }
  
    .border-sb-primary-btn-focus {
      border-color: $sb-primary-btn-focus !important;
      border-color: var(--sb-primary-btn-focus) !important;
    }
  
    --sb-primary-btn-disabled: #b1e4d7;
  
    .text-sb-primary-btn-disabled {
      color: $sb-primary-btn-disabled;
      color: var(--sb-primary-btn-disabled);
    }
  
    .background-sb-primary-btn-disabled {
      background-color: $sb-primary-btn-disabled;
      background-color: var(--sb-primary-btn-disabled);
    }
  
    .border-sb-primary-btn-disabled {
      border-color: $sb-primary-btn-disabled !important;
      border-color: var(--sb-primary-btn-disabled) !important;
    }
  
    --sb-notication-badge-red: #da3236;
  
    .text-sb-notication-badge-red {
      color: $sb-notication-badge-red;
      color: var(--sb-notication-badge-red);
    }
  
    .background-sb-notication-badge-red {
      background-color: $sb-notication-badge-red;
      background-color: var(--sb-notication-badge-red);
    }
  
    .border-sb-notication-badge-red {
      border-color: $sb-notication-badge-red !important;
      border-color: var(--sb-notication-badge-red) !important;
    }
  
    --sb-notication-badge-gray: #9b9b9b;
  
    .text-sb-notication-badge-gray {
      color: $sb-notication-badge-gray;
      color: var(--sb-notication-badge-gray);
    }
  
    .background-sb-notication-badge-gray {
      background-color: $sb-notication-badge-gray;
      background-color: var(--sb-notication-badge-gray);
    }
  
    .border-sb-notication-badge-gray {
      border-color: $sb-notication-badge-gray !important;
      border-color: var(--sb-notication-badge-gray) !important;
    }
  
    --sb-secondary-default-border: #c0c0c0;
  
    .text-sb-secondary-default-border {
      color: $sb-secondary-default-border;
      color: var(--sb-secondary-default-border);
    }
  
    .background-sb-secondary-default-border {
      background-color: $sb-secondary-default-border;
      background-color: var(--sb-secondary-default-border);
    }
  
    .border-sb-secondary-default-border {
      border-color: $sb-secondary-default-border !important;
      border-color: var(--sb-secondary-default-border) !important;
    }
  
    --sb-secondary-default-color: #949494;
  
    .text-sb-secondary-default-color {
      color: $sb-secondary-default-color;
      color: var(--sb-secondary-default-color);
    }
  
    .background-sb-secondary-default-color {
      background-color: $sb-secondary-default-color;
      background-color: var(--sb-secondary-default-color);
    }
  
    .border-sb-secondary-default-color {
      border-color: $sb-secondary-default-color !important;
      border-color: var(--sb-secondary-default-color) !important;
    }
  
    --sb-secondary-disabled-color: #bdc3c7;
  
    .text-sb-secondary-disabled-color {
      color: $sb-secondary-disabled-color;
      color: var(--sb-secondary-disabled-color);
    }
  
    .background-sb-secondary-disabled-color {
      background-color: $sb-secondary-disabled-color;
      background-color: var(--sb-secondary-disabled-color);
    }
  
    .border-sb-secondary-disabled-color {
      border-color: $sb-secondary-disabled-color !important;
      border-color: var(--sb-secondary-disabled-color) !important;
    }
  
    --sb-secondary-disabled-background: #f6f6f6;
  
    .text-sb-secondary-disabled-background {
      color: $sb-secondary-disabled-background;
      color: var(--sb-secondary-disabled-background);
    }
  
    .background-sb-secondary-disabled-background {
      background-color: $sb-secondary-disabled-background;
      background-color: var(--sb-secondary-disabled-background);
    }
  
    .border-sb-secondary-disabled-background {
      border-color: $sb-secondary-disabled-background !important;
      border-color: var(--sb-secondary-disabled-background) !important;
    }
  
    --sb-accordion-blue: #e5f0fe;
  
    .text-sb-accordion-blue {
      color: $sb-accordion-blue;
      color: var(--sb-accordion-blue);
    }
  
    .background-sb-accordion-blue {
      background-color: $sb-accordion-blue;
      background-color: var(--sb-accordion-blue);
    }
  
    .border-sb-accordion-blue {
      border-color: $sb-accordion-blue !important;
      border-color: var(--sb-accordion-blue) !important;
    }
  
    --sb-accordion-gray: #f9f9f9;
  
    .text-sb-accordion-gray {
      color: $sb-accordion-gray;
      color: var(--sb-accordion-gray);
    }
  
    .background-sb-accordion-gray {
      background-color: $sb-accordion-gray;
      background-color: var(--sb-accordion-gray);
    }
  
    .border-sb-accordion-gray {
      border-color: $sb-accordion-gray !important;
      border-color: var(--sb-accordion-gray) !important;
    }
  
    --sb-checklist-gray: #848f99;
  
    .text-sb-checklist-gray {
      color: $sb-checklist-gray;
      color: var(--sb-checklist-gray);
    }
  
    .background-sb-checklist-gray {
      background-color: $sb-checklist-gray;
      background-color: var(--sb-checklist-gray);
    }
  
    .border-sb-checklist-gray {
      border-color: $sb-checklist-gray !important;
      border-color: var(--sb-checklist-gray) !important;
    }
  
    --sb-border-gray: #d3d3d3;
  
    .text-sb-border-gray {
      color: $sb-border-gray;
      color: var(--sb-border-gray);
    }
  
    .background-sb-border-gray {
      background-color: $sb-border-gray;
      background-color: var(--sb-border-gray);
    }
  
    .border-sb-border-gray {
      border-color: $sb-border-gray !important;
      border-color: var(--sb-border-gray) !important;
    }
  
    --sb-charcoal: #333333;
  
    .text-sb-charcoal {
      color: $sb-charcoal;
      color: var(--sb-charcoal);
    }
  
    .background-sb-charcoal {
      background-color: $sb-charcoal;
      background-color: var(--sb-charcoal);
    }
  
    .border-sb-charcoal {
      border-color: $sb-charcoal !important;
      border-color: var(--sb-charcoal) !important;
    }
  
    --sb-sip-gray: #d8d8d8;
  
    .text-sb-sip-gray {
      color: $sb-sip-gray;
      color: var(--sb-sip-gray);
    }
  
    .background-sb-sip-gray {
      background-color: $sb-sip-gray;
      background-color: var(--sb-sip-gray);
    }
  
    .border-sb-sip-gray {
      border-color: $sb-sip-gray !important;
      border-color: var(--sb-sip-gray) !important;
    }
  
    --sb-sip-gray-border: #d9d9d9;
  
    .text-sb-sip-gray-border {
      color: $sb-sip-gray-border;
      color: var(--sb-sip-gray-border);
    }
  
    .background-sb-sip-gray-border {
      background-color: $sb-sip-gray-border;
      background-color: var(--sb-sip-gray-border);
    }
  
    .border-sb-sip-gray-border {
      border-color: $sb-sip-gray-border !important;
      border-color: var(--sb-sip-gray-border) !important;
    }
  
    --sb-sip-reallocate-gray: #ebebeb;
  
    .text-sb-sip-reallocate-gray {
      color: $sb-sip-reallocate-gray;
      color: var(--sb-sip-reallocate-gray);
    }
  
    .background-sb-sip-reallocate-gray {
      background-color: $sb-sip-reallocate-gray;
      background-color: var(--sb-sip-reallocate-gray);
    }
  
    .border-sb-sip-reallocate-gray {
      border-color: $sb-sip-reallocate-gray !important;
      border-color: var(--sb-sip-reallocate-gray) !important;
    }
  
    --sb-timeline-green: #21ab84;
  
    .text-sb-timeline-green {
      color: $sb-timeline-green;
      color: var(--sb-timeline-green);
    }
  
    .background-sb-timeline-green {
      background-color: $sb-timeline-green;
      background-color: var(--sb-timeline-green);
    }
  
    .border-sb-timeline-green {
      border-color: $sb-timeline-green !important;
      border-color: var(--sb-timeline-green) !important;
    }
  
    --sb-pale-orange: #fef0e5;
  
    .text-sb-pale-orange {
      color: $sb-pale-orange;
      color: var(--sb-pale-orange);
    }
  
    .background-sb-pale-orange {
      background-color: $sb-pale-orange;
      background-color: var(--sb-pale-orange);
    }
  
    .border-sb-pale-orange {
      border-color: $sb-pale-orange !important;
      border-color: var(--sb-pale-orange) !important;
    }
  
    --sb-text-gray: #495057;
  
    .text-sb-text-gray {
      color: $sb-text-gray;
      color: var(--sb-text-gray);
    }
  
    .background-sb-text-gray {
      background-color: $sb-text-gray;
      background-color: var(--sb-text-gray);
    }
  
    .border-sb-text-gray {
      border-color: $sb-text-gray !important;
      border-color: var(--sb-text-gray) !important;
    }
  
    --sb-description-gray: #969696;
  
    .text-sb-description-gray {
      color: $sb-description-gray;
      color: var(--sb-description-gray);
    }
  
    .background-sb-description-gray {
      background-color: $sb-description-gray;
      background-color: var(--sb-description-gray);
    }
  
    .border-sb-description-gray {
      border-color: $sb-description-gray !important;
      border-color: var(--sb-description-gray) !important;
    }
  
    --sb-black-50: rgba(0, 0, 0, 0.5);
  
    .text-sb-black-50 {
      color: $sb-black-50;
      color: var(--sb-black-50);
    }
  
    .background-sb-black-50 {
      background-color: $sb-black-50;
      background-color: var(--sb-black-50);
    }
  
    .border-sb-black-50 {
      border-color: $sb-black-50 !important;
      border-color: var(--sb-black-50) !important;
    }
  
    --sb-black-60: rgba(0, 0, 0, 0.6);
  
    .text-sb-black-60 {
      color: $sb-black-60;
      color: var(--sb-black-60);
    }
  
    .background-sb-black-60 {
      background-color: $sb-black-60;
      background-color: var(--sb-black-60);
    }
  
    .border-sb-black-60 {
      border-color: $sb-black-60 !important;
      border-color: var(--sb-black-60) !important;
    }
  
    --sb-black-86: rgba(0, 0, 0, 0.86);
  
    .text-sb-black-86 {
      color: $sb-black-86;
      color: var(--sb-black-86);
    }
  
    .background-sb-black-86 {
      background-color: $sb-black-86;
      background-color: var(--sb-black-86);
    }
  
    .border-sb-black-86 {
      border-color: $sb-black-86 !important;
      border-color: var(--sb-black-86) !important;
    }
  
    --sb-badge-green: #57b4e1;
  
    .text-sb-badge-green {
      color: $sb-badge-green;
      color: var(--sb-badge-green);
    }
  
    .background-sb-badge-green {
      background-color: $sb-badge-green;
      background-color: var(--sb-badge-green);
    }
  
    .border-sb-badge-green {
      border-color: $sb-badge-green !important;
      border-color: var(--sb-badge-green) !important;
    }
  
    --sb-black-40: rgba(0, 0, 0, 0.4);
  
    .text-sb-black-40 {
      color: $sb-black-40;
      color: var(--sb-black-40);
    }
  
    .background-sb-black-40 {
      background-color: $sb-black-40;
      background-color: var(--sb-black-40);
    }
  
    .border-sb-black-40 {
      border-color: $sb-black-40 !important;
      border-color: var(--sb-black-40) !important;
    }
  
    --info-color: #ffdbc1;
  
    .text-info-color {
      color: $info-color;
      color: var(--info-color);
    }
  
    .background-info-color {
      background-color: $info-color;
      background-color: var(--info-color);
    }
  
    .border-info-color {
      border-color: $info-color !important;
      border-color: var(--info-color) !important;
    }
  
    --info-bg-color: #fff0e6;
  
    .text-info-bg-color {
      color: $info-bg-color;
      color: var(--info-bg-color);
    }
  
    .background-info-bg-color {
      background-color: $info-bg-color;
      background-color: var(--info-bg-color);
    }
  
    .border-info-bg-color {
      border-color: $info-bg-color !important;
      border-color: var(--info-bg-color) !important;
    }
  
    --success-bg-color: #ecfcf8;
  
    .text-success-bg-color {
      color: $success-bg-color;
      color: var(--success-bg-color);
    }
  
    .background-success-bg-color {
      background-color: $success-bg-color;
      background-color: var(--success-bg-color);
    }
  
    .border-success-bg-color {
      border-color: $success-bg-color !important;
      border-color: var(--success-bg-color) !important;
    }
  
    --error-color: #ed6b66;
  
    .text-error-color {
      color: $error-color;
      color: var(--error-color);
    }
  
    .background-error-color {
      background-color: $error-color;
      background-color: var(--error-color);
    }
  
    .border-error-color {
      border-color: $error-color !important;
      border-color: var(--error-color) !important;
    }
  
    --error-bg-color: #fdf1f1;
  
    .text-error-bg-color {
      color: $error-bg-color;
      color: var(--error-bg-color);
    }
  
    .background-error-bg-color {
      background-color: $error-bg-color;
      background-color: var(--error-bg-color);
    }
  
    .border-error-bg-color {
      border-color: $error-bg-color !important;
      border-color: var(--error-bg-color) !important;
    }
  
    --sb-vanta-black: #000000;
  
    .text-sb-vanta-black {
      color: $sb-vanta-black;
      color: var(--sb-vanta-black);
    }
  
    .background-sb-vanta-black {
      background-color: $sb-vanta-black;
      background-color: var(--sb-vanta-black);
    }
  
    .border-sb-vanta-black {
      border-color: $sb-vanta-black !important;
      border-color: var(--sb-vanta-black) !important;
    }
  
    --sb-darkslateblue: #483d8b;
  
    .text-sb-darkslateblue {
      color: $sb-darkslateblue;
      color: var(--sb-darkslateblue);
    }
  
    .background-sb-darkslateblue {
      background-color: $sb-darkslateblue;
      background-color: var(--sb-darkslateblue);
    }
  
    .border-sb-darkslateblue {
      border-color: $sb-darkslateblue !important;
      border-color: var(--sb-darkslateblue) !important;
    }
  
    --sb-light-coral: #f08080;
  
    .text-sb-light-coral {
      color: $sb-light-coral;
      color: var(--sb-light-coral);
    }
  
    .background-sb-light-coral {
      background-color: $sb-light-coral;
      background-color: var(--sb-light-coral);
    }
  
    .border-sb-light-coral {
      border-color: $sb-light-coral !important;
      border-color: var(--sb-light-coral) !important;
    }
  
    --sb-deep-blue-custom: #0000ff;
  
    .text-sb-deep-blue-custom {
      color: $sb-deep-blue-custom;
      color: var(--sb-deep-blue-custom);
    }
  
    .background-sb-deep-blue-custom {
      background-color: $sb-deep-blue-custom;
      background-color: var(--sb-deep-blue-custom);
    }
  
    .border-sb-deep-blue-custom {
      border-color: $sb-deep-blue-custom !important;
      border-color: var(--sb-deep-blue-custom) !important;
    }
  
    --sb-alice-blue-custom: #f0f8ff;
  
    .text-sb-alice-blue-custom {
      color: $sb-alice-blue-custom;
      color: var(--sb-alice-blue-custom);
    }
  
    .background-sb-alice-blue-custom {
      background-color: $sb-alice-blue-custom;
      background-color: var(--sb-alice-blue-custom);
    }
  
    .border-sb-alice-blue-custom {
      border-color: $sb-alice-blue-custom !important;
      border-color: var(--sb-alice-blue-custom) !important;
    }
  
    --sb-green-custom: #008000;
  
    .text-sb-green-custom {
      color: $sb-green-custom;
      color: var(--sb-green-custom);
    }
  
    .background-sb-green-custom {
      background-color: $sb-green-custom;
      background-color: var(--sb-green-custom);
    }
  
    .border-sb-green-custom {
      border-color: $sb-green-custom !important;
      border-color: var(--sb-green-custom) !important;
    }
  
    --sb-orange-custom: #ffa500;
  
    .text-sb-orange-custom {
      color: $sb-orange-custom;
      color: var(--sb-orange-custom);
    }
  
    .background-sb-orange-custom {
      background-color: $sb-orange-custom;
      background-color: var(--sb-orange-custom);
    }
  
    .border-sb-orange-custom {
      border-color: $sb-orange-custom !important;
      border-color: var(--sb-orange-custom) !important;
    }
  
    --sb-black-30: rgba(0, 0, 0, 0.3);
  
    .text-sb-black-30 {
      color: $sb-black-30;
      color: var(--sb-black-30);
    }
  
    .background-sb-black-30 {
      background-color: $sb-black-30;
      background-color: var(--sb-black-30);
    }
  
    .border-sb-black-30 {
      border-color: $sb-black-30 !important;
      border-color: var(--sb-black-30) !important;
    }
  
    --sb-black-10: rgba(0, 0, 0, 0.1);
  
    .text-sb-black-10 {
      color: $sb-black-10;
      color: var(--sb-black-10);
    }
  
    .background-sb-black-10 {
      background-color: $sb-black-10;
      background-color: var(--sb-black-10);
    }
  
    .border-sb-black-10 {
      border-color: $sb-black-10 !important;
      border-color: var(--sb-black-10) !important;
    }
  
    --sb-black-11: rgba(0, 0, 0, 0.11);
  
    .text-sb-black-11 {
      color: $sb-black-11;
      color: var(--sb-black-11);
    }
  
    .background-sb-black-11 {
      background-color: $sb-black-11;
      background-color: var(--sb-black-11);
    }
  
    .border-sb-black-11 {
      border-color: $sb-black-11 !important;
      border-color: var(--sb-black-11) !important;
    }
  
    --sb-black-6: rgba(0, 0, 0, 0.06);
  
    .text-sb-black-6 {
      color: $sb-black-6;
      color: var(--sb-black-6);
    }
  
    .background-sb-black-6 {
      background-color: $sb-black-6;
      background-color: var(--sb-black-6);
    }
  
    .border-sb-black-6 {
      border-color: $sb-black-6 !important;
      border-color: var(--sb-black-6) !important;
    }
  
    --sb-black-5: rgba(0, 0, 0, 0.05);
  
    .text-sb-black-5 {
      color: $sb-black-5;
      color: var(--sb-black-5);
    }
  
    .background-sb-black-5 {
      background-color: $sb-black-5;
      background-color: var(--sb-black-5);
    }
  
    .border-sb-black-5 {
      border-color: $sb-black-5 !important;
      border-color: var(--sb-black-5) !important;
    }
  
    --sb-black-16: rgba(0, 0, 0, 0.16);
  
    .text-sb-black-16 {
      color: $sb-black-16;
      color: var(--sb-black-16);
    }
  
    .background-sb-black-16 {
      background-color: $sb-black-16;
      background-color: var(--sb-black-16);
    }
  
    .border-sb-black-16 {
      border-color: $sb-black-16 !important;
      border-color: var(--sb-black-16) !important;
    }
  
    --sb-black-15: rgba(0, 0, 0, 0.15);
  
    .text-sb-black-15 {
      color: $sb-black-15;
      color: var(--sb-black-15);
    }
  
    .background-sb-black-15 {
      background-color: $sb-black-15;
      background-color: var(--sb-black-15);
    }
  
    .border-sb-black-15 {
      border-color: $sb-black-15 !important;
      border-color: var(--sb-black-15) !important;
    }
  
    --sb-black-125: rgba(0, 0, 0, 0.125);
  
    .text-sb-black-125 {
      color: $sb-black-125;
      color: var(--sb-black-125);
    }
  
    .background-sb-black-125 {
      background-color: $sb-black-125;
      background-color: var(--sb-black-125);
    }
  
    .border-sb-black-125 {
      border-color: $sb-black-125 !important;
      border-color: var(--sb-black-125) !important;
    }
  
    --sb-black-20: rgba(0, 0, 0, 0.2);
  
    .text-sb-black-20 {
      color: $sb-black-20;
      color: var(--sb-black-20);
    }
  
    .background-sb-black-20 {
      background-color: $sb-black-20;
      background-color: var(--sb-black-20);
    }
  
    .border-sb-black-20 {
      border-color: $sb-black-20 !important;
      border-color: var(--sb-black-20) !important;
    }
  
    --sb-black-65: rgba(0, 0, 0, 0.65);
  
    .text-sb-black-65 {
      color: $sb-black-65;
      color: var(--sb-black-65);
    }
  
    .background-sb-black-65 {
      background-color: $sb-black-65;
      background-color: var(--sb-black-65);
    }
  
    .border-sb-black-65 {
      border-color: $sb-black-65 !important;
      border-color: var(--sb-black-65) !important;
    }
  
    --sb-black-75: rgba(0, 0, 0, 0.75);
  
    .text-sb-black-75 {
      color: $sb-black-75;
      color: var(--sb-black-75);
    }
  
    .background-sb-black-75 {
      background-color: $sb-black-75;
      background-color: var(--sb-black-75);
    }
  
    .border-sb-black-75 {
      border-color: $sb-black-75 !important;
      border-color: var(--sb-black-75) !important;
    }
  
    --sb-black-80: rgba(0, 0, 0, 0.8);
  
    .text-sb-black-80 {
      color: $sb-black-80;
      color: var(--sb-black-80);
    }
  
    .background-sb-black-80 {
      background-color: $sb-black-80;
      background-color: var(--sb-black-80);
    }
  
    .border-sb-black-80 {
      border-color: $sb-black-80 !important;
      border-color: var(--sb-black-80) !important;
    }
  
    --sb-black-85: rgba(0, 0, 0, 0.85);
  
    .text-sb-black-85 {
      color: $sb-black-85;
      color: var(--sb-black-85);
    }
  
    .background-sb-black-85 {
      background-color: $sb-black-85;
      background-color: var(--sb-black-85);
    }
  
    .border-sb-black-85 {
      border-color: $sb-black-85 !important;
      border-color: var(--sb-black-85) !important;
    }
  
    --sb-black-86: rgba(0, 0, 0, 0.86);
  
    .text-sb-black-86 {
      color: $sb-black-86;
      color: var(--sb-black-86);
    }
  
    .background-sb-black-86 {
      background-color: $sb-black-86;
      background-color: var(--sb-black-86);
    }
  
    .border-sb-black-86 {
      border-color: $sb-black-86 !important;
      border-color: var(--sb-black-86) !important;
    }
  
    --sb-black-90: rgba(0, 0, 0, 0.9);
  
    .text-sb-black-90 {
      color: $sb-black-90;
      color: var(--sb-black-90);
    }
  
    .background-sb-black-90 {
      background-color: $sb-black-90;
      background-color: var(--sb-black-90);
    }
  
    .border-sb-black-90 {
      border-color: $sb-black-90 !important;
      border-color: var(--sb-black-90) !important;
    }
  
    --sb-black-56: rgba(0, 0, 0, 0.56);
  
    .text-sb-black-56 {
      color: $sb-black-56;
      color: var(--sb-black-56);
    }
  
    .background-sb-black-56 {
      background-color: $sb-black-56;
      background-color: var(--sb-black-56);
    }
  
    .border-sb-black-56 {
      border-color: $sb-black-56 !important;
      border-color: var(--sb-black-56) !important;
    }
  
    --sb-black-3: rgba(0, 0, 0, 0.03);
  
    .text-sb-black-3 {
      color: $sb-black-3;
      color: var(--sb-black-3);
    }
  
    .background-sb-black-3 {
      background-color: $sb-black-3;
      background-color: var(--sb-black-3);
    }
  
    .border-sb-black-3 {
      border-color: $sb-black-3 !important;
      border-color: var(--sb-black-3) !important;
    }
  
    --sb-black-7: rgba(0, 0, 0, 0.07);
  
    .text-sb-black-7 {
      color: $sb-black-7;
      color: var(--sb-black-7);
    }
  
    .background-sb-black-7 {
      background-color: $sb-black-7;
      background-color: var(--sb-black-7);
    }
  
    .border-sb-black-7 {
      border-color: $sb-black-7 !important;
      border-color: var(--sb-black-7) !important;
    }
  
    --sb-black-4: rgba(0, 0, 0, 0.04);
  
    .text-sb-black-4 {
      color: $sb-black-4;
      color: var(--sb-black-4);
    }
  
    .background-sb-black-4 {
      background-color: $sb-black-4;
      background-color: var(--sb-black-4);
    }
  
    .border-sb-black-4 {
      border-color: $sb-black-4 !important;
      border-color: var(--sb-black-4) !important;
    }
  
    --sb-black-12: rgba(0, 0, 0, 0.12);
  
    .text-sb-black-12 {
      color: $sb-black-12;
      color: var(--sb-black-12);
    }
  
    .background-sb-black-12 {
      background-color: $sb-black-12;
      background-color: var(--sb-black-12);
    }
  
    .border-sb-black-12 {
      border-color: $sb-black-12 !important;
      border-color: var(--sb-black-12) !important;
    }
  
    --sb-black-0: rgba(0, 0, 0, 0);
  
    .text-sb-black-0 {
      color: $sb-black-0;
      color: var(--sb-black-0);
    }
  
    .background-sb-black-0 {
      background-color: $sb-black-0;
      background-color: var(--sb-black-0);
    }
  
    .border-sb-black-0 {
      border-color: $sb-black-0 !important;
      border-color: var(--sb-black-0) !important;
    }
  
    // Offering Color Codes
    --offeringShortTerm: #fff6e4;
  
    .text-offeringShortTerm {
      color: $offeringShortTerm;
      color: var(--offeringShortTerm);
    }
  
    .background-offeringShortTerm {
      background-color: $offeringShortTerm;
      background-color: var(--offeringShortTerm);
    }
  
    .border-offeringShortTerm {
      border-color: $offeringShortTerm !important;
      border-color: var(--offeringShortTerm) !important;
    }
  
    --offeringLongTerm: #eaf8e7;
  
    .text-offeringLongTerm {
      color: $offeringLongTerm;
      color: var(--offeringLongTerm);
    }
  
    .background-offeringLongTerm {
      background-color: $offeringLongTerm;
      background-color: var(--offeringLongTerm);
    }
  
    .border-offeringLongTerm {
      border-color: $offeringLongTerm !important;
      border-color: var(--offeringLongTerm) !important;
    }
  
    --offeringTaxSaver: #eff6ff;
  
    .text-offeringTaxSaver {
      color: $offeringTaxSaver;
      color: var(--offeringTaxSaver);
    }
  
    .background-offeringTaxSaver {
      background-color: $offeringTaxSaver;
      background-color: var(--offeringTaxSaver);
    }
  
    .border-offeringTaxSaver {
      border-color: $offeringTaxSaver !important;
      border-color: var(--offeringTaxSaver) !important;
    }
  
    --offeringDreamPlanner: #f0efff;
  
    .text-offeringDreamPlanner {
      color: $offeringDreamPlanner;
      color: var(--offeringDreamPlanner);
    }
  
    .background-offeringDreamPlanner {
      background-color: $offeringDreamPlanner;
      background-color: var(--offeringDreamPlanner);
    }
  
    .border-offeringDreamPlanner {
      border-color: $offeringDreamPlanner !important;
      border-color: var(--offeringDreamPlanner) !important;
    }
  
    --offeringMyFirstCrore: #eff6ff;
  
    .text-offeringMyFirstCrore {
      color: $offeringMyFirstCrore;
      color: var(--offeringMyFirstCrore);
    }
  
    .background-offeringMyFirstCrore {
      background-color: $offeringMyFirstCrore;
      background-color: var(--offeringMyFirstCrore);
    }
  
    .border-offeringMyFirstCrore {
      border-color: $offeringMyFirstCrore !important;
      border-color: var(--offeringMyFirstCrore) !important;
    }
  
    --offeringEmergency: #ffefef;
  
    .text-offeringEmergency {
      color: $offeringEmergency;
      color: var(--offeringEmergency);
    }
  
    .background-offeringEmergency {
      background-color: $offeringEmergency;
      background-color: var(--offeringEmergency);
    }
  
    .border-offeringEmergency {
      border-color: $offeringEmergency !important;
      border-color: var(--offeringEmergency) !important;
    }
  
    --offeringWealthEdgePlan: #eaf8e7;
  
    .text-offeringWealthEdgePlan {
      color: $offeringWealthEdgePlan;
      color: var(--offeringWealthEdgePlan);
    }
  
    .background-offeringWealthEdgePlan {
      background-color: $offeringWealthEdgePlan;
      background-color: var(--offeringWealthEdgePlan);
    }
  
    .border-offeringWealthEdgePlan {
      border-color: $offeringWealthEdgePlan !important;
      border-color: var(--offeringWealthEdgePlan) !important;
    }
  
    --offeringWealthEdgePlus: #eaf8e7;
  
    .text-offeringWealthEdgePlus {
      color: $offeringWealthEdgePlus;
      color: var(--offeringWealthEdgePlus);
    }
  
    .background-offeringWealthEdgePlus {
      background-color: $offeringWealthEdgePlus;
      background-color: var(--offeringWealthEdgePlus);
    }
  
    .border-offeringWealthEdgePlus {
      border-color: $offeringWealthEdgePlus !important;
      border-color: var(--offeringWealthEdgePlus) !important;
    }
  
    --offeringEducation: #eff6ff;
  
    .text-offeringEducation {
      color: $offeringEducation;
      color: var(--offeringEducation);
    }
  
    .background-offeringEducation {
      background-color: $offeringEducation;
      background-color: var(--offeringEducation);
    }
  
    .border-offeringEducation {
      border-color: $offeringEducation !important;
      border-color: var(--offeringEducation) !important;
    }
  
    --offeringRetireConfident: #fff5ef;
  
    .text-offeringRetireConfident {
      color: $offeringRetireConfident;
      color: var(--offeringRetireConfident);
    }
  
    .background-offeringRetireConfident {
      background-color: $offeringRetireConfident;
      background-color: var(--offeringRetireConfident);
    }
  
    .border-offeringRetireConfident {
      border-color: $offeringRetireConfident !important;
      border-color: var(--offeringRetireConfident) !important;
    }
  
    --offeringChildHeadstart: #ffefef;
  
    .text-offeringChildHeadstart {
      color: $offeringChildHeadstart;
      color: var(--offeringChildHeadstart);
    }
  
    .background-offeringChildHeadstart {
      background-color: $offeringChildHeadstart;
      background-color: var(--offeringChildHeadstart);
    }
  
    .border-offeringChildHeadstart {
      border-color: $offeringChildHeadstart !important;
      border-color: var(--offeringChildHeadstart) !important;
    }
  
    --offeringExternalInvestments: #fff1f1;
  
    .text-offeringExternalInvestments {
      color: $offeringExternalInvestments;
      color: var(--offeringExternalInvestments);
    }
  
    .background-offeringExternalInvestments {
      background-color: $offeringExternalInvestments;
      background-color: var(--offeringExternalInvestments);
    }
  
    .border-offeringExternalInvestments {
      border-color: $offeringExternalInvestments !important;
      border-color: var(--offeringExternalInvestments) !important;
    }
  
    --offeringPrincipalProtection: #eaefff;
  
    .text-offeringPrincipalProtection {
      color: $offeringPrincipalProtection;
      color: var(--offeringPrincipalProtection);
    }
  
    .background-offeringPrincipalProtection {
      background-color: $offeringPrincipalProtection;
      background-color: var(--offeringPrincipalProtection);
    }
  
    .border-offeringPrincipalProtection {
      border-color: $offeringPrincipalProtection !important;
      border-color: var(--offeringPrincipalProtection) !important;
    }
  
    // goals bar graph indicator
    --goalScripboxContribution: #edf523;
  
    .text-goalScripboxContribution {
      color: $goalScripboxContribution;
      color: var(--goalScripboxContribution);
    }
  
    .background-goalScripboxContribution {
      background-color: $goalScripboxContribution;
      background-color: var(--goalScripboxContribution);
    }
  
    .border-goalScripboxContribution {
      border-color: $goalScripboxContribution !important;
      border-color: var(--goalScripboxContribution) !important;
    }
  
    --filterSelectedColor: #0078ff;
  
    .text-filterSelectedColor {
      color: $filterSelectedColor;
      color: var(--filterSelectedColor);
    }
  
    .background-filterSelectedColor {
      background-color: $filterSelectedColor;
      background-color: var(--filterSelectedColor);
    }
  
    .border-filterSelectedColor {
      border-color: $filterSelectedColor !important;
      border-color: var(--filterSelectedColor) !important;
    }
  
    //buttons color code
    --sb-sea-green: #00a08d;
  
    .text-sb-sea-green {
      color: $sb-sea-green;
      color: var(--sb-sea-green);
    }
  
    .background-sb-sea-green {
      background-color: $sb-sea-green;
      background-color: var(--sb-sea-green);
    }
  
    .border-sb-sea-green {
      border-color: $sb-sea-green !important;
      border-color: var(--sb-sea-green) !important;
    }
  
    --sb-red-error: #ff0000;
  
    .text-sb-red-error {
      color: $sb-red-error;
      color: var(--sb-red-error);
    }
  
    .background-sb-red-error {
      background-color: $sb-red-error;
      background-color: var(--sb-red-error);
    }
  
    .border-sb-red-error {
      border-color: $sb-red-error !important;
      border-color: var(--sb-red-error) !important;
    }
  
    --sb-dark-red: #cc2a2a;
  
    .text-sb-dark-red {
      color: $sb-dark-red;
      color: var(--sb-dark-red);
    }
  
    .background-sb-dark-red {
      background-color: $sb-dark-red;
      background-color: var(--sb-dark-red);
    }
  
    .border-sb-dark-red {
      border-color: $sb-dark-red !important;
      border-color: var(--sb-dark-red) !important;
    }
  
    --sb-sea-green-dark: #008878;
  
    .text-sb-sea-green-dark {
      color: $sb-sea-green-dark;
      color: var(--sb-sea-green-dark);
    }
  
    .background-sb-sea-green-dark {
      background-color: $sb-sea-green-dark;
      background-color: var(--sb-sea-green-dark);
    }
  
    .border-sb-sea-green-dark {
      border-color: $sb-sea-green-dark !important;
      border-color: var(--sb-sea-green-dark) !important;
    }
  
    --sb-hover-grey-bg: #f8f8f8;
  
    .text-sb-hover-grey-bg {
      color: $sb-hover-grey-bg;
      color: var(--sb-hover-grey-bg);
    }
  
    .background-sb-hover-grey-bg {
      background-color: $sb-hover-grey-bg;
      background-color: var(--sb-hover-grey-bg);
    }
  
    .border-sb-hover-grey-bg {
      border-color: $sb-hover-grey-bg !important;
      border-color: var(--sb-hover-grey-bg) !important;
    }
  
    --sb-bg-rose: #fff1f1;
  
    .text-sb-bg-rose {
      color: $sb-bg-rose;
      color: var(--sb-bg-rose);
    }
  
    .background-sb-bg-rose {
      background-color: $sb-bg-rose;
      background-color: var(--sb-bg-rose);
    }
  
    .border-sb-bg-rose {
      border-color: $sb-bg-rose !important;
      border-color: var(--sb-bg-rose) !important;
    }
  
    --sb-ui-blue-light-2: #f6f9ff;
  
    .text-sb-ui-blue-light-2 {
      color: $sb-ui-blue-light-2;
      color: var(--sb-ui-blue-light-2);
    }
  
    .background-sb-ui-blue-light-2 {
      background-color: $sb-ui-blue-light-2;
      background-color: var(--sb-ui-blue-light-2);
    }
  
    .border-sb-ui-blue-light-2 {
      border-color: $sb-ui-blue-light-2 !important;
      border-color: var(--sb-ui-blue-light-2) !important;
    }
  
    --sb-btn-box-shadow: rgba(0, 0, 0, 0.5);
  
    .text-sb-btn-box-shadow {
      color: $sb-btn-box-shadow;
      color: var(--sb-btn-box-shadow);
    }
  
    .background-sb-btn-box-shadow {
      background-color: $sb-btn-box-shadow;
      background-color: var(--sb-btn-box-shadow);
    }
  
    .border-sb-btn-box-shadow {
      border-color: $sb-btn-box-shadow !important;
      border-color: var(--sb-btn-box-shadow) !important;
    }
  
    --sb-opacity-5: rgba(0, 0, 0, 0.36);
  
    .text-sb-opacity-5 {
      color: $sb-opacity-5;
      color: var(--sb-opacity-5);
    }
  
    .background-sb-opacity-5 {
      background-color: $sb-opacity-5;
      background-color: var(--sb-opacity-5);
    }
  
    .border-sb-opacity-5 {
      border-color: $sb-opacity-5 !important;
      border-color: var(--sb-opacity-5) !important;
    }
  
    --sb-opacity-3: rgba(0, 0, 0, 0.6);
  
    .text-sb-opacity-3 {
      color: $sb-opacity-3;
      color: var(--sb-opacity-3);
    }
  
    .background-sb-opacity-3 {
      background-color: $sb-opacity-3;
      background-color: var(--sb-opacity-3);
    }
  
    .border-sb-opacity-3 {
      border-color: $sb-opacity-3 !important;
      border-color: var(--sb-opacity-3) !important;
    }
  
    --sb-grey-88: #e0e0e0;
  
    .text-sb-grey-88 {
      color: $sb-grey-88;
      color: var(--sb-grey-88);
    }
  
    .background-sb-grey-88 {
      background-color: $sb-grey-88;
      background-color: var(--sb-grey-88);
    }
  
    .border-sb-grey-88 {
      border-color: $sb-grey-88 !important;
      border-color: var(--sb-grey-88) !important;
    }
  
    // toggle color codes
    --sb-toggle-border-color: #636363;
  
    .text-sb-toggle-border-color {
      color: $sb-toggle-border-color;
      color: var(--sb-toggle-border-color);
    }
  
    .background-sb-toggle-border-color {
      background-color: $sb-toggle-border-color;
      background-color: var(--sb-toggle-border-color);
    }
  
    .border-sb-toggle-border-color {
      border-color: $sb-toggle-border-color !important;
      border-color: var(--sb-toggle-border-color) !important;
    }
  
    // input box color codes
    --sb-input-error: #f56565;
  
    .text-sb-input-error {
      color: $sb-input-error;
      color: var(--sb-input-error);
    }
  
    .background-sb-input-error {
      background-color: $sb-input-error;
      background-color: var(--sb-input-error);
    }
  
    .border-sb-input-error {
      border-color: $sb-input-error !important;
      border-color: var(--sb-input-error) !important;
    }
  
    --sb-input-success: #0fac85;
  
    .text-sb-input-success {
      color: $sb-input-success;
      color: var(--sb-input-success);
    }
  
    .background-sb-input-success {
      background-color: $sb-input-success;
      background-color: var(--sb-input-success);
    }
  
    .border-sb-input-success {
      border-color: $sb-input-success !important;
      border-color: var(--sb-input-success) !important;
    }
  
    --sb-dark-gray: darkgray;
  
    .text-sb-dark-gray {
      color: $sb-dark-gray;
      color: var(--sb-dark-gray);
    }
  
    .background-sb-dark-gray {
      background-color: $sb-dark-gray;
      background-color: var(--sb-dark-gray);
    }
  
    .border-sb-dark-gray {
      border-color: $sb-dark-gray !important;
      border-color: var(--sb-dark-gray) !important;
    }
  
    //custom colors
    --custom-color-1: #00000059;
  
    .text-custom-color-1 {
      color: $custom-color-1;
      color: var(--custom-color-1);
    }
  
    .background-custom-color-1 {
      background-color: $custom-color-1;
      background-color: var(--custom-color-1);
    }
  
    .border-custom-color-1 {
      border-color: $custom-color-1 !important;
      border-color: var(--custom-color-1) !important;
    }
  
    --custom-color-2: #e9ecef;
  
    .text-custom-color-2 {
      color: $custom-color-2;
      color: var(--custom-color-2);
    }
  
    .background-custom-color-2 {
      background-color: $custom-color-2;
      background-color: var(--custom-color-2);
    }
  
    .border-custom-color-2 {
      border-color: $custom-color-2 !important;
      border-color: var(--custom-color-2) !important;
    }
  
    --custom-color-3: #143158;
  
    .text-custom-color-3 {
      color: $custom-color-3;
      color: var(--custom-color-3);
    }
  
    .background-custom-color-3 {
      background-color: $custom-color-3;
      background-color: var(--custom-color-3);
    }
  
    .border-custom-color-3 {
      border-color: $custom-color-3 !important;
      border-color: var(--custom-color-3) !important;
    }
  
    --custom-color-4: #eef3ff;
  
    .text-custom-color-4 {
      color: $custom-color-4;
      color: var(--custom-color-4);
    }
  
    .background-custom-color-4 {
      background-color: $custom-color-4;
      background-color: var(--custom-color-4);
    }
  
    .border-custom-color-4 {
      border-color: $custom-color-4 !important;
      border-color: var(--custom-color-4) !important;
    }
  
    --custom-color-5: #c8ecfc;
  
    .text-custom-color-5 {
      color: $custom-color-5;
      color: var(--custom-color-5);
    }
  
    .background-custom-color-5 {
      background-color: $custom-color-5;
      background-color: var(--custom-color-5);
    }
  
    .border-custom-color-5 {
      border-color: $custom-color-5 !important;
      border-color: var(--custom-color-5) !important;
    }
  
    --custom-color-6: #53b4df;
  
    .text-custom-color-6 {
      color: $custom-color-6;
      color: var(--custom-color-6);
    }
  
    .background-custom-color-6 {
      background-color: $custom-color-6;
      background-color: var(--custom-color-6);
    }
  
    .background-custom-color-105 {
      background-color: $custom-color-105;
      background-color: var(--custom-color-105);
    }
  
    .border-custom-color-6 {
      border-color: $custom-color-6 !important;
      border-color: var(--custom-color-6) !important;
    }
  
    --custom-color-7: #d1dbe4;
  
    .text-custom-color-7 {
      color: $custom-color-7;
      color: var(--custom-color-7);
    }
  
    .background-custom-color-7 {
      background-color: $custom-color-7;
      background-color: var(--custom-color-7);
    }
  
    .border-custom-color-7 {
      border-color: $custom-color-7 !important;
      border-color: var(--custom-color-7) !important;
    }
  
    --custom-color-8: #f2f9ff;
  
    .text-custom-color-8 {
      color: $custom-color-8;
      color: var(--custom-color-8);
    }
  
    .background-custom-color-8 {
      background-color: $custom-color-8;
      background-color: var(--custom-color-8);
    }
  
    .border-custom-color-8 {
      border-color: $custom-color-8 !important;
      border-color: var(--custom-color-8) !important;
    }
  
    --custom-color-9: #ced4da;
  
    .text-custom-color-9 {
      color: $custom-color-9;
      color: var(--custom-color-9);
    }
  
    .background-custom-color-9 {
      background-color: $custom-color-9;
      background-color: var(--custom-color-9);
    }
  
    .border-custom-color-9 {
      border-color: $custom-color-9 !important;
      border-color: var(--custom-color-9) !important;
    }
  
    --custom-color-10: #0000005c;
  
    .text-custom-color-10 {
      color: $custom-color-10;
      color: var(--custom-color-10);
    }
  
    .background-custom-color-10 {
      background-color: $custom-color-10;
      background-color: var(--custom-color-10);
    }
  
    .border-custom-color-10 {
      border-color: $custom-color-10 !important;
      border-color: var(--custom-color-10) !important;
    }
  
    //
    --custom-color-11: #979797;
  
    .text-custom-color-11 {
      color: $custom-color-11;
      color: var(--custom-color-11);
    }
  
    .background-custom-color-11 {
      background-color: $custom-color-11;
      background-color: var(--custom-color-11);
    }
  
    .border-custom-color-11 {
      border-color: $custom-color-11 !important;
      border-color: var(--custom-color-11) !important;
    }
  
    --custom-color-12: #0288d1;
  
    .text-custom-color-12 {
      color: $custom-color-12;
      color: var(--custom-color-12);
    }
  
    .background-custom-color-12 {
      background-color: $custom-color-12;
      background-color: var(--custom-color-12);
    }
  
    .border-custom-color-12 {
      border-color: $custom-color-12 !important;
      border-color: var(--custom-color-12) !important;
    }
  
    --custom-color-13: #f7f7f7;
  
    .text-custom-color-13 {
      color: $custom-color-13;
      color: var(--custom-color-13);
    }
  
    .background-custom-color-13 {
      background-color: $custom-color-13;
      // background-color: var(--custom-color-13);
    }
  
    .border-custom-color-13 {
      border-color: $custom-color-13 !important;
      border-color: var(--custom-color-13) !important;
    }
  
    --custom-color-14: #9cc1ff;
  
    .text-custom-color-14 {
      color: $custom-color-14;
      color: var(--custom-color-14);
    }
  
    .background-custom-color-14 {
      background-color: $custom-color-14;
      background-color: var(--custom-color-14);
    }
  
    .border-custom-color-14 {
      border-color: $custom-color-14 !important;
      border-color: var(--custom-color-14) !important;
    }
  
    --custom-color-15: #f2f9fc;
  
    .text-custom-color-15 {
      color: $custom-color-15;
      color: var(--custom-color-15);
    }
  
    .background-custom-color-15 {
      background-color: $custom-color-15;
      background-color: var(--custom-color-15);
    }
  
    .border-custom-color-15 {
      border-color: $custom-color-15 !important;
      border-color: var(--custom-color-15) !important;
    }
  
    --custom-color-16: #0fac83;
  
    .text-custom-color-16 {
      color: $custom-color-16;
      color: var(--custom-color-16);
    }
  
    .background-custom-color-16 {
      background-color: $custom-color-16;
      background-color: var(--custom-color-16);
    }
  
    .border-custom-color-16 {
      border-color: $custom-color-16 !important;
      border-color: var(--custom-color-16) !important;
    }
  
    --custom-color-17: #f3f3f3;
  
    .text-custom-color-17 {
      color: $custom-color-17;
      color: var(--custom-color-17);
    }
  
    .background-custom-color-17 {
      background-color: $custom-color-17;
      background-color: var(--custom-color-17);
    }
  
    .border-custom-color-17 {
      border-color: $custom-color-17 !important;
      border-color: var(--custom-color-17) !important;
    }
  
    --custom-color-18: #797979;
  
    .text-custom-color-18 {
      color: $custom-color-18;
      color: var(--custom-color-18);
    }
  
    .background-custom-color-18 {
      background-color: $custom-color-18;
      background-color: var(--custom-color-18);
    }
  
    .border-custom-color-18 {
      border-color: $custom-color-18 !important;
      border-color: var(--custom-color-18) !important;
    }
  
    --custom-color-19: #8dbfff;
  
    .text-custom-color-19 {
      color: $custom-color-19;
      color: var(--custom-color-19);
    }
  
    .background-custom-color-19 {
      background-color: $custom-color-19;
      background-color: var(--custom-color-19);
    }
  
    .border-custom-color-19 {
      border-color: $custom-color-19 !important;
      border-color: var(--custom-color-19) !important;
    }
  
    --custom-color-20: #999;
  
    .text-custom-color-20 {
      color: $custom-color-20;
      color: var(--custom-color-20);
    }
  
    .background-custom-color-20 {
      background-color: $custom-color-20;
      background-color: var(--custom-color-20);
    }
  
    .border-custom-color-20 {
      border-color: $custom-color-20 !important;
      border-color: var(--custom-color-20) !important;
    }
  
    //
    --custom-color-21: #f6fcff;
  
    .text-custom-color-21 {
      color: $custom-color-21;
      color: var(--custom-color-21);
    }
  
    .background-custom-color-21 {
      background-color: $custom-color-21;
      background-color: var(--custom-color-21);
    }
  
    .border-custom-color-21 {
      border-color: $custom-color-21 !important;
      border-color: var(--custom-color-21) !important;
    }
  
    --custom-color-22: #f3fcff;
  
    .text-custom-color-22 {
      color: $custom-color-22;
      color: var(--custom-color-22);
    }
  
    .background-custom-color-22 {
      background-color: $custom-color-22;
      background-color: var(--custom-color-22);
    }
  
    .border-custom-color-22 {
      border-color: $custom-color-22 !important;
      border-color: var(--custom-color-22) !important;
    }
  
    --custom-color-23: #fee;
  
    .text-custom-color-23 {
      color: $custom-color-23;
      color: var(--custom-color-23);
    }
  
    .background-custom-color-23 {
      background-color: $custom-color-23;
      background-color: var(--custom-color-23);
    }
  
    .border-custom-color-23 {
      border-color: $custom-color-23 !important;
      border-color: var(--custom-color-23) !important;
    }
  
    --custom-color-24: #a2a2a2;
  
    .text-custom-color-24 {
      color: $custom-color-24;
      color: var(--custom-color-24);
    }
  
    .background-custom-color-24 {
      background-color: $custom-color-24;
      background-color: var(--custom-color-24);
    }
  
    .border-custom-color-24 {
      border-color: $custom-color-24 !important;
      border-color: var(--custom-color-24) !important;
    }
  
    --custom-color-25: #f44336;
  
    .text-custom-color-25 {
      color: $custom-color-25;
      color: var(--custom-color-25);
    }
  
    .background-custom-color-25 {
      background-color: $custom-color-25;
      background-color: var(--custom-color-25);
    }
  
    .border-custom-color-25 {
      border-color: $custom-color-25 !important;
      border-color: var(--custom-color-25) !important;
    }
  
    --custom-color-26: #4caf50;
  
    .text-custom-color-26 {
      color: $custom-color-26;
      color: var(--custom-color-26);
    }
  
    .background-custom-color-26 {
      background-color: $custom-color-26;
      background-color: var(--custom-color-26);
    }
  
    .border-custom-color-26 {
      border-color: $custom-color-26 !important;
      border-color: var(--custom-color-26) !important;
    }
  
    --custom-color-27: #b3b3b3;
  
    .text-custom-color-27 {
      color: $custom-color-27;
      color: var(--custom-color-27);
    }
  
    .background-custom-color-27 {
      background-color: $custom-color-27;
      background-color: var(--custom-color-27);
    }
  
    .border-custom-color-27 {
      border-color: $custom-color-27 !important;
      border-color: var(--custom-color-27) !important;
    }
  
    --custom-color-28: #00a551;
  
    .text-custom-color-28 {
      color: $custom-color-28;
      color: var(--custom-color-28);
    }
  
    .background-custom-color-28 {
      background-color: $custom-color-28;
      background-color: var(--custom-color-28);
    }
  
    .border-custom-color-28 {
      border-color: $custom-color-28 !important;
      border-color: var(--custom-color-28) !important;
    }
  
    --custom-color-29: #fff9f4;
  
    .text-custom-color-29 {
      color: $custom-color-29;
      color: var(--custom-color-29);
    }
  
    .background-custom-color-29 {
      background-color: $custom-color-29;
      background-color: var(--custom-color-29);
    }
  
    .border-custom-color-29 {
      border-color: $custom-color-29 !important;
      border-color: var(--custom-color-29) !important;
    }
  
    --custom-color-30: #2f6cdb;
  
    .text-custom-color-30 {
      color: $custom-color-30;
      color: var(--custom-color-30);
    }
  
    .background-custom-color-30 {
      background-color: $custom-color-30;
      background-color: var(--custom-color-30);
    }
  
    .border-custom-color-30 {
      border-color: $custom-color-30 !important;
      border-color: var(--custom-color-30) !important;
    }
  
    //
    --custom-color-31: #99bbf2;
  
    .text-custom-color-31 {
      color: $custom-color-31;
      color: var(--custom-color-31);
    }
  
    .background-custom-color-31 {
      background-color: $custom-color-31;
      background-color: var(--custom-color-31);
    }
  
    .border-custom-color-31 {
      border-color: $custom-color-31 !important;
      border-color: var(--custom-color-31) !important;
    }
  
    --custom-color-32: #fef1e7;
  
    .text-custom-color-32 {
      color: $custom-color-32;
      color: var(--custom-color-32);
    }
  
    .background-custom-color-32 {
      background-color: $custom-color-32;
      background-color: var(--custom-color-32);
    }
  
    .border-custom-color-32 {
      border-color: $custom-color-32 !important;
      border-color: var(--custom-color-32) !important;
    }
  
    --custom-color-33: #bdbdbd;
  
    .text-custom-color-33 {
      color: $custom-color-33;
      color: var(--custom-color-33);
    }
  
    .background-custom-color-33 {
      background-color: $custom-color-33;
      background-color: var(--custom-color-33);
    }
  
    .border-custom-color-33 {
      border-color: $custom-color-33 !important;
      border-color: var(--custom-color-33) !important;
    }
  
    --custom-color-34: #0090f0;
  
    .text-custom-color-34 {
      color: $custom-color-34;
      color: var(--custom-color-34);
    }
  
    .background-custom-color-34 {
      background-color: $custom-color-34;
      background-color: var(--custom-color-34);
    }
  
    .border-custom-color-34 {
      border-color: $custom-color-34 !important;
      border-color: var(--custom-color-34) !important;
    }
  
    --custom-color-35: #2e6ddb;
  
    .text-custom-color-35 {
      color: $custom-color-35;
      color: var(--custom-color-35);
    }
  
    .background-custom-color-35 {
      background-color: $custom-color-35;
      background-color: var(--custom-color-35);
    }
  
    .border-custom-color-35 {
      border-color: $custom-color-35 !important;
      border-color: var(--custom-color-35) !important;
    }
  
    --custom-color-36: #989898;
  
    .text-custom-color-36 {
      color: $custom-color-36;
      color: var(--custom-color-36);
    }
  
    .background-custom-color-36 {
      background-color: $custom-color-36;
      background-color: var(--custom-color-36);
    }
  
    .border-custom-color-36 {
      border-color: $custom-color-36 !important;
      border-color: var(--custom-color-36) !important;
    }
  
    --custom-color-37: #7d7d7d;
  
    .text-custom-color-37 {
      color: $custom-color-37;
      color: var(--custom-color-37);
    }
  
    .background-custom-color-37 {
      background-color: $custom-color-37;
      background-color: var(--custom-color-37);
    }
  
    .border-custom-color-37 {
      border-color: $custom-color-37 !important;
      border-color: var(--custom-color-37) !important;
    }
  
    --custom-color-38: #f1f8ff;
  
    .text-custom-color-38 {
      color: $custom-color-38;
      color: var(--custom-color-38);
    }
  
    .background-custom-color-38 {
      background-color: $custom-color-38;
      background-color: var(--custom-color-38);
    }
  
    .border-custom-color-38 {
      border-color: $custom-color-38 !important;
      border-color: var(--custom-color-38) !important;
    }
  
    --custom-color-39: #1b60df;
  
    .text-custom-color-39 {
      color: $custom-color-39;
      color: var(--custom-color-39);
    }
  
    .background-custom-color-39 {
      background-color: $custom-color-39;
      background-color: var(--custom-color-39);
    }
  
    .border-custom-color-39 {
      border-color: $custom-color-39 !important;
      border-color: var(--custom-color-39) !important;
    }
  
    --custom-color-40: #6d6d6d;
  
    .text-custom-color-40 {
      color: $custom-color-40;
      color: var(--custom-color-40);
    }
  
    .background-custom-color-40 {
      background-color: $custom-color-40;
      background-color: var(--custom-color-40);
    }
  
    .border-custom-color-40 {
      border-color: $custom-color-40 !important;
      border-color: var(--custom-color-40) !important;
    }
  
    //
    --custom-color-41: #767676;
  
    .text-custom-color-41 {
      color: $custom-color-41;
      color: var(--custom-color-41);
    }
  
    .background-custom-color-41 {
      background-color: $custom-color-41;
      background-color: var(--custom-color-41);
    }
  
    .border-custom-color-41 {
      border-color: $custom-color-41 !important;
      border-color: var(--custom-color-41) !important;
    }
  
    --custom-color-42: #f1f1f1;
  
    .text-custom-color-42 {
      color: $custom-color-42;
      color: var(--custom-color-42);
    }
  
    .background-custom-color-42 {
      background-color: $custom-color-42;
      background-color: var(--custom-color-42);
    }
  
    .border-custom-color-42 {
      border-color: $custom-color-42 !important;
      border-color: var(--custom-color-42) !important;
    }
  
    --custom-color-43: #ececec;
  
    .text-custom-color-43 {
      color: $custom-color-43;
      color: var(--custom-color-43);
    }
  
    .background-custom-color-43 {
      background-color: $custom-color-43;
      background-color: var(--custom-color-43);
    }
  
    .border-custom-color-43 {
      border-color: $custom-color-43 !important;
      border-color: var(--custom-color-43) !important;
    }
  
    --custom-color-44: #e2e2e2;
  
    .text-custom-color-44 {
      color: $custom-color-44;
      color: var(--custom-color-44);
    }
  
    .background-custom-color-44 {
      background-color: $custom-color-44;
      background-color: var(--custom-color-44);
    }
  
    .border-custom-color-44 {
      border-color: $custom-color-44 !important;
      border-color: var(--custom-color-44) !important;
    }
  
    --custom-color-45: #bbb;
  
    .text-custom-color-45 {
      color: $custom-color-45;
      color: var(--custom-color-45);
    }
  
    .background-custom-color-45 {
      background-color: $custom-color-45;
      background-color: var(--custom-color-45);
    }
  
    .border-custom-color-45 {
      border-color: $custom-color-45 !important;
      border-color: var(--custom-color-45) !important;
    }
  
    --custom-color-46: #cdcdcd;
  
    .text-custom-color-46 {
      color: $custom-color-46;
      color: var(--custom-color-46);
    }
  
    .background-custom-color-46 {
      background-color: $custom-color-46;
      background-color: var(--custom-color-46);
    }
  
    .border-custom-color-46 {
      border-color: $custom-color-46 !important;
      border-color: var(--custom-color-46) !important;
    }
  
    --custom-color-47: #0091ff;
  
    .text-custom-color-47 {
      color: $custom-color-47;
      color: var(--custom-color-47);
    }
  
    .background-custom-color-47 {
      background-color: $custom-color-47;
      background-color: var(--custom-color-47);
    }
  
    .border-custom-color-47 {
      border-color: $custom-color-47 !important;
      border-color: var(--custom-color-47) !important;
    }
  
    --custom-color-48: #cbb700;
  
    .text-custom-color-48 {
      color: $custom-color-48;
      color: var(--custom-color-48);
    }
  
    .background-custom-color-48 {
      background-color: $custom-color-48;
      background-color: var(--custom-color-48);
    }
  
    .border-custom-color-48 {
      border-color: $custom-color-48 !important;
      border-color: var(--custom-color-48) !important;
    }
  
    --custom-color-49: #26251e;
  
    .text-custom-color-49 {
      color: $custom-color-49;
      color: var(--custom-color-49);
    }
  
    .background-custom-color-49 {
      background-color: $custom-color-49;
      background-color: var(--custom-color-49);
    }
  
    .border-custom-color-49 {
      border-color: $custom-color-49 !important;
      border-color: var(--custom-color-49) !important;
    }
  
    --custom-color-50: #fffccd;
  
    .text-custom-color-50 {
      color: $custom-color-50;
      color: var(--custom-color-50);
    }
  
    .background-custom-color-50 {
      background-color: $custom-color-50;
      background-color: var(--custom-color-50);
    }
  
    .border-custom-color-50 {
      border-color: $custom-color-50 !important;
      border-color: var(--custom-color-50) !important;
    }
  
    //
    --custom-color-51: #fe7523;
  
    .text-custom-color-51 {
      color: $custom-color-51;
      color: var(--custom-color-51);
    }
  
    .background-custom-color-51 {
      background-color: $custom-color-51;
      background-color: var(--custom-color-51);
    }
  
    .border-custom-color-51 {
      border-color: $custom-color-51 !important;
      border-color: var(--custom-color-51) !important;
    }
  
    --custom-color-52: #5c5c5c;
  
    .text-custom-color-52 {
      color: $custom-color-52;
      color: var(--custom-color-52);
    }
  
    .background-custom-color-52 {
      background-color: $custom-color-52;
      background-color: var(--custom-color-52);
    }
  
    .border-custom-color-52 {
      border-color: $custom-color-52 !important;
      border-color: var(--custom-color-52) !important;
    }
  
    --custom-color-53: #fdefd8;
  
    .text-custom-color-53 {
      color: $custom-color-53;
      color: var(--custom-color-53);
    }
  
    .background-custom-color-53 {
      background-color: $custom-color-53;
      background-color: var(--custom-color-53);
    }
  
    .border-custom-color-53 {
      border-color: $custom-color-53 !important;
      border-color: var(--custom-color-53) !important;
    }
  
    --custom-color-54: #7f7f7f;
  
    .text-custom-color-54 {
      color: $custom-color-54;
      color: var(--custom-color-54);
    }
  
    .background-custom-color-54 {
      background-color: $custom-color-54;
      background-color: var(--custom-color-54);
    }
  
    .border-custom-color-54 {
      border-color: $custom-color-54 !important;
      border-color: var(--custom-color-54) !important;
    }
  
    --custom-color-55: #88cfe6;
  
    .text-custom-color-55 {
      color: $custom-color-55;
      color: var(--custom-color-55);
    }
  
    .background-custom-color-55 {
      background-color: $custom-color-55;
      background-color: var(--custom-color-55);
    }
  
    .border-custom-color-55 {
      border-color: $custom-color-55 !important;
      border-color: var(--custom-color-55) !important;
    }
  
    --custom-color-56: #407dcf;
  
    .text-custom-color-56 {
      color: $custom-color-56;
      color: var(--custom-color-56);
    }
  
    .background-custom-color-56 {
      background-color: $custom-color-56;
      background-color: var(--custom-color-56);
    }
  
    .border-custom-color-56 {
      border-color: $custom-color-56 !important;
      border-color: var(--custom-color-56) !important;
    }
  
    --custom-color-57: #13529e;
  
    .text-custom-color-57 {
      color: $custom-color-57;
      color: var(--custom-color-57);
    }
  
    .background-custom-color-57 {
      background-color: $custom-color-57;
      background-color: var(--custom-color-57);
    }
  
    .border-custom-color-57 {
      border-color: $custom-color-57 !important;
      border-color: var(--custom-color-57) !important;
    }
  
    --custom-color-58: #08bbee;
  
    .text-custom-color-58 {
      color: $custom-color-58;
      color: var(--custom-color-58);
    }
  
    .background-custom-color-58 {
      background-color: $custom-color-58;
      background-color: var(--custom-color-58);
    }
  
    .border-custom-color-58 {
      border-color: $custom-color-58 !important;
      border-color: var(--custom-color-58) !important;
    }
  
    --custom-color-59: #a7a7a7;
  
    .text-custom-color-59 {
      color: $custom-color-59;
      color: var(--custom-color-59);
    }
  
    .background-custom-color-59 {
      background-color: $custom-color-59;
      background-color: var(--custom-color-59);
    }
  
    .border-custom-color-59 {
      border-color: $custom-color-59 !important;
      border-color: var(--custom-color-59) !important;
    }
  
    --custom-color-60: #ddd;
  
    .text-custom-color-60 {
      color: $custom-color-60;
      color: var(--custom-color-60);
    }
  
    .background-custom-color-60 {
      background-color: $custom-color-60;
      background-color: var(--custom-color-60);
    }
  
    .border-custom-color-60 {
      border-color: $custom-color-60 !important;
      border-color: var(--custom-color-60) !important;
    }
  
    //
    --custom-color-61: #e6e6e6;
  
    .text-custom-color-61 {
      color: $custom-color-61;
      color: var(--custom-color-61);
    }
  
    .background-custom-color-61 {
      background-color: $custom-color-61;
      background-color: var(--custom-color-61);
    }
  
    .border-custom-color-61 {
      border-color: $custom-color-61 !important;
      border-color: var(--custom-color-61) !important;
    }
  
    --custom-color-62: #03a9f4;
  
    .text-custom-color-62 {
      color: $custom-color-62;
      color: var(--custom-color-62);
    }
  
    .background-custom-color-62 {
      background-color: $custom-color-62;
      background-color: var(--custom-color-62);
    }
  
    .border-custom-color-62 {
      border-color: $custom-color-62 !important;
      border-color: var(--custom-color-62) !important;
    }
  
    --custom-color-63: #fff4c6;
  
    .text-custom-color-63 {
      color: $custom-color-63;
      color: var(--custom-color-63);
    }
  
    .background-custom-color-63 {
      background-color: $custom-color-63;
      background-color: var(--custom-color-63);
    }
  
    .border-custom-color-63 {
      border-color: $custom-color-63 !important;
      border-color: var(--custom-color-63) !important;
    }
  
    --custom-color-64: #d7d7d7;
  
    .text-custom-color-64 {
      color: $custom-color-64;
      color: var(--custom-color-64);
    }
  
    .background-custom-color-64 {
      background-color: $custom-color-64;
      background-color: var(--custom-color-64);
    }
  
    .border-custom-color-64 {
      border-color: $custom-color-64 !important;
      border-color: var(--custom-color-64) !important;
    }
  
    --custom-color-65: #f8c9a3;
  
    .text-custom-color-65 {
      color: $custom-color-65;
      color: var(--custom-color-65);
    }
  
    .background-custom-color-65 {
      background-color: $custom-color-65;
      background-color: var(--custom-color-65);
    }
  
    .border-custom-color-65 {
      border-color: $custom-color-65 !important;
      border-color: var(--custom-color-65) !important;
    }
  
    --custom-color-66: #e7f0ff;
  
    .text-custom-color-66 {
      color: $custom-color-66;
      color: var(--custom-color-66);
    }
  
    .background-custom-color-66 {
      background-color: $custom-color-66;
      background-color: var(--custom-color-66);
    }
  
    .border-custom-color-66 {
      border-color: $custom-color-66 !important;
      border-color: var(--custom-color-66) !important;
    }
  
    --custom-color-67: #4a4b4d;
  
    .text-custom-color-67 {
      color: $custom-color-67;
      color: var(--custom-color-67);
    }
  
    .background-custom-color-67 {
      background-color: $custom-color-67;
      background-color: var(--custom-color-67);
    }
  
    .border-custom-color-67 {
      border-color: $custom-color-67 !important;
      border-color: var(--custom-color-67) !important;
    }
  
    --custom-color-68: #216ae3;
  
    .text-custom-color-68 {
      color: $custom-color-68;
      color: var(--custom-color-68);
    }
  
    .background-custom-color-68 {
      background-color: $custom-color-68;
      background-color: var(--custom-color-68);
    }
  
    .border-custom-color-68 {
      border-color: $custom-color-68 !important;
      border-color: var(--custom-color-68) !important;
    }
  
    --custom-color-69: #fcf1e8;
  
    .text-custom-color-69 {
      color: $custom-color-69;
      color: var(--custom-color-69);
    }
  
    .background-custom-color-69 {
      background-color: $custom-color-69;
      background-color: var(--custom-color-69);
    }
  
    .border-custom-color-69 {
      border-color: $custom-color-69 !important;
      border-color: var(--custom-color-69) !important;
    }
  
    --custom-color-70: #aaa;
  
    .text-custom-color-70 {
      color: $custom-color-70;
      color: var(--custom-color-70);
    }
  
    .background-custom-color-70 {
      background-color: $custom-color-70;
      background-color: var(--custom-color-70);
    }
  
    .border-custom-color-70 {
      border-color: $custom-color-70 !important;
      border-color: var(--custom-color-70) !important;
    }
  
    //
    --custom-color-71: #f5f5f5;
  
    .text-custom-color-71 {
      color: $custom-color-71;
      color: var(--custom-color-71);
    }
  
    .background-custom-color-71 {
      background-color: $custom-color-71;
      background-color: var(--custom-color-71);
    }
  
    .border-custom-color-71 {
      border-color: $custom-color-71 !important;
      border-color: var(--custom-color-71) !important;
    }
  
    --custom-color-72: #f2f2f2;
  
    .text-custom-color-72 {
      color: $custom-color-72;
      color: var(--custom-color-72);
    }
  
    .background-custom-color-72 {
      background-color: $custom-color-72;
      background-color: var(--custom-color-72);
    }
  
    .border-custom-color-72 {
      border-color: $custom-color-72 !important;
      border-color: var(--custom-color-72) !important;
    }
  
    --custom-color-73: #05ab85;
  
    .text-custom-color-73 {
      color: $custom-color-73;
      color: var(--custom-color-73);
    }
  
    .background-custom-color-73 {
      background-color: $custom-color-73;
      background-color: var(--custom-color-73);
    }
  
    .border-custom-color-73 {
      border-color: $custom-color-73 !important;
      border-color: var(--custom-color-73) !important;
    }
  
    --custom-color-74: #007eff;
  
    .text-custom-color-74 {
      color: $custom-color-74;
      color: var(--custom-color-74);
    }
  
    .background-custom-color-74 {
      background-color: $custom-color-74;
      background-color: var(--custom-color-74);
    }
  
    .border-custom-color-74 {
      border-color: $custom-color-74 !important;
      border-color: var(--custom-color-74) !important;
    }
  
    --custom-color-75: #d0021b;
  
    .text-custom-color-75 {
      color: $custom-color-75;
      color: var(--custom-color-75);
    }
  
    .background-custom-color-75 {
      background-color: $custom-color-75;
      background-color: var(--custom-color-75);
    }
  
    .border-custom-color-75 {
      border-color: $custom-color-75 !important;
      border-color: var(--custom-color-75) !important;
    }
  
    --custom-color-76: #fffcf5;
  
    .text-custom-color-76 {
      color: $custom-color-76;
      color: var(--custom-color-76);
    }
  
    .background-custom-color-76 {
      background-color: $custom-color-76;
      background-color: var(--custom-color-76);
    }
  
    .border-custom-color-76 {
      border-color: $custom-color-76 !important;
      border-color: var(--custom-color-76) !important;
    }
  
    --custom-color-77: #ebf5ff;
  
    .text-custom-color-77 {
      color: $custom-color-77;
      color: var(--custom-color-77);
    }
  
    .background-custom-color-77 {
      background-color: $custom-color-77;
      background-color: var(--custom-color-77);
    }
  
    .border-custom-color-77 {
      border-color: $custom-color-77 !important;
      border-color: var(--custom-color-77) !important;
    }
  
    --custom-color-78: #c2e0ff;
  
    .text-custom-color-78 {
      color: $custom-color-78;
      color: var(--custom-color-78);
    }
  
    .background-custom-color-78 {
      background-color: $custom-color-78;
      background-color: var(--custom-color-78);
    }
  
    .border-custom-color-78 {
      border-color: $custom-color-78 !important;
      border-color: var(--custom-color-78) !important;
    }
  
    --custom-color-79: #d8eafd;
  
    .text-custom-color-79 {
      color: $custom-color-79;
      color: var(--custom-color-79);
    }
  
    .background-custom-color-79 {
      background-color: $custom-color-79;
      background-color: var(--custom-color-79);
    }
  
    .border-custom-color-79 {
      border-color: $custom-color-79 !important;
      border-color: var(--custom-color-79) !important;
    }
  
    --custom-color-80: #0071e6;
  
    .text-custom-color-80 {
      color: $custom-color-80;
      color: var(--custom-color-80);
    }
  
    .background-custom-color-80 {
      background-color: $custom-color-80;
      background-color: var(--custom-color-80);
    }
  
    .border-custom-color-80 {
      border-color: $custom-color-80 !important;
      border-color: var(--custom-color-80) !important;
    }
  
    --custom-color-81: #fcfcfc;
  
    .text-custom-color-81 {
      color: $custom-color-81;
      color: var(--custom-color-81);
    }
  
    .background-custom-color-81 {
      background-color: $custom-color-81;
      background-color: var(--custom-color-81);
    }
  
    .border-custom-color-81 {
      border-color: $custom-color-81 !important;
      border-color: var(--custom-color-81) !important;
    }
  
    --custom-color-82: #f4d06f;
  
    .text-custom-color-82 {
      color: $custom-color-82;
      color: var(--custom-color-82);
    }
  
    .background-custom-color-82 {
      background-color: $custom-color-82;
      background-color: var(--custom-color-82);
    }
  
    .border-custom-color-82 {
      border-color: $custom-color-82 !important;
      border-color: var(--custom-color-82) !important;
    }
  
    --custom-color-83: #f7e7bd;
  
    .text-custom-color-83 {
      color: $custom-color-83;
      color: var(--custom-color-83);
    }
  
    .background-custom-color-83 {
      background-color: $custom-color-83;
      background-color: var(--custom-color-83);
    }
  
    .border-custom-color-83 {
      border-color: $custom-color-83 !important;
      border-color: var(--custom-color-83) !important;
    }
  
    --custom-color-84: #d0d7df;
  
    .text-custom-color-84 {
      color: $custom-color-84;
      color: var(--custom-color-84);
    }
  
    .background-custom-color-84 {
      background-color: $custom-color-84;
      background-color: var(--custom-color-84);
    }
  
    .border-custom-color-84 {
      border-color: $custom-color-84 !important;
      border-color: var(--custom-color-84) !important;
    }
  
    --custom-color-85: #0a0;
  
    .text-custom-color-85 {
      color: $custom-color-85;
      color: var(--custom-color-85);
    }
  
    .background-custom-color-85 {
      background-color: $custom-color-85;
      background-color: var(--custom-color-85);
    }
  
    .border-custom-color-85 {
      border-color: $custom-color-85 !important;
      border-color: var(--custom-color-85) !important;
    }
  
    --custom-color-86: #586473;
  
    .text-custom-color-86 {
      color: $custom-color-86;
      color: var(--custom-color-86);
    }
  
    .background-custom-color-86 {
      background-color: $custom-color-86;
      background-color: var(--custom-color-86);
    }
  
    .border-custom-color-86 {
      border-color: $custom-color-86 !important;
      border-color: var(--custom-color-86) !important;
    }
  
    --custom-color-87: #b8b8b8;
  
    .text-custom-color-87 {
      color: $custom-color-87;
      color: var(--custom-color-87);
    }
  
    .background-custom-color-87 {
      background-color: $custom-color-87;
      background-color: var(--custom-color-87);
    }
  
    .border-custom-color-87 {
      border-color: $custom-color-87 !important;
      border-color: var(--custom-color-87) !important;
    }
  
    --custom-color-88: #5d646c;
  
    .text-custom-color-88 {
      color: $custom-color-88;
      color: var(--custom-color-88);
    }
  
    .background-custom-color-88 {
      background-color: $custom-color-88;
      background-color: var(--custom-color-88);
    }
  
    .border-custom-color-88 {
      border-color: $custom-color-88 !important;
      border-color: var(--custom-color-88) !important;
    }
  
    --custom-color-89: #c4c5c9;
  
    .text-custom-color-89 {
      color: $custom-color-89;
      color: var(--custom-color-89);
    }
  
    .background-custom-color-89 {
      background-color: $custom-color-89;
      background-color: var(--custom-color-89);
    }
  
    .border-custom-color-89 {
      border-color: $custom-color-89 !important;
      border-color: var(--custom-color-89) !important;
    }
  
    --custom-color-90: #dedfe1;
  
    .text-custom-color-90 {
      color: $custom-color-90;
      color: var(--custom-color-90);
    }
  
    .background-custom-color-90 {
      background-color: $custom-color-90;
      background-color: var(--custom-color-90);
    }
  
    .border-custom-color-90 {
      border-color: $custom-color-90 !important;
      border-color: var(--custom-color-90) !important;
    }
  
    --custom-color-91: #e9f0fb;
  
    .text-custom-color-91 {
      color: $custom-color-91;
      color: var(--custom-color-91);
    }
  
    .background-custom-color-91 {
      background-color: $custom-color-91;
      background-color: var(--custom-color-91);
    }
  
    .border-custom-color-91 {
      border-color: $custom-color-91 !important;
      border-color: var(--custom-color-91) !important;
    }
  
    --custom-color-92: #d6d6d6;
  
    .text-custom-color-92 {
      color: $custom-color-92;
      color: var(--custom-color-92);
    }
  
    .background-custom-color-92 {
      background-color: $custom-color-92;
      background-color: var(--custom-color-92);
    }
  
    .border-custom-color-92 {
      border-color: $custom-color-92 !important;
      border-color: var(--custom-color-92) !important;
    }
  
    --custom-color-93: #838183;
  
    .text-custom-color-93 {
      color: $custom-color-93;
      color: var(--custom-color-93);
    }
  
    .background-custom-color-93 {
      background-color: $custom-color-93;
      background-color: var(--custom-color-93);
    }
  
    .border-custom-color-93 {
      border-color: $custom-color-93 !important;
      border-color: var(--custom-color-93) !important;
    }
  
    --custom-color-94: #ff6060;
  
    .text-custom-color-94 {
      color: $custom-color-94;
      color: var(--custom-color-94);
    }
  
    .background-custom-color-94 {
      background-color: $custom-color-94;
      background-color: var(--custom-color-94);
    }
  
    .border-custom-color-94 {
      border-color: $custom-color-94 !important;
      border-color: var(--custom-color-94) !important;
    }
  
    --custom-color-95: #764ba2;
  
    .text-custom-color-95 {
      color: $custom-color-95;
      color: var(--custom-color-95);
    }
  
    .background-custom-color-95 {
      background-color: $custom-color-95;
      background-color: var(--custom-color-95);
    }
  
    .border-custom-color-95 {
      border-color: $custom-color-95 !important;
      border-color: var(--custom-color-95) !important;
    }
  
    --custom-color-96: #667eea;
  
    .text-custom-color-96 {
      color: $custom-color-96;
      color: var(--custom-color-96);
    }
  
    .background-custom-color-96 {
      background-color: $custom-color-96;
      background-color: var(--custom-color-96);
    }
  
    .border-custom-color-96 {
      border-color: $custom-color-96 !important;
      border-color: var(--custom-color-96) !important;
    }
  
    --custom-color-97: #b12a5b;
  
    .text-custom-color-97 {
      color: $custom-color-97;
      color: var(--custom-color-97);
    }
  
    .background-custom-color-97 {
      background-color: $custom-color-97;
      background-color: var(--custom-color-97);
    }
  
    .border-custom-color-97 {
      border-color: $custom-color-97 !important;
      border-color: var(--custom-color-97) !important;
    }
  
    --custom-color-98: #cf556c;
  
    .text-custom-color-98 {
      color: $custom-color-98;
      color: var(--custom-color-98);
    }
  
    .background-custom-color-98 {
      background-color: $custom-color-98;
      background-color: var(--custom-color-98);
    }
  
    .border-custom-color-98 {
      border-color: $custom-color-98 !important;
      border-color: var(--custom-color-98) !important;
    }
  
    --custom-color-99: #ff8c7f;
  
    .text-custom-color-99 {
      color: $custom-color-99;
      color: var(--custom-color-99);
    }
  
    .background-custom-color-99 {
      background-color: $custom-color-99;
      background-color: var(--custom-color-99);
    }
  
    .border-custom-color-99 {
      border-color: $custom-color-99 !important;
      border-color: var(--custom-color-99) !important;
    }
  
    --custom-color-100: #ff8177;
  
    .text-custom-color-100 {
      color: $custom-color-100;
      color: var(--custom-color-100);
    }
  
    .background-custom-color-100 {
      background-color: $custom-color-100;
      background-color: var(--custom-color-100);
    }
  
    .border-custom-color-100 {
      border-color: $custom-color-100 !important;
      border-color: var(--custom-color-100) !important;
    }
  
    //
    --custom-color-101: #330867;
  
    .text-custom-color-101 {
      color: $custom-color-101;
      color: var(--custom-color-101);
    }
  
    .background-custom-color-101 {
      background-color: $custom-color-101;
      background-color: var(--custom-color-101);
    }
  
    .border-custom-color-101 {
      border-color: $custom-color-101 !important;
      border-color: var(--custom-color-101) !important;
    }
  
    --custom-color-102: #30cfd0;
  
    .text-custom-color-102 {
      color: $custom-color-102;
      color: var(--custom-color-102);
    }
  
    .background-custom-color-102 {
      background-color: $custom-color-102;
      background-color: var(--custom-color-102);
    }
  
    .border-custom-color-102 {
      border-color: $custom-color-102 !important;
      border-color: var(--custom-color-102) !important;
    }
  
    --custom-color-103: #3023ae;
  
    .text-custom-color-103 {
      color: $custom-color-103;
      color: var(--custom-color-103);
    }
  
    .background-custom-color-103 {
      background-color: $custom-color-103;
      background-color: var(--custom-color-103);
    }
  
    .border-custom-color-103 {
      border-color: $custom-color-103 !important;
      border-color: var(--custom-color-103) !important;
    }
  
    --custom-color-104: #c86dd7;
  
    .text-custom-color-104 {
      color: $custom-color-104;
      color: var(--custom-color-104);
    }
  
    .background-custom-color-104 {
      background-color: $custom-color-104;
      background-color: var(--custom-color-104);
    }
  
    .border-custom-color-104 {
      border-color: $custom-color-104 !important;
      border-color: var(--custom-color-104) !important;
    }
  
    --custom-color-105: #77aaff;
  
    .text-custom-color-105 {
      color: $custom-color-105;
      color: var(--custom-color-105);
    }
  
    .background-custom-color-105 {
      background-color: $custom-color-105;
      background-color: var(--custom-color-105);
    }
  
    .border-custom-color-105 {
      border-color: $custom-color-105 !important;
      border-color: var(--custom-color-105) !important;
    }
  
    --custom-color-106: #fff8e9;
  
    .text-custom-color-106 {
      color: $custom-color-106;
      color: var(--custom-color-106);
    }
  
    .background-custom-color-106 {
      background-color: $custom-color-106;
      background-color: var(--custom-color-106);
    }
  
    .border-custom-color-106 {
      border-color: $custom-color-106 !important;
      border-color: var(--custom-color-106) !important;
    }
  
    --custom-color-107: #fbe6cb;
  
    .text-custom-color-107 {
      color: $custom-color-107;
      color: var(--custom-color-107);
    }
  
    .background-custom-color-107 {
      background-color: $custom-color-107;
      background-color: var(--custom-color-107);
    }
  
    .border-custom-color-107 {
      border-color: $custom-color-107 !important;
      border-color: var(--custom-color-107) !important;
    }
  
    --custom-color-108: #eef9ec;
  
    .text-custom-color-108 {
      color: $custom-color-108;
      color: var(--custom-color-108);
    }
  
    .background-custom-color-108 {
      background-color: $custom-color-108;
      background-color: var(--custom-color-108);
    }
  
    .border-custom-color-108 {
      border-color: $custom-color-108 !important;
      border-color: var(--custom-color-108) !important;
    }
  
    --custom-color-109: #81c784;
  
    .text-custom-color-109 {
      color: $custom-color-109;
      color: var(--custom-color-109);
    }
  
    .background-custom-color-109 {
      background-color: $custom-color-109;
      background-color: var(--custom-color-109);
    }
  
    .border-custom-color-109 {
      border-color: $custom-color-109 !important;
      border-color: var(--custom-color-109) !important;
    }
  
    --custom-color-110: #ffc4c7;
  
    .text-custom-color-110 {
      color: $custom-color-110;
      color: var(--custom-color-110);
    }
  
    .background-custom-color-110 {
      background-color: $custom-color-110;
      background-color: var(--custom-color-110);
    }
  
    .border-custom-color-110 {
      border-color: $custom-color-110 !important;
      border-color: var(--custom-color-110) !important;
    }
  
    //
    --custom-color-111: #0d97ff;
  
    .text-custom-color-111 {
      color: $custom-color-111;
      color: var(--custom-color-111);
    }
  
    .background-custom-color-111 {
      background-color: $custom-color-111;
      background-color: var(--custom-color-111);
    }
  
    .border-custom-color-111 {
      border-color: $custom-color-111 !important;
      border-color: var(--custom-color-111) !important;
    }
  
    --custom-color-112: #00aa01;
  
    .text-custom-color-112 {
      color: $custom-color-112;
      color: var(--custom-color-112);
    }
  
    .background-custom-color-112 {
      background-color: $custom-color-112;
      background-color: var(--custom-color-112);
    }
  
    .border-custom-color-112 {
      border-color: $custom-color-112 !important;
      border-color: var(--custom-color-112) !important;
    }
  
    --custom-color-113: #f0f0f0;
  
    .text-custom-color-113 {
      color: $custom-color-113;
      color: var(--custom-color-113);
    }
  
    .background-custom-color-113 {
      background-color: $custom-color-113;
      background-color: var(--custom-color-113);
    }
  
    .border-custom-color-113 {
      border-color: $custom-color-113 !important;
      border-color: var(--custom-color-113) !important;
    }
  
    --custom-color-114: #b9b8b8;
  
    .text-custom-color-114 {
      color: $custom-color-114;
      color: var(--custom-color-114);
    }
  
    .background-custom-color-114 {
      background-color: $custom-color-114;
      background-color: var(--custom-color-114);
    }
  
    .border-custom-color-114 {
      border-color: $custom-color-114 !important;
      border-color: var(--custom-color-114) !important;
    }
  
    --custom-color-115: #cecece;
  
    .text-custom-color-115 {
      color: $custom-color-115;
      color: var(--custom-color-115);
    }
  
    .background-custom-color-115 {
      background-color: $custom-color-115;
      background-color: var(--custom-color-115);
    }
  
    .border-custom-color-115 {
      border-color: $custom-color-115 !important;
      border-color: var(--custom-color-115) !important;
    }
  
    --custom-color-116: #c6c6c6;
  
    .text-custom-color-116 {
      color: $custom-color-116;
      color: var(--custom-color-116);
    }
  
    .background-custom-color-116 {
      background-color: $custom-color-116;
      background-color: var(--custom-color-116);
    }
  
    .border-custom-color-116 {
      border-color: $custom-color-116 !important;
      border-color: var(--custom-color-116) !important;
    }
  
    --custom-color-117: #d6eaff;
  
    .text-custom-color-117 {
      color: $custom-color-117;
      color: var(--custom-color-117);
    }
  
    .background-custom-color-117 {
      background-color: $custom-color-117;
      background-color: var(--custom-color-117);
    }
  
    .border-custom-color-117 {
      border-color: $custom-color-117 !important;
      border-color: var(--custom-color-117) !important;
    }
  
    --custom-color-118: #d5d5d5;
  
    .text-custom-color-118 {
      color: $custom-color-118;
      color: var(--custom-color-118);
    }
  
    .background-custom-color-118 {
      background-color: $custom-color-118;
      background-color: var(--custom-color-118);
    }
  
    .border-custom-color-118 {
      border-color: $custom-color-118 !important;
      border-color: var(--custom-color-118) !important;
    }
  
    --custom-color-119: #d2d2d2;
  
    .text-custom-color-119 {
      color: $custom-color-119;
      color: var(--custom-color-119);
    }
  
    .background-custom-color-119 {
      background-color: $custom-color-119;
      background-color: var(--custom-color-119);
    }
  
    .border-custom-color-119 {
      border-color: $custom-color-119 !important;
      border-color: var(--custom-color-119) !important;
    }
  
    --custom-color-120: #dedfe0;
  
    .text-custom-color-120 {
      color: $custom-color-120;
      color: var(--custom-color-120);
    }
  
    .background-custom-color-120 {
      background-color: $custom-color-120;
      background-color: var(--custom-color-120);
    }
  
    .border-custom-color-120 {
      border-color: $custom-color-120 !important;
      border-color: var(--custom-color-120) !important;
    }
  
    //
    --custom-color-121: #d7e2e8;
  
    .text-custom-color-121 {
      color: $custom-color-121;
      color: var(--custom-color-121);
    }
  
    .background-custom-color-121 {
      background-color: $custom-color-121;
      background-color: var(--custom-color-121);
    }
  
    .border-custom-color-121 {
      border-color: $custom-color-121 !important;
      border-color: var(--custom-color-121) !important;
    }
  
    --custom-color-122: #dcdcdc;
  
    .text-custom-color-122 {
      color: $custom-color-122;
      color: var(--custom-color-122);
    }
  
    .background-custom-color-122 {
      background-color: $custom-color-122;
      background-color: var(--custom-color-122);
    }
  
    .border-custom-color-122 {
      border-color: $custom-color-122 !important;
      border-color: var(--custom-color-122) !important;
    }
  
    --custom-color-123: #de731d;
  
    .text-custom-color-123 {
      color: $custom-color-123;
      color: var(--custom-color-123);
    }
  
    .background-custom-color-123 {
      background-color: $custom-color-123;
      background-color: var(--custom-color-123);
    }
  
    .border-custom-color-123 {
      border-color: $custom-color-123 !important;
      border-color: var(--custom-color-123) !important;
    }
  
    --custom-color-124: #e9e9e9;
  
    .text-custom-color-124 {
      color: $custom-color-124;
      color: var(--custom-color-124);
    }
  
    .background-custom-color-124 {
      background-color: $custom-color-124;
      background-color: var(--custom-color-124);
    }
  
    .border-custom-color-124 {
      border-color: $custom-color-124 !important;
      border-color: var(--custom-color-124) !important;
    }
  
    --custom-color-125: #e3e3e3;
  
    .text-custom-color-125 {
      color: $custom-color-125;
      color: var(--custom-color-125);
    }
  
    .background-custom-color-125 {
      background-color: $custom-color-125;
      background-color: var(--custom-color-125);
    }
  
    .border-custom-color-125 {
      border-color: $custom-color-125 !important;
      border-color: var(--custom-color-125) !important;
    }
  
    --custom-color-126: #fcc;
  
    .text-custom-color-126 {
      color: $custom-color-126;
      color: var(--custom-color-126);
    }
  
    .background-custom-color-126 {
      background-color: $custom-color-126;
      background-color: var(--custom-color-126);
    }
  
    .border-custom-color-126 {
      border-color: $custom-color-126 !important;
      border-color: var(--custom-color-126) !important;
    }
  
    --custom-color-127: #f7c59f;
  
    .text-custom-color-127 {
      color: $custom-color-127;
      color: var(--custom-color-127);
    }
  
    .background-custom-color-127 {
      background-color: $custom-color-127;
      background-color: var(--custom-color-127);
    }
  
    .border-custom-color-127 {
      border-color: $custom-color-127 !important;
      border-color: var(--custom-color-127) !important;
    }
  
    --custom-color-128: #ffdabd;
  
    .text-custom-color-128 {
      color: $custom-color-128;
      color: var(--custom-color-128);
    }
  
    .background-custom-color-128 {
      background-color: $custom-color-128;
      background-color: var(--custom-color-128);
    }
  
    .border-custom-color-128 {
      border-color: $custom-color-128 !important;
      border-color: var(--custom-color-128) !important;
    }
  
    --custom-color-129: #ffe785;
  
    .text-custom-color-129 {
      color: $custom-color-129;
      color: var(--custom-color-129);
    }
  
    .background-custom-color-129 {
      background-color: $custom-color-129;
      background-color: var(--custom-color-129);
    }
  
    .border-custom-color-129 {
      border-color: $custom-color-129 !important;
      border-color: var(--custom-color-129) !important;
    }
  
    --custom-color-130: #f09819;
  
    .text-custom-color-130 {
      color: $custom-color-130;
      color: var(--custom-color-130);
    }
  
    .background-custom-color-130 {
      background-color: $custom-color-130;
      background-color: var(--custom-color-130);
    }
  
    .border-custom-color-130 {
      border-color: $custom-color-130 !important;
      border-color: var(--custom-color-130) !important;
    }
  
    //
    --custom-color-131: #f87739;
  
    .text-custom-color-131 {
      color: $custom-color-131;
      color: var(--custom-color-131);
    }
  
    .background-custom-color-131 {
      background-color: $custom-color-131;
      background-color: var(--custom-color-131);
    }
  
    .border-custom-color-131 {
      border-color: $custom-color-131 !important;
      border-color: var(--custom-color-131) !important;
    }
  
    --custom-color-132: #ff5858;
  
    .text-custom-color-132 {
      color: $custom-color-132;
      color: var(--custom-color-132);
    }
  
    .background-custom-color-132 {
      background-color: $custom-color-132;
      background-color: var(--custom-color-132);
    }
  
    .border-custom-color-132 {
      border-color: $custom-color-132 !important;
      border-color: var(--custom-color-132) !important;
    }
  
    --custom-color-133: #f5f5ee;
  
    .text-custom-color-133 {
      color: $custom-color-133;
      color: var(--custom-color-133);
    }
  
    .background-custom-color-133 {
      background-color: $custom-color-133;
      background-color: var(--custom-color-133);
    }
  
    .border-custom-color-133 {
      border-color: $custom-color-133 !important;
      border-color: var(--custom-color-133) !important;
    }
  
    --custom-color-134: rgba(198, 196, 196, 0.34);
  
    .text-custom-color-134 {
      color: $custom-color-134;
      color: var(--custom-color-134);
    }
  
    .background-custom-color-134 {
      background-color: $custom-color-134;
      background-color: var(--custom-color-134);
    }
  
    .border-custom-color-134 {
      border-color: $custom-color-134 !important;
      border-color: var(--custom-color-134) !important;
    }
  
    --custom-color-135: rgba(255, 0, 255, 0.9);
  
    .text-custom-color-135 {
      color: $custom-color-135;
      color: var(--custom-color-135);
    }
  
    .background-custom-color-135 {
      background-color: $custom-color-135;
      background-color: var(--custom-color-135);
    }
  
    .border-custom-color-135 {
      border-color: $custom-color-135 !important;
      border-color: var(--custom-color-135) !important;
    }
  
    --custom-color-136: rgba(255, 255, 255, 0.85);
  
    .text-custom-color-136 {
      color: $custom-color-136;
      color: var(--custom-color-136);
    }
  
    .background-custom-color-136 {
      background-color: $custom-color-136;
      background-color: var(--custom-color-136);
    }
  
    .border-custom-color-136 {
      border-color: $custom-color-136 !important;
      border-color: var(--custom-color-136) !important;
    }
  
    --custom-color-137: rgba(16, 0, 0, 0.1);
  
    .text-custom-color-137 {
      color: $custom-color-137;
      color: var(--custom-color-137);
    }
  
    .background-custom-color-137 {
      background-color: $custom-color-137;
      background-color: var(--custom-color-137);
    }
  
    .border-custom-color-137 {
      border-color: $custom-color-137 !important;
      border-color: var(--custom-color-137) !important;
    }
  
    --custom-color-138: rgba(51, 51, 51, 1);
  
    .text-custom-color-138 {
      color: $custom-color-138;
      color: var(--custom-color-138);
    }
  
    .background-custom-color-138 {
      background-color: $custom-color-138;
      background-color: var(--custom-color-138);
    }
  
    .border-custom-color-138 {
      border-color: $custom-color-138 !important;
      border-color: var(--custom-color-138) !important;
    }
  
    --custom-color-139: rgba(252, 198, 155, 0.4);
  
    .text-custom-color-139 {
      color: $custom-color-139;
      color: var(--custom-color-139);
    }
  
    .background-custom-color-139 {
      background-color: $custom-color-139;
      background-color: var(--custom-color-139);
    }
  
    .border-custom-color-139 {
      border-color: $custom-color-139 !important;
      border-color: var(--custom-color-139) !important;
    }
  
    --custom-color-140: rgba(252, 198, 155, 0);
  
    .text-custom-color-140 {
      color: $custom-color-140;
      color: var(--custom-color-140);
    }
  
    .background-custom-color-140 {
      background-color: $custom-color-140;
      background-color: var(--custom-color-140);
    }
  
    .border-custom-color-140 {
      border-color: $custom-color-140 !important;
      border-color: var(--custom-color-140) !important;
    }
  
    //
    --custom-color-141: rgba(252, 198, 155, 0.6);
  
    .text-custom-color-141 {
      color: $custom-color-141;
      color: var(--custom-color-141);
    }
  
    .background-custom-color-141 {
      background-color: $custom-color-141;
      background-color: var(--custom-color-141);
    }
  
    .border-custom-color-141 {
      border-color: $custom-color-141 !important;
      border-color: var(--custom-color-141) !important;
    }
  
    --custom-color-142: rgba(210, 210, 210, 0.3);
  
    .text-custom-color-142 {
      color: $custom-color-142;
      color: var(--custom-color-142);
    }
  
    .background-custom-color-142 {
      background-color: $custom-color-142;
      background-color: var(--custom-color-142);
    }
  
    .border-custom-color-142 {
      border-color: $custom-color-142 !important;
      border-color: var(--custom-color-142) !important;
    }
  
    --custom-color-143: rgba(255, 255, 255, 0.7);
  
    .text-custom-color-143 {
      color: $custom-color-143;
      color: var(--custom-color-143);
    }
  
    .background-custom-color-143 {
      background-color: $custom-color-143;
      background-color: var(--custom-color-143);
    }
  
    .border-custom-color-143 {
      border-color: $custom-color-143 !important;
      border-color: var(--custom-color-143) !important;
    }
  
    --custom-color-144: rgba(210, 210, 210, 0);
  
    .text-custom-color-144 {
      color: $custom-color-144;
      color: var(--custom-color-144);
    }
  
    .background-custom-color-144 {
      background-color: $custom-color-144;
      background-color: var(--custom-color-144);
    }
  
    .border-custom-color-144 {
      border-color: $custom-color-144 !important;
      border-color: var(--custom-color-144) !important;
    }
  
    --custom-color-145: rgba(17, 0, 0, 0.86);
  
    .text-custom-color-145 {
      color: $custom-color-145;
      color: var(--custom-color-145);
    }
  
    .background-custom-color-145 {
      background-color: $custom-color-145;
      background-color: var(--custom-color-145);
    }
  
    .border-custom-color-145 {
      border-color: $custom-color-145 !important;
      border-color: var(--custom-color-145) !important;
    }
  
    --custom-color-146: rgba(255, 0, 222, 0);
  
    .text-custom-color-146 {
      color: $custom-color-146;
      color: var(--custom-color-146);
    }
  
    .background-custom-color-146 {
      background-color: $custom-color-146;
      background-color: var(--custom-color-146);
    }
  
    .border-custom-color-146 {
      border-color: $custom-color-146 !important;
      border-color: var(--custom-color-146) !important;
    }
  
    --custom-color-147: rgba(16, 172, 132, 0.4);
  
    .text-custom-color-147 {
      color: $custom-color-147;
      color: var(--custom-color-147);
    }
  
    .background-custom-color-147 {
      background-color: $custom-color-147;
      background-color: var(--custom-color-147);
    }
  
    .border-custom-color-147 {
      border-color: $custom-color-147 !important;
      border-color: var(--custom-color-147) !important;
    }
  
    --custom-color-148: rgba(16, 172, 132, 0);
  
    .text-custom-color-148 {
      color: $custom-color-148;
      color: var(--custom-color-148);
    }
  
    .background-custom-color-148 {
      background-color: $custom-color-148;
      background-color: var(--custom-color-148);
    }
  
    .border-custom-color-148 {
      border-color: $custom-color-148 !important;
      border-color: var(--custom-color-148) !important;
    }
  
    --custom-color-149: rgba(175, 175, 175, 0.8);
  
    .text-custom-color-149 {
      color: $custom-color-149;
      color: var(--custom-color-149);
    }
  
    .background-custom-color-149 {
      background-color: $custom-color-149;
      background-color: var(--custom-color-149);
    }
  
    .border-custom-color-149 {
      border-color: $custom-color-149 !important;
      border-color: var(--custom-color-149) !important;
    }
  
    --custom-color-150: rgba(238, 249, 236, 0);
  
    .text-custom-color-150 {
      color: $custom-color-150;
      color: var(--custom-color-150);
    }
  
    .background-custom-color-150 {
      background-color: $custom-color-150;
      background-color: var(--custom-color-150);
    }
  
    .border-custom-color-150 {
      border-color: $custom-color-150 !important;
      border-color: var(--custom-color-150) !important;
    }
  
    //
    --custom-color-151: rgba(132, 143, 153, 0.2);
  
    .text-custom-color-151 {
      color: $custom-color-151;
      color: var(--custom-color-151);
    }
  
    .background-custom-color-151 {
      background-color: $custom-color-151;
      background-color: var(--custom-color-151);
    }
  
    .border-custom-color-151 {
      border-color: $custom-color-151 !important;
      border-color: var(--custom-color-151) !important;
    }
  
    --custom-color-152: #ffe2cc;
  
    .text-custom-color-152 {
      color: $custom-color-152;
      color: var(--custom-color-152);
    }
  
    .background-custom-color-152 {
      background-color: $custom-color-152;
      background-color: var(--custom-color-152);
    }
  
    .border-custom-color-152 {
      border-color: $custom-color-152 !important;
      border-color: var(--custom-color-152) !important;
    }
  
    --custom-color-153: #00c853;
  
    .text-custom-color-153 {
      color: $custom-color-153;
      color: var(--custom-color-153);
    }
  
    .background-custom-color-153 {
      background-color: $custom-color-153;
      background-color: var(--custom-color-153);
    }
  
    .border-custom-color-153 {
      border-color: $custom-color-153 !important;
      border-color: var(--custom-color-153) !important;
    }
  
    --custom-color-154: #ffa000;
  
    .text-custom-color-154 {
      color: $custom-color-154;
      color: var(--custom-color-154);
    }
  
    .background-custom-color-154 {
      background-color: $custom-color-154;
      background-color: var(--custom-color-154);
    }
  
    .border-custom-color-154 {
      border-color: $custom-color-154 !important;
      border-color: var(--custom-color-154) !important;
    }
  
    --custom-color-155: #d32f2f;
  
    .text-custom-color-155 {
      color: $custom-color-155;
      color: var(--custom-color-155);
    }
  
    .background-custom-color-155 {
      background-color: $custom-color-155;
      background-color: var(--custom-color-155);
    }
  
    .border-custom-color-155 {
      border-color: $custom-color-155 !important;
      border-color: var(--custom-color-155) !important;
    }
  
    --custom-color-156: #fef1e8;
  
    .text-custom-color-156 {
      color: $custom-color-156;
      color: var(--custom-color-156);
    }
  
    .background-custom-color-156 {
      background-color: $custom-color-156;
      background-color: var(--custom-color-156);
    }
  
    .border-custom-color-156 {
      border-color: $custom-color-156 !important;
      border-color: var(--custom-color-156) !important;
    }
  
    --custom-color-157: #c4c4c4;
  
    .text-custom-color-157 {
      color: $custom-color-157;
      color: var(--custom-color-157);
    }
  
    .background-custom-color-157 {
      background-color: $custom-color-157;
      background-color: var(--custom-color-157);
    }
  
    .border-custom-color-157 {
      border-color: $custom-color-157 !important;
      border-color: var(--custom-color-157) !important;
    }
  
    --custom-color-158: #fef3eb;
  
    .text-custom-color-158 {
      color: $custom-color-158;
      color: var(--custom-color-158);
    }
  
    .background-custom-color-158 {
      background-color: $custom-color-158;
      background-color: var(--custom-color-158);
    }
  
    .border-custom-color-158 {
      border-color: $custom-color-158 !important;
      border-color: var(--custom-color-158) !important;
    }
  
    --custom-color-159: #f58023;
  
    .text-custom-color-159 {
      color: $custom-color-159;
      color: var(--custom-color-159);
    }
  
    .background-custom-color-159 {
      background-color: $custom-color-159;
      background-color: var(--custom-color-159);
    }
  
    .border-custom-color-159 {
      border-color: $custom-color-159 !important;
      border-color: var(--custom-color-159) !important;
    }
  
    --custom-color-160: #ff969a;
  
    .text-custom-color-160 {
      color: $custom-color-160;
      color: var(--custom-color-160);
    }
  
    .background-custom-color-160 {
      background-color: $custom-color-160;
      background-color: var(--custom-color-160);
    }
  
    .border-custom-color-160 {
      border-color: $custom-color-160 !important;
      border-color: var(--custom-color-160) !important;
    }
  
    //
    --custom-color-161: #ff5555;
  
    .text-custom-color-161 {
      color: $custom-color-161;
      color: var(--custom-color-161);
    }
  
    .background-custom-color-161 {
      background-color: $custom-color-161;
      background-color: var(--custom-color-161);
    }
  
    .border-custom-color-161 {
      border-color: $custom-color-161 !important;
      border-color: var(--custom-color-161) !important;
    }
  
    --custom-color-162: #858385;
  
    .text-custom-color-162 {
      color: $custom-color-162;
      color: var(--custom-color-162);
    }
  
    .background-custom-color-162 {
      background-color: $custom-color-162;
      background-color: var(--custom-color-162);
    }
  
    .border-custom-color-162 {
      border-color: $custom-color-162 !important;
      border-color: var(--custom-color-162) !important;
    }
  
    --custom-color-163: #ffefb9;
  
    .text-custom-color-163 {
      color: $custom-color-163;
      color: var(--custom-color-163);
    }
  
    .background-custom-color-163 {
      background-color: $custom-color-163;
      background-color: var(--custom-color-163);
    }
  
    .border-custom-color-163 {
      border-color: $custom-color-163 !important;
      border-color: var(--custom-color-163) !important;
    }
  
    --custom-color-164: #ffc234;
  
    .text-custom-color-164 {
      color: $custom-color-164;
      color: var(--custom-color-164);
    }
  
    .background-custom-color-164 {
      background-color: $custom-color-164;
      background-color: var(--custom-color-164);
    }
  
    .border-custom-color-164 {
      border-color: $custom-color-164 !important;
      border-color: var(--custom-color-164) !important;
    }
  
    --custom-color-165: #a89fa7;
  
    .text-custom-color-165 {
      color: $custom-color-165;
      color: var(--custom-color-165);
    }
  
    .background-custom-color-165 {
      background-color: $custom-color-165;
      background-color: var(--custom-color-165);
    }
  
    .border-custom-color-165 {
      border-color: $custom-color-165 !important;
      border-color: var(--custom-color-165) !important;
    }
  
    --custom-color-166: #afd0c6;
  
    .text-custom-color-166 {
      color: $custom-color-166;
      color: var(--custom-color-166);
    }
  
    .background-custom-color-166 {
      background-color: $custom-color-166;
      background-color: var(--custom-color-166);
    }
  
    .border-custom-color-166 {
      border-color: $custom-color-166 !important;
      border-color: var(--custom-color-166) !important;
    }
  
    --custom-color-167: #ace2d4;
  
    .text-custom-color-167 {
      color: $custom-color-167;
      color: var(--custom-color-167);
    }
  
    .background-custom-color-167 {
      background-color: $custom-color-167;
      background-color: var(--custom-color-167);
    }
  
    .border-custom-color-167 {
      border-color: $custom-color-167 !important;
      border-color: var(--custom-color-167) !important;
    }
  
    --custom-color-168: #a3a1a3;
  
    .text-custom-color-168 {
      color: $custom-color-168;
      color: var(--custom-color-168);
    }
  
    .background-custom-color-168 {
      background-color: $custom-color-168;
      background-color: var(--custom-color-168);
    }
  
    .border-custom-color-168 {
      border-color: $custom-color-168 !important;
      border-color: var(--custom-color-168) !important;
    }
  
    --custom-color-169: #cb961d;
  
    .text-custom-color-169 {
      color: $custom-color-169;
      color: var(--custom-color-169);
    }
  
    .background-custom-color-169 {
      background-color: $custom-color-169;
      background-color: var(--custom-color-169);
    }
  
    .border-custom-color-169 {
      border-color: $custom-color-169 !important;
      border-color: var(--custom-color-169) !important;
    }
  
    --custom-color-170: #c8c8c8;
  
    .text-custom-color-170 {
      color: $custom-color-170;
      color: var(--custom-color-170);
    }
  
    .background-custom-color-170 {
      background-color: $custom-color-170;
      background-color: var(--custom-color-170);
    }
  
    .border-custom-color-170 {
      border-color: $custom-color-170 !important;
      border-color: var(--custom-color-170) !important;
    }
  
    //
    --custom-color-171: #cbcbcb;
  
    .text-custom-color-171 {
      color: $custom-color-171;
      color: var(--custom-color-171);
    }
  
    .background-custom-color-171 {
      background-color: $custom-color-171;
      background-color: var(--custom-color-171);
    }
  
    .border-custom-color-171 {
      border-color: $custom-color-171 !important;
      border-color: var(--custom-color-171) !important;
    }
  
    --custom-color-172: #c0d7ff;
  
    .text-custom-color-172 {
      color: $custom-color-172;
      color: var(--custom-color-172);
    }
  
    .background-custom-color-172 {
      background-color: $custom-color-172;
      background-color: var(--custom-color-172);
    }
  
    .border-custom-color-172 {
      border-color: $custom-color-172 !important;
      border-color: var(--custom-color-172) !important;
    }
  
    --custom-color-173: #cacaca;
  
    .text-custom-color-173 {
      color: $custom-color-173;
      color: var(--custom-color-173);
    }
  
    .background-custom-color-173 {
      background-color: $custom-color-173;
      background-color: var(--custom-color-173);
    }
  
    .border-custom-color-173 {
      border-color: $custom-color-173 !important;
      border-color: var(--custom-color-173) !important;
    }
  
    --custom-color-174: #dadada;
  
    .text-custom-color-174 {
      color: $custom-color-174;
      color: var(--custom-color-174);
    }
  
    .background-custom-color-174 {
      background-color: $custom-color-174;
      background-color: var(--custom-color-174);
    }
  
    .border-custom-color-174 {
      border-color: $custom-color-174 !important;
      border-color: var(--custom-color-174) !important;
    }
  
    --custom-color-175: #dadde0;
  
    .text-custom-color-175 {
      color: $custom-color-175;
      color: var(--custom-color-175);
    }
  
    .background-custom-color-175 {
      background-color: $custom-color-175;
      background-color: var(--custom-color-175);
    }
  
    .border-custom-color-175 {
      border-color: $custom-color-175 !important;
      border-color: var(--custom-color-175) !important;
    }
  
    --custom-color-176: #d0d0d0;
  
    .text-custom-color-176 {
      color: $custom-color-176;
      color: var(--custom-color-176);
    }
  
    .background-custom-color-176 {
      background-color: $custom-color-176;
      background-color: var(--custom-color-176);
    }
  
    .border-custom-color-176 {
      border-color: $custom-color-176 !important;
      border-color: var(--custom-color-176) !important;
    }
  
    --custom-color-177: #d1d1d1;
  
    .text-custom-color-177 {
      color: $custom-color-177;
      color: var(--custom-color-177);
    }
  
    .background-custom-color-177 {
      background-color: $custom-color-177;
      background-color: var(--custom-color-177);
    }
  
    .border-custom-color-177 {
      border-color: $custom-color-177 !important;
      border-color: var(--custom-color-177) !important;
    }
  
    --custom-color-178: #eff8ff;
  
    .text-custom-color-178 {
      color: $custom-color-178;
      color: var(--custom-color-178);
    }
  
    .background-custom-color-178 {
      background-color: $custom-color-178;
      background-color: var(--custom-color-178);
    }
  
    .border-custom-color-178 {
      border-color: $custom-color-178 !important;
      border-color: var(--custom-color-178) !important;
    }
  
    --custom-color-179: #e7e7e7;
  
    .text-custom-color-179 {
      color: $custom-color-179;
      color: var(--custom-color-179);
    }
  
    .background-custom-color-179 {
      background-color: $custom-color-179;
      background-color: var(--custom-color-179);
    }
  
    .border-custom-color-179 {
      border-color: $custom-color-179 !important;
      border-color: var(--custom-color-179) !important;
    }
  
    --custom-color-180: #eae9e9;
  
    .text-custom-color-180 {
      color: $custom-color-180;
      color: var(--custom-color-180);
    }
  
    .background-custom-color-180 {
      background-color: $custom-color-180;
      background-color: var(--custom-color-180);
    }
  
    .border-custom-color-180 {
      border-color: $custom-color-180 !important;
      border-color: var(--custom-color-180) !important;
    }
  
    //
    --custom-color-181: #efefef;
  
    .text-custom-color-181 {
      color: $custom-color-181;
      color: var(--custom-color-181);
    }
  
    .background-custom-color-181 {
      background-color: $custom-color-181;
      background-color: var(--custom-color-181);
    }
  
    .border-custom-color-181 {
      border-color: $custom-color-181 !important;
      border-color: var(--custom-color-181) !important;
    }
  
    --custom-color-182: #e5efff;
  
    .text-custom-color-182 {
      color: $custom-color-182;
      color: var(--custom-color-182);
    }
  
    .background-custom-color-182 {
      background-color: $custom-color-182;
      background-color: var(--custom-color-182);
    }
  
    .border-custom-color-182 {
      border-color: $custom-color-182 !important;
      border-color: var(--custom-color-182) !important;
    }
  
    --custom-color-183: #eef4ff;
  
    .text-custom-color-183 {
      color: $custom-color-183;
      color: var(--custom-color-183);
    }
  
    .background-custom-color-183 {
      background-color: $custom-color-183;
      background-color: var(--custom-color-183);
    }
  
    .border-custom-color-183 {
      border-color: $custom-color-183 !important;
      border-color: var(--custom-color-183) !important;
    }
  
    --custom-color-184: #e9f6fd;
  
    .text-custom-color-184 {
      color: $custom-color-184;
      color: var(--custom-color-184);
    }
  
    .background-custom-color-184 {
      background-color: $custom-color-184;
      background-color: var(--custom-color-184);
    }
  
    .border-custom-color-184 {
      border-color: $custom-color-184 !important;
      border-color: var(--custom-color-184) !important;
    }
  
    --custom-color-185: #e8fcf7;
  
    .text-custom-color-185 {
      color: $custom-color-185;
      color: var(--custom-color-185);
    }
  
    .background-custom-color-185 {
      background-color: $custom-color-185;
      background-color: var(--custom-color-185);
    }
  
    .border-custom-color-185 {
      border-color: $custom-color-185 !important;
      border-color: var(--custom-color-185) !important;
    }
  
    --custom-color-186: #ee6b67;
  
    .text-custom-color-186 {
      color: $custom-color-186;
      color: var(--custom-color-186);
    }
  
    .background-custom-color-186 {
      background-color: $custom-color-186;
      background-color: var(--custom-color-186);
    }
  
    .border-custom-color-186 {
      border-color: $custom-color-186 !important;
      border-color: var(--custom-color-186) !important;
    }
  
    --custom-color-187: #0000001a;
  
    .text-custom-color-187 {
      color: $custom-color-187;
      color: var(--custom-color-187);
    }
  
    .background-custom-color-187 {
      background-color: $custom-color-187;
      background-color: var(--custom-color-187);
    }
  
    .border-custom-color-187 {
      border-color: $custom-color-187 !important;
      border-color: var(--custom-color-187) !important;
    }
  
    --custom-color-188: #0bac83;
  
    .text-custom-color-188 {
      color: $custom-color-188;
      color: var(--custom-color-188);
    }
  
    .background-custom-color-188 {
      background-color: $custom-color-188;
      background-color: var(--custom-color-188);
    }
  
    .border-custom-color-188 {
      border-color: $custom-color-188 !important;
      border-color: var(--custom-color-188) !important;
    }
  
    --custom-color-189: #00000080;
  
    .text-custom-color-189 {
      color: $custom-color-189;
      color: var(--custom-color-189);
    }
  
    .background-custom-color-189 {
      background-color: $custom-color-189;
      background-color: var(--custom-color-189);
    }
  
    .border-custom-color-189 {
      border-color: $custom-color-189 !important;
      border-color: var(--custom-color-189) !important;
    }
  
    --custom-color-190: #3b3b3b;
  
    .text-custom-color-190 {
      color: $custom-color-190;
      color: var(--custom-color-190);
    }
  
    .background-custom-color-190 {
      background-color: $custom-color-190;
      background-color: var(--custom-color-190);
    }
  
    .border-custom-color-190 {
      border-color: $custom-color-190 !important;
      border-color: var(--custom-color-190) !important;
    }
  
    //
    --custom-color-191: #48535f;
  
    .text-custom-color-191 {
      color: $custom-color-191;
      color: var(--custom-color-191);
    }
  
    .background-custom-color-191 {
      background-color: $custom-color-191;
      background-color: var(--custom-color-191);
    }
  
    .border-custom-color-191 {
      border-color: $custom-color-191 !important;
      border-color: var(--custom-color-191) !important;
    }
  
    --custom-color-192: #4a4a4a;
  
    .text-custom-color-192 {
      color: $custom-color-192;
      color: var(--custom-color-192);
    }
  
    .background-custom-color-192 {
      background-color: $custom-color-192;
      background-color: var(--custom-color-192);
    }
  
    .border-custom-color-192 {
      border-color: $custom-color-192 !important;
      border-color: var(--custom-color-192) !important;
    }
  
    --custom-color-193: #4e93ef;
  
    .text-custom-color-193 {
      color: $custom-color-193;
      color: var(--custom-color-193);
    }
  
    .background-custom-color-193 {
      background-color: $custom-color-193;
      background-color: var(--custom-color-193);
    }
  
    .border-custom-color-193 {
      border-color: $custom-color-193 !important;
      border-color: var(--custom-color-193) !important;
    }
  
    --custom-color-194: #438aff;
  
    .text-custom-color-194 {
      color: $custom-color-194;
      color: var(--custom-color-194);
    }
  
    .background-custom-color-194 {
      background-color: $custom-color-194;
      background-color: var(--custom-color-194);
    }
  
    .border-custom-color-194 {
      border-color: $custom-color-194 !important;
      border-color: var(--custom-color-194) !important;
    }
  
    --custom-color-195: #4885ed;
  
    .text-custom-color-195 {
      color: $custom-color-195;
      color: var(--custom-color-195);
    }
  
    .background-custom-color-195 {
      background-color: $custom-color-195;
      background-color: var(--custom-color-195);
    }
  
    .border-custom-color-195 {
      border-color: $custom-color-195 !important;
      border-color: var(--custom-color-195) !important;
    }
  
    --custom-color-196: #566574;
  
    .text-custom-color-196 {
      color: $custom-color-196;
      color: var(--custom-color-196);
    }
  
    .background-custom-color-196 {
      background-color: $custom-color-196;
      background-color: var(--custom-color-196);
    }
  
    .border-custom-color-196 {
      border-color: $custom-color-196 !important;
      border-color: var(--custom-color-196) !important;
    }
  
    --custom-color-197: #556674;
  
    .text-custom-color-197 {
      color: $custom-color-197;
      color: var(--custom-color-197);
    }
  
    .background-custom-color-197 {
      background-color: $custom-color-197;
      background-color: var(--custom-color-197);
    }
  
    .border-custom-color-197 {
      border-color: $custom-color-197 !important;
      border-color: var(--custom-color-197) !important;
    }
  
    --custom-color-198: #5890da;
  
    .text-custom-color-198 {
      color: $custom-color-198;
      color: var(--custom-color-198);
    }
  
    .background-custom-color-198 {
      background-color: $custom-color-198;
      background-color: var(--custom-color-198);
    }
  
    .border-custom-color-198 {
      border-color: $custom-color-198 !important;
      border-color: var(--custom-color-198) !important;
    }
  
    --custom-color-199: #5393ff;
  
    .text-custom-color-199 {
      color: $custom-color-199;
      color: var(--custom-color-199);
    }
  
    .background-custom-color-199 {
      background-color: $custom-color-199;
      background-color: var(--custom-color-199);
    }
  
    .border-custom-color-199 {
      border-color: $custom-color-199 !important;
      border-color: var(--custom-color-199) !important;
    }
  
    --custom-color-200: #586473;
  
    .text-custom-color-200 {
      color: $custom-color-200;
      color: var(--custom-color-200);
    }
  
    .background-custom-color-200 {
      background-color: $custom-color-200;
      background-color: var(--custom-color-200);
    }
  
    .border-custom-color-200 {
      border-color: $custom-color-200 !important;
      border-color: var(--custom-color-200) !important;
    }
  
    //
    --custom-color-201: #5194fe;
  
    .text-custom-color-201 {
      color: $custom-color-201;
      color: var(--custom-color-201);
    }
  
    .background-custom-color-201 {
      background-color: $custom-color-201;
      background-color: var(--custom-color-201);
    }
  
    .border-custom-color-201 {
      border-color: $custom-color-201 !important;
      border-color: var(--custom-color-201) !important;
    }
  
    --custom-color-202: #6287d0;
  
    .text-custom-color-202 {
      color: $custom-color-202;
      color: var(--custom-color-202);
    }
  
    .background-custom-color-202 {
      background-color: $custom-color-202;
      background-color: var(--custom-color-202);
    }
  
    .border-custom-color-202 {
      border-color: $custom-color-202 !important;
      border-color: var(--custom-color-202) !important;
    }
  
    --custom-color-203: #6d7278;
  
    .text-custom-color-203 {
      color: $custom-color-203;
      color: var(--custom-color-203);
    }
  
    .background-custom-color-203 {
      background-color: $custom-color-203;
      background-color: var(--custom-color-203);
    }
  
    .border-custom-color-203 {
      border-color: $custom-color-203 !important;
      border-color: var(--custom-color-203) !important;
    }
  
    --custom-color-204: #6fbfaa;
  
    .text-custom-color-204 {
      color: $custom-color-204;
      color: var(--custom-color-204);
    }
  
    .background-custom-color-204 {
      background-color: $custom-color-204;
      background-color: var(--custom-color-204);
    }
  
    .border-custom-color-204 {
      border-color: $custom-color-204 !important;
      border-color: var(--custom-color-204) !important;
    }
  
    --custom-color-205: #6b7682;
  
    .text-custom-color-205 {
      color: $custom-color-205;
      color: var(--custom-color-205);
    }
  
    .background-custom-color-205 {
      background-color: $custom-color-205;
      background-color: var(--custom-color-205);
    }
  
    .border-custom-color-205 {
      border-color: $custom-color-205 !important;
      border-color: var(--custom-color-205) !important;
    }
  
    --custom-color-206: #8295a7;
  
    .text-custom-color-206 {
      color: $custom-color-206;
      color: var(--custom-color-206);
    }
  
    .background-custom-color-206 {
      background-color: $custom-color-206;
      background-color: var(--custom-color-206);
    }
  
    .border-custom-color-206 {
      border-color: $custom-color-206 !important;
      border-color: var(--custom-color-206) !important;
    }
  
    --custom-color-207: #9ea7ae;
  
    .text-custom-color-207 {
      color: $custom-color-207;
      color: var(--custom-color-207);
    }
  
    .background-custom-color-207 {
      background-color: $custom-color-207;
      background-color: var(--custom-color-207);
    }
  
    .border-custom-color-207 {
      border-color: $custom-color-207 !important;
      border-color: var(--custom-color-207) !important;
    }
  
    --custom-color-208: #919294;
  
    .text-custom-color-208 {
      color: $custom-color-208;
      color: var(--custom-color-208);
    }
  
    .background-custom-color-208 {
      background-color: $custom-color-208;
      background-color: var(--custom-color-208);
    }
  
    .border-custom-color-208 {
      border-color: $custom-color-208 !important;
      border-color: var(--custom-color-208) !important;
    }
  
    --custom-color-209: #999999;
  
    .text-custom-color-209 {
      color: $custom-color-209;
      color: var(--custom-color-209);
    }
  
    .background-custom-color-209 {
      background-color: $custom-color-209;
      background-color: var(--custom-color-209);
    }
  
    .border-custom-color-209 {
      border-color: $custom-color-209 !important;
      border-color: var(--custom-color-209) !important;
    }
  
    --custom-color-210: #9bc1ff;
  
    .text-custom-color-210 {
      color: $custom-color-210;
      color: var(--custom-color-210);
    }
  
    .background-custom-color-210 {
      background-color: $custom-color-210;
      background-color: var(--custom-color-210);
    }
  
    .border-custom-color-210 {
      border-color: $custom-color-210 !important;
      border-color: var(--custom-color-210) !important;
    }
  
    //
    --custom-color-211: #969da5;
  
    .text-custom-color-211 {
      color: $custom-color-211;
      color: var(--custom-color-211);
    }
  
    .background-custom-color-211 {
      background-color: $custom-color-211;
      background-color: var(--custom-color-211);
    }
  
    .border-custom-color-211 {
      border-color: $custom-color-211 !important;
      border-color: var(--custom-color-211) !important;
    }
  
    --custom-color-212: rgb(216, 216, 216);
  
    .text-custom-color-212 {
      color: $custom-color-212;
      color: var(--custom-color-212);
    }
  
    .background-custom-color-212 {
      background-color: $custom-color-212;
      background-color: var(--custom-color-212);
    }
  
    .border-custom-color-212 {
      border-color: $custom-color-212 !important;
      border-color: var(--custom-color-212) !important;
    }
  
    --custom-color-213: rgb(96, 96, 105);
  
    .text-custom-color-213 {
      color: $custom-color-213;
      color: var(--custom-color-213);
    }
  
    .background-custom-color-213 {
      background-color: $custom-color-213;
      background-color: var(--custom-color-213);
    }
  
    .border-custom-color-213 {
      border-color: $custom-color-213 !important;
      border-color: var(--custom-color-213) !important;
    }
  
    --custom-color-214: rgba(247, 247, 247, 1);
  
    .text-custom-color-214 {
      color: $custom-color-214;
      color: var(--custom-color-214);
    }
  
    .background-custom-color-214 {
      background-color: $custom-color-214;
      background-color: var(--custom-color-214);
    }
  
    .border-custom-color-214 {
      border-color: $custom-color-214 !important;
      border-color: var(--custom-color-214) !important;
    }
  
    --custom-color-215: rgba(72, 83, 95, 0.56);
  
    .text-custom-color-215 {
      color: $custom-color-215;
      color: var(--custom-color-215);
    }
  
    .background-custom-color-215 {
      background-color: $custom-color-215;
      background-color: var(--custom-color-215);
    }
  
    .border-custom-color-215 {
      border-color: $custom-color-215 !important;
      border-color: var(--custom-color-215) !important;
    }
  
    --custom-color-216: rgba(0, 122, 255, 1);
  
    .text-custom-color-216 {
      color: $custom-color-216;
      color: var(--custom-color-216);
    }
  
    .background-custom-color-216 {
      background-color: $custom-color-216;
      background-color: var(--custom-color-216);
    }
  
    .border-custom-color-216 {
      border-color: $custom-color-216 !important;
      border-color: var(--custom-color-216) !important;
    }
  
    --custom-color-217: rgba(255, 255, 255, 0);
  
    .text-custom-color-217 {
      color: $custom-color-217;
      color: var(--custom-color-217);
    }
  
    .background-custom-color-217 {
      background-color: $custom-color-217;
      background-color: var(--custom-color-217);
    }
  
    .border-custom-color-217 {
      border-color: $custom-color-217 !important;
      border-color: var(--custom-color-217) !important;
    }
  
    --custom-color-218: rgba(179, 245, 228, 0.6);
  
    .text-custom-color-218 {
      color: $custom-color-218;
      color: var(--custom-color-218);
    }
  
    .background-custom-color-218 {
      background-color: $custom-color-218;
      background-color: var(--custom-color-218);
    }
  
    .border-custom-color-218 {
      border-color: $custom-color-218 !important;
      border-color: var(--custom-color-218) !important;
    }
  
    --custom-color-219: rgba(16, 172, 132, 0.35);
  
    .text-custom-color-219 {
      color: $custom-color-219;
      color: var(--custom-color-219);
    }
  
    .background-custom-color-219 {
      background-color: $custom-color-219;
      background-color: var(--custom-color-219);
    }
  
    .border-custom-color-219 {
      border-color: $custom-color-219 !important;
      border-color: var(--custom-color-219) !important;
    }
  
    --custom-color-220: rgba(15, 172, 133, 1);
  
    .text-custom-color-220 {
      color: $custom-color-220;
      color: var(--custom-color-220);
    }
  
    .background-custom-color-220 {
      background-color: $custom-color-220;
      background-color: var(--custom-color-220);
    }
  
    .border-custom-color-220 {
      border-color: $custom-color-220 !important;
      border-color: var(--custom-color-220) !important;
    }
  
    --custom-color-221: rgba(255, 246, 228, 1);
  
    .text-custom-color-221 {
      color: $custom-color-221;
      color: var(--custom-color-221);
    }
  
    .background-custom-color-221 {
      background-color: $custom-color-221;
      background-color: var(--custom-color-221);
    }
  
    .border-custom-color-221 {
      border-color: $custom-color-221 !important;
      border-color: var(--custom-color-221) !important;
    }
  
    --custom-color-223: #fffdf5;
  
    .text-custom-color-223 {
      color: $custom-color-223;
      color: var(--custom-color-223);
    }
  
    .background-custom-color-223 {
      background-color: $custom-color-223;
      background-color: var(--custom-color-223);
    }
  
    .border-custom-color-223 {
      border-color: $custom-color-223 !important;
      border-color: var(--custom-color-223) !important;
    }
  
    --custom-color-224: #ffb74d;
  
    .text-custom-color-224 {
      color: $custom-color-224;
      color: var(--custom-color-224);
    }
  
    .background-custom-color-224 {
      background-color: $custom-color-224;
      background-color: var(--custom-color-224);
    }
  
    .border-custom-color-224 {
      border-color: $custom-color-224 !important;
      border-color: var(--custom-color-224) !important;
    }
  
    --custom-color-225: #fffbf8;
  
    .text-custom-color-225 {
      color: $custom-color-225;
      color: var(--custom-color-225);
    }
  
    .background-custom-color-225 {
      background-color: $custom-color-225;
      background-color: var(--custom-color-225);
    }
  
    .border-custom-color-225 {
      border-color: $custom-color-225 !important;
      border-color: var(--custom-color-225) !important;
    }
  
    --custom-color-226: rgba(255, 255, 255, 0.9);
  
    .text-custom-color-226 {
      color: $custom-color-226;
      color: var(--custom-color-226);
    }
  
    .background-custom-color-226 {
      background-color: $custom-color-226;
      background-color: var(--custom-color-226);
    }
  
    .border-custom-color-226 {
      border-color: $custom-color-226 !important;
      border-color: var(--custom-color-226) !important;
    }
  
    --custom-color-227: #bea9ff;
  
    .text-custom-color-227 {
      color: $custom-color-227;
      color: var(--custom-color-227);
    }
  
    .background-custom-color-227 {
      background-color: $custom-color-227;
      background-color: var(--custom-color-227);
    }
  
    .border-custom-color-227 {
      border-color: $custom-color-227 !important;
      border-color: var(--custom-color-227) !important;
    }
  
    --custom-color-222: #424242;
    --custom-color-233: rgba(0, 0, 0, 0.4);
    --custom-color-234: #ededed;
  
    --custom-color-235: #11c095;
  
    .border-custom-color-235 {
      border-color: $custom-color-235 !important;
      border-color: var(--custom-color-235) !important;
    }
    
    --custom-color-236: #F9FDFF;
    
    .text-custom-color-236 {
      color: $custom-color-236;
      color: var(--custom-color-236);
    }
    
    .background-custom-color-236 {
      background-color: $custom-color-236;
      background-color: var(--custom-color-236);
    }
  
    .border-custom-color-235 {
      border-color: $custom-color-236 !important;
      border-color: var(--custom-color-236) !important;
    }

    --custom-color-blue: #0D5D7F;
    
    .text-custom-color-blue {
      color: $custom-color-blue;
      color: var(--custom-color-blue);
    }
    
    .background-custom-color-blue {
      background-color: $custom-color-blue;
      background-color: var(--custom-color-blue);
    }
  
    .border-custom-color-235 {
      border-color: $custom-color-blue !important;
      border-color: var(--custom-color-blue) !important;
    }
    
  }
  
  body.dark {
    --sb-white: #444;
    --sb-black: #bbb;
    --sb-black-85: #bbb;
    --sb-charcoal: #ccc;
    --sb-black-65: #ccc;
    --sb-sidemenu-gray: #333;
    --sb-hover-grey-bg: #333;
    --sb-slate-gray: #333;
    --sb-slate-gray: #eee;
    --custom-color-137: rgba(18, 18, 18, 0.3);
    --custom-color-142: rgba(18, 18, 18, 0.3);
    --sb-grey-88: #5f5f5f;
    --custom-color-113: #5f5f5f;
    --custom-color-222: #8e8e8e;
    --custom-color-17: #5a5a5a;
    --sb-ui-blue-light-2: #222;
    --custom-color-178: #333;
    --custom-color-69: #333;
    --custom-color-21: #333;
    --custom-color-8: #333;
    --sb-gray: #333;
    --card-layout-primary-text: #ccc;
    --sb-vanta-black: #fff;
    --custom-color-13: #333;
    --custom-color-60: #999;
    --custom-color-44: #333;
  }