@import "../utils/all";
@import "../utils/colors";

.no-padding-margin-bottom {
  padding-bottom: 0;
  margin-bottom: 0;
}

.sticky-footer .row .col {
  padding: 0;
}

.change-funds a {
  color: $sb-orange;
  color: var(--sb-orange);
}

a:link {
  color: $sb-black;
  color: var(--sb-black);
  text-decoration: none;
}

a:visited {
  color: $sb-black;
  color: var(--sb-black);
  text-decoration: none;
}

a:hover {
  color: $sb-black;
  color: var(--sb-black);
  text-decoration: none;
}

a:active {
  color: $sb-black;
  color: var(--sb-black);
  text-decoration: none;
}

a:focus {
  color: $sb-black;
  color: var(--sb-black);
}

.row {
  margin-bottom: 10px;
}

.col {
  min-height: 1px;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: $font-16;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: $font-16;
  }
}

input,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea {
  font-size: $font-16;
}

.progress {
  margin: 0;
}

nav {
  line-height: 50px;
}

.material-icons {
  text-rendering: auto;
}

.toast {
  background-color: $sb-black;
  background-color: var(--sb-black);
  word-break: normal;
}

#toast-container {
  bottom: 55px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.dropdown-content li > a {
  color: $sb-black;
  color: var(--sb-black);
}

.side-nav li:hover,
.side-nav li.active {
  background-color: $sb-white;
  background-color: var(--sb-white);
}

input[type=text]:disabled,
input[type=text][readonly="readonly"],
input[type=password]:disabled,
input[type=password][readonly="readonly"],
input[type=email]:disabled,
input[type=email][readonly="readonly"],
input[type=url]:disabled,
input[type=url][readonly="readonly"],
input[type=time]:disabled,
input[type=time][readonly="readonly"],
input[type=date]:disabled,
input[type=date][readonly="readonly"],
input[type=datetime-local]:disabled,
input[type=datetime-local][readonly="readonly"],
input[type=tel]:disabled,
input[type=tel][readonly="readonly"],
input[type=number]:disabled,
input[type=number][readonly="readonly"],
input[type=search]:disabled,
input[type=search][readonly="readonly"],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly="readonly"] {
  color: $sb-black-60;
  color: var(--sb-black-60);
}

label,
.input-field label,
input[type=date]:focus:not([readonly]) + label,
input[type=datetime-local]:focus:not([readonly]) + label,
input[type=email]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label,
input[type=password]:focus:not([readonly]) + label,
input[type=search]:focus:not([readonly]) + label,
input[type=tel]:focus:not([readonly]) + label,
input[type=text]:focus:not([readonly]) + label,
input[type=time]:focus:not([readonly]) + label,
input[type=url]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: $sb-charcoal;
  color: var(--sb-charcoal);
}

.clear_block {
  float: right;
}

.number-circle {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 4px;
  background: $custom-color-133;
  background: var(--custom-color-133);
  border: 2px solid $sb-slate-gray;
  border: 2px solid var(--sb-slate-gray);
  color: $sb-slate-gray;
  color: var(--sb-slate-gray);
  text-align: center;
  font: 15px Arial, sans-serif;
  margin: 0 auto;
}

.declaration-section {
  padding-bottom: 1rem;
}

.large-input-label {
  margin-top: 5px;
}

.input-label {
  margin-top: 30px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.security-message {
  margin-top: 50px;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=date]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  box-shadow: none;
}

input[type=date],
input[type=datetime-local],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea.materialize-textarea {
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
}

select.browser-default {
  height: 35px;
  -webkit-appearance: none;
  outline: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url('../images/icons/interactive-utility/black/expand-more-small.png');
  background-position: right;
  background-repeat: no-repeat;
  line-height: 17px;
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  padding-left: 12px;
  font-weight: bold;
  font-style: normal;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  color: $sb-black;
  color: var(--sb-black);
}

.grey-text.text-darken-3 {
  color: $custom-color-222 !important;
  color: var(--custom-color-222) !important;
}

[type=radio]:checked + label::after,
.with-gap-blue {
  border-radius: 50%;
  border: 2px solid $sb-blue-link;
  background-color: $sb-blue-link;
  z-index: 0;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
}

[type=radio]:checked + label::before,
.with-gap-border {
  border-radius: 50%;
  border: 1px solid $sb-gray;
}

.container {
  margin: 0 auto;
  max-width: 992px;
  width: 100%;
}

.container .row {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (min-width: 1310px) {
  .container {
    max-width: 1128px;
  }
}
