@import "../utils/all";

#fund-header {
  margin: auto 10px;

  .fund-header-image-container {
    // border: 1px solid $sb-gray;
    // border: 1px solid var(--sb-gray);
    height: 25px;
    width: 25px;
    float: left;
  }

  .fund-header-image {
    padding: 1px;
  }

  .fund-header-container {
    margin-left: 35px;

    .fund-header-header-text {
      display: inline-flex;
      font-size: $font-16;
      font-weight: 600;
    }

    .fund-header-sub-header-text {
      font-size: $font-14;
      color: $card-layout-description-text;
      color: var(--card-layout-description-text);
    }
  }

  .fund-performance-link {
    font-size: $font-14;
    color: $sb-blue-link;
    color: var(--sb-blue-link);
    padding-top: 15px;
    margin-left: 35px;
    padding-bottom: 15px;
    cursor: pointer;
  }
}
