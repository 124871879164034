 .flex-grow {
    flex-grow: 1;
  }
  
  .d-flex {
    display: flex;
  }
  
  .d-inline {
    display: inline;
  }
  
  .d-inline-block {
    display: inline-block;
  }
  
  .flex-row-wrapper {
    display: flex;
    flex-direction: row;
  }
  
  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  
  .flex-col-reverse {
    flex-direction: column-reverse;
  }

  .flex-col-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .flex-1 {
    flex: 1;
  }
  
  .flex-2 {
    flex: 2;
  }
  
  .flex-3 {
    flex: 3;
  }
  
  .flex-4 {
    flex: 4;
  }
  
  .flex-5 {
    flex: 5;
  }

  .flex-6 {
    flex: 6;
  }
  
  .flex-0 {
    flex: 0;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }

  .justify-center {
    justify-content: center;
  }
  
  .justify-space-between {
    justify-content: space-between;
  }
  
  .justify-space-evenly {
    justify-content: space-evenly;
  }
  
  .justify-space-around {
    justify-content: space-around;
  }
  
  .justify-flex-end {
    justify-content: flex-end;
  }
  
  .align-center {
    align-items: center;
  }
  
  .align-baseline {
    align-items: baseline;
  }  

  .align-end {
    align-items: flex-end;
  }
  
  .align-self-center {
    align-self: center;
  }
  
  .align-self-baseline {
    align-self: baseline;
  }
  
  .align-self-end {
    align-self: flex-end;
  }

  .align-content-stretch {
    align-content: stretch;
  }
  
  .align-item-stretch {
    align-items: stretch;
  }

  .row-flex {
    display: flex;
  }

  .flex-space-between {
    flex: 1;
    justify-content: space-between;
  }
 
  