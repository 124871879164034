.jade-view {
  .jade-app-bar {
    padding: 10px;
    border-bottom: 1px solid $sb-gray;
    border-bottom: 1px solid var(--sb-gray);
    text-align: center;

    .sb-jade-logo {
      max-height: 35px;
    }
  }

  .jade-content-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 30px 0;
  }

  .jade-status-message {
    padding: 0 12px;
    line-height: 1.4;
    font-size: $font-16;
  }

  .jade-sticky-footer {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
  }

  .go-to-button {
    background: $sb-primary-btn-default;
    background: var(--sb-primary-btn-default);
    height: 53px;
    width: 100%;
    color: $sb-white;
    color: var(--sb-white);
    font-size: 15px;
    border: none;
    cursor: pointer;
  }
}

@media only screen and (min-width: 993px) {
  .jade-view {
    .jade-content-container {
      max-width: 524px;
    }

    .jade-sticky-footer {
      max-width: 524px;
      position: relative;
    }
  }
}
