.tax-filing-card {
  padding: 0;
  margin: 0;
  margin-bottom: 20px !important;

  .btn-custom {
    width: 100% !important;
    height: 40px !important;
    font-size: 14px;
    margin: 10px 0;
    border-radius: 8px !important;
    padding: 8px 16px !important;
  }
}

.tax-filing-intro-card {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  min-height: auto;

  &:hover {
    cursor: default;
  }
}

.tax-filing-otp-form {
  .btn-custom {
    min-width: inherit;
    width: 100% !important;
    border-radius: 8px !important;
    padding: 8px 16px !important;
  }
}

.income-tax-intro-section-title {
  margin-bottom: 5px;
}

@media only screen and (max-width: 993px) {
  .tax-filing-status {
    .btn-custom {
      min-width: inherit;
      border-radius: 8px !important;
      padding: 8px 10px !important;
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 993px) {
  .tax-filing-card {
    ul {
      min-height: 160px;
    }

    .tax-card-more {
      min-height: 270px;
    }
  }
}
