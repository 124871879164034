.input-field {
  .prefix {
    font-size: $font-24;
    top: 0;
    background: $sb-gray;
    background: var(--sb-gray);
    border-radius: 2px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 1px 0 0 7px;
    line-height: 40px;
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
    color: $sb-black;
    color: var(--sb-black);
  }

  .amount-field__input {
    font-size: $font-24;
    height: 42px;

    &:focus {
      border: 1px solid $sb-gray !important;
      border: 1px solid var(--sb-gray) !important;
      box-shadow: none !important;
    }
  }

  .amount-field__input-disabled {
    font-size: $font-24;
    height: 42px;
    background-color: #f5f5f5;

    &:focus {
      border: 1px solid $sb-gray !important;
      border: 1px solid var(--sb-gray) !important;
      box-shadow: none !important;
    }
  }
}

.input-field .prefix ~ label {
  margin-left: 0;
}

.input-field .prefix ~ input {
  width: 83%;
  width: calc(96% - 3rem);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.amount-input-field {
  label[data-error]::after {
    top: 70px !important;
  }

  .input-field {
    .prefix {
      min-height: 44px;
    }
  }
}
