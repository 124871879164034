@import "base/all";
@import "components/all";
@import "utils/all";
@import "ketwich";
@import "mainColor";

html {
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.2px;
}

body {
  background-color: $sb-white;
  background-color: var(--sb-white);
  color: $sb-black;
  color: var(--sb-black);
  margin-bottom: 60px;
  overflow: overlay;
}

.spinner-green-check {
  color: $custom-color-16;
  color: var(--custom-color-16);
}

a.sb-blue-link {
  color: $sb-blue-link;
  color: var(--sb-blue-link);
  text-decoration: none;
  word-wrap: normal;

  &:hover,
  :active,
  :visited,
  :link,
  :focus {
    color: $sb-blue-link;
    color: var(--sb-blue-link);
    text-decoration: none;
  }
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.border-right {
  border-right: 2px solid $sb-border-gray;
  border-right: 2px solid var(--sb-border-gray);
}

.failure-yellow-color {
  color: $sb-failure-yellow;
  color: var(--sb-failure-yellow);
}

.cursor-pointer {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cursor-disabled {
  cursor: not-allowed;
}

.align-block-center {
  margin-left: auto;
  margin-right: auto;
}

.button-height-50 {
  height: 50px !important;
  text-transform: none !important;
}

.input-label {
  font-size: 14px;
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  cursor: text;
  font-weight: 600;
}

strong {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.font-black {
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
}

.center-div {
  display: flex;
  justify-content: space-between;
  padding-top: 42px;
  padding-bottom: 12px;
}

.circle-image {
  border-radius: 50% !important;
}

.blue-anchor-tag {
  color: $sb-blue-link;
  color: var(--sb-blue-link);
  cursor: pointer;
  text-decoration: none;

  &:visited,
  &:link,
  &:hover,
  &:active {
    color: $sb-blue-link;
    color: var(--sb-blue-link);
  }
}

.blue-anchor-tag-disabled {
  color: $sb-blue-link-disabled;
  color: var(--sb-blue-link-disabled);
  cursor: not-allowed;
  text-decoration: none;

  &:visited,
  &:link,
  &:hover,
  &:active {
    color: $sb-blue-link-disabled;
    color: var(--sb-blue-link-disabled);
  }
}

.sb-orange {
  color: $sb-orange;
  color: var(--sb-orange);
}

.no-visibility {
  visibility: hidden;
}

.sb-heading {
  font-size: $font-16;
  text-align: center;
}

.balance-amount {
  font-size: $font-24;
  display: block;
  font-weight: 600;
}

.balance-amount-small {
  font-size: $font-16;
  display: block;
  font-weight: 500;
}

.amount-value-title {
  font-size: $font-14;
  font-weight: 600;
  margin-bottom: 2px;
}

.investment-portfolio .balance-amount {
  font-size: $font-20;
}

.recommended-funds-title {
  font-size: $font-14;
  padding: 10px;
  font-weight: 700;
  text-align: center;
}

.asset-class-title {
  font-size: $font-15;
  font-weight: 600;
  background: $custom-color-17;
  background: var(--custom-color-17);
  padding: 5px 10px 5px 20px;

  &.margin-v5-h10 {
    margin: 0;
  }
}

.terms-title {
  font-size: $font-20;
  text-align: center;
  padding: 2% 0;
}

label[data-error]::after {
  white-space: nowrap;
}

#profile-switcher {
  .user-name,
  .header {
    color: $sb-black;
    color: var(--sb-black);
  }
}

select.browser-default {
  &.no-border-left {
    border-left: none;
    padding-left: 20px;
  }
}

.extend-sip-duration {
  width: 88% !important;
  padding-left: 10% !important;
}

i.sb-small-icon {
  font-size: $font-20;
}

i.sb-medium-icon {
  font-size: $font-42;
}

.block {
  display: block;
}

.sb-notification-container {
  position: relative;
}

.whatsapp-mobile-logo path {
  fill: $sb-primary-btn-default;
  fill: var(--sb-primary-btn-default);
}

@media only screen and (max-width: 992px) {
  .center-div {
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 36px;
  }
}


.longterm-link a {
  color: $sb-orange;
  color: var(--sb-orange);
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.audit-checkbox {
  position: relative !important;
  visibility: visible !important;
  left: 0 !important;
}

#main .notification-icon {
  top: 115px;
  right: 3%;
}

.sb-link,
.sb-link:hover,
.sb-link:active,
.sb-link:visited,
.sb-link:focus {
  color: $sb-orange;
  color: var(--sb-orange);
  cursor: pointer;
}

.content {
  padding: 0 15px;
}

.country-code.prefix {
  font-size: $font-16;
  line-height: 34px;
}

.indian-mobile-input {
  .input-field .prefix ~ input {
    width: 84%;
    width: calc(100% - 58px);
  }
}

.no-box-shadow {
  box-shadow: none;
}

.img-responsive {
  max-width: 100%;
}

.email-sms-notification-container {
  overflow-y: auto;
  @media only screen and (min-width: 993px) {
    .row .col {
      padding-left: 0;
    }
  }
}

.ipv-recorder-container {
  position: relative;
  max-height: 300px;
}

.btn-custom.custom-capsule-button {
  background: $sb-white;
  background: var(--sb-white);
  color: $sb-gray;
  color: var(--sb-gray);
  font-size: $font-18;
}

.truncate-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.material-icons.desktop-investor-menu-family-icon {
  font-size: $font-36;
  color: $sb-menu-gray;
  color: var(--sb-menu-gray);
  position: relative;
  top: 10px;
}

.forgot-password-link {
  margin-left: 5px;
}

.investment-intro-card {
  padding: 3px 5px;
  background-color: $sb-white;
  background-color: var(--sb-white);
  margin: 10px;
  border-bottom: 1px solid $sb-gray;
  border-bottom: 1px solid var(--sb-gray);
}

.freshwidget-customimage {
  display: none;
}

.valign-super {
  vertical-align: super;
}

.desktop-menu-label {
  display: none;
}

.investment-list-padding {
  padding: 1rem 0;
}

.new-investment-card {
  background: $sb-white;
  background: var(--sb-white);
  border: 1px solid $custom-color-115;
  border: 1px solid var(--custom-color-115);
  margin: 10px 10px 30px 10px;
  text-align: center;
  box-shadow: 0 5px 14px 0 $sb-black-11;
  box-shadow: 0 5px 14px 0 var(--sb-black-11);

  &.inner-card {
    margin: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $custom-color-115;
    border-bottom: 1px solid var(--custom-color-115);
  }

  .title {
    padding: 10px 0;
    font-size: $font-18;
  }

  .title-row {
    background: $custom-color-17;
    background: var(--custom-color-17);
  }

  .title-row-alignment {
    margin-left: 60px;
  }

  .performance-amount {
    font-size: $font-28;
  }

  .performance-amount-container {
    padding: 15px 0;
  }

  .net-investment-title {
    font-size: $font-18;
    padding: 10px 5px;
  }

  .card-section-amount {
    font-size: $font-18;
  }

  .border-top-bottom {
    border-top: 1px solid $custom-color-115;
    border-top: 1px solid var(--custom-color-115);
    border-bottom: 1px solid $custom-color-115;
    border-bottom: 1px solid var(--custom-color-115);
  }

  .border-right {
    border-right: 1px solid $custom-color-115;
    border-right: 1px solid var(--custom-color-115);
  }

  .performance-info-section {
    padding: 5px 0 7px 0;
  }

  .card-section-content {
    padding: 10px 0;
    font-size: $font-14;
  }
}

.btn-full {
  min-width: 100%;
}

ul.audit-li li::before {
  content: " ";
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: $sb-black-50;
  background: var(--sb-black-50);
  display: inline-block;
  margin-right: 5px;
}

ul.bullet-list li::before {
  content: " ";
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: $sb-black;
  background: var(--sb-black);
  display: inline-block;
  margin-right: 5px;
}

ul.bullet-list-new li::before {
  content: " ";
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: $custom-color-19;
  background: var(--custom-color-19);
  display: inline-block;
  margin-right: 9px;
}

.link-title {
  font-size: $font-12;
}

.dashboard-section-title {
  font-weight: 600;
  font-size: $font-16;
  margin: 4px 0;
}

.dashboard-checklist {
  padding: 10px;

  .dashboard-checklist-item {
    font-size: $font-16;

    i {
      color: $custom-color-20;
      color: var(--custom-color-20);
    }

    a {
      color: $sb-orange;
      color: var(--sb-orange);
      border-bottom: 1px solid $sb-orange;
      border-bottom: 1px solid var(--sb-orange);
    }
  }
}

.invest-instruction-form-container {
  .invest-more__type-title-row {
    margin-top: 10px;

    .invest-more__type-title {
      font-size: 20px;
      color: $sb-black;
      color: var(--sb-black);
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
  }

  .invest-more__type-container {
    padding: 0 0.75rem;
    margin-bottom: 20px;

    .invest-more__investment-type {
      padding: 0;

      .invest-more__radio {
        margin: 0 auto;
        padding: 0;

        .radio-input-label::before,
        .radio-input-label::after {
          display: none;
        }

        .radio-input-label {
          text-align: center;
          border: 1px solid;
          border-radius: 24px;
          min-height: 42px;
          padding: 0.5rem 1.5rem;

          // &.sip-radio-label {
          //   margin-right: 1rem;
          // }

          // &.one-time-radio-label {
          //   margin-left: 1rem;
          // }
        }

        [type="radio"]:checked + label {
          border-color: $sb-primary-btn-default;
          border-color: var(--sb-primary-btn-default);
          background-color: $sb-primary-btn-default;
          background-color: var(--sb-primary-btn-default);
          color: $sb-white-fixed;
          color: var(--sb-white-fixed);
        }

        [type="radio"]:not(:checked) + label {
          border-color: $sb-secondary-default-border;
          border-color: var(--sb-secondary-default-border);
          color: $sb-secondary-default-color;
          color: var(--sb-secondary-default-color);
        }
      }

      .custom-search-fund {
        [type="radio"]:checked + label {
          border: 1px solid $sb-blue-link;
          border: 1px solid var(--sb-blue-link);
          color: $sb-black-86;
          color: var(--sb-black-86);
          background-color: $sb-ui-blue-light-2;
          background-color: var(--sb-ui-blue-light-2);
        }

        [type="radio"]:not(:checked) + label {
          border: 1px solid $sb-opacity-5;
          border: 1px solid var(--sb-opacity-5);
          background-color: $sb-white;
          background-color: var(--sb-white);
          color: $sb-black-50;
          color: var(--sb-black-50);
        }

        label {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.invest-more__calculation-container {
  background-color: $sb-ui-blue-light-2;
  background-color: var(--sb-ui-blue-light-2);
  padding: 2rem;
  margin: 1rem 1rem 2rem 1rem;

  .invest-more__calculation-item {
    .invest-more__calculation-amount {
      font-weight: 600;
      font-size: 18px;
      color: $card-layout-primary-text;
      color: var(--card-layout-primary-text);
    }

    .invest-more__calculation-year {
      font-weight: 600;
      font-size: 16px;
      color: $card-layout-description-text;
      color: var(--card-layout-description-text);
    }
  }

  .invest-more__calculation-disclaimer {
    font-size: 1rem;
    color: $sb-secondary-default-color;
    color: var(--sb-secondary-default-color);
  }
}

.money-types-container,
.fund-type-card-intro-container {
  .scard-container {
    .scard-title {
      display: inline-block;
    }

    .four-kinds-recommended-wrapper {
      position: relative;
    }

    .btn-custom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .four-kinds-recommended-label {
      position: absolute;
      left: -9px;
      top: 10px;
      padding: 2px 5px 1px 5px;
      font-size: $font-10;
      color: $sb-white;
      color: var(--sb-white);
      border-radius: 2px;
    }

    .four-kinds-recommended-label-green {
      position: absolute;
      left: -16px;
      top: -4px;
      padding: 2px 5px 1px 5px;
      font-size: $font-10;
      color: $sb-white;
      color: var(--sb-white);
      border-radius: 2px;
    }
  }

  .fund-icon-size {
    max-width: 50px;
  }

  .money-banner-short-term {
    width: 80px;
    position: absolute;
    bottom: 20px;
    right: 10px;
  }

  .money-banner-icon {
    bottom: 20px;
    position: absolute;
    right: 10px;
    width: 80px;
  }

  .money-banner-icon-new {
    position: absolute;
    width: 80px;
  }

  .fund-info-border {
    border-right: 2px solid $sb-white;
    border-right: 2px solid var(--sb-white);
  }
}

.fund-type-info-bg {
  background-color: $custom-color-22;
  background-color: var(--custom-color-22);
  padding: 6px 2px;
  text-align: center;
}

.fund-type-card-banner {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  min-height: 120px;
  background: $offeringEmergency;
  background: var(--offeringEmergency);

  &.type-equity {
    background: $offeringLongTerm;
    background: var(--offeringLongTerm);
  }

  &.type-principal_protection {
    background: $offeringPrincipalProtection;
    background: var(--offeringPrincipalProtection);
  }

  &.type-debt {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-elss,
  &.type-tax_filing {
    background: $offeringTaxSaver;
    background: var(--offeringTaxSaver);
  }

  &.type-liquid {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  &.type-overseas_education {
    background: $offeringEducation;
  }

  &.type-independent_income {
    background: $offeringRetireConfident;
    background: var(--offeringRetireConfident);
  }

  &.type-financial_freedom {
    background: $offeringRetireConfident;
    background: var(--offeringRetireConfident);
  }

  &.type-wealth_edge {
    background: $offeringWealthEdgePlan;
    background: var(--offeringWealthEdgePlan);
  }

  &.type-lifestyle_goals {
    background: $offeringDreamPlanner;
    background: var(--offeringDreamPlanner);
  }

  &.type-indian_education {
    background: $offeringEducation;
  }

  &.type-wealth_accelerator {
    background: $offeringWealthEdgePlan;
    background: var(--offeringWealthEdgePlan);
  }

  &.type-secure_growth {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-crorepati {
    background: $offeringMyFirstCrore;
    background: var(--offeringMyFirstCrore);
  }

  &.type-child_crorepati {
    background: $offeringChildHeadstart;
    background: var(--offeringChildHeadstart);
  }

  &.type-emergency_fund {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  &.type-smart_transfer_plan {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-long_term_wealth_us {
    background: $offeringLongTerm;
    background: var(--offeringLongTerm);
  }

  &.type-short_term_money_us {
    background: $offeringShortTerm;
    background: var(--offeringShortTerm);
  }

  &.type-emergency_fund_us {
    background: $offeringEmergency;
    background: var(--offeringEmergency);
  }

  &.type-tax_saver_plan_us {
    background: $offeringTaxSaver;
    background: var(--offeringTaxSaver);
  }

  &.type-principal_protection_us {
    background: $offeringPrincipalProtection;
    background: var(--offeringPrincipalProtection);
  }

  &.new-fund-type-card {
    min-height: auto;
  }

  .card-image {
    width: 60px;
    height: auto;
  }
}

.fund-type-card-intro-container {
  .fund-type-card-banner {
    border-radius: 0;
  }
}

i.zero-state-invest-flow-icon {
  font-size: $font-18;
  margin-right: 5px;
  vertical-align: text-top;

  &.grey-icon {
    color: $custom-color-20;
    color: var(--custom-color-20);
  }

  &.black-icon {
    color: $sb-black;
    color: var(--sb-black);
  }
}

.schedule-pickup-status-container {
  .title {
    margin-top: 15px;
    font-weight: 600;
    font-size: $font-14;
  }

  .disabled-text {
    color: $sb-menu-gray;
    color: var(--sb-menu-gray);
  }

  .collapsible-list {
    .list-header {
      line-height: 1.2;
      padding-top: 10px;

      .why-text {
        color: $sb-orange;
        color: var(--sb-orange);
        border-bottom: 1px dotted $sb-orange;
        border-bottom: 1px dotted var(--sb-orange);
      }

      .divider {
        border-left: 1px solid $sb-menu-gray;
        border-left: 1px solid var(--sb-menu-gray);
      }
    }

    .list-body {
      font-size: $font-14;
      color: $sb-menu-gray;
      color: var(--sb-menu-gray);
    }
  }

  .download-form-icon {
    color: $sb-white;
    color: var(--sb-white);
    vertical-align: middle;
    padding-right: 5px;
  }

  .document-checklist-container {
    border: 1px solid $sb-grey-88;
    border: 1px solid var(--sb-grey-88);
  }
}

.extend-sip-amt-button {
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  padding: 8px 12px;
  font-size: $font-16;
  margin: 10px 5px 10px 10px;
  color: $sb-gray;
  color: var(--sb-gray);
}

.extend-sip-amt-button-active {
  padding: 8px 12px;
  font-size: $font-16;
  margin: 10px 5px 10px 10px;
  color: $sb-orange;
  color: var(--sb-orange);
  border: 1px solid $sb-orange;
  border: 1px solid var(--sb-orange);
}

.strike-text {
  text-decoration: line-through;
}

.icon-check {
  border: 2px solid $sb-gray;
  border: 2px solid var(--sb-gray);
  border-radius: 20px;
  font-size: $font-18;
}

i.color-transparent {
  color: transparent;
}

.document-received-status {
  border: 1px solid $sb-orange;
  border: 1px solid var(--sb-orange);
  padding: 10px;
  margin: 10px 0;
}

.sb-link-blue {
  a {
    color: $sb-orange;
    color: var(--sb-orange);
    cursor: pointer;
  }

  &.m-top-30 {
    margin-top: 30px;
  }
}

.kyc-radio-container {
  margin: 12px auto;
}

.kyc-check-image {
  margin: 20px 0;
  text-align: center;
}

.kyc-options {
  .kyc-radio-explanation {
    border: 1px solid $sb-menu-gray;
    border: 1px solid var(--sb-menu-gray);
    padding: 5px 10px;
  }
}

.kyc-radio-explanation {
  margin: 5px 35px;

  .title {
    font-size: $font-16;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.kyc-title {
  font-weight: bold;
  margin: 10px 0;
  font-size: $font-18;
}

.kyc-info-text {
  text-align: center;
  font-weight: bold;
}

.kyc-thank-image {
  margin-top: 20px;
}

.kyc-address-container {
  border: 1px solid $sb-menu-gray;
  border: 1px solid var(--sb-menu-gray);
  padding: 10px;
}

.kyc-address-table {
  border: 1px solid $sb-menu-gray;
  border: 1px solid var(--sb-menu-gray);
  margin: 14px 0;

  .date-container {
    border-right: 1px solid $sb-menu-gray;
    border-right: 1px solid var(--sb-menu-gray);
  }

  .address-container {
    border-top: 1px solid $sb-menu-gray;
    border-top: 1px solid var(--sb-menu-gray);
  }

  .table-heading {
    font-size: $font-16;
    font-weight: bold;
    padding: 5px 0 7px 0;
  }

  .table-content-container {
    padding: 6px 0;
  }
}

.position-fix {
  position: relative !important;
  display: inline-block;
}

.secondary-dashboard-btn {
  border: 1px solid $sb-secondary-default-border;
  border: 1px solid var(--sb-secondary-default-border);
  text-transform: uppercase;
}

.secondary-footer-top-button {
  position: absolute;
  bottom: 70px;
  width: 100%;

  a {
    color: $sb-blue-link;
    color: var(--sb-blue-link);
    font-weight: 500;
  }
}

.last-login-time {
  font-size: $font-10;
  font-weight: normal;
}

.signup-error-message-text {
  font-size: $font-14;
  font-weight: 500;
}

.add-additional-info {
  text-align: center;
  cursor: pointer;

  .add-additional-info-icon {
    padding: 0 10px;
    margin: 10px 0;
    font-size: $font-20;
  }

  .add-additional-info-text {
    vertical-align: super;
  }
}

.retake-button {
  color: $sb-orange;
  color: var(--sb-orange);
  font-size: $font-18;
  display: inline-block;

  .add-additional-info-text {
    vertical-align: super;
    padding-left: 6px;
  }
}

.family-member-gender {
  .invest-more__radio {
    padding-left: 0;
    padding-bottom: 10px;
  }
}

.signup-page-title {
  margin: 6px;
  font-size: $font-20;
  font-weight: 600;
}

.red-error-block {
  background: $custom-color-23;
  background: var(--custom-color-23);
  border: 1px solid $custom-color-126;
  border: 1px solid var(--custom-color-126);
  padding: 5px;
  text-align: center;

  a {
    color: $sb-orange;
    color: var(--sb-orange);
  }
}

.dropdown-content li > a,
.dropdown-content li > span {
  &:focus,
  &:hover {
    color: $sb-white;
    color: var(--sb-white);
  }
}

.radio-input-label {
  font-size: $font-14;
  font-weight: 600;
}

.select-input-error {
  border: 1px solid $custom-color-25 !important;
  border: 1px solid var(--custom-color-25) !important;
  backface-visibility: hidden;
  perspective: 1000px;

  &.mini-select-box {
    padding: 0 6px;
  }
}

.optional-input-text {
  color: $custom-color-20;
  color: var(--custom-color-20);
  font-weight: 500;
}

textarea.materialize-textarea {
  margin: 0;
}

select:focus {
  border: 1px solid $custom-color-70;
  border: 1px solid var(--custom-color-70);
  box-shadow: none;
}

.notification-container {
  [type="checkbox"] + label {
    display: block;
  }
}

.collection .collection-item {
  border: none;
}

.progress.orange {
  border-radius: 0;
}

.investor-icon-image {
  vertical-align: middle;
}

.investor-do-dont-text {
  display: inline;
  font-size: $font-16;
  font-weight: 600;
  padding-top: 5px;
  margin-left: 5px;
}

.trust-page-title {
  font-size: $font-20;
  font-weight: 600;
  max-width: 250px;
  margin: 0 auto;
}

.trust-page-content {
  text-align: center;
  margin: 10px 5px 0 5px;
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  padding: 22px 0 22px 0;
}

.image-content {
  font-weight: 500;
  font-size: $font-16;
}

.image-number {
  font-size: $font-25;
  font-weight: 600;
}

.years-section {
  background-image: url('../images/signup/trust-years.png');
  background-repeat: repeat;
}

.cities-section {
  background-image: url('../images/signup/trust-cities.png');
  background-repeat: repeat;
}

.savings-section {
  background-image: url('../images/signup/trust-savings.png');
  background-repeat: repeat;
}

.valign-middle {
  vertical-align: middle;
}

.content-container.no-container-padding {
  padding: 0;
}

.image-upload-button {
  position: relative;
}

.investment-plan-container {
  #app-container-heading .app-container-heading-text {
    padding: 10px 0;
  }
}

.card-intro-banner-image {
  max-width: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.intro-card-title {
  font-size: $font-20;
  font-weight: 600;
  color: $sb-black;
  color: var(--sb-black);
  margin-left: 5px;
}

.intro-card-description {
  font-size: $font-14;
  font-weight: 400;
  line-height: 1.3;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  margin: 0 0 10px 5px;
}

.intro-block-title {
  font-size: $font-14;
  font-weight: 600;
  color: $sb-black;
  color: var(--sb-black);
  margin-top: 10px;
}

.intro-block-description {
  font-size: $font-14;
  font-weight: 400;
  line-height: 1.3;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  margin-bottom: 10px;
}

.block-left-divider {
  border-left: 1px solid $custom-color-42;
  border-left: 1px solid var(--custom-color-42);
}

.fd-btn-bottom a {
  bottom: -8px !important;

  img {
    max-width: 64px;
    width: 100%;
  }
}

.margin-top-30 {
  margin-top: 30px;
}

.coming-soon-badge {
  text-align: center;
  font-size: $font-24;
  color: $custom-color-28;
  color: var(--custom-color-28);
  padding: 10px 30px;
}

.custom-amount-container {
  margin: 12px 0;
}

.company-table {
  td {
    vertical-align: initial;
  }

  p {
    margin: 0;
  }

  .sb-link {
    color: $sb-orange;
    color: var(--sb-orange);
  }
}

.preloader-small {
  width: 15px;
  height: 15px;
  color: $sb-orange;
  color: var(--sb-orange);
}

.faq-otions {
  margin-top: 2px;
}

.payment-heading {
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
}

.instruction-box {
  padding: 15px;
  margin: 5px 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background: $custom-color-29;
  background: var(--custom-color-29);
  border: 1px solid $custom-color-127;
  border: 1px solid var(--custom-color-127);
  border-radius: 10px;

  .timer {
    font-size: 30px;
  }

  .instruction-message {
    font-weight: 550;
    margin-bottom: 15px;
  }
}

.disclaimer {
  margin-top: 25px;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  text-align: center;
  font-size: 14px;
}

.mandate-title {
  font-size: $font-20;
  padding: 2% 0;
}

.skylight-dialog {
  background: $sb-white !important;
  background: var(--sb-white) !important;
}

.new-investment-modal {
  .skylight-dialog {
    min-height: 200px !important;
  }
}

.span-new {
  background: $custom-color-30;
  background: var(--custom-color-30);
  padding: 1px 5px;
  margin: auto 7px;
  color: $sb-white;
  color: var(--sb-white);
}

.money-types-content ul li {
  padding-left: 10px;
  padding-bottom: 4px;
}

.disabled-fund-selection-block {
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);

  label {
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
  }
}

.badge-container {
  display: flex;
  justify-content: center;
}

.button-container {
  width: '100%';
  background-color: $sb-white;
  background-color: var(--sb-white);
}

.badge-container-1 {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.disabled-card {
  background: 'linear - gradient(180deg, #FFFFFF 0 %, rgba(255, 255, 255, 0) 100 %), #FFFFFF';
}

.badge {
  color: $sb-white;
  color: var(--sb-white);
  background: $custom-color-31;
  background: var(--custom-color-31);
  padding: 0 10px;
  border-radius: 5px;
  font-size: 12px;
}

.block-disabled {
  background-color: $sb-secondary-disabled-background;
  background-color: var(--sb-secondary-disabled-background);
}

.fund-move-amount {
  margin: 0;
  text-align: right;
  font-weight: 600;
}

.header-band {
  padding: 10px;
  background-color: $sb-white;
  background-color: var(--sb-white);
}

.header-band-20 {
  padding: 20px;
  background-color: $sb-white;
  background-color: var(--sb-white);
}

.fund-description-arrow {
  border: 1px solid $sb-secondary-default-border;
  border: 1px solid var(--sb-secondary-default-border);
  border-radius: 5px;
  padding-top: 8px;
  text-align: center;
  height: 40px;
  width: 30px;
}

.pointer-cursor {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed !important;
}

.withdrawal-total {
  background-color: $custom-color-32;
  background-color: var(--custom-color-32);

  .withdrawal-item {
    border: none !important;
    padding: 0 20px;
    padding-bottom: 15px;
  }
}

.offering-header-link {
  color: $sb-blue-link;
  color: var(--sb-blue-link);
  cursor: pointer;
}

.blue-link {
  color: $sb-blue-link !important;
  color: var(--sb-blue-link) !important;
  cursor: pointer;
}

.font-16 {
  font-size: $font-16;
}

.font-32 {
  font-size: $font-32;
}

.gray-text {
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
}

.card-layout-primary-text {
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

.investment-image-card {
  padding: 10px;

  .offering-icon-alignment {
    min-height: 50px;
    display: flex;
    align-items: center;
    align-self: center;
  }

  .investment-image {
    max-width: 100%;
    width: 40px;
  }

  .scard-title {
    font-size: $font-16;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
    font-weight: 400;
  }

  .new-badge {
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    background: $sb-badge-green;
    background: var(--sb-badge-green);
    color: $sb-white;
    color: var(--sb-white);
  }
}

.investment-item {
  .portfolio-investment-summary {
    margin-bottom: 30px;
    margin-top: 15px;
  }

  .dot-icon-container {
    margin-top: 4px;
    width: 30px;
  }

  .dot-icon {
    font-size: 16px;
    font-weight: 900;
  }

  .investment-item-content {
    width: 100%;
  }

  .vertical-line {
    width: 1px;
    border: 1px dashed $custom-color-128;
    border: 1px dashed var(--custom-color-128);
    min-height: inherit;
    height: calc(100% - 30px);
    margin-left: 6px;
  }

  .investment-item-content {
    width: 100%;
  }

  .investment-title {
    font-weight: 600;
    font-size: $font-16;
  }

  .investment-item-parameters {
    margin-bottom: 25px;
  }

  .investment-item-parameter {
    margin: 8px auto;

    .parameter-label {
      color: $card-layout-description-text;
      color: var(--card-layout-description-text);
      font-size: $font-16;
    }
  }
}

.investment-history {
  padding: 15px 20px;

  .investment-item:last-child {
    .vertical-line {
      display: none;
    }

    .portfolio-investment-summary {
      margin-bottom: 0;
    }

    .investment-item-parameters {
      margin-bottom: 0;
    }
  }
}

.portfolio-investment-summary {
  line-height: 1.2;

  &.summary-card-lineheight {
    line-height: 1.5;
  }

  .card-amount-group {
    margin-top: 6px;
  }

  .card-amount-label {
    font-size: $font-16;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
  }

  .separator {
    border: 1px solid $custom-color-72;
    border: 1px solid var(--custom-color-72);
    margin-bottom: 0;
    margin-top: 10px;
  }

  .card-amount-label-large {
    font-size: $font-18;
    font-weight: 600;
  }

  .card-heading {
    font-size: $font-16;
    color: $sb-menu-icon;
    color: var(--sb-menu-icon);
    font-weight: 600;
    padding-bottom: 10px;
  }

  .card-amount {
    font-size: $font-16;
    display: block;
    font-weight: 500;
  }
}

.fund-header-navigation {
  padding: 20px;
  padding-bottom: 0;
  background-color: $sb-white;
  background-color: var(--sb-white);
}

.fund-details {
  padding: 20px;
  background-color: $sb-white;
  background-color: var(--sb-white);

  .fund-heading-group {
    margin: 7px auto;
  }

  .fund-details-heading {
    font-size: $font-16;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
    font-weight: 600;
  }

  .separator {
    margin: 0;
    margin-bottom: 7px;
    border: 1px solid $custom-color-44;
    border: 1px solid var(--custom-color-44);
  }

  .fund-parameter-group {
    margin: 7px auto;
    text-align: right;
  }

  .fund-parameter-group:last-child {
    margin-bottom: 0;
  }
}

.growth-rate-info {
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  border-top: 1px solid $custom-color-44;
  border-top: 1px solid var(--custom-color-44);

  .label-name {
    text-transform: uppercase;
  }
}

.growth-rate-explanation {
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);

  .explanation-text {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  ul {
    margin: 0;
    margin-left: 15px;
  }

  ul li {
    list-style-type: disc;
  }
}

#overlay {
  position: fixed;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: $sb-vanta-black;
  background: var(--sb-vanta-black);
  display: none;
  will-change: opacity;
}

#overlay.show {
  z-index: 1002;
  display: block;
  opacity: 0.5;
}

.border-top {
  border-top: 1px solid $custom-color-115;
  border-top: 1px solid var(--custom-color-115);
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 $custom-color-139;
    box-shadow: 0 0 0 0 var(--custom-color-139);
  }

  100% {
    box-shadow: 0 0 0 20px $custom-color-140;
    box-shadow: 0 0 0 20px var(--custom-color-140);
  }
}

@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0 $custom-color-139;
    box-shadow: 0 0 0 0 var(--custom-color-139);
  }

  100% {
    box-shadow: 0 0 0 70px $custom-color-140;
    box-shadow: 0 0 0 70px var(--custom-color-140);
  }
}

.intro-bubble-wrapper {
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.intro-bubble-pulse {
  width: 12px;
  height: 12px;
  background: $custom-color-141;
  background: var(--custom-color-141);
  border-radius: 50%;
  animation: shadow-pulse 3s infinite;
}

.tooltip {
  position: relative !important;
  display: inherit !important;
}

.tooltip-wrapper {
  background: $sb-white;
  background: var(--sb-white);
  padding: 20px;
  margin: auto;
  max-width: 300px;

  .tooltip-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tooltip-content {
    display: flex;
    flex-direction: column;
  }

  .tooltip-button-group {
    margin-top: 10px;

    .btn-custom {
      font-size: $font-16;
      height: auto;
      width: auto;
      padding: 8px 18px;
      border-radius: 8px;
    }
  }
}

.tippy-popper {
  left: 60px !important;
}

.tippy-popper-hidden {
  display: none;
}

.investment-journey-card {
  padding: 16px;
  .investment-journey-heading {
    font-size: $font-16;
    font-weight: 600;
  }

  .rightChevron {
    color: $custom-color-233;
    color: var(--custom-color-233);
  }
}

.menu-item-card {
  padding: 18px 0;
  border-top: 1px solid #E0E0E0;
  // border-bottom: 1px solid #f7f7f7;
}

.logout-menu-item-card {
  padding: 18px 0;
  border-bottom: 1px solid #E0E0E0;
}

.coming-soon-wrapper {
  position: relative;

  .coming-soon-label {
    position: absolute;
    left: -6px;
    top: 5px;
  }
}

.growth-rate-label {
  .label-name {
    text-transform: uppercase;
  }
}

.benchmark-returns {
  .benchmark-heading {
    color: $sb-black;
    color: var(--sb-black);
    font-weight: 600;
  }

  .benchmark-subAssetClasses {
    padding-top: 5px;
  }
}

.sticky-footer-text-above-footer-button {
  background: $sb-white;
  background: var(--sb-white);
  overflow: visible;
  z-index: 99;
  height: auto;
  padding: 10px;

  .subheading {
    color: $sb-checklist-gray;
    color: var(--sb-checklist-gray);
  }
}

.sticky-footer-text {
  background: $sb-white;
  background: var(--sb-white);
  overflow: visible;
  z-index: 99;
  height: auto;
  font-size: 16px;
  padding: 10px;

  .subheading {
    color: $sb-checklist-gray;
    color: var(--sb-checklist-gray);
  }
}

.test-iswaiting-loader {
  > div {
    background-color: $sb-white;
    background-color: var(--sb-white);
    width: 100%;
    height: 100%;
  }

  .investment-journey-card {
    margin-left: -10px;
  }
}

@media only screen and (max-width: 992px) {
  .sticky-footer-text {
    bottom: 0;
    width: 100%;
    padding: 20px;
    position: fixed;
  }

  .sticky-footer-text-above-footer-button {
    bottom: 65px;
    width: 100%;
    padding: 20px;
    position: fixed;
  }

  .test-iswaiting-loader {
    > div {
      background-color: $sb-white;
      background-color: var(--sb-white);
      width: 100%;
      height: 100vh;
    }
  }
}

.custom-checkbox {
  display: inline-block;

  .box {
    display: inline-block;
    top: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  }

  .box-unchecked {
    display: inline-block;
    background-color: transparent;
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
  }

  .box-checked {
    display: inline-block;
    border: 1px solid $sb-primary-btn-default;
    border: 1px solid var(--sb-primary-btn-default);
    background-color: $sb-primary-btn-default;
    background-color: var(--sb-primary-btn-default);
  }

  .box-disabled {
    display: inline-block;
    border-color: transparent;
    background-color: $custom-color-33;
    background-color: var(--custom-color-33);
  }

  .check-show {
    display: inline-block;
    top: 0;
    left: 1px;
    width: 7px;
    height: 12px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid $sb-white;
    border-right: 2px solid var(--sb-white);
    border-bottom: 2px solid $sb-white;
    border-bottom: 2px solid var(--sb-white);
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    margin-bottom: 0.1rem;
  }

  .check-hide {
    display: inline-block;
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
  }
}

.move-funds-list {
  background: $sb-white;
  background: var(--sb-white);
  box-shadow: -2px 2px 13px 0 $custom-color-142;
  box-shadow: -2px 2px 13px 0 var(--custom-color-142);
  color: $sb-black;
  color: var(--sb-black);
  padding: 15px;
  margin: 10px;

  .fund-list-item.modal-item {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .fund-list-item:first-child {
    padding-top: 0 !important;
  }

  .fund-list-item:last-child {
    padding-bottom: 0 !important;
  }

  .overlay {
    opacity: 0.3;
  }
}

.fund-list-item {
  padding-top: 15px;
  padding-bottom: 15px;

  .fund-image-container {
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
    height: 25px;
    width: 25px;
  }

  .fund-image {
    padding: 3px;
  }

  .fund-details-container {
    width: 100%;
  }
}

.skylight-move-asset-modal {
  .skylight-dialog {
    width: 90% !important;
    left: 30% !important;
    min-height: auto !important;
    top: 50% !important;
    padding-bottom: 0 !important;
  }

  .skylight-overlay {
    z-index: 100 !important;
  }

  .skylight-close-button {
    display: none;
  }

  .move-asset-modal-body {
    padding-top: 13px;
    padding-bottom: 13px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .move-asset-modal-header {
    padding-top: 10px;
  }

  .move-asset-header-title {
    font-size: 20px;
    font-weight: '500';
  }

  .move-asset-subheading {
    font-size: 14;
  }

  .move-asset-modal-buttons {
    button {
      border-radius: 5px;
      font-size: 14px;
      height: auto !important;
      padding: 10px !important;
    }

    .secondary-btn {
      color: $custom-color-34;
      color: var(--custom-color-34);
    }
  }

  @media only screen and (min-width: 992px) {
    .skylight-dialog {
      left: 55% !important;
      width: 50% !important;
      max-width: 500px;
      padding-bottom: 15px !important;
    }
  }
}

.preloader-wrapper.small.move-preloader {
  height: 20px;
  width: 20px;
}

.fund-image-container {
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  height: 25px;
  width: 25px;
}

.fund-image {
  padding: 3px;
}

.fund-details-container {
  width: 100%;
}

.move-funds-note {
  margin: 10px;
}

.move-funds-note.link {
  color: $custom-color-34;
  color: var(--custom-color-34);
  font-weight: '500';
  text-align: center;
}

.detail-item {
  padding-top: 7px;
  padding-bottom: 7px;
}

.detail-item.heading {
  font-size: '500';
  border-top: 1px solid $sb-light-gray;
  border-top: 1px solid var(--sb-light-gray);
}

.skylight-load-asset-modal {
  .skylight-dialog {
    width: 90% !important;
    left: 30% !important;
    min-height: 165px !important;
    top: 70% !important;
    padding: 0 !important;
    border-radius: 8px !important;
  }

  .skylight-overlay {
    z-index: 100 !important;
  }

  .skylight-close-button {
    display: none;
  }

  @media only screen and (min-width: 992px) {
    .skylight-dialog {
      left: 55% !important;
      width: 50% !important;
      max-width: 500px;
      top: 60% !important;
    }
  }
}

.minor-disable-class {
  pointer-events: none;
  opacity: 0.6;
}

.sb-date-input-wrapper {
  .dob-text-style {
    border-radius: 5px;
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
    font-size: $font-14;
    font-weight: 700;
    margin-bottom: 5px;
    margin-right: 15px;
    margin-top: 10px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    width: 50px;
  }
  .signup-flow-v5-date-input {
    border-radius: 12px;
    border: 1px solid #e4e4e7; 
    box-sizing: unset;
    color: #3f3f46;
    flex: 1;
    font-size: 16px;
    font-weight: normal;
    height: auto;
    line-height: 18px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 16px;
    margin-top: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: start;
    &:last-child {
      margin-right: 0;
    }
    &:hover &:active {
      border-color:red; //#d4d4d8;
    }
    &:focus {
      border: 1px solid #2563eb !important;
      box-shadow: 0px 0px 0px 2px #dbeafe !important;
    }
    &:disabled {
      border: 1px solid #e4e4e7;
      background-color: #fafafa;
      color: #71717a;
    }
    &::placeholder{
      color: #71717A;
    }
  }
}

.income-error,
.error-class {
  color: $custom-color-25;
  color: var(--custom-color-25);
}

li {
  .list-style-outside {
    list-style-position: outside;
  }
}

.right-align {
  text-align: right;
}

.input-field .amount-field__input {
  width: calc(96% - 3rem);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 42px;
  margin-bottom: 0;
}

.input-field .ycof-amount-field__input {
  border: 1px solid $sb-opacity-5;
  border: 1px solid var(--sb-opacity-5);
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 0;

  &::placeholder {
    color: $sb-black-60;
    color: var(--sb-black-60);
    font-size: 16px;
  }
}

.search-fund-wizard {
  .input-field input[type=number] + label::after {
    top: 65px;
    font-size: 12px;
  }
}

.sb-words {
  margin: 2px;

  &::first-letter {
    text-transform: capitalize;
  }
}

.currency-error-msg {
  color: $sb-notication-badge-red;
  color: var(--sb-notication-badge-red);
}

.new-label {
  background-color: $custom-color-35;
  background-color: var(--custom-color-35);
  padding: 2px 5px 1px 5px !important;
  font-size: 10px;
  color: $sb-white;
  color: var(--sb-white);
  float: left;
  margin-top: 15px;
  margin-left: 5px;
}

.cas-timeout-error-message {
  font-size: $font-18;
  color: $custom-color-17;
  color: var(--custom-color-17);
  padding: 15px;
}

.disc-style-list > li {
  list-style-type: disc;
}

.file-drop {
  position: relative;
  height: 4rem;
  width: 100%;
  padding: 0 0.75rem;
}

.file-drop > .file-drop-target {
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 4px;
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  border: none;
  background-color: $sb-gray;
  background-color: var(--sb-gray);
  box-shadow: none;
  z-index: 50;
  opacity: 1;
  color: $sb-white;
  color: var(--sb-white);
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: 0 0 13px 3px $sb-gray;
  box-shadow: 0 0 13px 3px var(--sb-gray);
}


.isa-agreement > li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.SingleDatePicker_picker {
  position: relative !important;
  top: 0 !important;
}

.DayPicker__withBorder {
  box-shadow: none !important;
}

.SingleDatePickerInput__withBorder {
  border: none !important;
}

.DateInput {
  display: none !important;
}

.DayPicker__horizontal {
  width: 100% !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

@media only screen and (max-width: 420px) {
  .DayPicker__horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .tippy-popper {
    left: 0 !important;
  }
}

.dot-separator {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $custom-color-36;
  background: var(--custom-color-36);
  margin: 0.7rem 1.2rem;
  padding: 0.1rem;
}

.what-do-we-think-about-fund {
  .scard-container {
    padding: 0 !important;
    border: 0.5px solid $sb-card-border !important;
    border: 0.5px solid var(--sb-card-border) !important;
    margin: 10px 10px 0 10px;
    overflow: hidden;
  }

  .scard-grid-container {
    height: 200px !important;
    padding: 20px;
    border-radius: 0;
    overflow: hidden;
  }

  .autoHeight-for-no-para {
    .scard-grid-container {
      height: auto !important;
      padding-bottom: 1.5rem;
    }
  }

  .scard-grid-container-border {
    border-bottom: 0.5px solid $sb-card-border;
    border-bottom: 0.5px solid var(--sb-card-border);
  }

  .scard-grid-container-border:nth-last-child(-n+2) {
    border-bottom: none;
  }

  .right-border-without-over-all:nth-child(odd) {
    border-right: 0.5px solid $sb-card-border;
    border-right: 0.5px solid var(--sb-card-border);
  }

  .right-border-with-over-all:nth-child(even) {
    border-right: 0.5px solid $sb-card-border;
    border-right: 0.5px solid var(--sb-card-border);
  }

  @media only screen and (max-width: 991px) {
    .scard-container {
      margin: 2.5px 10px;
    }

    .scard-grid-container p {
      font-size: 13px;
    }

    .leftMargin {
      margin-right: 5px !important;
    }

    .rightMargin {
      margin-left: 5px !important;
    }

    .scard-grid-container {
      height: 180px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .leftMargin {
      margin-left: 0 !important;
    }

    .rightMargin {
      margin-right: 0 !important;
    }
  }
}

.is-investment-allowed {
  cursor: not-allowed !important;
  color: $custom-color-37;
  color: var(--custom-color-37);
}

.smooth-hide {
  // Hide content via default
  .hide-smooth-component {
    visibility: collapse;
    opacity: 0;
    height: 0;
    transition: visibility 0s linear 0.33s, opacity 0.13s linear, height 0.33s linear;
  }

  // Show if checkbox is clicked
  .show-smooth-component {
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: visibility 0s linear 0.33s, opacity 0.13s linear, height 0.33s linear;
    transition-delay: 0s;
  }
}

.ppp-image-container {
  height: 40px;
  overflow-y: hidden;

  .img-responsive {
    height: 50px !important;
  }
}

.ppp-fund-name {
  margin-top: 0 !important;
  font-size: 14px !important;

  .blue-anchor-tag {
    text-decoration: underline;
  }
}

.nowrap {
  white-space: nowrap;
}

.scarousel-container {
  .BrainhubCarousel__dots {
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;

    .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
      background: $sb-black-40;
      background: var(--sb-black-40);
    }

    .BrainhubCarousel__dot {
      border: none;
      border-radius: 2px;
      padding: 0;
      margin-top: 5px;
      margin-right: 4px;
      height: 3px;
      width: 10px;
      background: $sb-black-15;
      background: var(--sb-black-15);
      box-sizing: content-box;
    }

    li:last-child button.BrainhubCarousel__dot {
      margin-right: 0;
    }

    .BrainhubCarousel__dot::before {
      background: transparent;
    }
  }
}

.horizontal-slider {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 12px;
  scrollbar-width: thin;
}

.horizontal-slider::-webkit-scrollbar {
  height: 3px;
}

.horizontal-slider:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.horizontal-slider::-webkit-scrollbar-track {
  background: $sb-white;
  background: var(--sb-white);
  box-shadow: none;
  border-radius: 2px;
}

.horizontal-slider::-webkit-scrollbar-thumb {
  visibility: hidden;
  color: $sb-light-gray;
  color: var(--sb-light-gray);
}

.horizontal-slider-item {
  width: 250px;
  flex: 0 0 auto;
}

.horizontal-slider-head-container {
  display: flex;
  align-items: center;
  padding: 12px;
}

.horizontal-slider-heading {
  flex: 2;
  font-size: 20px;
  font-weight: 600;
}

.scroll-button-container {
  flex: 1;
  text-align: right;
}

.scroll-button {
  min-height: 25px;
  border-radius: 25px;
  padding: 2px;
  margin: auto 8px;
  color: $sb-gray;
  color: var(--sb-gray);
  background: transparent;
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
}

.scroll-button.active {
  color: $sb-green;
  color: var(--sb-green);
}

.scroll-button:active,
.scroll-button:focus {
  background: transparent;
}

.tabs-container {
  display: flex;
  align-items: center;
  background: $sb-white;
  background: var(--sb-white);
  border-bottom: 1px solid $sb-gray;
  border-bottom: 1px solid var(--sb-gray);
}

.tabs-container .tab {
  flex: 1;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabs-container .active-tab {
  border-bottom: 2px solid $sb-blue-link;
  border-bottom: 2px solid var(--sb-blue-link);
}

.graph-label-text {
  flex: 0.7;
}

.relative-position {
  position: relative;
}

.absolute-position {
  position: absolute;
}

.plan-type-card {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.tab-info-container {
  padding: 25px 15px;
}

.description-container {
  padding: 25px 15px;
}

.plan-image {
  overflow: hidden;
}

.svg-image {
  align-items: center;
  display: flex;
  position: relative;
  vertical-align: text-top;
}

@media only screen and (min-width: 993px) {
  .plan-type-card {
    padding: 25px;
    flex-direction: row;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .svg-image {
    position: relative;
    display: inline-block;
    vertical-align: text-top;
  }

  .plan-image {
    border-radius: 8px;
    align-self: baseline;
  }

  .tab-info-container {
    padding: 0;
  }

  .margin-desktop {
    margin: 0 10px;
  }

  .description-container {
    padding: 0;
    margin-left: 15px;
  }
}

.investor-type-box {
  width: 18px;
  height: 8px;
  position: absolute;
  bottom: 3px;
  left: 24px;
  border: 1px solid $sb-white;
  border: 1px solid var(--sb-white);
  border-radius: 5px;
}

.graph-label-percentage {
  flex: 0.3;
  text-align: right;
}

.test-upwardly-migration {
  display: flex !important;
  align-items: center;

  .upwardly-migration-icon {
    align-self: start;
    height: 20px;
    margin-right: 16px;
    margin-top: 2px;
    width: 20px;
  }

  @media only screen and (max-width: 992px) {
    .upwardly-migration-icon {
      margin-left: 0;
    }
  }
}

.upwardly-survey {
  margin-top: 5px;

  a {
    color: $custom-color-39;
    color: var(--custom-color-39);
    font-weight: 600;
    font-size: 15px;
  }
}

@media only screen and (max-width: 412px) {
  .upwardly-survey {
    margin-top: 0;
    text-align: right;
  }

  .faq-otions {
    margin-top: 200px !important;
  }
}

input.one-page-planner {
  margin-bottom: 0;
}

ul.check-circle-list li::before {
  content: "";
  background-image: url('../images/rebalance/no-action.png');
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 18px;
  width: 30px;
  border-radius: 8px;
  display: inline-block;
  margin-right: 5px;
  position: absolute;
}

ul.check-circle-list {
  p {
    margin-left: 28px;
  }

  li {
    padding-left: 0;
  }
}

.mileStone-container {
  .badge-header {
    position: relative;
    text-align: center;

    p {
      position: absolute;
      top: 40px;
      width: 100%;
    }

    .mileStone-img {
      width: 100%;
      height: 216px;
    }

    @media only screen and (max-width: 992px) {
      .mileStone-img {
        height: 185px;
      }
    }

    .mileStone-badge {
      position: absolute;
      bottom: -64px;
      width: 100%;

      img {
        width: 110px;
      }
    }
  }

  .sub-para {
    color: $custom-color-40;
    color: var(--custom-color-40);
  }

  .mileStone-msg {
    color: $custom-color-41;
    color: var(--custom-color-41);
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

@media only screen and (max-width: 412px) {
  .show-on-mobile {
    display: block;
  }

  .fund-house-label {
    margin-left: 16px !important;
  }
}

.divider-thick {
  border-bottom: 6px solid $custom-color-124;
  border-bottom: 6px solid var(--custom-color-124);
}

.divider-line-4 {
  border-bottom: 4px solid $sb-hover-grey-bg;
  border-bottom: 4px solid var(--sb-hover-grey-bg);
}

.divider-thin {
  border-top: 1px solid $custom-color-124;
}

#address-proof:disabled {
  color: $sb-black-80;
  color: var(--sb-black-80);
}

.fund-houses {
  .sb-avatar {
    background-color: $sb-white;
    background-color: var(--sb-white);
    overflow: hidden;
  }
}

.fund-basket {
  .fund-image-container {
    border: none !important;
  }
}

.disabled-div {
  opacity: 0.5;
}

.flex-flexend {
  flex: 1;
  justify-content: flex-end;
}

.word-spacing-2 {
  word-spacing: 2px;
}

.align-items-flex-start {
  align-items: flex-start;
}

.ecas-import-timeline {
  .timeline-title {
    color: $sb-black-60;
    color: var(--sb-black-60);
  }
}

.filter-list {
  position: absolute;
  background-color: $sb-white;
  background-color: var(--sb-white);
  z-index: 2;
  max-height: 262px;
  width: 100%;
  overflow: auto;
  border-radius: 2%;
  box-shadow: 0 -2px 4px $sb-black-10, 2.5px 2px 4px $sb-black-10, -2.5px 2px 4px $sb-black-10;
  box-shadow: 0 -2px 4px var(--sb-black-10), 2.5px 2px 4px var(--sb-black-10), -2.5px 2px 4px var(--sb-black-10);

  ul {
    list-style: none;

    li {
      padding: 15px 16px;
      cursor: pointer;
      font-family: 'Lato';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0;
      text-align: left;
      color: $sb-black-65;
      color: var(--sb-black-65);

      &:hover {
        background-color: $sb-ui-blue-light-2;
        background-color: var(--sb-ui-blue-light-2);
      }

      &:visited,
      &:active {
        background-color: $sb-ui-blue-light-2;
        background-color: var(--sb-ui-blue-light-2);
        color: $sb-black-86;
        color: var(--sb-black-86);
      }
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#___reactour > div {
  color: $sb-vanta-black;
  color: var(--sb-vanta-black);
}

.border-solid {
  border: 1px solid;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;/* IE and Edge */
  scrollbar-width: none;
}

.sticky-tab {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: $sb-white;
  background-color: var(--sb-white);
  transition: all 0.3s;
}

.sticky-tab-top {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: $sb-white;
  background-color: var(--sb-white);
  transition: all 0.3s;
}

.dashed-line {
  border-top: 1px dashed $sb-grey-88;
  border-top: 1px dashed var(--sb-grey-88);
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.border-1px-solid {
  border: 1px solid;
  border-radius: 4px;
}

.border-small-solid {
  border: 0.5px solid;
  border-radius: 4px;
}

.summary-modal-width {
  width: 400px;
}

.overflow-hidden {
  overflow: hidden;
}

.card-style {
  font-size: 25px;
  color: $sb-black;
  color: var(--sb-black);
  opacity: 0.4;

  &:active,
  &:visited {
    opacity: 0.9;
  }

  &:hover {
    opacity: 0.6;
  }
}

.order-one {
  order: 1;
}

.order-two {
  order: 2;
}

.do-it-later-mob {
  position: absolute;
  bottom: 52px;
  width: 100%;
  background: #fff;
  padding-top: 10px;
}

.no-style-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.shadow-pnl-drpdwn {
  box-shadow: -2.5px 2px 4px 0px $sb-black-10, 2.5px 2px 4px 0px $sb-black-10, 0px -2px 4px 0px $sb-black-10;
  box-shadow: -2.5px 2px 4px 0px --sb-black-10, 2.5px 2px 4px 0px --sb-black-10, 0px -2px 4px 0px --sb-black-10;
}

.shadow-card-swipe {
  box-shadow: 0px 2px 4px 0px $sb-black-5;
  box-shadow: 0px 2px 4px 0px --sb-black-5;
}

.shadow-bottom-bar {
  box-shadow: 0px -1px 4px $sb-black-10;
  box-shadow: 0px -1px 4px --sb-black-10;
}

.no-style-button:focus {
  background-color: transparent;
}

.border-right-grey {
  border-right: 1px solid $sb-grey-88;
  border-right: 1px solid var(--sb-grey-88);
}

.rotate-270 {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

.grid-item {
  width: 72px;
  height: 72px;
  align-self: center;
  justify-self: center;
}

.space-pre-wrap {
  white-space: pre-wrap;
}

.footer-box-shadow {
  box-shadow: 0px -1px 4px $sb-black-10;
}

.ml-auto{
  margin-left: auto;
}

.quote-font {
  font-size: $font-24;
  font-weight: 800;
  line-height: 40px;
  color: $sb-black-86;
}

.orange-bar {
  height: 6px;
  width: 61px;
  background-color: $sb-orange;
}

.box-shadow-none {
  box-shadow: none;
}

.epf-banner div:first-child {
  box-shadow: none !important;
}

.label-name-lower {
  text-transform: none;
}

.scross-sell-card {
  padding: 0 !important;
  border: 0.5px solid $sb-card-border !important;
  border: 0.5px solid var(--sb-card-border) !important;
  border-radius: 5px;
  margin: 10px 10px 0 0px;
  overflow: hidden;
  background-color: $custom-color-225;
  background-color: var(--custom-color-225);
}

.button-notification {
  color: white;
  // display: inline-block; 
  position: relative;
  padding-right: 4px; 
}

.w3-count{
  position: absolute;
   padding: 3px 2px;
   background: #FF4F38;
   color: #fff;
   border-radius: 4px;
   top: -2px;
   right: -10px;
   width: 24px;
   font-size: 10px;
   z-index: 200;
   text-align: center;
}

.notification{
  ::-webkit-scrollbar {
    width: 8px;
    background: none;
    border-radius: 5px;
}
}

.scroll-thumb ::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 0px rgb(0 0 0 / 0%);
  background-color: #E0E0E0;
  background-clip: padding-box;
  border-right: 4px solid transparent;
  border-radius: 0;
  width: 4px;
}

.scroll-track ::-webkit-scrollbar-track{
    display: none;
    margin-right: 4px;
}

.border-hidden {
  border: 'none'
}

.recommended-funds{
  &.recommended-funds-container{
    padding:0 10px; 
    }
  @media only screen and (max-width: 992px) {
    &.recommended-funds-container{
      padding:0 16px; 
      }
  }
}

#duration-input input[type=number]+label:after {
  top: 54px !important
}
