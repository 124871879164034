.cta-box-with-gradient {
  box-shadow: 2px 3px 15px 0 $sb-black-16;
  box-shadow: 2px 3px 15px 0 var(--sb-black-16);
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  min-height: 165px;
  // &.cta-box-bg-1 {
  //   background: url('../images/mf-sahi-hai.png') no-repeat bottom right, linear-gradient(45deg, $custom-color-95 0%, $custom-color-96 100%);
  //   background: url('../images/mf-sahi-hai.png') no-repeat bottom right, linear-gradient(45deg, var(--custom-color-95) 0%, var(--custom-color-96) 100%);
  // }

  &.cta-box-bg-1 {
    background: linear-gradient(45deg, $custom-color-95 0%, $custom-color-96 100%);
    background: linear-gradient(45deg, var(--custom-color-95) 0%, var(--custom-color-96) 100%);
  }

  &.cta-box-bg-2 {
    background: linear-gradient(45deg, $custom-color-97 0%, $custom-color-98 36%, $custom-color-99 78%, $custom-color-100 100%);
    background: linear-gradient(45deg, var(--custom-color-97) 0%, var(--custom-color-98) 36%, var(--custom-color-99) 78%, var(--custom-color-100) 100%);
  }

  &.cta-box-bg-3 {
    background: linear-gradient(43deg, $custom-color-101 0%, $custom-color-102 100%);
    background: linear-gradient(43deg, var(--custom-color-101) 0%, var(--custom-color-102) 100%);
  }

  .cta-box-title {
    font-size: 26px;
    font-weight: 400;
    color: $sb-white;
    color: var(--sb-white);
    padding: 10px 0;
  }

  .cta-button-container {
    margin-top: 10px;
  }

  .read-more-transparent-btn {
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);

    &.featured-read-more {
      padding: 6px 20px;
      // margin-top: 50px;
      // color: $sb-white;
      // color: var(--sb-white);
    }
  }

  .cta-box-button {
    display: inline-block;
    border: 1px solid $sb-white;
    border: 1px solid var(--sb-white);
    font-size: 20px;
    background: $sb-white;
    background: var(--sb-white);
    margin-top: 0;
    color: $sb-charcoal;
    color: var(--sb-charcoal);
  }
}

@media only screen and (max-width: 420px) {
  .mobile-app-download-cta {
    .cta-box-with-gradient {
      .cta-box-button {
        font-size: 16px;
      }

      .cta-box-title {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .cta-box-with-gradient {
    min-height: 0;

    .cta-box-title {
      font-size: 22px;
    }

    .cta-box-button {
      font-size: 18px;
    }

    .cta-button-container {
      margin-bottom: 20px;
    }
  }
}

.nudge-card {
  background: url('https://content0.scripbox.com/content/attachment/1812/nudge-v-1.png') no-repeat bottom right, linear-gradient(-134deg, $custom-color-104 0%, $custom-color-103 100%);
  background: url('https://content0.scripbox.com/content/attachment/1812/nudge-v-1.png') no-repeat bottom right, linear-gradient(-134deg, var(--custom-color-104) 0%, var(--custom-color-103) 100%);
  background-size: contain;
  margin: 10px 0;
  box-shadow: 2px 3px 15px 0 $sb-black-16;
  box-shadow: 2px 3px 15px 0 var(--sb-black-16);
  border-radius: 8px;
  min-height: 150px;
  padding: 10px;
}

@media only screen and (min-width: 1310px) {
  .nudge-card {
    margin: 10px;
  }
}

.nudge-card-title {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  color: $sb-white;
  color: var(--sb-white);
}

.nudge-cta {
  font-size: 1em;
  font-weight: 600;
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  background-color: $sb-white;
  background-color: var(--sb-white);
  padding: 6px 15px;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  margin: 7px auto;
}

.nudge-cta:hover,
.nudge-cta:active,
.nudge-cta:visited,
.nudge-cta:focus {
  color: $sb-charcoal;
  color: var(--sb-charcoal);
  background-color: $sb-white;
  background-color: var(--sb-white);
  cursor: pointer;
}
