@import "../../../styles/utils/all";

.footer-link-container {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  padding: 83px 0 16px 0;
  text-align: center;
}

.footer-link-sub-container {
  display: flex;
}

.footer-link-icon {
  height: 24px;
  width: 24px;
}

@media only screen and (min-width: 993px) {
  .footer-link-container {
    border-top: 1px $custom-color-13 solid;
    border-top: 1px var(--custom-color-13) solid;
    padding: 18px 27px;
  }

  .footer-link-icon {
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
}
