.reallocate-sip-faq {
  .Collapsible {
    .Collapsible__contentInner {
      border: 0;

      p {
        margin: 0;
        color: $sb-black;
        color: var(--sb-black);
      }
    }
  }
}

.reallocate-sip-sprite {
  background-image: url('../images/reallocate-sip/reallocate-sip-sprite.png');
  width: 41px;
  height: 40px;
  display: inline-block;

  &.new-sip-icon {
    background-position: -10px -5px;
  }

  &.move-sip-icon {
    background-position: -60px -4px;
  }

  &.move-sip-timeline {
    background-position: -155px -5px;
    width: 71px;
    height: 70px;
  }
}

.reallocate-confirmation-modal {
  .scard-container.full-width-slist-card {
    padding: 10px 0;
    margin: 10px 0;
  }

  .skylight-dialog {
    z-index: 101 !important;
  }

  .skylight-overlay {
    z-index: 100 !important;
  }
}

.move-sip-container {
  border-radius: 8px;
  margin: 10px 0;
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);

  .top-section {
    background: $sb-white;
    background: var(--sb-white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 10px;

    .scard-container {
      background: $sb-white;
      background: var(--sb-white);
    }
  }

  .bottom-section {
    padding-top: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: $custom-color-108;
    background: var(--custom-color-108);

    .scard-container {
      background: $sb-white;
      background: var(--sb-white);
    }
  }

  .scard-container.full-width-slist-card {
    border-radius: 8px;
  }

  .scard-container {
    margin: 0;
  }
}

.reallocate-indicator-container {
  text-align: center;
  position: relative;
}

.reallocate-indicator-right {
  text-align: right;
  position: relative;
}

.reallocate-sip-icon {
  border-radius: 50%;
  margin-right: 4px;
  margin-top: -3px;

  &.material-icons {
    font-size: $font-18;
    color: $custom-color-109;
    color: var(--custom-color-109);
  }
}
.reallocate-sip-icon-black {
  border-radius: 50%;
  margin-right: 4px;
  margin-top: -3px;

  &.material-icons {
    font-size: $font-18;
    color: $sb-opacity-5;
    color: var(--sb-opacity-5);
  }
}

.reallocate-indicator-position {
  width: 100%;
  position: absolute;
}

.reallocate-indicator {
  margin: 0 auto;
  background: $sb-white;
  background: var(--sb-white);
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  border-radius: 30px;
  display: inline-block;
  padding: 2px 12px;

  span {
    display: inline-block;
  }
}

.reallocation-sip-button {
  .btn-disabled {
    color: $sb-white;
    color: var(--sb-white);
    cursor: not-allowed;
    border: 1px solid $sb-primary-btn-disabled;
    border: 1px solid var(--sb-primary-btn-disabled);
    background-color: $sb-primary-btn-disabled;
    background-color: var(--sb-primary-btn-disabled);
    font-size: $font-16;
    font-weight: 600;
    padding: 6px;
    display: block;
    width: 100%;
    border-radius: 8px;
    margin-top: 6px;
  }
}
