@import "../../../styles/utils/colors";

.more-menu-item {
  align-items: center;
  display: flex;
  padding: 16px;
  
    .divider {
      top: -16px;
    }
}

.more-menu-item-border {
  border-bottom: 1px solid $custom-color-13;
  border-bottom: 1px solid var(--custom-color-13);
}

.more-menu-item-sub-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.more-menu-item-icon-container {
  align-items: center;
  display: flex;
  padding-right: 12px;
}

.more-menu-item-arrow-icon {
  color: $sb-black-86;
  color: var(--sb-black-86);
  opacity: 0.4;
}

.more-menu-item-text-container {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
}

.more-menu-item-text {
  color: $sb-black-86;
  color: var(--sb-black-86);
  opacity: 0.5;
}

.menu-tag {
  background: #FFF8E9;
  border-radius: 10px;
  padding: 4px 8px;
}
@media (min-width: 993px) {
  .more-menu-item {
    padding: 24px;

    .divider {
      top: -24px;
    }
  }
}