
.first-investment-modal {
  .skylight-overlay {
    z-index: 100 !important;
  }

  .skylight-wrapper .skylight-dialog {
    z-index: 3000;
    width: 95% !important;
    min-height: auto !important;
    max-width: 475px !important;
    top: 20% !important;
    left: 10px !important;
    right: 10px !important;
    margin: auto !important;
    overflow-y: auto;
  }

  .modal-content {
    text-align: center;
    padding-top: 15px;
  }

  .modal-content-text {
    padding-bottom: 45px;
  }

  .modal-header {
    color: $custom-color-145;
    color: var(--custom-color-145);
    font-size: 24px;
    font-weight: 700;
  }

  .modal-text {
    color: $sb-black-50;
    color: var(--sb-black-50);
    font-weight: 600;
    margin-top: 10px;
  }

  @media only screen and (min-width: 993px) {
    .first-investment-modal {
      .skylight-wrapper .skylight-dialog {
        width: 475px !important;
        top: 25% !important;
        left: -25% !important;
      }
    }
  }
}
