.upi-component {
  .previous-results {
    text-align: center;
    font-size: 18px;

    .error-icon {
      color: $sb-notication-badge-red;
      color: var(--sb-notication-badge-red);
    }
  }

  .vpa-input-box {
    margin-top: 10px;

    .vpa-input-label {
      font-weight: 650;
      color: $sb-black-86;
      color: var(--sb-black-86);
    }

    .vpa-input {
      width: 100%;
      font-size: 16px;
      padding: 5px;
      border: 1px solid $sb-gray;
      border: 1px solid var(--sb-gray);
      margin: 7px auto;
      height: 41px;
      border-radius: 4px;
    }

    .vpa-input-error {
      font-weight: 650;
      color: $sb-red-error;
      color: var(--sb-red-error);
    }
  }

  .upi-intent-app-image {
    height: 45px;
    padding: 12px 16px;
    border-radius: 4px;
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    background-color: $sb-white;
    background-color: var(--sb-white);
    border: 0.5px solid $custom-color-113;
    border: 0.5px solid var(--custom-color-113);
  }

  .upi-supported-methods {
    margin-top: 15px;

    .upi-supported-heading {
      font-weight: 550;
      font-size: 16px;
      color: $sb-charcoal;
      color: var(--sb-charcoal);
      margin-bottom: 8px;
    }

    .upi-supported-method {
      margin-right: 10px;
    }

    .upi-supported-others {
      color: $sb-gray;
      color: var(--sb-gray);
    }
  }

  .upi-instructions {
    margin-top: 26px;
    color: $sb-charcoal;
    color: var(--sb-charcoal);
  }

  .other-apps-card {
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    background-color: $sb-white;
    background-color: var(--sb-white);
  }
}
