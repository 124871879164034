.scard-container-version-1 {
  padding: 10px;

  .scard-title {
    font-size: 16px;
  }

  .scard-description {
    font-size: 16px;
    color: $sb-checklist-gray;
    color: var(--sb-checklist-gray);
  }
}
