#personalised_portfolio_modal {
  .sinformation-card {
    padding: 16px;
    border-radius: 5px;

    &.sinformation-card-outline-success {
      background-color: $custom-color-108;
      background-color: var(--custom-color-108);
      border: 0px;
    }
  }


  @media only screen and (min-width: 993px) {
    .scard-container {
      margin-left: 0;
      margin-right: 0;
    }

    .cardArrow {
      .material-icons {
        margin-left: 12px;
      }
    }  
  }
}
