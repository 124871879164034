.disable-action {
  pointer-events: none;
  opacity: 0.5;
  display: block;
}

.document-checklist-title {
  font-size: $font-22;
}

.document-checklist-description {
  font-size: $font-16;
  color: $sb-black;
  color: var(--sb-black);
}

.document-checklist-item {
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 12px 0 6px 0;

  .checklist-arrow {
    margin-top: 2px;
  }
}

.doc-upload-success-icon {
  color: $sb-primary-btn-default;
  color: var(--sb-primary-btn-default);
}

hr.doc-checklist-divider {
  border: 0.5px solid $sb-light-gray;
  border: 0.5px solid var(--sb-light-gray);
  margin: 0;
}

.checklist-item {
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid $sb-grey-88;
  border-bottom: 1px solid var(--sb-grey-88);
  cursor: pointer;

  b {
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 600;
  }
}

.document-checklist-items {
  margin-top: 20px;
}

.document-checklist-container,
.checklist-container {
  a {
    color: $sb-black;
    color: var(--sb-black);
  }
}

.checklist-arrow {
  float: right;
  margin-top: 10px;
}

.checklist-success-img {
  width: 100%;

  h5 {
    padding: 10px;
    text-align: center;
  }

  .sticky-footer {
    margin: 0 auto;
    text-align: center;
  }
}

.checklist-title {
  font-size: 21px;
  margin-left: 20px;
}

.checklist-description {
  font-size: 16px;
  color: $sb-checklist-gray;
  color: var(--sb-checklist-gray);
  font-weight: 200;
  margin-left: 20px;
}

.checklist-item-msg {
  font-size: 14px;
  color: $sb-checklist-gray;
  color: var(--sb-checklist-gray);
  font-weight: 200;
}

@media only screen and (max-width: 993px) {
  .checklist-container {
    background-color: $sb-white;
    background-color: var(--sb-white);
    margin-top: -20px;
    padding-top: 10px;
  }
}

.last-element {
  border-bottom: none;
}

.checklist-btn-nudge {
  display: inline-block;
  margin-top: 10px;
  background-color: $sb-white;
  background-color: var(--sb-white);
  border: 2px solid $sb-gray;
  border: 2px solid var(--sb-gray);
  padding: 5px;

  &:hover {
    background-color: $sb-white;
    background-color: var(--sb-white);
  }
}
