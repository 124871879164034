.ReferralCard {
  background: linear-gradient(-134deg, $custom-color-104 0%, $custom-color-103 100%);
  background: linear-gradient(-134deg, var(--custom-color-104) 0%, var(--custom-color-103) 100%);
  color: $sb-white;
  color: var(--sb-white);
  padding: 1px !important;
  padding-top: 5px;
  cursor: pointer;

  .content {
    position: relative;
    height: 150px;
    padding: 5px;
  }

  .text-content {
    margin: 5% auto;
    z-index: 2;
  }

  .title {
    color: $sb-white;
    color: var(--sb-white);
    font-size: 1.4em;
    line-height: 1.2em;
    font-weight: 500;
  }

  .referButton {
    font-size: 1em;
    font-weight: 600;
    color: $sb-black;
    color: var(--sb-black);
    background-color: $sb-white;
    background-color: var(--sb-white);
    padding: 6px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
    margin: 7px auto;
  }

  img {
    height: 130px;
    width: auto;
    position: absolute;
    bottom: 2px;
    right: 10px;
    z-index: 1;
  }
}
