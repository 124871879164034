
.skylight-updates-modal {
  .skylight-wrapper .skylight-dialog {
    z-index: 3000;
    width: 90% !important;
    min-height: auto !important;
    max-width: 475px;
    top: 20% !important;
    left: 20px !important;
    right: 20px !important;
    margin: auto !important;
    overflow-y: auto;
  }

  .updates-modal-content {
    padding: 0 5px;
  }

  .updates-modal-heading {
    font-size: $font-18;
    font-weight: 600;
  }

  .updates-modal-items {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .updates-modal-item {
    padding: 10px 0;
  }

  .updates-modal-tick {
    max-width: 16px;

    img {
      margin-top: 3px;
    }
  }

  .modal-item-header {
    font-weight: 600;
  }

  .updates-modal-footer {
    .btn-custom {
      font-size: $font-16;
      height: auto;
      width: auto;
      padding: 8px 40px;
      border-radius: 8px;
      margin: auto;
      margin-bottom: 15px;
    }
  }

  @media only screen and (min-width: 993px) {
    .skylight-updates-modal {
      .skylight-wrapper .skylight-dialog {
        width: 475px !important;
        top: 25% !important;
        left: -25% !important;
      }
    }
  }
}
