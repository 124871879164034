@import "../../../../styles/utils/colors";

.slist-container {
  border: 1px solid $sb-grey-88;
  border: 1px solid var(--sb-grey-88);
  padding: 16px 20px 16px 16px;
  background-color: $sb-white;
  background-color: var(--sb-white);

  &:hover {
    background-color: $sb-ui-blue-light-2;
    background-color: var(--sb-ui-blue-light-2);
    cursor: pointer;
  }

  &:active,
  &:visited {
    background-color: $sb-ui-blue-light-2;
    background-color: var(--sb-ui-blue-light-2);
    cursor: pointer;
  }

  .card-style {
    font-size: 25px;
    color: $sb-black;
    color: var(--sb-black);
    opacity: 0.4;

    &:active,
    &:visited {
      opacity: 0.9;
    }

    &:hover {
      opacity: 0.6;
    }
  }
  @media only screen and (max-width: 992px) {
    border-left-width: 0px;
    border-right-width: 0px;
  }
}

.slist-wrapper-container {
  .slist-wrapper {
     padding-left: 15px;
     padding-right: 15px; 
    }
@media only screen and (max-width: 992px) {
    .slist-wrapper {
      padding-left: 0px;
     padding-right: 0px;
    }
  }
}