@import "../../../../styles/utils/colors";

.chips .active-chip {
  border: 1px solid $sb-blue-link !important;
  border: 1px solid var(--sb-blue-link) !important;
  background-color: $sb-ui-blue-light-2 !important;
  background-color: var(--sb-ui-blue-light-2) !important;
  color: $sb-black-86;
  color: var(--sb-black-86);
}

.horizontal-chip-slider {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: overlay;
  flex: 2;
}

.horizontal-chip-slider::-webkit-scrollbar {
  height: 3px;
}

.horizontal-chip-slider:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.horizontal-chip-slider::-webkit-scrollbar-track {
  background: $sb-white;
  background: var(--sb-white);
  box-shadow: none;
  border-radius: 2px;
}

.horizontal-chip-slider::-webkit-scrollbar-thumb {
  visibility: hidden;
  color: $sb-light-gray;
  color: var(--sb-light-gray);
}

.horizontal-chip-slider-item {
  min-width: unset !important;
  width: auto;
  flex: 0 0 auto;
}

.horizontal-chip-slider-head-container {
  display: flex;
  align-items: center;
  padding: 12px;
}

.horizontal-chip-slider-heading {
  flex: 2;
  font-size: 20px;
  font-weight: 600;
}
