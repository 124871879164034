.skylight-active-goal-plans-modal {
  .skylight-wrapper .skylight-dialog {
    z-index: 3000;
    width: 90% !important;
    max-width: 475px;
    max-height: auto !important;
    top: 30% !important;
    left: 20px !important;
    right: 20px !important;
    margin: auto !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 993px) {
  .skylight-active-goal-plans-modal {
    .skylight-wrapper .skylight-dialog {
      width: 475px !important;
      top: 30% !important;
      left: 0% !important;
    }
  }
}
