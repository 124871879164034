@import "../utils/all";

#fund-header-modal {
  .scard-container {
    padding: 20px;
    border: 0.5px solid $sb-card-border;
    border: 0.5px solid var(--sb-card-border);
    margin: 0;
  }

  .scard-title {
    font-size: 18px;
  }

  .fund-header-image-container {
    height: 24px;
    width: 24px;
    margin-top: 2px;
  }

  .fund-header-image {
    padding: 1px;
  }

  .fund-header-header-text {
    display: inline-flex;
    font-size: $font-16;
    font-weight: 600;
  }

  .fund-header-sub-header-text {
    font-size: $font-14;
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
  }

  .fund-performance-link {
    font-size: $font-14;
    color: $sb-blue-link;
    color: var(--sb-blue-link);
    padding-top: 15px;
    margin-left: 35px;
    padding-bottom: 15px;
    cursor: pointer;
  }

  .recommendation {
    color: $custom-color-54;
    color: var(--custom-color-54);
    font-size: 16px;
  }

  .fact-sheet-table-heading {
    font-weight: bold;
  }

  .separator {
    border: 1px solid $custom-color-113;
    border: 1px solid var(--custom-color-113);
    margin: 3px 0;
  }

  @media only screen and (max-width: 991px) {
    .fund-sb-view-image-container {
      margin-left: 5px;
    }

    .recommendation {
      font-size: 12px !important;
    }

    .scard-container {
      padding: 10px;
    }
  }

  @media only screen and (min-width: 992px) {
    .fund-sb-view-image-container {
      margin-left: 9px;
    }

    .addFund-marginLeft {
      margin-left: 0.6rem;
    }

    .header-pick-fund-btn {
      margin-top: -16px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .remove-desktop-padding {
    padding: 0 !important;
  }

  .fund-sb-view-image-container {
    height: 15px;
    width: 15px;
    padding-top: 2px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .padding-left-4 {
    padding-left: 4px !important;
  }

  .fund-sb-view-image-container {
    height: 13px;
    width: 13px;
    margin-left: 5px;
  }

  .recommendation {
    .sb-typography-v2 {
      font-size: 12px !important;
    }
  }

  .fact-sheet-close {
    position: absolute;
    right: 5px;
    top: 10px;
  }
}

.show-close-icon {
  #fund-header-modal {
    margin: 16px 0 0 !important;
  }
}
