.investment-plan-card {
  margin: 10px;
  border-radius: 8px;
  box-shadow: -2px 2px 13px 0 $custom-color-142;
  box-shadow: -2px 2px 13px 0 var(--custom-color-142);
  border: 0.5px solid $custom-color-113;
  border: 0.5px solid var(--custom-color-113);
  text-align: center;

  .plan-car-header {
    background: $custom-color-26;
    background: var(--custom-color-26);
    min-height: 120px;
    margin-bottom: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
  }

  .total-investment-amount {
    font-size: $font-18;
    font-weight: 600;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
  }

  .monthly-amount {
    font-size: $font-32;
    font-weight: 600;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
  }

  .investment-type {
    font-size: $font-16;
    margin-bottom: 10px;
    color: $sb-secondary-default-border;
    color: var(--sb-secondary-default-border);
  }

  .recommended-plan-badge-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    margin-left: auto;
    margin-right: auto;

    .recommended-plan-badge {
      background: $custom-color-62;
      background: var(--custom-color-62);
      display: inline-block;
      padding: 4px 8px;
      border-radius: 8px;
      color: $sb-white;
      color: var(--sb-white);
    }
  }
}
