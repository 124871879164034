.sbadge-container {
  display: inline-block;
  padding: 0 10px;
  margin: 0 2px;

  &.success {
    border-radius: 8px;
    background: $sb-success-green;
    background: var(--sb-success-green);
    border: 1px solid $sb-success-green;
    border: 1px solid var(--sb-success-green);
  }

  .sbadge-label {
    font-size: $font-12;
    color: $sb-white;
    color: var(--sb-white);
    margin: 0;
    padding: 0;
  }
}

.full-width-card-container {
  background: $sb-white;
  background: var(--sb-white);
  margin-bottom: 10px;
  padding-top: 14px;
  padding-bottom: 6px;

  &.no-bg {
    background: inherit;
  }

  &.card-horizontal-space {
    padding-left: 6px;
    padding-right: 6px;
  }

  &.accordian-card {
    margin-bottom: 0;
    border-bottom: 2px solid $card-layout-background;
    border-bottom: 2px solid var(--card-layout-background);
  }

  .card-header-title {
    font-size: $font-20;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
    font-weight: 600;
    line-height: 1.4;
  }

  .card-header-description {
    font-size: $font-16;
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
  }

  p {
    margin: 0 0 4px 0;
  }

  .amount-value-title {
    color: $card-layout-description-text;
    color: var(--card-layout-description-text);
    font-size: $font-16;
    font-weight: 500;
  }

  .balance-amount {
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
    line-height: 0.8;
  }

  .input-field .card-title-label {
    font-size: $font-16;
    font-weight: 600;
    color: $card-layout-primary-text;
    color: var(--card-layout-primary-text);
  }
}

.card-input-key {
  font-size: $font-16;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
}

.card-input-label {
  font-size: $font-16;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  font-weight: 600;
}

.scard-radio-button {
  margin-bottom: 16px;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
}

i.withdraw-help-icon {
  color: $custom-color-93;
  color: var(--custom-color-93);
  font-size: $font-14;
  position: relative;
  top: 2px;
  left: 4px;
}

.scheme-name {
  font-size: $font-16;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  font-weight: 600;
}

.scheme-amount {
  font-size: $font-16;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  font-weight: 600;
}

[type="checkbox"].filled-in:checked + .radio-input-label::after,
[type="checkbox"].filled-in:not(:checked) + .radio-input-label::after {
  border-radius: 9px;
}

.withdraw-amount-progress-bar {
  height: 16px;
  border-radius: 30px;

  &.present-bar {
    background: $sb-nuetral-yellow;
    background: var(--sb-nuetral-yellow);
  }

  &.future-bar {
    background: $sb-success-green;
    background: var(--sb-success-green);
  }
}

.withdraw-info-accordion-btn {
  font-size: $font-16;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  font-weight: 600;
  padding: 6px 0;
  border-bottom: 1px solid $card-layout-background;
  border-bottom: 1px solid var(--card-layout-background);
  cursor: pointer;
}

.withdraw-info-accordion-body {
  font-size: $font-14;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  padding: 6px 2px;
}

.withdraw-custom-amount-input {
  border: 1px solid $custom-color-116;
  border: 1px solid var(--custom-color-116);
  border-radius: 4px;
  padding: 4px;
  max-width: 100px;
  font-size: $font-14;
  text-align: right;

  &:focus {
    outline: none;
  }

  &.animate-entry {
    transition: opacity 2s ease-in;
  }
}

.withdraw-checkbox-fix {
  position: relative !important;
  display: inline-block;
  margin-left: -14px;
}

[type="checkbox"] + label.withdraw-radio-label {
  line-height: 1.4;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
}

.withdraw-confimation {
  .card-input-label {
    font-weight: 500;
  }
}

.offering-description-container {
  @media only screen and (max-device-width: 370px) {
    width: auto;
  }

  .scard-title {
    font-size: $font-16;
  }

  .scard-description {
    font-size: $font-16;
  }

  .intro-block-title {
    font-size: $font-16;
  }

  .fund-name {
    font-size: $font-16;
    text-decoration: underline;
    padding-left: 0;
    margin-top: 10px;

    @media only screen and (max-device-width: 370px) {
      margin-top: 0;
    }
  }

  .performance-graph-title {
    margin-left: 15px;
  }

  .fund-type-info-bg {
    background-color: transparent;
  }

  .fund-info-border {
    border: none !important;
  }
}

.loss-value-title {
  font-size: $font-12;
  color: $custom-color-18;
  color: var(--custom-color-18);
  text-align: right;
}

.loss-value-amount {
  font-size: $font-12;
  color: $custom-color-18;
  color: var(--custom-color-18);
  text-align: center;
}

.border-left {
  border-left: 1px solid $custom-color-115;
  border-left: 1px solid var(--custom-color-115);
}

.withdraw-confirm-button {
  @media only screen and (min-width: 993px) {
    margin-right: 20px;
  }

  @media only screen and (max-width: 992px) {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.withdraw-page-cancel-button {
  border: 1px solid;
  font-size: 20px;
  border-color: $sb-secondary-default-color;
  border-color: var(--sb-secondary-default-color);
}

.withdraw-button-container {
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
}

.modal-key {
  font-size: $font-16;
  color: $card-layout-description-text;
  color: var(--card-layout-description-text);
  padding-bottom: 10px;
  animation-delay: 1ms;
}

.border-bottom {
  border-bottom: 1px solid $custom-color-151;
  border-bottom: 1px solid var(--custom-color-151);
}

.border-top {
  border-top: 1px solid $sb-gray;
  border-top: 1px solid var(--sb-gray);
}

.modal {
  background-color: $sb-white;
  background-color: var(--sb-white);
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.1;

  .modal-heading {
    font-weight: 600;
    font-size: 21px;
    padding-bottom: 6px;
    color: $sb-black;
    color: var(--sb-black);
  }

  .modal-subheading-bold {
    font-size: $font-16;
    color: $sb-black;
    color: var(--sb-black);
    font-weight: 600;
    margin-bottom: 10px;
  }

  .modal-subheading {
    font-size: 16px;
    color: $sb-checklist-gray;
    color: var(--sb-checklist-gray);
  }
}

.help {
  line-height: 0.8;
  color: $sb-blue-link;
  color: var(--sb-blue-link);
  cursor: pointer;
  font-size: 18px !important;
  float: left;
  box-sizing: border-box;
}
