@import "../../../../styles/utils/colors";

.graph-progress-bar {
  border-radius: 5px;
  height: 5.99px;
  background-color: $offeringTaxSaver;
  background-color: var(--offeringTaxSaver);
}

.graph-inner-progress-bar {
  border-radius: 5px;
  background-color: $custom-color-14;
  background-color: var(--custom-color-14);
  height: 100%;
}
