.cas-summary-title {
  font-size: $font-18;
  font-weight: 600;
  line-height: '1.2';
}

.cas-summary-header {
  font-size: $font-20;
  font-weight: 600;
  line-height: '1.2';
}
