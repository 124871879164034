$container-max-width: 620px;

@media only screen and (min-device-width: 300px) and (max-device-width: 350px) {
  .btn-custom,
  .white-button,
  .secondary-btn,
  .login #email,
  #password {
    font-size: $font-14;
  }

  .icon-color-style {
    font-size: $font-14;
    margin-top: 6px;
  }

  .button-list-btn {
    margin-top: 30px;
  }

  .login #email,
  #password {
    font-size: $font-14;
  }

  .image-preview-section {
    max-height: 200px;
  }

  .preview-image {
    max-height: 300px;
  }
}

@media only screen and (max-width: 992px) {
  // Verloop Chat
  .verloop-button {
    display: none !important;
  }

  .collection-item.sb-investor-profile {
    padding: 10px;
  }

  .input-field .amount-field__input {
    margin-left: 42px !important;
  }

  .move-asset-modal-buttons .secondary-btn {
    margin-left: 0;
  }

  iframe html body a#chat-start-button {
    display: none;
  }

  .custom-amount-container {
    .input-field .prefix ~ label {
      font-size: $font-12;
    }
  }

  #withdraw-container {
    #app-container-heading {
      display: none;
    }
  }

  .withdraw-modal-wrapper {
    .skylight-wrapper .skylight-dialog {
      min-height: 400px !important;
      position: fixed !important;
      top: 20% !important;
      left: 10px !important;
      right: 10px !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      width: auto !important;
      height: auto !important;
    }
  }

  .investment-calendar .scard-container {
    margin-left: 0;
  }

  .withdraw-buttons {
    position: initial;
    min-width: 400px;
    max-width: $container-max-width;
    background-color: inherit;
    text-align: left;
    border-top: none;
    height: auto;
    clear: both;
    margin: 20px 0;
    border-radius: 8px;

    .row {
      .col {
        width: auto;
        margin: 10px;
        border-right: none;
        display: inline-block;
        float: none;
      }
    }

    &.desktop-center {
      text-align: center;
    }
  }

  .sb-card-container {
    background: $card-layout-background;
    background: var(--card-layout-background);

    &.dark {
      background: $sb-white;
      background: var(--sb-white);
    }
  }

  .image-preview-section {
    max-height: 200px;
  }

  .preview-image {
    max-height: 300px;
  }

  .fixed-action-btn {
    position: absolute;
  }

  .modal-sticky-footer {
    bottom: 0;
  }

  .investment-portfolio-card .investment-category {
    font-size: 17px;
  }

  .investment-portfolio {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #olark-wrapper {
    display: none;
  }

  .freshwidget-customimage {
    display: none !important;
  }

  #intercom-container iframe.intercom-launcher-discovery-frame,
  #intercom-container iframe.intercom-launcher-frame,
  #intercom-container iframe.intercom-borderless-frame,
  #intercom-container iframe.intercom-notifications-frame,
  #intercom-container iframe.intercom-launcher-badge-frame {
    display: none;
  }

  .hide-on-mobile-device {
    display: none;
  }

  .margin-top-20-sm {
    margin-top: 20px;
  }

  .margin-top-10-sm {
    margin-top: 10px !important;
  }

  .footer-position {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background: $sb-white;
    background: var(--sb-white);
  }

  .side-nav-text {
    font-weight: normal;
  }

  .sub-side-nav-text {
    display: block;
    margin-left: -25px;
    font-size: $font-14;
    color: $sb-black-50;
    color: var(--sb-black-50);
    font-weight: normal;
  }

  .sub-side-nav-divider {
    border-left: 1px solid $sb-grey-88;
    border-left: 1px solid var(--sb-grey-88);
    bottom: 45%;
    margin-top: -30px;
    position: absolute;
    top: 10%;
  }

  .more-sub-menu {
    font-size: $font-14;
    line-height: 16px;
  }

  .side-nav-li-text-active {
    color: $sb-black-86 !important;
    color: var(--sb-black-86) !important;
    vertical-align: text-top;
    font-weight: normal;
    padding-left: 15px;
  }

  .main-container-mt {
    margin-top: 60px;
  }

  .test-wealth-app-container {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.withdraw-fund-edit-card {
  border: 0.5px solid $custom-color-113;
  border: 0.5px solid var(--custom-color-113);
  box-shadow: -2px 2px 13px 0 $custom-color-142;
  box-shadow: -2px 2px 13px 0 var(--custom-color-142);
}

.withdraw-fund-edit-card-disable {
  background: $custom-color-17 !important;
  background: var(--custom-color-17) !important;
}

.nudge-container {
  padding: 0 12px 20px 12px;
}

@media only screen and (min-width: 993px) {
  html,
  body {
    height: 100%;
  }

  html body {
    background-color: $sb-white;
    background-color: var(--sb-white);
    margin-bottom: 0 !important;
  }

  .graph-note {
    margin-right: 6px;
  }

  .graph-note.single-line {
    margin-bottom: 4px;

    .education-plan-graph-amount {
      display: inline-block;
      float: right;

      .amount-value-title {
        margin-bottom: 0;
      }
    }
  }

  .investment-info-notification-card {
    border: 1px solid $sb-border-gray;
    border: 1px solid var(--sb-border-gray);

    p {
      font-size: $font-14;
    }
  }

  .secondary-btn,
  .secondary-btn-full {
    border: 1px solid $sb-secondary-default-border !important;
    border: 1px solid var(--sb-secondary-default-border) !important;
    border-radius: 8px;

    &:focus,
    &:active {
      border: 1px solid $sb-secondary-default-color;
      border: 1px solid var(--sb-secondary-default-color);
    }

    &:hover {
      border: 1px solid $sb-secondary-default-color;
      border: 1px solid var(--sb-secondary-default-color);
    }

    &.btn-disabled {
      border: 1px solid $sb-secondary-disabled-background;
      border: 1px solid var(--sb-secondary-disabled-background);
      background-color: $sb-secondary-disabled-background;
      background-color: var(--sb-secondary-disabled-background);
    }
  }

  .full-width-card-container.card-horizontal-space {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .indian-mobile-input {
    .input-field .prefix ~ input {
      width: calc(100% - 67px) !important;
    }
  }

  .money-page-btn-padding {
    padding: 15px;
  }

  .desktop-right-align {
    text-align: right;
  }

  .secondary-menu-item {
    font-size: $font-14;
    padding: 0 4px !important;
    margin-left: 7px;
  }

  .sticky-footer .secondary-footer-top-button {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .forgotPasswordContainer {
    .sticky-footer {
      text-align: center;

      .secondary-btn,
      .btn-custom {
        min-width: inherit;
      }
    }
  }

  .content-container .sb-gradient-card {
    background-image: none;
    padding: 10px 10px 12px 10px;
  }

  .desktop-top-nav {
    background-color: $sb-white;
    background-color: var(--sb-white);
    box-shadow: 0 2px 4px 1px $sb-black-10;
    box-shadow: 0 2px 4px 1px var(--sb-black-10);
    margin: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  .sticky-footer {
    z-index: 8;
  }

  .desktop-menu-container {
    width: 184px;
    display: inline-block;
    float: left;
    min-height: 600px;
    position: fixed;
    margin-top: 84px;
    margin-bottom: 20px;
    border: 1px solid $sb-grey-88;
    border: 1px solid var(--sb-grey-88);
    border-radius: 5px;
  }

  .more-menu-container {
    background-color: $sb-white;
    background-color: var(--sb-white);
    border-radius: 5px;
    box-shadow: 0 2px 4px $custom-color-137;
    box-shadow: 0 2px 4px var(--custom-color-137);
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .feedback-container {
    padding: 12px !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center;
  }

  .more-sub-menu-icon {
    height: 16px !important;
    width: 16px !important;
    float: left;
  }

  .more-sub-menu {
    font-size: 14px;
    line-height: 16px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .desktop-menu-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .desktop-menu-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .content-wrapper {
    background: $sb-white;
    background: var(--sb-white);
    padding: 68px 0 30px 0;
    display: inline-block;
    float: left;
    margin-left: 208px;
    width: calc(100% - 208px);
  }

  .content-wrapper-without-sidebar {
    background: $sb-white;
    background: var(--sb-white);
    //display: inline-block;
    float: left;
    width: 60%;
    width: 100%;
    margin-top: 55px;
    padding: 30px;
  }

  .content-wrapper-without-sidebar-v5 {
    padding: 6px 0 0 0;
  }

  .content-wrapper-without-sidebar-center-aligned {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .app-bar-back-button-container {
      width: 100%;
      max-width: 620px;
    }
  }

  .content-container {
    width: 100%;
    min-width: 400px;
    max-width: $container-max-width;
    float: left;
    margin-right: 12px;
    .snotifcation-card {
      margin: 10px;
    }
  }

  .nudge-container {
    width: 100%;
    max-width: $container-max-width;
    display: inline-block;
    float: left;
    padding: 0;
    margin-top: 14px;
  }

  .nudge-container-height {
    height: 150px;
  }

  .gradient-card-icon {
    max-width: 120px;
  }

  #app-container-heading {
    min-width: 400px;
    max-width: $container-max-width;
  }

  .app-bar__back-button {
    align-items: center;
    color: $sb-menu-icon;
    color: var(--sb-menu-icon);
    display: flex;
    font-size: 17px;
    left: 275px;
    margin: 9px 4px 4px -6px;
    padding-left: 0;
    padding-right: 0;
    z-index: 9;
  }

  .card-outline-btn,
  .scard-cta-button {
    float: left;
  }

  .card-outline-btn {
    width: auto !important;
    margin: 0 auto !important;
    padding: 12px 30px !important;
    text-align: left;
  }

  .btn-custom {
    border: 1px solid $sb-primary-btn-default;
    border: 1px solid var(--sb-primary-btn-default);
  }

  .secondary-btn,
  .scard-cta-button,
  .btn-custom {
    border-radius: 8px !important;
    padding: 8px 16px !important;
    height: auto !important;
    width: auto !important;
    font-size: $font-20;
    min-width: 200px;
    display: inline-block;
  }

  .secondary-btn-full {
    border-radius: 8px !important;
    padding: 8px 16px !important;
    height: auto !important;
    width: 100% !important;
    font-size: $font-20;
    min-width: 200px;
    display: inline-block;
  }

  .modal-custom-btn,
  .modal-secondary-btn {
    width: 100% !important;
    font-size: $font-16 !important;
  }

  .card-button-container {
    text-align: center;
  }

  .sticky-footer {
    position: initial;
    min-width: 400px;
    max-width: $container-max-width;
    background-color: inherit;
    text-align: left;
    border-top: none;
    height: auto;
    clear: both;
    margin: 20px 0;

    .row {
      .col {
        width: auto;
        margin: 10px;
        border-right: none;
        display: inline-block;
        float: none;
      }
    }

    &.desktop-center {
      text-align: center;
    }
  }

  .full-height-container.dashboard-container {
    height: auto;
  }

  .desktop-secondary-menu-item-container li {
    a:hover {
      background: transparent;
    }
  }

  #main {
    width: 100%;
    height: 100%;
  }

  select.browser-default {
    line-height: 26px;
  }

  .main-container {
    width: 100%;
  }

  .desktop-container {
    // width: 400px;
    background: $sb-white;
    background: var(--sb-white);
  }

  .desktop-logo-container {
    min-width: 250px;
    display: inline-block;
  }

  .desktop-logo {
    text-align: center;
    margin: 10px 20px 10px 0;
  }

  .desktop-top-nav {
    text-align: left;
  }

  .footer-link-list {
    position: absolute;
    bottom: 10px;
    left: 30px;

    a {
      color: $sb-white;
      color: var(--sb-white);
    }
  }

  .footer-link {
    display: inline-block;
    margin-left: 15px;
    font-size: 13px;
    text-shadow: 3px 3px 10px $sb-vanta-black;
    text-shadow: 3px 3px 10px var(--sb-vanta-black);
  }

  .login-wrapper {
    min-height: 100vh;
    height: auto;

    .secondary-btn,
    .btn-custom {
      width: 100% !important;
      font-size: $font-16 !important;
    }
  }

  .destkop-header-space {
    padding-top: 90px;
  }

  .basic-details-container {
    .sticky-footer {
      min-width: auto;
    }
  }

  .investment-calendar .scard-container {
    margin-left: 0;
  }

  .signup-initial-container {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    background: $sb-white;
    background: var(--sb-white);
    border-radius: 8px;
    padding: 20px 30px;
    margin-bottom: 30px;
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    border: 0.5px solid $custom-color-113;
    border: 0.5px solid var(--custom-color-113);

    .content-container,
    #app-container-heading {
      max-width: 400px;
      width: 100%;
      min-width: 0;
      float: none;
    }
  }

  .align-vertical-center {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .forgotPasswordContainer,
  .loginContainer {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    background: $sb-white;
    background: var(--sb-white);
    border-radius: 8px;
    padding: 20px 0;
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    border: 0.5px solid $custom-color-113;
    border: 0.5px solid var(--custom-color-113);
  }

  .desktop-side-nav li:hover,
  .desktop-side-nav li.active {
    background-color: $custom-color-44;
    background-color: var(--custom-color-44);
  }

  .menu-list .divider {
    display: none;
  }

  .profile-menu-content {
    text-align: left;

    a,
    span.menu-span {
      padding: 10px 0 10px 16px;
      display: block;

      i,
      div.svg-class {
        padding-bottom: 0;
        font-size: $font-25;
        color: $sb-menu-icon;
        color: var(--sb-menu-icon);
        margin-left: 16px;
      }

      .side-nav-text,
      .side-nav-text:active,
      &:visited,
      &:link,
      &:focus {
        color: $sb-black-50;
        color: var(--sb-black-50);
      }
    }

    .highlight {
      .row {
        background: $custom-color-225;
        background: var(--custom-color-225);
        font-weight: 600;
      }

      .side-nav-text {
        color: $sb-black-86 !important;
        color: var(--sb-black-86) !important;
      }

      .col.s2 .material-icons,
      i {
        color: $sb-black-86 !important;
        color: var(--sb-black-86) !important;
      }

      .icon-style {
        filter: invert(60%) sepia(83%) saturate(2302%) hue-rotate(345deg) brightness(99%) contrast(94%);
      }
    }

    .sub-side-nav-text {
      color: $sb-black-50;
      color: var(--sb-black-50);
      display: block;
      font-size: $font-14;
      font-weight: normal;
      line-height: normal;
      margin-right: 16px;
      padding-left: 13px;
    }

    .sub-side-nav-divider {
      border-left: 1px solid $sb-grey-88;
      border-left: 1px solid var(--sb-grey-88);
      bottom: 45%;
      margin-top: -30px;
      position: absolute;
      top: 10%;
    }

    .feedbackText {
      padding-left: 12px;
    }

    .side-nav-li-bg-active {
      background-color: $custom-color-225;
      background-color: var(--custom-color-225);
      cursor: pointer;
    }

    .side-nav-li-text-active {
      color: $sb-black-86 !important;
      color: var(--sb-black-86) !important;
      vertical-align: middle;
      font-weight: normal;
    }

    .desktop-secondary-menu-item-container {
      .highlight.row {
        background: transparent;

        .side-nav-text,
        i {
          color: $sb-black;
          color: var(--sb-black);
        }
      }
    }
  }

  .desktop-menu {
    text-align: left;

    a,
    span.menu-span {
      padding: 12px;
      display: block;

      i,
      div.svg-class {
        padding-bottom: 0;
        font-size: $font-25;
        color: $sb-menu-icon;
        color: var(--sb-menu-icon);
        margin-left: 16px;
      }

      .side-nav-text,
      .side-nav-text:active,
      &:visited,
      &:link,
      &:focus {
        color: $sb-black-50;
        color: var(--sb-black-50);
      }

      &:hover {
        background-color: $custom-color-13;
        background-color: var(--custom-color-13);
      }
    }

    .highlight {
      .row {
        background: $custom-color-225;
        background: var(--custom-color-225);
        font-weight: 600;
      }

      .side-nav-text {
        color: $sb-black-86 !important;
        color: var(--sb-black-86) !important;
      }

      .col.s2 .material-icons,
      i {
        color: $sb-black-86 !important;
        color: var(--sb-black-86) !important;
      }

      .icon-style {
        filter: invert(60%) sepia(83%) saturate(2302%) hue-rotate(345deg) brightness(99%) contrast(94%);
      }
    }

    .sub-highlight {
      .sub-side-nav-text {
        color: $sb-orange;
        color: var(--sb-orange);
      }
    }

    .sub-side-nav-text {
      color: $sb-black-50;
      color: var(--sb-black-50);
      display: block;
      font-size: $font-14;
      font-weight: normal;
      line-height: normal;
      margin-right: 16px;
      padding-left: 12px;
    }

    .sub-side-nav-divider {
      border-left: 1px solid $sb-grey-88;
      border-left: 1px solid var(--sb-grey-88);
      bottom: 45%;
      margin-top: -30px;
      position: absolute;
      top: 10%;
    }

    .feedbackText {
      padding-left: 16px;
    }

    .side-nav-li-bg-active {
      background-color: $custom-color-225;
      background-color: var(--custom-color-225);
      cursor: pointer;
    }

    .side-nav-li-text-active {
      color: $sb-black-86 !important;
      color: var(--sb-black-86) !important;
      vertical-align: middle;
      font-weight: normal;
    }

    .desktop-secondary-menu-item-container {
      .highlight.row {
        background: transparent;

        .side-nav-text,
        i {
          color: $sb-black;
          color: var(--sb-black);
        }
      }
    }
  }

  .sub-menu {
    .sub-menu__trigger {
      .col.s10,
      .col.s12 {
        padding: 0;
      }

      .col.s2 .material-icons {
        margin-top: 10px;
        cursor: pointer;
        color: $custom-color-11;
        color: var(--custom-color-11);
      }
    }

    .sub-menu__trigger .row:hover {
      background: $custom-color-13;
      background: var(--custom-color-13);
    }
  }

  .desktop-menu-label {
    display: inline-block !important;
    font-size: 11px;
    color: $sb-white;
    color: var(--sb-white);
    text-align: left;
  }

  .side-nav-text {
    vertical-align: text-top;
    font-weight: normal;
    cursor: pointer;
  }

  .margin-left {
    margin-left: 14px;
  }

  .menu-no-dropdown-text-style {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 32px;
  }

  .no-dropdown {
    vertical-align: text-top;
  }

  .menu-icon-style {
    width: 20px;
    height: 20px;
    float: left;

    .profile-menu-icon {
      height: 20px;
      width: 20px;
    }
  }

  .menu-dropdown-text-style {
    max-height: 50px;
    margin-left: 32px;
  }

  #app-container-heading {
    box-shadow: none;
    padding: 5px 0;
    border-radius: 4px 4px 0 0;
  }

  .login {
    max-width: 524px;
    margin: 0 auto;
  }

  #app-container-heading .app-container-heading-text {
    padding: 10px;
    text-align: center;
  }

  .desktop-collection {
    background: $sb-white;
    background: var(--sb-white);
    border: none;
  }

  .investment-plan-transactions-container,
  .investment-plan-transactions-container .desktop-collection-item,
  .account-statement-container .desktop-collection-item {
    background: $sb-white;
    background: var(--sb-white);
  }

  .desktop-flat-btn {
    font-size: $font-12;
    height: 40px;
  }

  .desktop-cursor {
    cursor: pointer;
  }

  .desktop-vertical-icon {
    margin-top: 7px;
  }

  .account-statement-container {
    .account-statement-load-more {
      padding-bottom: 10px;
      color: $sb-orange;
      color: var(--sb-orange);
    }
  }

  .desktop-dropdown {
    vertical-align: middle;
  }

  .ReactModalPortal,
  .sticky-footer {
    // width: 370px;
    // left: 15px;
    // position: absolute;
    // bottom: 15px;
  }

  #sidenav-overlay {
    background-color: transparent;
  }

  .content-container {
    // height: 460px;
    // overflow-y: auto;
  }

  .content-container.dashboard-content-container {
    // height: 520px;
    // overflow-y: auto;
  }

  .full-height-container {
    // height: 545px;
  }

  .desktop-side-nav .collection {
    margin: 0;
    border: none;
  }

  .desktop-side-nav {
    box-shadow: none;
    max-width: 248px;
  }

  #main .notification-icon {
    right: 440px !important;
    top: 17px !important;
    position: absolute !important;

    .btn-floating {
      background-color: transparent;
      box-shadow: none;
    }

    .sb-notification-container {
      background-color: transparent;
    }
  }

  .image-uploader-container {
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
  }

  .image-upload-wrapper {
    position: relative;
  }

  #toast-container {
    margin: 0 auto;
    max-width: 400px;
    max-height: 30px;
    top: 6%;
  }

  #toast-container .toast {
    float: none;
    background: $sb-white;
    background: var(--sb-white);
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
    border-radius: 3px;
    color: $sb-charcoal;
    color: var(--sb-charcoal);
    -webkit-box-shadow: -33px 21px 109px 0 $sb-black-15;
    -webkit-box-shadow: -33px 21px 109px 0 var(--sb-black-15);
    -moz-box-shadow: -33px 21px 109px 0 $sb-black-15;
    -moz-box-shadow: -33px 21px 109px 0 var(--sb-black-15);
    box-shadow: -33px 21px 109px 0 $sb-black-15;
    box-shadow: -33px 21px 109px 0 var(--sb-black-15);
  }

  .modal-footer .modal-sticky-footer {
    position: fixed;
    padding: 2px 25px;
    max-width: 500px;
    margin-left: 0;
  }

  .app-bar-left-button {
    text-align: left;
  }

  a:hover {
    cursor: pointer;
  }

  .dropdown-arrow-container {
    float: right;
    padding-top: 3px;
  }

  .desktop-dropdown-icon {
    color: $sb-black-40;
    color: var(--sb-black-40);
    font-size: $font-24;
    height: 22px;
    margin-left: 10px;
    margin-top: 1px;
    width: 22px;
  }

  .app-bar-left-button .back-button-icon-desktop {
    font-size: 40px;
  }

  .app-bar {
    position: fixed;
    z-index: 10;
    border-bottom: 1px solid $sb-gray;
    border-bottom: 1px solid var(--sb-gray);
    box-shadow: none;
  }

  .desktop-active-investor-menu {
    margin-top: 12px;

    .sb-avatar {
      background-color: $sb-white;
      background-color: var(--sb-white);
      color: $sb-menu-icon;
      color: var(--sb-menu-icon);
    }
  }

  .sb-avatar.app-bar__avatar {
    align-items: center;
    background-color: $sb-orange;
    background-color: var(--sb-orange);
    color: $sb-white;
    color: var(--sb-white);
    display: flex;
    justify-content: center;
    line-height: 32px !important;
    font-size: 14px !important;
  }

  #main .notification-icon {
    display: inline-block;
    z-index: 8;
    height: 40px;
    width: 40px;
  }

  .desktop-no-row-margin {
    margin: 0;
  }

  .new-container {
    // max-height: 600px;
    // overflow-y: auto;
    background-color: $sb-white;
    background-color: var(--sb-white);
    // max-width: 400px;
    // margin: 0 auto;
    // border-radius: 4px;
  }

  .strike-through-login {
    text-align: center;
    border-bottom: 1px solid $custom-color-122;
    border-bottom: 1px solid var(--custom-color-122);
    width: 30%;
    line-height: 0.1em;
    margin: 30px auto 30px auto;

    & span {
      padding: 3px 10px;
      font-weight: bold;
      margin-top: 10px;
      background-color: $sb-white;
      background-color: var(--sb-white);
    }
  }

  .ipv-container {
    margin-bottom: 60px;
  }

  .btn-custom.custom-capsule-button {
    border: 1px solid $sb-gray;
    border: 1px solid var(--sb-gray);
  }

  .hide-on-desktop {
    display: none;
  }

  .desktop-center {
    text-align: center;
  }

  .desktop-profile-switcher {
    color: $sb-white;
    color: var(--sb-white);
    display: flex;
    justify-content: flex-end;
    text-align: left;

    ul {
      margin: 0;
    }
  }

  .desktop-investor-menu-wrapper {
    position: absolute;
    min-width: 185px;
    z-index: 100;
    border: 1px solid $custom-color-17;
    border: 1px solid var(--custom-color-17);
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    border-top: none;
    max-height: 500px;
    overflow: auto;
  }

  .desktop-profile-wrapper {
    position: absolute;
    width: 276px;
    z-index: 100;
    border: 1px solid $custom-color-17;
    border: 1px solid var(--custom-color-17);
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    border-top: none;
    height: 480px;
    background: $sb-white;
    background: var(--sb-white);
    overflow: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .desktop-profile-wrapper::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .desktop-profile-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .profile-menu-layout {
    border-radius: 5px;
    margin-top: 5px;
    right: -10px;
  }

  .desktop-help-desk-menu-wrapper {
    position: absolute;
    right: 0;
    width: 270px;
    border-radius: 5px;
    z-index: 100;
    border: 1px solid $custom-color-17;
    border: 1px solid var(--custom-color-17);
    box-shadow: -2px 2px 13px 0 $custom-color-142;
    box-shadow: -2px 2px 13px 0 var(--custom-color-142);
    border-top: none;
    max-height: 500px;
    overflow: auto;
    margin-top: 16px;
  }

  .desktop-logout-container {
    color: $sb-white;
    color: var(--sb-white);
    padding: 10px;
    margin-top: 10px;
    display: inline-block;
    vertical-align: middle;

    & i {
      color: $sb-white;
      color: var(--sb-white);
    }

    .side-nav-text {
      color: $sb-white;
      color: var(--sb-white);
    }

    .old-version-header-icon {
      color: $sb-menu-gray;
      color: var(--sb-menu-gray);
    }

    .old-version-header-font {
      color: $sb-black-86;
      color: var(--sb-black-86);
      margin-left: 10px;
    }
  }

  .desktop-collection-item.investor-menu-list {
    background-color: $sb-white !important;
    background-color: var(--sb-white) !important;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: $custom-color-17 !important;
      background-color: var(--custom-color-17) !important;
    }
  }

  .desktop-collection-item.profile-menu-list {
    padding: 10px 16px;
  }

  .desktop-collection-item.helpdesk-menu-list {
    background-color: $sb-white !important;
    background-color: var(--sb-white) !important;
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
      background-color: $sb-ui-blue-light-2 !important;
      background-color: var(--sb-ui-blue-light-2) !important;
    }
  }

  .white-button {
    border: 1px solid $sb-orange;
    border: 1px solid var(--sb-orange);
  }

  .desktop-active-investor-container {
    display: flex;
    align-items: center;
  }

  .desktop-active-investor-name-container {
    align-items: center;
    display: flex;
  }

  .desktop-active-investor-name {
    color: $sb-vanta-black;
    color: var(--sb-vanta-black);
    font-size: $font-16;
    font-weight: bold;
    float: left;
    line-height: 19px;
    padding: 0 3px 0 12px;
  }

  .signup-container .app-container-heading-text {
    max-width: none;
  }

  .login {
    top: auto;
    transform: none;

    .home-login-button {
      margin-top: 35px;
    }

    .home-signup-button {
      background: $sb-white;
      background: var(--sb-white);
      border: 1px solid $sb-blue;
      border: 1px solid var(--sb-blue);
      color: $sb-blue;
      color: var(--sb-blue);
      margin-top: 10px;
    }

    .home-action-divider {
      width: 40px;
      height: 1px;
      background: $custom-color-70;
      background: var(--custom-color-70);
      margin: 35px auto 25px auto;
    }

    .home-action-divider-title {
      font-size: $font-14;
    }
  }

  .desktop-home-top-nav {
    position: absolute;
    left: 200px;
    top: 20px;

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 30px;

        a {
          color: $sb-white;
          color: var(--sb-white);
          font-size: $font-20;

          &:hover {
            border-bottom: 3px solid $sb-orange;
            border-bottom: 3px solid var(--sb-orange);
          }
        }
      }
    }
  }

  .material-icons.desktop-investor-menu-family-icon {
    font-size: $font-36;
    color: $sb-menu-gray;
    color: var(--sb-menu-gray);
    position: inherit !important;
  }

  .row.desktop-investor-list-item {
    margin: 0;
  }

  .material-tooltip {
    background: $sb-black-90;
    background: var(--sb-black-90);
    overflow: visible;
    padding: 10px !important;
  }

  .material-tooltip .backdrop {
    display: none !important;
  }

  .material-tooltip::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid $sb-black-90;
    border-left: 5px solid var(--sb-black-90);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  .investment-card .right-arrow {
    text-align: right;
    display: block;
  }

  .freshwidget-customimage {
    margin-bottom: 20px;
    position: absolute;
    right: 100px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: $custom-color-71;
    background-color: var(--custom-color-71);
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $sb-black-30;
    -webkit-box-shadow: inset 0 0 6px var(--sb-black-30);
    background-color: $custom-color-71;
    background-color: var(--custom-color-71);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px $sb-black-30;
    -webkit-box-shadow: inset 0 0 6px var(sb-black-30);
    background-color: $custom-color-59;
    background-color: var(--custom-color-59);
  }

  #olark-wrapper {
    .olark-launch-button {
      width: 68px !important;
      bottom: 55px !important;
      left: 45px !important;
      transition: none !important;

      &:hover {
        bottom: 57px !important;
      }
    }

    .olark-launch-button .olark-button-text {
      display: none !important;
    }

    #olark-container {
      left: 20px !important;
      right: auto !important;
    }
  }

  // .content-container .ipv-recorder-container,
  .recorder {
    max-width: 340px;
  }

  .investment-intro-card {
    padding: 5px 3px;
  }

  .hidden-menu-for-signup {
    display: none;
  }

  .red-error-block {
    a {
      color: $sb-black !important;
      color: var(--sb-black) !important;
      font-weight: 600;
    }
  }

  .no-border-padding-shadow {
    box-shadow: none !important;
    padding: 0 !important;
    border: 0 !important;
  }

  .right-align-lg {
    text-align: right;
  }

  .font-14-lg {
    font-size: $font-14 !important;
  }

  .no-left-right-space-lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .investment-history {
    padding-top: 0 !important;
  }

  .plans-page-card {
    padding: 1px;
    border: 1px solid $sb-light-gray;
    border: 1px solid var(--sb-light-gray);
    border-radius: 5px;
  }

  .switcher-tabs-display {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .switcher-tabs-display::-webkit-scrollbar {
    width: 5px;
  }

  .switcher-tabs-display::-webkit-scrollbar-track {
    background: $sb-white;
    background: var(--sb-white);
    box-shadow: none;
    border-radius: 2px;
  }

  .switcher-tabs-display::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  .switcher-tabs-display:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .tabs-container .tab {
    margin: auto 12px;
  }
}

@media only screen and (min-width: 1310px) {
  .nudge-container {
    max-width: 276px;
    margin-left: 12px;
  }

  .nudge-container-height {
    height: 250px;
  }

  .schedule-call-nudge {
    .call-wrapper {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .call-illustration-container {
      align-self: flex-end;
      .call-illustration {
        max-width: 96px;
      }
    }
  }
}

@media screen and (min-width: 1800px) {
  #main {
    .new-container {
      // height: 700px;
      // width: 450px;
      // max-width: 450px;
      // max-height: 700px;
    }

    .content-container {
      // height: 575px;
    }

    .content-container.dashboard-content-container {
      // height: 635px;
    }

    .full-height-container {
      // height: 625px;
    }

    .login {
      margin-top: 30px;
    }

    .home-action-divider {
      margin: 55px auto 25px auto;
    }

    .sticky-footer {
      // width: 420px;
    }

    .notification-icon {
      right: 490px !important;
    }

    .content-container .ipv-recorder-container,
    .recorder {
      max-width: 400px;
    }
  }

  .nri-signup-link {
    margin-top: 50px;
  }

  .benchmark-section {
    padding-top: 20px;
  }
}

@media only screen and (min-height: 700px) and (min-width: 993px) {
  .footer-position {
    display: flex;
    height: 590px;
    //min-height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 576px) {
  .education-plan-graph-block {
    .education-plan-progress-block {
      padding: 14px 0;
    }

    .education-plan-college-year {
      font-size: $font-14;
    }
  }

  .nudge-container-height {
    height: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .full-width-on-mobile {
    width: 100%;
  }

  .graph-note {
    margin-bottom: 8px;
  }

  .education-plan-graph-amount {
    display: inline-block;
    float: right;

    .amount-value-title {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-device-width: 370px) {
  .money-types-container,
  .fund-type-card-intro-container {
    .money-banner-icon {
      max-width: 90px;
      bottom: 2px !important;
      max-height: 100px;
    }

    .money-banner-short-term {
      max-width: 90px;
      bottom: 4px !important;
      max-height: 100px;
    }
  }

  .fund-type-card-banner {
    min-height: 125px !important;
  }
}

.new-label {
  background-color: $sb-hover-grey-bg !important;
  background-color: var(--sb-hover-grey-bg) !important;
  border-radius: 11px;
  color: $sb-purple !important;
  font-size: $font-14;
  line-height: 14px;
  margin: auto 7px !important;
  padding: 1px 5px;
}
