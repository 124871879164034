@import "../utils/all";

.investment-history-stats {
  .stats-heading {
    font-size: $font-16;
    font-weight: 600;
  }

  .stats-subheading {
    font-size: $font-16;
  }

  .parameter-group {
    margin: 15px 0;
    font-size: $font-16;
  }

  .parameter-group:last-child {
    margin-bottom: 0;
  }

  .secondary-param {
    margin-top: -10px;

    .secondary-param-label {
      margin-left: 10px;
    }
  }
}
