.search-fund-btn {
  .search-btn {
    background: $sb-white;
    background: var(--sb-white);
    border: 2px solid $sb-grey-88;
    border: 2px solid var(--sb-grey-88);
    box-sizing: border-box;
    border-radius: 5px;
    text-align: left;
    padding: 10px 15px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
  }

  button:focus {
    background-color: $sb-white;
    background-color: var(--sb-white);
  }

  .browse-tag-card {
    height: 80px;
    background: $sb-white;
    background: var(--sb-white);
    border: 1px solid $sb-grey-88;
    border: 1px solid var(--sb-grey-88);
    box-shadow: 0 2px 4px $custom-color-137;
    box-shadow: 0 2px 4px var(--custom-color-137);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
}

.remove-fund-from-busket {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}
