.sb-avatar {
  border-radius: 8%;
  -webkit-border-radius: 8%;
  -moz-border-radius: 8%;
  display: inline-block;
  background-color: $sb-menu-icon;
  background-color: var(--sb-menu-icon);
  text-align: center;
  color: $sb-white;
  color: var(--sb-white);
}

.stacked-avatar {
  border-radius: 6px;
  border: 0.5px solid;
}

.stacked-count {
  opacity: 0.7;
  background-color: $sb-vanta-black;
  background-color: var(--sb-vanta-black);
}
