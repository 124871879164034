@import "../../../styles/utils/all";
@import "../../../styles/utils/colors";

.side-menu-bar-footer-container {
  display: flex;
  justify-content: space-evenly;
  margin: 8px 0px 12px 0px;
}

.side-menu-bar-footer-ios-container {
  align-items: flex-start;
  display: flex !important;
  height: 14px !important;
  margin: 0 !important;
  padding: 0 !important;
  &:hover {
    background-color: transparent !important;
  }
}

.side-menu-bar-footer-divider {
  background-color: $sb-grey-88;
  background-color: var(--sb-grey-88);
  height: 14px;
  width: 1px;
}

.side-menu-bar-footer-android-container {
  @extend .side-menu-bar-footer-ios-container;
  align-items: center;
}

.side-menu-bar-footer-ios-text {
  font-size: $font-12;
  font-weight: normal;
  line-height: 15px;
  margin-left: 7px;
  margin-top: 1px;
  cursor: pointer;
  &:hover {
    color: $sb-vanta-black !important;
    color: var(--sb-vanta-black) !important;
  }
}

.side-menu-bar-footer-android-text {
  @extend .side-menu-bar-footer-ios-text;
  margin-top: 2px;
}

.side-menu-bar-footer-icon {
  height: 13px;
  width: 13px;
}

.side-menu-bar-footer-text {
  color: $sb-opacity-5;
  color: var(--sb-opacity-5);
  display: flex;
  font-size: $font-12;
  font-weight: normal;
  justify-content: center;
  line-height: 14px;
}

.side-menu-bar-footer-sub-text {
  font-size: $font-10;
  font-weight: bold;
  line-height: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (min-width: 993px) {
  .side-menu-bar-footer {
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
}

@media only screen and (max-width: 992px) {
  .side-menu-bar-footer {
    margin-bottom: 60px;
  }
}
