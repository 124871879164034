@import "../utils/all";

#offering-header {
  margin: 15px 10px;
  line-height: 1.2;

  .offering-image {
    max-height: 50px;
  }

  .offering-header-container {

    .offering-header-header-text {
      font-size: $font-28;
      color: $card-layout-primary-text;
      color: var(--card-layout-primary-text);
      font-weight: 600;
    }

    .offering-header-sub-header-text {
      font-size: $font-14;
    }
  }
}

@media only screen and (min-width: 992px) {
  #offering-header {
    margin-top: 30px;

    .offering-header-container {
      padding-top: 0.5rem;
    }
  }
}
