@import "../utils/all";
@import "../utils/colors";

.btn-custom {
  text-align: center;
  font-size: $font-20;
  display: block;
  width: 100%;
  color: $sb-white-fixed;
  color: var(--sb-white-fixed);
  background-color: $sb-primary-btn-default;
  background-color: var(--sb-primary-btn-default);
  border: none;
  height: 53px;
  border-radius: 0;
  text-transform: uppercase;

  &:focus,
  &:active {
    color: $sb-white-fixed;
    color: var(--sb-white-fixed);
    background-color: $sb-primary-btn-focus;
    background-color: var(--sb-primary-btn-focus);
  }

  &:hover {
    color: $sb-white-fixed;
    color: var(--sb-white-fixed);
    background-color: $sb-primary-btn-hover;
    background-color: var(--sb-primary-btn-hover);
  }

  &.sb-small-btn {
    width: auto;
    height: auto;
    font-size: $font-16;
    border: none;
    padding: 8px 16px !important;
    margin: 10px auto;
  }

  &.download-disabled-button {
    color: $sb-disable-gray;
    color: var(--sb-disable-gray);
    cursor: default;
    background-color: $sb-white;
    background-color: var(--sb-white);
    border: 1px solid $sb-disable-gray;
    border: 1px solid var(--sb-disable-gray);
  }

  &.btn-disabled {
    color: $sb-white;
    color: var(--sb-white);
    cursor: not-allowed;
    border: 1px solid $sb-primary-btn-disabled;
    border: 1px solid var(--sb-primary-btn-disabled);
    background-color: $sb-primary-btn-disabled;
    background-color: var(--sb-primary-btn-disabled);
  }
}

.btn-disabled {
  color: $sb-secondary-disabled-color;
  color: var(--sb-secondary-disabled-color);
  cursor: not-allowed;
}

.secondary-btn,
.secondary-btn-full {
  text-align: center;
  font-size: $font-20;
  display: block;
  width: 100%;
  color: $sb-secondary-default-color;
  color: var(--sb-secondary-default-color);
  background-color: $sb-white;
  background-color: var(--sb-white);
  height: 53px;
  border: none;
  border-radius: 0;
  text-transform: uppercase;

  &:focus,
  &:active {
    color: $sb-secondary-default-color;
    color: var(--sb-secondary-default-color);
    background-color: $custom-color-13;
    background-color: var(--custom-color-13);
  }

  &:hover {
    color: $sb-secondary-default-color;
    color: var(--sb-secondary-default-color);
    background-color: $sb-light-gray;
    background-color: var(--sb-light-gray);
  }

  &.btn-disabled {
    color: $sb-secondary-disabled-color;
    color: var(--sb-secondary-disabled-color);
    cursor: not-allowed;
    background-color: $sb-secondary-disabled-background;
    background-color: var(--sb-secondary-disabled-background);
  }

  &.with-border {
    border: 1px solid $sb-secondary-default-border;
    border: 1px solid var(--sb-secondary-default-border);
  }
}

.modal-custom-btn {
  text-align: center;
  font-size: $font-20;
  display: block;
  width: 100%;
  color: $sb-white;
  color: var(--sb-white);
  background-color: $sb-primary-btn-default;
  background-color: var(--sb-primary-btn-default);
  border: none;
  height: 53px;
  border-radius: 0;
  text-transform: uppercase;

  &:focus,
  &:active {
    background-color: $sb-primary-btn-focus;
    background-color: var(--sb-primary-btn-focus);
  }

  &.btn-disabled {
    color: $sb-white;
    color: var(--sb-white);
    cursor: not-allowed;
    border: 1px solid $sb-primary-btn-disabled;
    border: 1px solid var(--sb-primary-btn-disabled);
    background-color: $sb-primary-btn-disabled;
    background-color: var(--sb-primary-btn-disabled);
  }
}

.modal-secondary-btn {
  text-align: center;
  font-size: $font-20;
  display: block;
  width: 100%;
  color: $sb-secondary-default-color;
  color: var(--sb-secondary-default-color);
  background-color: $sb-white;
  background-color: var(--sb-white);
  height: 53px;
  border: 2px solid $sb-light-gray;
  border: 2px solid var(--sb-light-gray);
  border-radius: 0;
  text-transform: uppercase;
  margin-top: 10px;

  &:focus,
  &:active {
    background-color: $custom-color-13;
    background-color: var(--custom-color-13);
  }

  &.btn-disabled {
    color: $sb-secondary-disabled-color;
    color: var(--sb-secondary-disabled-color);
    cursor: not-allowed;
    background-color: $sb-secondary-disabled-background;
    background-color: var(--sb-secondary-disabled-background);
  }
}

.white-button {
  text-align: center;
  margin: 0 auto;
  font-size: $font-20;
  display: block;
  width: 100%;
  max-width: 280px;
  color: $sb-black;
  color: var(--sb-black);
  background-color: $sb-white;
  background-color: var(--sb-white);
  border: 1px solid $sb-orange;
  border: 1px solid var(--sb-orange);
  border-radius: 2px;
  height: 53px;
  text-transform: uppercase;

  &:focus,
  &:active,
  &:hover {
    background-color: $sb-white;
    background-color: var(--sb-white);
    color: $sb-black;
    color: var(--sb-black);
  }
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  z-index: 99;
  height: 53px;
  margin-bottom: 0;
  text-align: center;
  line-height: 1.3;
  background: white;

  &.height-auto {
    height: auto;
  }
}

.icon-color-style {
  color: $sb-black;
  color: var(--sb-black);
}

.button-divider {
  border: none;
}

.filter-btn {
  border: 1px dotted $sb-menu-icon;
  border: 1px dotted var(--sb-menu-icon);
  color: $sb-menu-icon;
  color: var(--sb-menu-icon);
  width: 100%;
  padding: 0;
  font-size: $font-10;
  text-transform: uppercase;

  &:focus,
  &:active,
  &:hover,
  &.filter-btn-selected {
    background-color: $sb-light-gray;
    background-color: var(--sb-light-gray);
    color: $sb-black;
    color: var(--sb-black);
  }

  &.success {
    background-color: $sb-primary-btn-hover;
    background-color: var(--sb-primary-btn-hover);
    color: $sb-white;
    color: var(--sb-white);
  }
}

.amt-capsule {
  height: 50px;
  border: 1px dotted $sb-menu-icon;
  border: 1px dotted var(--sb-menu-icon);
  color: $sb-menu-icon;
  color: var(--sb-menu-icon);

  .amt-capsule-block-container {
    width: 100%;
  }

  &.amt-capsule-selected {
    background-color: $sb-white;
    background-color: var(--sb-white);
    color: $sb-black;
    color: var(--sb-black);
  }
}

.aadhaar-link-button {
  border: 1px solid $sb-gray;
  border: 1px solid var(--sb-gray);
  border-radius: 2px;
  padding: 10px 20px;
  text-align: center;
  background: none;
  color: $sb-orange;
  color: var(--sb-orange);
  width: 100%;
  margin: 20px auto;
  display: block;
  text-transform: uppercase;

  &.default-aadhaar-btn {
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: $sb-orange;
      color: var(--sb-orange);
      background-color: $custom-color-42;
      background-color: var(--custom-color-42);
    }
  }
}

.termbutton {
  border: none;
  background: none;
  color: $sb-black;
  color: var(--sb-black);
  display: inline-block;
  padding: 0;
  margin: 0;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    color: $sb-black;
    color: var(--sb-black);
    background: none;
    border: none;
  }
}

.card-button-container {
  margin: 10px;
}

.btn-card-radius {
  border-radius: 8px;
  padding: 8px 0;
  height: auto;
}

.card-outline-btn {
  text-align: center;
  font-size: $font-16;
  font-weight: 600;
  width: 100%;
  display: block;
  padding: 6px;
  color: $card-layout-primary-text;
  color: var(--card-layout-primary-text);
  background-color: $sb-white;
  background-color: var(--sb-white);
  border-radius: 8px;
  border: 1px solid $card-layout-primary-text;
  border: 1px solid var(--card-layout-primary-text);
  text-transform: uppercase;

  &:focus {
    background-color: $sb-white;
    background-color: var(--sb-white);
  }
}

.btn-add-fund {
  text-align: center;
  font-size: $font-14;
  display: block;
  padding: 8px 32px;
  color: $sb-white;
  color: var(--sb-white);
  background-color: $sb-primary-btn-default;
  background-color: var(--sb-primary-btn-default);
  border: none;
  border-radius: 6px;
  text-transform: uppercase;

  &:focus,
  &:active {
    color: $sb-white;
    color: var(--sb-white);
    background-color: $sb-primary-btn-focus;
    background-color: var(--sb-primary-btn-focus);
  }

  &:hover {
    color: $sb-white;
    color: var(--sb-white);
    background-color: $sb-primary-btn-hover;
    background-color: var(--sb-primary-btn-hover);
  }
}

@media only screen and (max-width: 560px) {
  .btn-custom {
    border-top: 1px solid $sb-primary-btn-default;
    border-top: 1px solid var(--sb-primary-btn-default);

    &:focus,
    &:active {
      border-top: 1px solid $sb-primary-btn-focus;
      border-top: 1px solid var(--sb-primary-btn-focus);
    }

    &:hover {
      border-top: 1px solid $sb-primary-btn-hover;
      border-top: 1px solid var(--sb-primary-btn-hover);
    }
  }

  .secondary-btn,
  .secondary-btn-full {
    border-top: 1px solid $sb-light-gray;
    border-top: 1px solid var(--sb-light-gray);

    &:focus,
    &:active {
      border-top: 1px solid $sb-secondary-default-color;
      border-top: 1px solid var(--sb-secondary-default-color);
    }

    &:hover {
      border-top: 1px solid $sb-light-gray;
      border-top: 1px solid var(--sb-light-gray);
    }

    &.with-border:hover {
      border: 1px solid $sb-secondary-default-border;
      border: 1px solid var(--sb-secondary-default-border);
    }
  }

  .retry-button {
    width: 100% !important;

    .secondary-btn {
      width: 100% !important;
      border: 1px solid $sb-light-gray;
      border: 1px solid var(--sb-light-gray);
      font-weight: 500;
    }
  }

  .upgrade-confirmation-modal {
    .test-invest {
      border-radius: 8px;
    }

    .secondary-btn,
    .secondary-btn-full {
      border: 1px solid $sb-secondary-default-border !important;
      border: 1px solid var(--sb-secondary-default-border) !important;
      border-radius: 8px;
    }
  }
}

.default-text-transform {
  text-transform: none !important;
}
