.skyLight-stp-modal {
  .skylight-dialog {
    left: 0% !important;
    width: 100% !important;
    top: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    min-height: 100% !important;
  }

  .stp-modal-outer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .stp-modal-inner {
    position: relative;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: none;
  }

  .stp-process-steps div.card-with-placeholder-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $custom-color-91;
    background: var(--custom-color-91);
    border-radius: 30px;
    width: 60px;
    height: 60px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .skyLight-stp-modal {
    .skylight-dialog {
      left: 15% !important;
      width: 70% !important;
      top: 10% !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      min-height: 500px !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .skyLight-stp-modal {
    .skylight-dialog {
      width: 70% !important;
      top: 10% !important;
      min-height: 600px !important;
      max-width: 500px !important;
      margin: 0% 10% 10% !important;
    }
  }
}
