@import '../../../../styles/utils/colors';

.modal-overlay-div {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: $sb-black-30;
  background-color: var(--sb-black-30);
  &.dark-mode {
    background-color: $sb-black-75;
    background-color: var(--sb-black-75);
  }
}

.modal-content-div {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10000;
  text-align: left;
  cursor: pointer;

  &.hideContent {
    display: none;
  }
}

.offering-dashboard-manage-funds.modal-dialog-div {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.mobile-help-desk.modal-dialog-div {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  padding: 0;
}

.account-family-investors.modal-dialog-div {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  padding: 0px;
}

.modal-content-div::after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: '';
  vertical-align: middle;
}

.modal-dialog-div {
  position: relative;
  outline: 0;
  width: auto;
  display: inline-block;
  box-sizing: border-box;
  cursor: default;
  background-color: $sb-white;
  background-color: var(--sb-white);
  vertical-align: middle;
  max-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  &.center-modal {
    border-radius: 2px;
  }

  &.full-screen {
    margin: 0;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}


.modal-header {
  display: flex;
  align-items: center;

  .modal-title {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .modal-close-icon {
    display: flex;
    align-self: flex-start;
   
    i {   
      font-size: 24px;
      color: rgba(0, 0, 0, 0.3);
    }

    &.floating-icon {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

.modal-children {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.bottom-to-top {
  -webkit-animation-name: slideToTop;
  animation-name: slideToTop;
  overflow: hidden;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideToTop {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.right-to-left {
  -webkit-animation-name: slideToTop;
  animation-name: slideToLeft;
  overflow: hidden;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.left-to-right {
  -webkit-animation-name: slideToRight;
  animation-name: slideToRight;
  overflow: hidden;
  animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideToRight {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideToTop {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media only screen and (min-width: 420px) {
  .modal-dialog-div {
    .modal-header {
      padding: 24px;

      .floating-icon {
        right: 30px;
      }
    }

    .modal-children{ 
      padding: 0 24px;
      margin-bottom: 24px;
    }
  }
  
  .offering-dashboard-manage-funds.modal-dialog-div {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  .mobile-help-desk.modal-dialog-div {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    padding: 0;
  }
}

@media only screen and (max-width: 560px) {
  @-webkit-keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .modal-dialog-div {
    vertical-align: bottom;
    width: 100%;
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    overflow: hidden;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &.center-modal {
      vertical-align: middle;
    }

    .modal-header{
      padding: 16px;
    }

    .modal-children {
      padding: 0 16px 16px;
    }
  }

  .offering-dashboard-manage-funds.modal-dialog-div {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  .mobile-help-desk.modal-dialog-div {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    padding: 0;
  }

  .modal-overlay-div {
    align-items: end;
  }

  .modal-content-div {
    &.center-modal {
      top: 0px;
      padding: 0;
    }
  }

  .mobile-help-desk-content {
    top: 0%;
  }

  .modal-children {
    animation: 0.6s fade-in;
    animation-timing-function: ease-in-out;

    .btn-custom {
      width: 100% !important;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  .rounded-top-mobile {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

@media only screen and (max-width: 991px) {
  .modal-content-div {
    &.start-from-top-on-mobile {
      top: 0%;
    }
  }
  .offering-dashboard-manage-funds.modal-dialog-div {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;

    .stooltip-list-item {
      padding-top: 26px;
      padding-bottom: 26px;
      padding-left: 32px;
      border-bottom: 1px solid $sb-black-10;
      border-bottom: 1px solid var(--sb-black-10);
    }
  }
  .mobile-help-desk.modal-dialog-div {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    padding: 0;
  }
}
@media only screen and (min-width: 993px) {
  .modal-dialog-div {
    max-width: 650px;
    border-radius: 5px;
    // padding: 0;
    // margin-top: 0;
  }
  
  #linked_bank_modal {
    .modal-dialog-div {
      max-width: 374px;
      border-radius: 5px;
    }
  }

  #personalised_portfolio_modal {
    .modal-dialog-div {
      width: 528px;
      border-radius: 5px;
    }

    .modal-close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .offering-dashboard-manage-funds.modal-dialog-div {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mobile-help-desk.modal-dialog-div {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    padding: 0;
  }

  .account-family-investors.modal-dialog-div {
    border-radius: 12px !important;
    padding: 0;
  }

  .modal-dialog-div.increment-sip-modal {
    max-width: 500px;
  }

  // From 300 to 800
  @for $i from 0 through 10 {
    $value: 300 + $i * 50;
    .modal-dialog-div.desktop-width-#{$value} {
      width: #{$value}px;
    }
    .modal-dialog-div.desktop-max-width-#{$value} {
      max-width: #{$value}px;
    }
    .modal-dialog-div.desktop-min-width-#{$value} {
      min-width: #{$value}px;
    }
  }
}

@media only screen and (max-width: 992px) and (min-width: 560px) {
  .modal-dialog-div {
    margin: 0 auto;
    width: 80%;
  }
}

.modal-heading {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  padding-top: 15px;
  color: $sb-black;
  color: var(--sb-black);
}

.modal-description {
  font-size: 17px;
  color: $sb-checklist-gray;
  color: var(--sb-checklist-gray);
  padding-bottom: 30px;
}
