#relation_capture_modal {

  .drop-drown-width {
    width: 42%;
    position: relative;
  }
  @media only screen and (min-width: 993px) {
    .modal-dialog-div {
      width: 550px;
      border-radius: 5px;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 560px) {
    .modal-dialog-div {
      margin: 0 auto;
      width: 59%;
    }
  }

  @media only screen and (min-width: 993px) {
    select.browser-default {
      line-height: 20px;
      border-radius: 5px;
  }
}

  select.browser-default {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $sb-black-86;
    color: var(--sb-black-86);
    border-radius: 5px;
}
  

 
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .invest-more__type-title-row {
    margin-top: 10px;

    .invest-more__type-title {
      font-size: 20px;
      color: $sb-black;
      color: var(--sb-black);
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
  }

  .invest-more__type-container {
    padding: 0 12px 0 12px;
    margin-bottom: 20px;

    .invest-more__investment-type {
      padding: 0;

      .invest-more__radio {
        margin: 0 auto;
        padding: 0;

        .radio-input-label::before,
        .radio-input-label::after {
          display: none;
        }

        .radio-input-label {
          text-align: center;
          border: 1px solid;
          border-radius: 5px;
          width: 144px;
          min-height: 104px;
          padding: 12px 10px;
          font-style: normal;
          letter-spacing: 0px;
          line-height: 18px;
          font-size: 12px;
          display: block;
        }

        [type="radio"]:checked + label {
          border-color: $sb-blue-link;
          border-color: var(--sb-blue-link);
          background-color: $sb-ui-blue-light-2;
          background-color: var(--sb-ui-blue-light-2);
          color: $sb-white-fixed;
          color: var(--sb-white-fixed);
          font-weight: 700;
          color: rgba(0, 0, 0, 0.86);
        }

        [type="radio"]:not(:checked) + label {
          border-color: $sb-grey-88;
          border-color: var(--sb-grey-88);
          color: $sb-white;
          color: var(--sb-white);
          font-weight: normal;
          color: rgba(0, 0, 0, 0.5);
          opacity: 4;
        }
      }
    }
  }
    .dropdownContentWrapper .list {
      overflow-y: scroll;
      position: fixed;
      z-index: 200000;
      background: #ffffff;
      box-shadow: 0px -2px 4px var(--sb-black-10), 2.5px 2px 4px var(--sb-black-10), -2.5px 2px 4px var(--sb-black-10);
      border-radius: 5px;
      min-width: 210px;

    }

    @media only screen and (min-width: 993px) {
      .sb-avatar.app-bar__avatar {
        align-items: center;
        background-color: #f58024;
        background-color: var(--sb-orange);
        color: #ffffff;
        color: var(--sb-white);
        display: inline-flex;
        justify-content: center;
        line-height: 32px !important;
        font-size: 14px !important;
    }
}
}