@import "../../../../styles/utils/colors";

.stab-container {
    display: flex;
    align-items: center;
    background-color: transparent;

    .stab {
        flex: 1;
        text-align: center;
        padding: 10px 15px;
        border-bottom: 2px solid $custom-color-38;
        border-bottom: 2px solid var(--custom-color-38);
        
        &.active-tab {
            border-bottom: 2px solid $sb-blue-link;
            border-bottom: 2px solid var(--sb-blue-link);

            &.rounded-active-tab {
                border: 1px solid $sb-blue-link;
                border: 1px solid var(--sb-blue-link);
                background-color: var(--sb-ui-blue-light-2);
                &:hover {
                    border-bottom: 1px solid $sb-blue-link;
                    border-bottom: 1px solid var(--sb-blue-link);
                }
            }

            &:hover {
            border-bottom: 2px solid $sb-blue-link;
            border-bottom: 2px solid var(--sb-blue-link);
        }
        }

        &.stab-xlarge {
            max-height: 66px;
            padding-bottom: 23px;
            padding-top: 22px;
            
            &.sub-title {
                padding-bottom: 12px;
                padding-top: 12px;
            }
        }
        &.stab-round-size {
            max-height: 52px;
            padding-bottom: 16px;
            padding-top: 16px;
            
            &.sub-title {
                padding-bottom: 12px;
                padding-top: 12px;
            }
        }

        &.stab-large {
            max-height: 60px;
            padding-bottom: 20px;
            padding-top: 20px;
            
            &.sub-title {
                padding-bottom: 10px;
                padding-top: 10px;
            }
        }
    
        &.stab-medium {
            max-height: 44px;
            padding-bottom: 14px;
            padding-top: 14px;
        }
    
        &.stab-small {
            max-height: 32px;
            padding-bottom: 6px;
            padding-top: 6px;
        }

        &:hover {
            border-bottom: 2px solid $sb-blue-link-36;
            border-bottom: 2px solid var(--sb-blue-link-36);
        }
    }
    .new-stab{
        border: 1px solid $sb-grey-88;
        border: 1px solid var(--sb-grey-88);
        &:hover {
            border: 1px solid $sb-blue-link-36;
            border: 1px solid var(--sb-blue-link-36);
        }
        &:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}
